import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-picklist-standardization',
  templateUrl: './picklist-standardization.component.html',
  styleUrls: ['./picklist-standardization.component.css']
})
export class PicklistStandardizationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
