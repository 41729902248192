
<div class="sticky-top">

</div>


<app-new-filters [reportHeader]="'Productivity Report'" (onfilter)="OnFilter($event)">

</app-new-filters>
<div class="page-body blur">

  <div class="container-xl container-sm container-md">
    <!--Widget first row-->
    <div class="row">
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Shift days</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.ShiftDays | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">No of days worked</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.NoOfDaysWorked | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">No of late days worked</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.NoOfLateDaysWorked | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Avg case count per provider and day</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.AvgCaseCountPerProvider | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Avg late day cases per provider, day</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.AvgLateDayCases | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Avg shift hours per provider and day</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.AvgShiftHoursPerProvider | number}}</div>
        </div>
      </div>
    </div>


    <!--Widget second row-->
    <div class="row">
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Total case hours</h4>
          </div>
          <div class="text row2-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.TotalCaseHours | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Total solo case hours</h4>
          </div>
          <div class="text row2-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.TotalSoloCaseHours | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Total prime hours</h4>
          </div>
          <div class="text row2-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.TotalPrimeHours | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Total non prime hours</h4>
          </div>
          <div class="text row2-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.TotalNonPrimeHours | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">
              Avg prime hours per provider
            </h4>
          </div>
          <div class="text row2-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.AvgPrimeHoursPerProvider | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Avg non prime hours per provider</h4>
          </div>
          <div class="text row2-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.AvgNonPrimeHoursPerProvider | number}}</div>
        </div>
      </div>
    </div>


    <div class="row">
      <!--Line chart-->
      <div class="col-6">
        <div class="chart-container" style="height:300px;">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Avg Case Hours per Provider and Day by Week</h4>
          </div>


          <div style="height: 265px;">
            <div style="padding-left:10px;padding-top:10px">
              <img src="./assets/quantityp.svg" height="10" width="10"> Prime <img src="./assets/forecastp.svg" height="10" width="10"> Non Prime
            </div>
            <div *ngIf="_lineConfig">
              <d3-line [config]="_lineConfig">
                <div id="linecontainer" style="width:100%;height:95%"></div>
              </d3-line>
            </div>
          </div>
        </div>
      </div>

      <!--Bar chart -->
      <div class="col-6">
        <div class="chart-container" style="height:300px;">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Avg Case Hours per Day by Provider</h4>
          </div>


          <div style="padding-left:10px;padding-top:10px">
            <span style="height: 70px; width: 70px; background-color: #269ffb; border-radius: 10px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> &nbsp;Prime&nbsp;
            <span style="height:70px;width:70px;background-color:#26e7a5;border-radius:10px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> &nbsp;Non Prime
          </div>

          <div style="height: 235px; overflow-y: auto ">
            <div *ngIf="_barConfig">
              <d3-h-bar-stacked [config]="_barConfig" (barclick)="BarBulkSelected($event)" (deselectbar)="DeselectBar()">
                <div id="barcontainer" style="width:95%;"></div>
              </d3-h-bar-stacked>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="card" style="max-height: calc(100vh - 260px);">
        <div class="card-body-scrollable card-body-scrollable-shadow">


          <table class="casemixgraphic fixTableHead ">


            <thead>
              <tr>
                <th>Provider Name</th>
                <th>No of cases</th>
                <th>No of days worked</th>
                <th>Avg Case count per day</th>
                <th>No of late days worked</th>
                <th>Avg late case count per day</th>
                <th>Total case hours</th>
                <th>Total solo case hours</th>
                <th>Total prime case hours</th>
              </tr>
            </thead>
            <caption *ngIf="tabularData!=undefined && tabularData.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
            <tbody>
              <tr *ngFor="let c of tabularData">
                <td>{{c.Providername}}</td>
                <td>{{c.NoOfCases}}</td>
                <td>{{c.NoOfDaysWorked}}</td>
                <td>{{c.AvgCaseCount}}</td>
                <td>{{c.NoOfLateDaysWorked}}</td>
                <td>{{c.AvgLateDayCases}}</td>
                <td>{{c.TotalCaseHours  | number : '1.2-2'}}</td>
                <td>{{c.TotalSoloCaseHours}}</td>
                <td>{{c.TotalNonPrimeCaseHours  | number : '1.2-2'}}</td>
              </tr>


            </tbody>

          </table>


        </div>
      </div>
    </div>
  </div>
</div>
