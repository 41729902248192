import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {

  constructor(private route: ActivatedRoute, private commonService: CommonService, private router: Router) {

  }

  ngOnInit(): void {
    //setTimeout(() => {
    //  if (!sessionStorage.getItem("userlogin")) {
    //    location.reload();
    //  }
    //}, 10000)
    this.route.queryParams
      .subscribe(params => {
        sessionStorage.setItem("token", params.token);
        sessionStorage.setItem("userlogin", params.userlogin);

      });

    //this.route.queryParams
    //  .subscribe(params => {
    //    sessionStorage.setItem("token", params.token);
    //    sessionStorage.setItem("userlogin", params.userlogin);
    //    window.location.href = "/";
    //  }
    //  );
  }

}
