import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class Mean {

  private config: any = {
    height: 140,
    barwidth: 60,
    margin_left: 15,
    margin_right: 40,
    margin_top: 20,
    margin_bottom: 20,
    units_label: "Mean"
  };

  private width: any = undefined;
  private max: any = undefined;
  private x: any = undefined;
  private x1: any = undefined;
  private y: any = undefined;
  private svg: any = undefined;

  private barcolors: any = ["#bbc3ce", "#8a95a3"];

  private container: string;
  private meansgraphdata: any;
  private configuration: any;

  constructor(container: string, meansgraphdata: any, configuration: any) {
    this.container = container;
    this.meansgraphdata = meansgraphdata;

    //if (this.meansgraphdata.length < 2) {
    //  let ob = JSON.parse(JSON.stringify(this.meansgraphdata[0]));
    //  ob.Outcome = "None";
    //  ob.Metric = "None";
    //  this.meansgraphdata.push(ob);
    //}

    this.configuration = configuration;
    this.configure(this.configuration);
  }


  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    // this.width = this.config.barwidth * Object.keys(this.meansgraphdata).length + this.config.margin_left + this.config.margin_right;
    this.width = this.config.barwidth * 2 + this.config.margin_left + this.config.margin_right;

    //determine the max mean + std dev for the dates to display        
    this.max = d3.max(this.meansgraphdata.map((d) => { return d.Mean + d.StdDev; }));


    let domain = this.meansgraphdata.map((d) => { return d.Metric; });
    if (domain.length == 1)
      domain.push("");
    //map each metric location on the x-axis
    this.x = d3.scaleBand()
      .rangeRound([this.config.margin_left, this.width - this.config.margin_right])
      .domain(this.meansgraphdata.map((d) => { return d.Metric; }))
      .paddingOuter(0.2)
      .paddingInner(0.6);

    //map each metric location on the x-axis
    this.x1 = d3.scaleBand()
      .rangeRound([this.config.margin_left, this.width - this.config.margin_right])
      .domain(domain)
      .paddingOuter(0.2)
      .paddingInner(0.6);

    //scale mean to y-axis
    this.y = d3.scaleLinear()
      .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
      .domain([0, this.max]);
  }

  Render() {
    this.draw();
  }
  private draw() {

    //create svg to to display bars
    this.svg = d3.select(this.container)
      .append("div")
      .classed("meangraphcontainer", true)
      .append('svg:svg')
      .attr('viewBox', "0 0 " + this.width + " " + this.config.height)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    //append the x-axis for metric labels
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x));

    //draw the title for y-axis
    this.svg.append("text")
      .attr("class", "roomusebyhourtext")
      .attr("text-anchor", "middle")
      .attr("transform", "translate(10," + ((this.config.height - this.config.margin_bottom) / 2) + ")rotate(-90)")
      .text(this.config.unitslabel);

    //draw bars for mean values
    this.svg.append("g")
      .selectAll("rect")
      .data(this.meansgraphdata)
      .enter().append("rect")
      .attr("fill", (d, i) => { return this.barcolors[i]; })
      .attr("x", (d) => { return this.x(d.Metric) + (this.meansgraphdata.length < 2 ? (this.x1.bandwidth() / 2)-3 : 0); })
      .attr("width", this.x1.bandwidth())
      .attr("y", this.config.height - this.config.margin_bottom)
      .attr("height", 0)
      .transition()
      .duration(1500)
      .attr("y", (d) => { return this.y(d.Mean); })
      .attr("height", (d) => { return this.config.height - this.config.margin_bottom - this.y(d.Mean); });

    //draw line to indicate std dev
    this.svg.append("g")
      .selectAll("line")
      .data(this.meansgraphdata)
      .enter().append("line")
      .attr("stroke", "#3c3f42")
      .attr("stroke-linecap", "butt")
      .attr("stroke-width", 1)
      .attr("x1", (d) => { return this.x(d.Metric) + this.x.bandwidth() / 2; })
      .attr("x2", (d) => { return this.x(d.Metric) + this.x.bandwidth() / 2; })
      .attr("y1", (d) => { return this.y(d.Mean + d.StdDev); })
      .attr("y2", (d) => { return this.y(d.Mean + d.StdDev); })
      .transition()
      .duration(1500)
      .attr("y2", (d) => { return this.y(d.Mean); });

    this.svg.append("g")
      .selectAll("line")
      .data(this.meansgraphdata)
      .enter().append("line")
      .attr("stroke", "#3c3f42")
      .attr("stroke-linecap", "butt")
      .attr("stroke-width", 1)
      .attr("x1", (d) => { return this.x(d.Metric) + this.x.bandwidth() / 2 - 5; })
      .attr("x2", (d) => { return this.x(d.Metric) + this.x.bandwidth() / 2 + 5; })
      .attr("y1", (d) => { return this.y(d.Mean + d.StdDev); })
      .attr("y2", (d) => { return this.y(d.Mean + d.StdDev); });

    //draw labels for mean values
    this.svg.append("g")
      .selectAll("text")
      .data(this.meansgraphdata)
      .enter().append("text")
      .attr("class", "countsgraphtext")
      .style("text-anchor", "left")
      .attr("x", (d) => { return this.x(d.Metric) + this.x.bandwidth() / 2 + 4; })
      .attr("y", (d) => { return this.y(d.Mean) - 10; })
      .text((d) => { return d.Mean + ' \xB1 ' + d.StdDev; })

  }

  public update(meansgraphdata: any, newConfiguration: any) {

    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }

    this.meansgraphdata = meansgraphdata;
    d3.select("" + this.container).select("svg").remove();
    this.draw();
  }
}
