<button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modal-confirm-download">
  Download Excel
</button>


<button id="openModalButton" [hidden]="true" data-bs-toggle="modal" data-bs-target="#modal-confirm-download">Open Modal</button>
<div class="modal fade" id="modal-confirm-download" tabindex="-1" 
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="width: 500px;height:50px;overflow-y:auto">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Data download attestation</h5>
        <button type="button" class="btn-close" id="closeModalButton" data-bs-dismiss="modal" aria-label="Close" (click)=" isAgreed = false;"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;">
        <div class="card">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div style="padding: 7px;">
              <div>
                <p>
                  Raw data elements from ORBIS contain ePHI and downloading them requires acknowledgement of responsibility for correct use.
                </p>
                <p>
                  By downloading, you confirm that you will take appropriate precautions to avoid misuse, including but not limited to:
                </p>
                <div>
                  <ul>
                    <li>Avoiding distribution of elements of ePHI from this file</li>
                    <li>Compliance with Institutional standards for device security on any device to which a file is downloaded or retained</li>
                    <li>Avoiding sharing images or screens of the file directly with ePHI elements left unobscured</li>
                  </ul>
                </div>
              </div>
              <hr style="margin:3px;" />
              <div style="text-align: center;font-weight:bold;font-size:19px;">
                <input style="zoom: 1.5; border: 2px solid gray;" class="form-check-input" type="checkbox" [(ngModel)]="isAgreed">&nbsp;<span style="top:4px;position:relative;">Agree</span>   <br />  <br />
                <button type="button" class="btn btn-link" [disabled]="!isAgreed" (click)="AgreeAndDownload()">
                  Download
                </button>
                <button type="button" class="btn btn-link" [disabled]="!isAgreed" (click)="AgreeAndSendEmail()">
                  Email
                </button>
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="modal-password" tabindex="-1" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Set downloaded file password</h5>

        <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="text-align:center;">
        <!--<input type="password" [(ngModel)]="password" placeholder="Excel encryption password" style="width:250px;height:33px;" />&nbsp;&nbsp;-->
        <button type="button" class="btn btn btn-link" (click)="Download()">
          Download
        </button>
        <button type="button" class="btn btn btn-link" (click)="SendEmail()">
          Email
        </button>
      </div>
    </div>
  </div>
</div>
