import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bar } from '../Bar';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.css']
})
export class BarComponent implements OnInit {

  @Input() container: string = ''
  @Input() barData: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<Bar> = new EventEmitter();

  bar!: Bar;

  constructor() {
    
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.bar = new Bar(this.container, this.barData, this.configuration);
    this.bar.Render();
    this.myOutput.emit(this.bar);
  }
}
