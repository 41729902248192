import * as d3 from "d3";
import { LineConfig, LineModel, LineValues, MarkerStyle } from "../models/linemodel";

export class LineChart {

  private _svg: any;
  private _xscale: any;
  private _yscale: any;
  private _xscalecopy: any;
  private _yscalecopy: any;
  private _xaxis: any;
  private _yaxis: any;
  private _colorscale: any;
  private _strokewidthscale: any;
  private _markerfillscale: any;
  private _markerstrokescale: any;
  private _brush: any;
  private _zoom: any;
  private _paths: any;
  private _bisectDate: any;
  private _zoomlevel: number = 1;
  private _focus: any;
  private _tooltip: any;
  private _tooltipX: any;
  private _leftMove: number = 0;
  private _config: LineConfig = new LineConfig();
  lineclick: any;
  singlelineclick: any;
  private isLineSelected: boolean = false;
  deselectline: any;

  get config(): LineConfig {
    return this._config;
  }
  set config(value: LineConfig) {
    this._config = value;
  }

  contructor() {
  }

  private ClearCharts() {

    //let _width = (this._config.width - (this._config.margin_left + this._config.margin_right));
    //let _height = (this._config.height - (this._config.margin_top + this._config.margin_bottom));

    d3.select(this._config.container).selectAll("*").remove();
    this._svg = d3.select(this._config.container).append("svg")
      .on("click", () => {
        if (!this.isLineSelected) {
          this._svg.selectAll(".lines").style("opacity", 1);
          if (this.deselectline) {
            this.deselectline();
          }
        }
        this.isLineSelected = false;
      })
      //.attr('viewBox', "0 0 " + this._config.width + " " + this._config.height)
      //.attr('preserveAspectRatio', "xMinYMin meet")
      .attr("width", this._config.width)
      .attr("height", this._config.height)
      .append("g").attr("class", "main")
      .attr("transform", `translate(${this._config.margin_left},${this._config.margin_top})`);

    this._tooltip = d3.select(this._config.container)
      .append("div")
      .attr("class", "d3-tooltip-new")
      .style("display", "none")
      .style("opacity", 0)
      .style("position", "absolute")
    //.style("font-weight", "bold")
    //.style("padding", "10px")
    //.style("background", "rgba(0, 0, 0, 0.8)")
    //.style("color", "#fff")
    //.style("border-radius", "2px");


  }
  private InitiateTooltip() {


    this._tooltipX = this._svg
      .append("g")
      // .append("text")
      .attr("class", "d3-tooltip-line")
      //.attr("transform", `translate(${100},${200})`)
      //.style("display", "none")
      .style("opacity", 0)
    //.style("position", "absolute")
    //  .style("font-weight", "bold")
    //   .style("padding", "10px")
    //    .style("background", "lightgray")
    //    .style("color", "gray")
    //     .style("border-radius", "2px");

    this._tooltipX.append("rect")
      .attr("x", 0)
      .attr("y", -10)
      .attr("height", 30)
      .style("stroke", "lightgray")
      .style("stroke-width", "1px")
      .style("fill", "lightgray");
    this._tooltipX.append("text")
      .attr("x", 2)
      .attr("y", 10)
      .style("font-weight", "bold")
      .style("fill", "gray");
  }
  private ConfigureScales() {


    this._bisectDate = d3.bisector((d: LineValues) => { return d.xaxis; }).center;

    /************** Creating X SCale ***********/
    let newArray = Array.prototype.concat.apply([], this._config.data.map((d: any) => { return d.values; }));

    let ext = d3.extent(newArray, function (d: any) { return (d as LineValues).xaxis; }) as Array<any>;

    if (this._config.xaxistype == "number") {
      this._xscale = d3.scaleLinear().domain(ext)
        .range([0, this._config.width - this._config.margin_left - this._config.margin_right]);

    }
    else if (this._config.xaxistype == "datetime") {
      this._xscale = d3.scaleTime().domain(ext)
        .range([0, this._config.width - this._config.margin_left - this._config.margin_right]);
    }
    else if (this._config.xaxistype == "string") {
      let domain = newArray.map(m => m.xaxis).filter(function (value: any, index: any, self: any) {
        return self.indexOf(value) === index;
      });
      //domain.sort(function (a, b) {
      //  if (a && b) {
      //    let xa = a.split('-');
      //    let xb = b.split('-');
      //    return parseInt(xa[0] + (parseInt(xa[1]) < 10 ? "0" + xa[1] : xa[1])) - parseInt(xb[0] + (parseInt(xb[1]) < 10 ? "0" + xb[1] : xb[1]));
      //  }
      //});

      this._xscale = d3.scaleBand()
        .domain(domain)
        .range([0, this._config.width - this._config.margin_left - this._config.margin_right]);
    }
    else {
      this._xscale = d3.scaleLog().domain(ext)
        .range([0, this._config.width - this._config.margin_left - this._config.margin_right]);
    }


    /************** Creating Y SCale ***********/

    ext = d3.extent(newArray, function (d: any) { return (d as LineValues).value; }) as Array<number>;
    //let ext1 = d3.extent(newArray, function (d: any) { return (d as LineValues).y1; }) as Array<any>;
    this._yscale = d3.scaleLinear()
      .domain(ext)
      //.domain([0, ext1[1] > ext[1] ? ext1[1] : ext[1]])
      .range([this._config.height - this._config.margin_top - this._config.margin_bottom, 0]);

    /************** Creating Color SCale ***********/

    this._colorscale = d3.scaleOrdinal()
      .domain(this._config.data.map((m: any) => m.key))
      .range(this._config.data.map((m: LineModel) => m.linestyle.color));
    //.range(this._config. linestyle.map((m: any) => m.color));

    this._markerstrokescale = d3.scaleOrdinal()
      .domain(this._config.data.map((m: any) => m.key))
      .range(this._config.data.map((m: LineModel) => m.markerstyle.stroke));
    //.range(this._config.markerstyle.map((m: any) => m.stroke));

    this._markerfillscale = d3.scaleOrdinal()
      .domain(this._config.data.map((m: any) => m.key))
      .range(this._config.data.map((m: LineModel) => m.markerstyle.fill));
    //.range(this._config.markerstyle.map((m: any) => m.fill));

    //alert(this._config.data.map((m: any) => m.key))

    this._strokewidthscale = d3.scaleOrdinal()
      .domain(this._config.data.map((m: any) => m.key))
      .range(this._config.data.map((m: LineModel) => m.linestyle.width));
    //.range(this._config.linestyle.map(m => m.width));




    /************** Copy SCale ***********/
    this._xscalecopy = this._xscale.copy();
    this._yscalecopy = this._yscale.copy();

    /************** Creating brush ***********/
    //this._brush = d3.brushX()
    //  .extent([[0, 0], [this._config.width - this._config.margin_left - this._config.margin_right, this._config.height - this._config.margin_top - this._config.margin_bottom - 1]])
    //  .on("end", (event: any) => {
    //    if (event.selection) {
    //      if (this._config.xaxistype != "string") {
    //        this.UpdateChart(event);
    //      }
    //      else {
    //        let domain = this._xscale.domain()
    //        this._xscale.invert = (() => {

    //          let range = this._xscale.range()
    //          let scale = d3.scaleQuantize().domain(range).range(domain)

    //          return function (x: any) {
    //            return scale(x)
    //          }
    //        })();
    //        let st = domain.indexOf(this._xscale.invert(event.selection[0]));
    //        let en = domain.indexOf(this._xscale.invert(event.selection[1]));
    //       // this._config.lineselection(domain.filter((d: string, i: number) => { return i >= st && i <= en }));
    //      }
    //      this._svg.select(".paths").call(this._brush.move, null);
    //    }
    //  });
    /************** Creating Zoom ***********/
    let extent = [
      [0, this._config.margin_top],
      [this._config.width - this._config.margin_left - this._config.margin_right, this._config.height - this._config.margin_top - this._config.margin_bottom]
    ];

    this._zoom = d3.zoom()
      .scaleExtent([1, Infinity])
      //.translateExtent(extent as any)
      .extent(extent as any)
      .on("zoom", (event: any) => {
        this._svg.select(".shapes").remove();
        this._focus.style("opacity", 0);
        let xsc = event.transform.rescaleX(this._xscale);
        let ysc = event.transform.rescaleY(this._yscale);

        this._xscalecopy = xsc.copy();
        this._yscalecopy = ysc.copy();
        //this._svg.select(".x-axis").Remove();
        // this._svg.select(".x-axis").remove();

        this._xaxis.call(d3.axisBottom(xsc).ticks(5))

          .selectAll("text")
          .attr("y", 15)
          .attr("x", 9)
          .attr("dy", ".35em")
          // .style("fill", "red")
          .attr("transform", "rotate(30)")
          .style("text-anchor", "start");

        this._yaxis//.call(d3.axisLeft(ysc));
          .call(make_y_gridlines(ysc, this.config.tick_size_y)
            .tickSize(-this._config.width + (this._config.margin_right + this._config.margin_left))
            .tickFormat(null)
          )

        /*********************** Grid lines Rect ***************/
        this._yaxis.selectAll("g").selectAll(".gridline-rect").remove();
        let f = this._yaxis.selectAll("g")._groups[0]//.select("line");
        let r = d3.select(f[0]).attr("transform").split(',')[1].replace(")", "");
        let r1 = d3.select(f[1]).attr("transform").split(',')[1].replace(")", "");
        let h = parseInt(r) - parseInt(r1)

        for (var i = 0; i < f.length; i++) {
          if (i % 2 == 1) {
            let g = d3.select(f[i]);
            let w = g.select("line").attr("x2");
            g.append("rect").attr("class", "gridline-rect").attr("width", w).attr("height", h).style("fill", "lightgray")
              // .attr("transform", g.attr("transform"))
              .style("opacity", 0.1)
          }

        }


        /*********************** Create Grid Lines ***************/
        this._svg.selectAll(".tick").selectAll("line").style("opacity", 0.1);//.style("stroke","red");
        this._svg.selectAll(".domain").style("opacity", 0);

        function make_y_gridlines(y: any, tick: number) {
          return d3.axisLeft(y)
            .ticks(tick)
        }

        this._paths.attr("d", (d: LineModel, i: number) => {

          let line = d3.line()
            .x((d1: any) => { return xsc((d1 as LineValues).xaxis) })
            .y((d1: any) => { return ysc((d1 as LineValues).value) });


          //line = this.SetLineStyle(line, this._config.linestyle[i].style);
          line = this.SetLineStyle(line, d.linestyle.style);
          return line(d.values as any);
        });
        this._svg.select(".shapes").remove();
        this.DrawShapes(xsc, ysc);



      });
  }

  private DrawAxisTexts() {
    let axisTexts = this._svg.attr("class", "axistexts").attr("font-weight", "bold");

    axisTexts.append("g")
      .attr("transform", `translate(${(this._config.width - this._config.margin_left - this._config.margin_right) / 2},${this._config.height - 27})`)
      .append("text")
      .attr("text-anchor", "middle")
      .text(() => { return this._config.xlabel; });

    axisTexts.append("g")
      .attr("transform", `translate(${-this._config.margin_left + 27},${100})rotate(-90)`)
      .append("text")
      .attr("text-anchor", "middle")
      .text(() => { return this._config.ylabel; });

  }

  private DrawAxis() {
    /*********************** Create X axis ***************/
    this._xaxis = this._svg.append("g")
      .attr("class", "x-axis")
      // .attr("clip-path", "url(#clip-line)")
      .attr("clip-path", "url(#" + this._config.container.replace("#", "") + "-clip-axis)")
      .attr("transform", `translate(${this._leftMove},${this._config.height - this._config.margin_top - this._config.margin_bottom})`)
      .call(d3.axisBottom(this._xscale)
        .ticks(this.config.tick_size_x)
      );


    this._xaxis.selectAll("text")
      .attr("y", 15)
      .attr("x", 9)
      .attr("dy", ".35em")
      .attr("transform", "rotate(30)")
      .style("text-anchor", "start");





    /*********************** Create Y axis ***************/
    this._yaxis = this._svg.append("g")
      .attr("class", "y-axis")
      .attr("clip-path", "url(#" + this._config.container.replace("#", "") + "-clipy-line)")
      //.attr("clip-path", "url(#clipy-line)")
      //.call(d3.axisLeft(this._yscale));
      .call(make_y_gridlines(this._yscale, this.config.tick_size_y)
        .tickSize(-this._config.width + (this._config.margin_right + this._config.margin_left))
        .tickFormat(this._config.yaxisformat)
      )


    /*********************** Grid lines Rect ***************/
    this._yaxis.selectAll("g").selectAll(".gridline-rect").remove();
    let f = this._yaxis.selectAll("g")._groups[0]//.select("line");
    let r = d3.select(f[0]).attr("transform").split(',')[1].replace(")", "");
    let r1 = d3.select(f[1]).attr("transform").split(',')[1].replace(")", "");
    let h = parseInt(r) - parseInt(r1)

    for (var i = 0; i < f.length; i++) {
      if (i % 2 == 1) {
        let g = d3.select(f[i]);
        let w = g.select("line").attr("x2");
        g.append("rect").attr("class", "gridline-rect").attr("width", w).attr("height", h).style("fill", "lightgray")
          // .attr("transform", g.attr("transform"))
          .style("opacity", 0.1)
      }

    }




    //t._groups[0].forEach((f: any) => {
    //  let g = d3.select(f);
    //  let w=g.select("line").attr("x2");
    //  g.append("rect").attr("class","gridline-rect").attr("width", w).attr("height", 50).style("fill", "lightgray")
    //   // .attr("transform", g.attr("transform"))
    //    .style("opacity", 0.3)


    //})

    //.each((r: any) => {
    //console.log(  d3.select(r))//.select("line").style("stroke", "blue")
    //})


    //console.log(this._svg.selectAll(".tick").selectAll("line"))
    /***********************Hiding axis lines ***************/
    this._svg.selectAll(".tick").selectAll("line").style("opacity", 0.1);
    this._svg.selectAll(".domain").style("opacity", 0);

    //setTimeout(() => {
    //  console.log(d3.selectAll(".tick"))
    //  d3.selectAll(".tick")//.selectAll("line")
    //    .each((l: any) => {
    //      console.log(d3.select("line").attr("x1"))
    //    })
    //},2000)

    /*********************** Create Grid Lines ***************/
    function make_y_gridlines(y: any, tick: number) {
      return d3.axisLeft(y)
        .ticks(tick)
    }
    //this._svg.append("g")
    //  .attr("class", "grid")
    //  .call(make_y_gridlines(this._yscale)
    //    .tickSize(-this._config.width)
    //    .tickFormat(null)
    //  )
  }

  private AddText(data: LineModel, pathGroup: any, xscale: any, yscale: any) {
    let shapeGroup = pathGroup.append("g")
      .attr("class", "line-text")
      //.attr("clip-path", "url(#clip-line)");
      .attr("clip-path", "url(#" + this._config.container.replace("#", "") + "-clip-line)");

    shapeGroup.selectAll(".line-text-t")
      .data(data.values)
      .enter()
      .append("text")
      .attr("class", "line-text-t")

      .style("stroke-width", 1)
      .attr("transform", (d1: any) => {
        //console.log(yscale((d1 as LineValues).value))
        return `translate(${xscale((d1 as LineValues).xaxis) + this._leftMove},${yscale((d1 as LineValues).value) + 10})`;
      })
      .text((d: LineValues) => {
        return d.value;
      });
  }

  private AddCircle(data: LineModel, pathGroup: any, markerStyle: MarkerStyle, xscale: any, yscale: any) {
    let shapeGroup = pathGroup.append("g")
      .attr("class", "shapes")
      //.attr("clip-path", "url(#clip-line)");
      .attr("clip-path", "url(#" + this._config.container.replace("#", "") + "-clip-line)");

    shapeGroup.selectAll(".circle")
      .data(data.values)
      .enter()
      .append("circle")
      .attr("class", "circle")
      .attr("r", markerStyle?.size)
      .style("fill", this._markerfillscale(data.key))
      .style("stroke", this._markerstrokescale(data.key))
      .style("stroke-width", 1)
      .attr("transform", (d1: any) => {
        return `translate(${xscale((d1 as LineValues).xaxis) + this._leftMove},${yscale((d1 as LineValues).value)})`;
      });
  }
  private AddPlus(data: LineModel, pathGroup: any, markerStyle: MarkerStyle, xscale: any, yscale: any) {
    let shapeGroup = pathGroup.append("g")
      .attr("class", "shapes")
      //.attr("clip-path", "url(#clip-line)");
      .attr("clip-path", "url(#" + this._config.container.replace("#", "") + "-clip-line)");
    let st = markerStyle?.size / 10 * 3;
    let en = markerStyle?.size / 10 * 7;

    shapeGroup.selectAll(".plus")
      .data(data.values.filter(t => !t.isdummyvalue))
      .enter()
      .append("polyline")
      .attr("class", "plus")
      .attr("points", `${st},0 ${en},0 ${en},${st} ${markerStyle?.size},${st} ${markerStyle?.size},${en} ${en},${en} ${en},${markerStyle?.size} ${st},${markerStyle?.size} ${st},${en} 0,${en} 0,${st} ${st},${st} ${st},0`)
      .style("fill", this._markerfillscale(data.key))
      .style("stroke", this._markerstrokescale(data.key))
      .style("stroke-width", 1)
      .style("opacity", 1)
      .attr("transform", (d1: any) => {
        return `translate(${xscale((d1 as LineValues).xaxis) - (markerStyle?.size / 2) + this._leftMove},${yscale((d1 as LineValues).value) - (markerStyle?.size / 2)})`;
      });
  }

  private AddDiamond(data: LineModel, pathGroup: any, markerStyle: MarkerStyle, xscale: any, yscale: any) {
    let shapeGroup = pathGroup.append("g")
      .attr("class", "shapes")
      //.attr("clip-path", "url(#clip-line)");
      .attr("clip-path", "url(#" + this._config.container.replace("#", "") + "-clip-line)");

    let st = markerStyle?.size / 2;

    shapeGroup.selectAll(".diamond")
      .data(data.values.filter(f => !f.isdummyvalue))
      .enter()
      .append("polyline")
      .attr("class", "diamond")
      .attr("points", `${st},0 ${markerStyle?.size},${st} ${st},${markerStyle?.size} 0,${st} ${st},0`)
      .style("fill", this._markerfillscale(data.key))
      .style("stroke", this._markerstrokescale(data.key))
      .style("stroke-width", 1)
      .style("opacity", 1)
      .attr("transform", (d1: any) => {

        return `translate(${xscale((d1 as LineValues).xaxis) - (markerStyle?.size / 2) + this._leftMove},${yscale((d1 as LineValues).value) - (markerStyle?.size / 2)})`;
      });
  }

  private DrawLines() {
    /************** Set Clip Path ***************/

    let cli = this._svg.append("defs").append("clipPath")
      //.attr("id", "clip-line")
      .attr("id", this._config.container.replace("#", "") + "-clip-line")
      .append("rect")
      .attr("x", -7)
      .attr("y", -10)
      .attr("width", this._config.width - this._config.margin_left - this._config.margin_right + 20)
      .attr("height", this._config.height - this._config.margin_top - this._config.margin_bottom + 20);

    let cli1 = this._svg.append("defs").append("clipPath")
      //.attr("id", "clip-line")
      .attr("id", this._config.container.replace("#", "") + "-clip-axis")
      .append("rect")
      .attr("x", -7)
      .attr("y", -10)
      .attr("width", this._config.width - this._config.margin_left)
      .attr("height", this._config.height - this._config.margin_top - this._config.margin_bottom);

    if (this._config.xaxistype == "string") {
      cli.attr("x", -this._xscale.bandwidth() / 2);
    }
    this._svg.append("defs").append("clipPath")
      .attr("id", this._config.container.replace("#", "") + "-clipy-line")
      .append("rect")
      .attr("x", -this._config.margin_left)
      .attr("y", -10)
      /*.attr("width", this._config.width - this._config.margin_left - this._config.margin_right)*/
      .attr("width", this._config.width)
      .attr("height", this._config.height - this._config.margin_top - this._config.margin_bottom + 25);



    /************** Draw Lines ***************/
    this._svg.select(".paths").remove();
    let paths = this._svg.append("g").attr("class", "paths")
    if (this._config.xaxistype == "string") {
      paths.attr("transform", `translate(${this._xscale.bandwidth() / 2},0)`)
    }

    let pathgroups = paths.selectAll(".pathgroups")
      .data(this._config.data)
      .enter()
      .append("g")
      .attr("class", "pathgroups");

    this._paths = pathgroups.append("path")
      //.attr("clip-path", "url(#clip-line)")
      .attr("clip-path", "url(#" + this._config.container.replace("#", "") + "-clip-line)")
      .attr("class", "lines")
      .attr("id", (d1: LineModel) => {
        return this._config.container.replace("#", "") + d1.key.split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
      })
      .attr("fill", "none")
      .style("stroke", (c: LineModel) => { return this._colorscale(c.key) })
      .style("stroke-width", (c: LineModel) => {
        return this._strokewidthscale(c.key).toString() + "px"
      })
      .attr("d", (d: LineModel, i: number) => {

        let line = d3.line()
          .x((d1: any) => { return this._xscale((d1 as LineValues).xaxis) + this._leftMove })
          .y((d1: any) => { return this._yscale((d1 as LineValues).value) });


        //line = this.SetLineStyle(line, this._config.linestyle[i].style);
        line = this.SetLineStyle(line, d.linestyle.style);
        return line(d.values as any);
      })
      .on("click", (d: any, d1: LineModel) => {
        if (this.singlelineclick) {
          this.isLineSelected = true;
          this.ClickDisable(d1);
          this.singlelineclick(d1);
        }
      })
  }

  private SetLineStyle(line: any, style: string) {
    if (style == "curvestepafter") {
      line.curve(d3.curveStepAfter);
    }
    else if (style == "curvestep") {
      line.curve(d3.curveStep);
    }
    else if (style == "curvestepbefore") {
      line.curve(d3.curveStepBefore);
    }
    return line;
  }

  private DrawShapes(xscale: any, yscale: any) {
    let paths = this._svg.select(".paths");
    let pathgroups = paths.selectAll(".pathgroups");

    /************** Add Shapes ***************/
    pathgroups.each((d: LineModel, i: number) => {

      // let markerstyle = this._config.markerstyle[i];
      let markerstyle = d.markerstyle;
      if (markerstyle?.shape == "circle") {
        this.AddCircle(d, paths, markerstyle, xscale, yscale);
      }
      else if (markerstyle?.shape == "plus") {
        this.AddPlus(d, paths, markerstyle, xscale, yscale);
      }
      else if (markerstyle?.shape == "diamond") {
        this.AddDiamond(d, paths, markerstyle, xscale, yscale);
      }

    });

    /*********Adding Texts *********/
    this._svg.selectAll(".line-text").remove();
    if (this.config.is_line_text) {
      pathgroups.each((d: LineModel, i: number) => {
        this.AddText(d, paths, xscale, yscale);
      });
    }

  }

  public Render() {
    this.ClearCharts();
    this.ConfigureScales();
    this.DrawAxis();
    this.ToolTip(this._svg.append("rect"));
    this.DrawLines();

    this.DrawShapes(this._xscale, this._yscale);
    this.DrawAxisTexts();

    this._focus = this._svg.append("g").style("opacity", 0);
    this.InitiateTooltip();

    /************** Call Brush ***************/
    if (this._config.zoomtype == "brush") {
      let paths = this._svg.select(".paths");
      paths.call(this._brush);

      this.ToolTip(this._svg.select(".overlay"));
    }
    else if (this._config.zoomtype == "zoom") {
      let svg = d3.select(this._config.container).select("svg");
      svg.call(this._zoom);

    }

  }

  private ToolTip(rect: any) {

    rect
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this._config.width - this._config.margin_left - this._config.margin_right)
      .attr("height", this._config.height - this._config.margin_top - this._config.margin_bottom)
      .style("fill", "lightgray")
      .style("opacity", 0)
      .on("mouseover", (d: any) => {
        this._focus.style("opacity", 1);
        this._tooltip.style("display", "block").style("opacity", 1);
        this._tooltipX.style("display", "block").style("opacity", 1);
      })
      .on("click", (d: any) => {
        if (this._config.xaxistype == "string") {
          let val = this.StringTooltip();
          this.lineclick(val);
        }
        else {
          let val = this.DateNumberTooltip();
          this.lineclick(val);
        }

      })
      .on("mouseout", (d: any) => {
        this._focus.style("opacity", 0);
        this._tooltip.style("display", "block").style("opacity", 0);
        this._tooltipX.style("display", "block").style("opacity", 0);
      })
      .on("mousemove", (d: any) => {
        this._focus.style("opacity", 1);
        if (this._config.xaxistype == "string") {
          let val = this.StringTooltip();
          this.Tick(val, d);
        }
        else {
          let val = this.DateNumberTooltip();
          this.Tick(val, d);
        }

      });
  }
  private DateNumberTooltip() {
    type LineTemp = {
      x?: any;
      y?: any;
      xaxis?: any;
      value?: any;
      additional_data?: any;
      key?: string;
      color?: string;
    };
    let val: Array<any> = [];
    /* console.log((d3.pointer(event)[0]))*/
    let x0 = this._xscalecopy.invert(d3.pointer(event)[0]);
    // console.log(this._xscale.bandwidth())


    for (var i = 0; i < this._config.data.length; i++) {
      //console.log(this._config.data[i])
      const obj: LineTemp = {};
      let b = this._bisectDate(this._config.data[i].values, x0, 0);
      let s = this._config.data[i].values[b];

      if (!s.isdummyvalue) {
        obj.x = this._xscalecopy(s.xaxis);
        obj.y = this._yscalecopy(s.value);
        obj.xaxis = s.xaxis;
        obj.value = s.value;
        obj.additional_data = s.additional_data;
        obj.key = this._config.data[i].key;
        obj.color = this._config.data[i].linestyle.color;
        //obj.color = this._config.linestyle[i].color;
        val.push(obj);
      }
    }
    return val;
  }
  private StringTooltip() {
    type LineTemp = {
      x?: any;
      y?: any;
      xaxis?: any;
      value?: any;
      additional_data?: any;
      key?: string;
      color?: string;
    };

    let val: Array<any> = [];

    var eachBand = this._xscale.step();
    var index = Math.round(((d3.pointer(event)[0] - (this._xscale.bandwidth() / 2)) / eachBand));
    let xTick = this._xscale.domain()[index];

    for (var i = 0; i < this._config.data.length; i++) {
      var fData = this._config.data[i].values.filter((f: any) => f.xaxis == xTick)
      if (fData[0]?.value != undefined && fData[0]?.value != null && !fData[0]?.isdummyvalue) {
        //console.log(fData)
        const obj: LineTemp = {};
        // let b = this._bisectDate(this._config.data[i].values, x0, 1);
        // let s = this._config.data[i].values[b];
        obj.key = this._config.data[i].key;
        obj.color = this._config.data[i].linestyle.color;
        //obj.color = this._config.linestyle[i].color;
        obj.x = this._xscale(xTick) + (this._xscale.bandwidth() / 2);
        obj.y = this._yscale(fData[0]?.value);
        obj.xaxis = xTick;
        obj.value = fData[0]?.value;
        obj.additional_data = fData[0]?.additional_data;
        val.push(obj);
      }

    }
    return val;
  }
  /* Tooltip single bar */
  private SingleBar(data: any, key: string) {
    let html = "";
    //for (var i = 0; i < d1.data.length; i++) {
    //let data = d1.data[i];
    if (data && data.value) {


      html += `<div style="display:flex;text-align:center;">
                            <div style="background-color:${data.color};" class="marker"></div>
                            <div class="marker-text">${key}:</div>
                            <div class="marker-value">${data.value}</div>
                         </div>`;

      if (this.config.tooltip_keys && this.config.tooltip_keys.length > 0 && data.additional_data) {
        for (var j = 0; j < this.config.tooltip_keys.length; j++) {
          let g = this.config.tooltip_keys[j];
          html += `<div class="additional-text" style="padding:5px;">${g.display_text} : ${data.additional_data[g.display_key]}</div>`
        }
      }

      // }
    }
    return html;
  }

  private Tick(val: Array<any>, d: any) {

    let w = this._config.width - this._config.margin_left - this._config.margin_right;
    let h = this._config.height - this._config.margin_top - this._config.margin_bottom;
    this._tooltip.style("top", (d.layerY + 15) + 'px').style("left", (w < d.layerX + 250 ? d.layerX - 100 : d.layerX) + 'px')
      .style("display", "block").style("opacity", 1).style("height", "auto")
      .html(() => {
        let html = "";
        if (this.config.xaxistype == "datetime") {
          var format = d3.timeFormat(this.config.datetimeformat);
          html += `<div class="header">${format(val[0].xaxis)}</div>`;
        }
        else {
          html += `<div class="header">${val[0].xaxis}</div>`;
        }
        html += "<div class='body'>";
        for (var i = 0; i < val.length; i++) {

          html += this.SingleBar(val[i], val[i].key);
          if (this.config.tooltip_keys && this.config.tooltip_keys.length > 0 && val[i].additional_data) {
            html += "<div class='separater'></div>"
          }
        }
        //for (var i = 0; i < val.length; i++) {
        //  if (val[i].value != undefined && val[i].value != null) {
        //    html += `<div style="padding-top:3px"><span style="background-color:${val[i].color};disply:inline;border-radius:3px;border:lightgray 0px solid">&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;${val[i].key}&nbsp;:&nbsp;${val[i].value}</div>`;
        //  }
        //}
        //for (var i = 0; i < val.length; i++) {
        //  if (val[i].additionalvalue) {
        //    html += `<div style="padding-top:3px">&nbsp;${val[i].additionalvalue.key}&nbsp;:&nbsp;${val[i].additionalvalue.value}</div>`;
        //  }
        //}
        return html + "</div>";
      });


    if (val[0]) {

      /*xaxis text*/
      //let txt = this._tooltipX.select("text").text(() => {

      //  if (this._config.xaxistype == "number") {
      //    return val[0].xaxis.toFixed(20);
      //  }
      //  else if (this._config.xaxistype == "datetime") {
      //    var format = d3.timeFormat(this.config.datetimeformat);
      //    return format(val[0].xaxis)
      //  }
      //  else {
      //    return val[0].xaxis;
      //  }

      //});
      //let tmpPos = val[0].x + txt.node().getBBox().width + this._config.margin_left + this._config.margin_right;
      //this._tooltipX.select("rect").attr("width", txt.node().getBBox().width + 4);
      //this._tooltipX.attr("transform", () => {
      //  if (tmpPos < this._config.width)
      //    return `translate(${(val[0].x)},${this._config.height - this._config.margin_bottom - this._config.margin_top + 15})`
      //  else
      //    return `translate(${(this._config.width - (this._config.margin_left + this._config.margin_right) - txt.node().getBBox().width)},${this._config.height - this._config.margin_bottom - this._config.margin_top + 15})`
      //});




      //setTimeout(() => {
      this._focus.selectAll(".focus").remove();
      this._focus.selectAll(".focus-line").remove();
      //this._focus.selectAll(".crosslinex").remove();
      //this._focus.selectAll(".crossliney").remove();

      this._focus.append("line")
        .attr("class", "focus-line")
        .attr("x1", val[0].x)
        .attr("y1", 0)
        .attr("x2", val[0].x)
        //.attr("opacity", 0.3)
        .attr("stroke-dasharray", "4,4")
        .attr("y2", this.config.height - this.config.margin_bottom - this.config.margin_top)
        .style("stroke", "gray")

      this._focus.selectAll(".focus")
        .data(val)
        .enter()
        .append("circle")
        .attr("class", "focus")
        .style("fill", (d: any) => {
          return d.color;
        })
        .style("stroke", "#fff")
        .style("stroke-width", "2px")
        .attr("clip-path", "url(#" + this._config.container.replace("#", "") + "-clip-line)")
        //.attr("clip-path", "url(#clip-line)")
        .attr("r", 7)
        .attr("cx", (x: any) => x.x + this._leftMove)
        .attr("cy", (x: any) => x.y);

    }

  }
  public ZoomIn() {
    this._zoomlevel += 0.2;
    let svg = d3.select(this._config.container).select("svg");
    this._zoom.scaleBy(svg, this._zoomlevel);
  }
  ClickDisable(head: LineModel) {
    let h = this._config.container.replace("#", "") + head.key.split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
    this._svg.selectAll(".lines").each((child: LineValues) => {
      let h1 = this._config.container.replace("#", "") + child.key.split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
      if (h == h1) {
        d3.select("#" + h1)
          .style("opacity", 1);
      }
      else {
        d3.select("#" + h1)
          .style("opacity", 0.2);
      }
    })
  }
}
