<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'metricdashboard'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Metric Dashboard for&nbsp;
          <span *ngIf="filters!=undefined && filters?.provider=='All'">
            <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
            {{filters?.facility=="All"?'All Facilities':''}}&nbsp;
            <span *ngIf="filters!=undefined && filters?.surgeService!='All'">{{filters?.surgeService}}</span>&nbsp; {{filters?.surgeService=="All"?'All Services':''}}&nbsp;
            {{filters?.staffRole+'s'}} &nbsp;
          </span>
          <span *ngIf="filters!=undefined && filters?.provider!='All'">
            {{filters?.provider!="All"?filters?.providerName:''}} &nbsp;
          </span>{{totalCount}} Total Cases
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">

  <div class="container-xl">



    <div class="row row-cards" style="padding:10px 0px">
      <ng-container *ngFor="let m of metrics; index as i;">
        <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6" *ngFor="let f of GetfailedCasesPercentage(m.Metric);">
          <div class="card">
            <div class="card-header metrictitle click-header" style="border:0px !important">
              <h4 class="card-title" style="cursor:pointer;">
                <span data-bs-toggle="modal" data-bs-target="#modal-metric" (click)="GetMetricDescription(f.Metric,f.MetricName)">{{f.MetricName}}</span>
                &nbsp;<span *ngIf="f.Metric.toLowerCase()=='turnovertime'  && filters.surgeService=='All'" data-bs-toggle="modal" data-bs-target="#modal-turnover-thresholds"
                            (click)="GetTurnoverThresholds()">(mins) &#8804; &nbsp;<img src="./assets/info.png" style="width:16px;height:16px;" /></span>
                <span style="color: #811b01;" *ngIf="f.Metric.toLowerCase()=='turnovertime' && filters.surgeService!='All'">(mins) &#8804; &nbsp; {{turnoverThreshold}}</span>
              </h4>

            </div>
            <div class="card-body  click-body" style="text-align: center!important;" *ngIf="m.DisplayPercentage">
              <ng-container *ngIf="f.TotalCases>0">

                <div id="{{m.Metric}}">
                  <div class="row">
                    <div class="col" style="text-align:right;" *ngIf="GetIsInsights(m.Metric,f.MetricName)=='Y'">

                      <img src="./assets/insights.png" style="width:30px;height:30px;" (click)="GetInsights(m.Metric,f.MetricName,f.InsightsHeader)" data-bs-toggle="modal" data-bs-target="#modal-recomendations" />
                    </div>
                  </div>
                  <app-gauge [container]="'#gauge' + i" [targetValue]="-1" [targetText]="''" [tickValue]="f.FailedPercentage"
                             (eventOut)="GetGauge($event)" [metric]="m.Metric">
                    <div id="{{'gauge'+i}}" class="orbisgauge" data-bs-toggle="modal" (click)="GetMetricBar(f.Metric,f.MetricName)"
                         data-bs-target="#modal-metric-bar-detail"></div>
                  </app-gauge>
                </div>
                <!--style="position:relative;top:-40px"-->
                <!--<div class="row">
                  <div class="col" style="text-align:left;padding-left:33px;">
                    <img src="./assets/insights.png" style="width:30px;height:30px;" (click)="GetInsights(f.Metric)" data-bs-toggle="modal" data-bs-target="#modal-recomendations" />
                  </div>
                  <div class="col" style="text-align: right; padding-right: 40px;">
                    <img src="./assets/failedcases.png" style="width:25px;height:25px;"
                         (click)="GetFailedCase(f.Metric,f.MetricName)" data-bs-toggle="modal" data-bs-target="#modal-metric-failcasedetail" />
                  </div>
                </div>-->
                <!--style="position:relative;top:-20px;"-->
                <div>
                  <span *ngIf="m.Aspire" style="padding:2px;">{{m.TargetText}}</span>
                  <span *ngIf="!m.Aspire" style="padding:2px;">&nbsp;</span>
                </div>
              </ng-container>
              <div *ngIf="f.TotalCases<=0" style="text-align:center;">
                No Data Available
              </div>
            </div>
            <!--style="position:relative;top:-20px;"-->
            <div>
              <div *ngIf="m.DisplayAggregate && m.Metric.toLowerCase()=='dormancy_twoplussurgeons'" class="card-footer click-footer" data-bs-toggle="modal" data-bs-target="#modal-dormancy-twoPlusSurgeons-table" (click)="GetDormancy_twoPlusSurgeons()"
                   style="text-align: center!important;">


                <table class="table card-table table-vcenter" style="border-top: 1px solid lightgray;">
                  <tbody>
                    <tr>
                      <td class="orbisvaluename">Non-Utilized (mins)</td>
                      <td class="orbisvaluedata" style="font-size:1em;font-weight:bold">{{f.FailedCases}}</td>

                    </tr>
                    <tr>
                      <td class="orbisvaluename">Total (mins)</td>
                      <td class="orbisvaluedata" style="font-size:1em;font-weight:bold">{{f.TotalCases}}</td>
                    </tr>

                  </tbody>
                </table>

              </div>
              <div>
                <diV *ngIf="m.DisplayAggregate && m.Metric.toLowerCase()=='rvu'">
                  <table class="table card-table table-vcenter" style="border-top: 1px solid lightgray;">
                    <tbody>
                      <tr>
                        <td class="orbisvaluename">Count</td>
                        <td class="orbisvaluedata">{{f.TotalCases}}</td>
                        <td class="orbisvalueunit">Cases</td>
                      </tr>
                      <tr>
                        <td class="orbisvaluename">Mean</td>
                        <td class="orbisvaluedata">{{f.Mean}}</td>
                        <td class="orbisvalueunit">Units</td>
                      </tr>
                      <tr>
                        <td class="orbisvaluename">Median</td>
                        <td class="orbisvaluedata">{{f.Median}} </td>
                        <td class="orbisvalueunit">Units</td>
                      </tr>
                    </tbody>
                  </table>
                </diV>
              </div>
              <div class="card-footer click-footer"
                   style="text-align: center!important;" *ngIf="f.TotalCases>0 && m.Metric.toLowerCase()!='dormancy_twoplussurgeons'"
                   (click)="GetFailedCase(f.Metric,f.MetricName)" data-bs-toggle="modal" data-bs-target="#modal-metric-failcasedetail">
                
                <div class="countcontainer" *ngIf="m.DisplayFail || m.Metric.toLowerCase()=='turnovertime'">
                  <span class="failimg"><img src="../../assets/failed.png"></span>
                  <span class="reportcount">{{f.FailedCases}}</span>
                  <span class="denominator"> of {{f.TotalCases}} cases</span>
                </div>
                <div style="font-weight:600;" *ngIf="f.Cost!=null && f.Cost!=undefined">Hospital Cost Implications&nbsp;:&nbsp;
                <span style="color: #cc1c16">$ {{f.Cost  | number}}</span></div>

                <diV *ngIf="m.DisplayAggregate && m.Metric.toLowerCase()=='surgicalsupplycost'">
                  <table class="table card-table table-vcenter" style="border-top: 1px solid lightgray;">
                    <tbody>
                      <tr>
                        <td class="orbisvaluename">Count</td>
                        <td class="orbisvaluedata">{{f.TotalCases}}</td>
                        <td class="orbisvalueunit">Cases</td>
                      </tr>
                      <tr>
                        <td class="orbisvaluename">Mean</td>
                        <td class="orbisvaluedata">{{f.Mean}}</td>
                        <td class="orbisvalueunit">Dollars</td>
                      </tr>
                      <tr>
                        <td class="orbisvaluename">Median</td>
                        <td class="orbisvaluedata">{{f.Median}} </td>
                        <td class="orbisvalueunit">Dollars</td>
                      </tr>
                    </tbody>
                  </table>
                </diV>

                <diV *ngIf="m.DisplayAggregate && m.Metric.toLowerCase()!='rvu' && m.Metric.toLowerCase()!='surgicalsupplycost' && (m.Metric.toLowerCase()=='dormancy_twoplussurgeons'||m.Metric.toLowerCase()=='turnovertime'|| m.Metric.toLowerCase()=='inductiontime')">
                  <table class="table card-table table-vcenter" style="border-top: 1px solid lightgray;">
                    <tbody>
                      <tr>
                        <td class="orbisvaluename">Count</td>
                        <td class="orbisvaluedata">{{f.TotalCases}}</td>
                        <td class="orbisvalueunit">Cases</td>
                      </tr>
                      <tr>
                        <td class="orbisvaluename">Mean</td>
                        <td class="orbisvaluedata">{{f.Mean}}</td>
                        <td class="orbisvalueunit">Minutes</td>
                      </tr>
                      <tr>
                        <td class="orbisvaluename">Median</td>
                        <td class="orbisvaluedata">{{f.Median}} </td>
                        <td class="orbisvalueunit">Minutes</td>
                      </tr>
                    </tbody>
                  </table>

                </diV>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6" *ngIf="m.Metric=='ScheduledVsActual'">
          <div class="card">
            <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric"
                 (click)="GetMetricDescription('ScheduledVsActual', 'Scheduled vs Actual')">
              <h4 class="card-title" style="cursor:pointer;">Scheduled vs Actual</h4>

            </div>
            <div class="card-body p-0 click-body" style="padding:30px 30px !important; text-align: center !important;">
              <div *ngIf="barData!=undefined && barData.length>0">
                <app-percentagebar [container]="'#percentageBar'" [barData]="barData" [margin]="margin">
                  <div id="percentageBar"></div>
                </app-percentagebar>
              </div>
              <div *ngIf="barData==undefined || barData?.length==0" style="text-align:center;">
                No Data Available
              </div>
            </div>
            <div class="card-footer click-footer" style="text-align: center!important;">
              <diV *ngIf="cmm!=undefined">
                <table class="table card-table table-vcenter">
                  <tbody *ngFor="let c of GetCMM('ScheduledVsActual')">

                    <tr>
                      <td class="orbisvaluename">{{c.MetricName}}</td>
                      <td class="orbisvaluedata"><span *ngIf="c.Mean>0">+</span>{{c.Mean}}</td>
                      <td class="orbisvalueunit">Minutes</td>
                    </tr>
                  </tbody>
                </table>
              </diV>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="m.Metric=='PhaseTimes'">
          <div class="card">
            <div class="card-header metrictitle click-header" data-bs-toggle="modal" data-bs-target="#modal-metric" (click)="GetMetricDescription(m.Metric,m.MetricName)">
              <h4 class="card-title" style="cursor:pointer;"> {{m.MetricName}}</h4>

            </div>
            <div class="card-body p-0 click-body" data-bs-toggle="modal" *ngIf="timeLineData!=undefined">
              <app-timeline [container]="'#timeline'" [timeLineData]="timeLineData">
                <div id="timeline" style="width: 97%;margin-top: 50px;"></div>
              </app-timeline>

              <div *ngIf="timeTableData!=undefined" class="phasetimetable" style="padding-top:50px;">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th *ngFor="let t of timeTableData;let i = index" [ngStyle]="bgColors[i]">{{t.MetricName}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let m of aggregate">
                      <td style="background-color: #bbbec1;">{{m}}</td>
                      <td *ngFor="let t of timeTableData;let i = index" [ngStyle]="bgColors[i]">{{t[m]}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
    </div>
  </div>
</div>
<div class="modal modal-blur fade show" id="modal-metric" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Definition of {{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div [innerHtml]="metric"></div>
      </div>
    </div>
  </div>
</div>
<div class="modal modal-blur fade show" id="modal-metric-allcases" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title"> Case Details  </h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;" *ngIf="Allcases!=undefined">
        <span *ngIf="Allcases.length==1000">only 1000 cases shown</span>
        <div class="card" style="height: 90vh !important">

          <div class="card-body-scrollable card-body-scrollable-shadow">

            <table class="casemixgraphic" *ngIf="Allcases!=undefined">
              <tr>
                <th *ngFor="let c of metricfields">{{getORBISClosedCaseFields(c.Field)}}</th>
              </tr>
              <caption *ngIf="Allcases!=undefined && Allcases.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
              <tbody>

                <tr *ngFor="let c of Allcases">
                  <ng-container *ngFor="let d of metricfields">

                    <td *ngIf="d.Field!='CaseDate' && d.Field!='InRoom' && d.Field!='LeaveOR'">
                      {{c[d.Field] }}
                    </td>
                    <td *ngIf="d.Field=='CaseDate' ">{{c[d.Field] | date:'MM/dd/yyyy'  }}</td>
                    <td *ngIf="d.Field=='InRoom' || d.Field=='LeaveOR'" style="min-width:150px">{{c[d.Field] | date:'MM/dd/yyyy h:mm a'  }}</td>


                  </ng-container>
                </tr>


              </tbody>

            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal modal-blur fade show" id="modal-metric-bar-detail" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered tmodal-dialog" role="document" style="width:65%!important">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">{{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="height: 90vh !important; text-align: center !important" *ngIf="fullbarData!=undefined">
        <app-bar [container]="'#bar11'" [barData]="fullbarData" [configuration]="configuration">
          <div id="bar11" style="width: 80%; height: 300px; margin:0 auto"></div>
        </app-bar>
      </div>
    </div>
  </div>
</div>
<div class="modal modal-blur fade show" id="modal-metric-failcasedetail" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered tmodal-dialog" role="document" style="min-width:100%!important;">
    <div class="modal-content" style="width:1700px!important;">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Cases Failing {{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="failedcases!=undefined" style="height: 82vh; overflow-y: auto; padding-top: 2px !important">
        <app-failed-cases [metric]="metric"
                          [metricfields]="metricfields"
                          [ORBISClosedCaseFields]="ORBISClosedCaseFields"
                          [ORBISClosedCase]="ORBISClosedCase"
                          [ORBISFailDetails]="ORBISFailDetails"
                          [ORBISClosedCaseMetric]="ORBISClosedCaseMetric"
                          [metricCode]="metricCode"
                          [failedcases]="failedcases"></app-failed-cases>

      </div>
    </div>
  </div>
</div>

<div class="modal modal-blur fade show" id="modal-turnover-thresholds" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="width: 500px;min-height:50px;overflow-y:auto">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Turnover Thresholds</h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <div class="card" style="text-align: center;">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div>
              <table class="casemixgraphic fixTableHead" *ngIf="turnOver!=undefined">
                <thead>
                  <tr>
                    <th>Procedure Type</th>
                    <th>Turnover Threshold (mins)</th>
                    <th>Facility</th>
                  </tr>
                </thead>
                <caption *ngIf="turnOver!=undefined && turnOver.length == 0" style="text-align:center;font-weight:600">No Records to Display</caption>
                <tbody>
                  <tr *ngFor="let c of turnOver">
                    <td>{{c.SurgeService}}</td>
                    <td>{{c.TurnoverThreshold  | number}}</td>
                    <td>{{c.Facility}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal modal-blur fade show blur" id="modal-dormancy-twoPlusSurgeons-table" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Multi Room Dormancy Details </h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <!--<div style="padding:10px; text-align:right;font-weight:bold;">
          <span style=" background-color: #9ef79e; border: lightgray solid 1px; ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;>72 Hours&nbsp;
          <span style="background-color: #fbfb84; border: lightgray solid 1px; ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;24-72 Hours&nbsp;
          <span style="background-color: #fba7a7; border: lightgray solid 1px; ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;< 24 Hours&nbsp;
        </div>-->
        <div class="card" style="height: 80vh !important; text-align: center;">

          <div class="card-body-scrollable card-body-scrollable-shadow" *ngIf="dormancytwoPlusSurgeonsDetails!=undefined && dormancytwoPlusSurgeonsDetails.length>0">


            <table class="casemixgraphic fixTableHead" *ngIf="dormancytwoPlusSurgeonsDetails!=undefined">

              <thead>
                <tr>
                  <th>Facility</th>
                  <th>Location</th>
                  <th>Case Date</th>
                  <th>Surgical Service</th>
                  <th style="min-width:200px">Suregon</th>

                  <th>Rooms</th>
                  <th>Total (mins)</th>
                  <th>Utilized (mins)</th>
                  <th>Non Utilized (mins)</th>
                  <!--<th>Staff ID</th>-->
                  <!--<th>Last updated Date </th>-->
                  <!--<th>Cost</th>-->
                </tr>
              </thead>

              <caption *ngIf="dormancytwoPlusSurgeonsDetails!=undefined && dormancytwoPlusSurgeonsDetails.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
              <tbody>

                <tr *ngFor="let c of dormancytwoPlusSurgeonsDetails">
                  <!--[ngClass]="c.Color"-->
                  <td>{{c.Facility}}</td>
                  <td>{{c.Location}}</td>

                  <td>{{c.CaseDate  | date:'MM/dd/yyyy'}}</td>
                  <td>{{c.SurgService}}</td>
                  <td>{{c.Surgeon}}</td>
                  <td>{{c.Rooms}}</td>
                  <td>{{c.Total_Minutes}}</td>
                  <td>{{c.Utilized_Minutes}}</td>
                  <td>{{c.Non_Utilized_Minutes}}</td>
                  <!--<td>{{c.StaffID}}</td>-->
                  <!--<td>{{c.LastUpdatedTime | date:'MM/dd/yyyy'}}</td>-->
                  <!--<td>{{c.Cost}}</td>-->
                  <!--<td>${{c.Cost}}</td>-->
                </tr>
              </tbody>
            </table>


          </div>
          <div class="card-footer">
            <div class="row" *ngIf="dormancytwoPlusSurgeonsDetails!=undefined && dormancytwoPlusSurgeonsDetails.length > 0">
              <div class="col-12">
                <app-pagination [RecordsPerPage]="100" [TotalRecords]="totalrecords" (pageEvent)="PageEventCaseCancel($event)" [CurrentPage]="page.currentPage">
                </app-pagination>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal modal-blur fade show blur" id="modal-recomendations" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="min-width: 1000px;min-height:50px;overflow-y:auto">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title" *ngIf="filters" style="font-size: 15px;">
          Insights for: <span style="color:blue!important">{{metricName}}</span> Period: <span style="color:blue!important">{{filters.fromDate}}</span> to <span style="color:blue!important">{{filters.toDate}}</span> Facility: <span style="color:blue!important">{{filters.facility}}</span> Location: <span style="color:blue!important">{{filters.location}}</span> Service: <span style="color:blue!important">{{filters.surgeService}}</span>
        </h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <!--<div style="text-align:center;font-size:18px;padding-top:7px;">
        <div [innerHtml]="insightsHeader"></div>
      </div>-->
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <div class="card" style="text-align: center;">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div *ngIf="insightsData" style="height: 500px; overflow-y: auto;">
              <app-dynamic-recomendations [recomendations]="insightsData" [recomendationString]="undefined"></app-dynamic-recomendations>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
