import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pie } from '../Pie';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent implements OnInit {

  @Input() container: string = ''
  @Input() pieData: any;
  @Input() piemetric: any;
  @Input() config: any;
  @Input() scale: any;
  @Output() myOutput: EventEmitter<Pie> = new EventEmitter();

  pie!: Pie;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.config = (this.config == undefined || this.config == null ? {} : this.config);
    this.config['piemetric'] = this.piemetric;
    this.pie = new Pie(this.container, this.pieData, this.config, this.scale);
    this.pie.Render();
    this.myOutput.emit(this.pie);
  }
}
