<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'financial-portfolio'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Financial Portfolio &nbsp;<span *ngIf="filters!=undefined && filters.surgeService!='All'">{{' All '+filters?.surgeService}}</span>&nbsp; {{filters?.surgeService=="All"?'All Services':''}}&nbsp;
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-body">

  <div class="container-xl">
    <div class="row">
      <div *ngIf="FinancialPortfolio!=undefined && FinancialPortfolio.length == 0" style="font-weight:600"> No Financial Data Available</div>
      <div class="financialportfolio col-xl-7 col-md-12 col-sm-12" *ngIf="FinancialPortfolio!=undefined && FinancialPortfolio.length > 0">
        <table>
          <tr>
            <th>Metric</th>
            <th>Q1</th>
            <th>Q2</th>
            <th>Q3</th>
            <th>Q4</th>
            <th>Total</th>
          </tr>
          <tr>
            <td class="financialportfoliocategory" colspan="6">Volume</td>
          </tr>
          <tr *ngFor="let m of GetFinancialPortfolioCategory('Volume')">
            <td class="financialportfoliofield">{{m.Metric}}</td>
            <td class="financialportfoliovalue">{{m.Q1}}</td>
            <td class="financialportfoliovalue">{{m.Q2}}</td>
            <td class="financialportfoliovalue">{{m.Q3}}</td>
            <td class="financialportfoliovalue">{{m.Q4}}</td>
            <td class="financialportfoliovalue">{{m.Total}}</td>
          </tr>

          <tr>
            <td class="financialportfoliocategory" colspan="6">Financials</td>
          </tr>
          <tr *ngFor="let m of GetFinancialPortfolioCategory('Financials')">
            <td class="financialportfoliofield">{{m.Metric}}</td>
            <td class="financialportfoliovalue">{{m.Q1 | currency : "USD" : "symbol" : "1.0-0" }}</td>
            <td class="financialportfoliovalue">{{m.Q2 | currency : "USD" : "symbol" : "1.0-0" }}</td>
            <td class="financialportfoliovalue">{{m.Q3 | currency : "USD" : "symbol" : "1.0-0" }}</td>
            <td class="financialportfoliovalue">{{m.Q4 | currency : "USD" : "symbol" : "1.0-0" }}</td>
            <td class="financialportfoliovalue">{{m.Total | currency : "USD" : "symbol" : "1.0-0"}}</td>
          </tr>

          <tr>
            <td class="financialportfoliocategory" colspan="6">Payer Mix</td>
          </tr>
          <tr *ngFor="let m of GetFinancialPortfolioCategory('Payer Mix')">
            <td class="financialportfoliofield">{{m.Metric}}</td>
            <td class="financialportfoliovalue">{{m.Q1}}%</td>
            <td class="financialportfoliovalue">{{m.Q2}}%</td>
            <td class="financialportfoliovalue">{{m.Q3}}%</td>
            <td class="financialportfoliovalue">{{m.Q4}}%</td>
            <td class="financialportfoliovalue">{{m.Total}}%</td>
          </tr>

        </table>

        <div class="financialdef">
          <h2>Definitions</h2>
          <table>
            <tr>
              <th>Net Revenue</th>
              <td>This is an estimate of net revenue, based on historical reimbursement trends.  We need to use an estimate because not all claims have been paid when the report is created, especially for more current services.</td>
            </tr>
            <tr>
              <th>Contribution Margin</th>
              <td>Net Revenue Composite less Direct Expenses.  Direct Expenses include labor, drugs and other supplies that typically vary with volume.</td>
            </tr>
            <tr>
              <th>Operating Margin</th>
              <td>Net Revenue Composite less Direct Expenses and Indirect Expenses.  Indirect Expenses include overhead like faculty funding, administrative expenses, and building expenses (utiliites, ops & maintenance, housekeeping, etc.).  These typically don’t vary with volume.</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="col-xl-5 col-md-12 col-sm-12">
        <div *ngIf="FinancialsGraph!=undefined && FinancialsGraph.length > 0" style="border: solid 1px #cccbca; margin-bottom: 20px;">
          <div class="financialgraphtitle">Financial Summary</div>
          <app-financialsgraph [container]="'#financialsgraph'" [financialsdata]="FinancialsGraph" [configuration]="{ sectionwidth: 80 }">
            <div id="financialsgraph" style="max-width: 600px; display: block; padding: 20px; margin: auto; overflow: auto;">
            </div>
          </app-financialsgraph>
        </div>

        <div *ngIf="PieData!=undefined && PieData.length > 0" style="border: solid 1px #cccbca; margin-bottom: 20px;">
          <div class="financialgraphtitle">Payer Mix</div>
          <app-pie [container]="'#payermixpie'" [pieData]="PieData">
            <div id="payermixpie" style="max-width: 400px; padding: 20px; display: block; margin: auto; overflow: auto;">
            </div>
          </app-pie>
        </div>

      </div>
    </div>
  </div>
</div>
