import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { HBarConfig, HbarData, HData } from '../models/hbarmodel';
import { HStackedBarChart } from './HStackedBar';

@Component({
  selector: 'd3-h-bar-stacked',
  templateUrl: './h-bar-stacked.component.html',
  styleUrls: ['./h-bar-stacked.component.css']
})
export class HBarStackedComponent {
  @Input() config: HBarConfig = new HBarConfig();
  @Output() barclick: EventEmitter<HbarData> = new EventEmitter();
  @Output() singlebarclick: EventEmitter<HData> = new EventEmitter();
  @Output() deselectbar: EventEmitter<any> = new EventEmitter();

  private _bar: HStackedBarChart | undefined;

  ngAfterViewInit(): void {
    this._bar = new HStackedBarChart();
    this._bar.config = this.config;
    if (this.barclick.observers.length > 0) {
      this._bar.barclick = (d: HbarData) => {
        this.barclick.emit(d);
      }
    }
    if (this.singlebarclick.observers.length > 0) {
      this._bar.singlebarclick = (d: HData) => {
        this.singlebarclick.emit(d);
      }
    }
    if (this.deselectbar.observers.length > 0) {
      this._bar.deselectbar = () => {
        this.deselectbar.emit();
      }
    }
    let d = document.getElementById(this._bar.config.container.replace("#", ""));
    if (d) {
      this._bar.config.width = d.offsetWidth;// - this._bar.config.margin_left - this._bar.config.margin_right;
      this._bar.config.height = this._bar.config.bar_height == 0 ? d.offsetHeight : (this._bar.config.chart_data.length * this._bar.config.bar_height) + (this._bar.config.margin_top + this._bar.config.margin_bottom);// d.offsetHeight;// - this._bar.config.margin_top - this._bar.config.margin_bottom;
      this._bar.Render();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this._bar) {
      let d = document.getElementById(this._bar.config.container.replace("#", ""));
      if (d) {
        this._bar.config.width = d.offsetWidth;// - this._bar.config.margin_left - this._bar.config.margin_right;
        this._bar.config.height = this._bar.config.bar_height == 0 ? d.offsetHeight : (this._bar.config.chart_data.length * this._bar.config.bar_height) + (this._bar.config.margin_top + this._bar.config.margin_bottom);// d.offsetHeight;// - this._bar.config.margin_top - this._bar.config.margin_bottom;
        this._bar.Render();
      }
    }
  }
}
