import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Filters } from '../Filters';
import { CommonService } from '../common.service';
import { json } from 'd3-fetch';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxCaptureService } from 'ngx-capture';
import { tap } from 'rxjs/operators';
import * as d3 from 'd3';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-providercomp',
  templateUrl: './providercomp.component.html',
  styleUrls: ['./providercomp.component.css']
})
export class ProvidercompComponent implements OnInit {
  ProviderComparisonMetrics: any;
  ProviderComparisonSelectedMetrics: any;
  ProviderComparisonTableData: any;
  ProviderComparisonBarData: any;
  ProviderComparisonProvider: any;
  captureService: any;
  filters: Filters;
  img: any;
  metricTable: any;
  @ViewChild('screen', { static: true }) screen: any;
  selectedMetricCategory: string;
  metricsMultiSettings: IDropdownSettings = {};
  configProviderCompBar: any;
  barWidth: string;
  isChartView: boolean = true;

  constructor(private commonService: CommonService, captureService: NgxCaptureService, private toastr: ToastrService) {
    this.captureService = captureService;
    this.metricsMultiSettings = {
      singleSelection: false,
      idField: 'Metric',
      textField: 'MetricName',
      itemsShowLimit: 5,
      enableCheckAll: false,
      allowSearchFilter: false
    };
  }
  downloadBase64File = function (contentType, base64Data, fileName) {
    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  ngOnInit(): void {
    this.ProviderComparisonMetrics = undefined;
    this.ProviderComparisonSelectedMetrics = undefined;

    this.selectedMetricCategory = "Operational";
    this.GetProviderComparisonMetrics();
    if (document.getElementById("barDiv"))
      this.barWidth = (document.getElementById("barDiv").clientWidth + 100).toString() + 'px';
  }

  DownloadCallBack(password: string) {
    if (!this.ProviderComparisonSelectedMetrics) {
      this.toastr.error("please select atlease one metric");
      return;
    }
    else if (this.ProviderComparisonSelectedMetrics.length == 0) {
      this.toastr.error("please select atlease one metric");
      return;
    }
    let selectedMetrics = this.ProviderComparisonSelectedMetrics.map(m => m.Metric);
    let selectedMetricIds = this.ProviderComparisonMetrics.filter(m => selectedMetrics.indexOf(m.Metric) > -1).map(m => m.MetricId);
    this.commonService.GetProviderComparisonDownload(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, selectedMetricIds, password);
  }
  SendEmailCallBack(password: string) {
    if (!this.ProviderComparisonSelectedMetrics) {
      this.toastr.error("please select atlease one metric");
      return;
    }
    else if (this.ProviderComparisonSelectedMetrics.length == 0) {
      this.toastr.error("please select atlease one metric");
      return;
    }
    let selectedMetrics = this.ProviderComparisonSelectedMetrics.map(m => m.Metric);
    let selectedMetricIds = this.ProviderComparisonMetrics.filter(m => selectedMetrics.indexOf(m.Metric) > -1).map(m => m.MetricId);
    this.commonService.GetProviderComparisonEmail(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, selectedMetricIds, password)
      .subscribe(res => {
        if (res == "NO") {
          this.toastr.warning("Email is not configured");
        }
        else {
          this.toastr.success(`Email sent to ${res}. It can take up to 5 minutes for email delivery to complete.`);
        }
      });
  }

  LoadData() {
    this.ProviderComparisonTableData = undefined;
    this.ProviderComparisonBarData = undefined;
    this.ProviderComparisonProvider = undefined;
    this.metricTable = {}
      ;
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    this.commonService.GetProviderComparison(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.surgeService, this.filters.fromDate, this.filters.toDate).subscribe(d => {

      this.ProviderComparisonTableData = d["ProviderComparisonTableData"];
      this.ProviderComparisonBarData = d["ProviderComparisonBarData"];
      this.ProviderComparisonProvider = d["ProviderComparisonProvider"];
      let data = groupBy(this.ProviderComparisonProvider, 'Metric');
      for (const key in data) {
        let metricUnit = this.GetMetricUnit(key);
        let x = data[key];
        let y;
        if (metricUnit == "%")
          y = x.sort((x, y) => x.MetricValue > y.MetricValue ? -1 : 1);
        else
          y = x.sort((x, y) => x.MetricValue > y.MetricValue ? 1 : -1);
        this.metricTable[key] = this.SplitProviderIntoColumns(y);

      }
      //console.log(this.GetProviderComparisonProvider("OnTime", '%'));
      //setTimeout(() => {

      //}, 500);
    })
  }
  getchangeofMetrics(y) {

    var newobject = { ...y }
    this.ProviderComparisonSelectedMetrics = newobject;



  }
  OnFilter(e: Filters) {
    this.filters = e;
    this.LoadData();
  }
  GetProviderComparisonMetrics() {
    this.ProviderComparisonMetrics = undefined;
    this.ProviderComparisonSelectedMetrics = undefined;
    this.commonService.GetProviderComparisonMetrics(this.selectedMetricCategory).subscribe(d => {

      this.ProviderComparisonMetrics = d["ProviderComparisonMetrics"].sort((x, y) => x.MetricName > y.MetricName ? 1 : -1);;
      this.ProviderComparisonSelectedMetrics = []; //[this.ProviderComparisonMetrics[0], this.ProviderComparisonMetrics[1]];
    });
  }
  downloadImage() {

    d3.selectAll(".test").selectAll("svg").attr("width", document.getElementById("barDiv").clientWidth + 100 + 30).attr("height", (this.configProviderCompBar.height + 40));
    //d3.select("#dayBar").select("svg").attr("width", (this.configServiceBar.width)).attr("height", (this.configServiceBar.height));


    this.captureService.getImage(this.screen.nativeElement, true)
      .pipe(
        tap(img => {
          this.img = img;
          this.downloadBase64File('image/png', img, 'ProviderComparision.png');
          //d3.select("#serviceBar").select("svg").attr("width", null).attr("height", null);
          //d3.select("#dayBar").select("svg").attr("width", null).attr("height", null);
          d3.selectAll(".test").selectAll("svg").attr("width", null).attr("height", null);
        })

      ).subscribe();

  }
  identify(index: number, item: any): number {
    return item.id;
  }

  GetMetricUnit(metric: string) {
    var x = this.ProviderComparisonMetrics.filter(c => c.Metric.toLowerCase() == metric.toLowerCase());
    if (x != undefined && x.length > 0) {
      return x[0].MetricUnit;
    }
    else
      return "";
  }

  GetMetricDescription(metric: string) {
    var x = this.ProviderComparisonMetrics.filter(c => c.Metric.toLowerCase() == metric.toLowerCase());
    if (x != undefined && x.length > 0) {
      return x[0].MetricDescription;
    }
    else
      return "";
  }

  GetProviderComparisonTableData(metric: string) {

    if (this.ProviderComparisonTableData != undefined) {
      var x = this.ProviderComparisonTableData.filter(c => c.Metric.toLowerCase() == metric.toLowerCase());
      if (x != undefined && x.length > 0) {
        return x;
      }
    }
    return [];
  }

  GetProviderComparisonBarData(metric: string, metricunit: string) {

    this.configProviderCompBar = { width: 500, height: 200, max: 100, paddingInner: 0.2, paddingOuter: 0.2, barwidth: 30, margin_top: 20, margin_bottom: 40, margin_left: 40, margin_right: 10, yaxis_ticks: 10, targetline: 0, units_label: metricunit }

    if (this.ProviderComparisonBarData != undefined) {
      var x = this.ProviderComparisonBarData.filter(c => c.Metric.toLowerCase() == metric.toLowerCase());
      if (x != undefined && x.length > 0) {
        return x;
      }
    }
    return [];
  }
  OnSelect(eve: any) {
    this.isChartView = false;
    setTimeout(() => {
      this.isChartView = true;
    })

    //this.isChartView = true;
  }
  OnDeSelect(eve: any) {
    this.isChartView = false;
    setTimeout(() => {
      this.isChartView = true;
    })

    //
  }
  GetProviderComparisonProvider(metric: string, metricunit: string) {
    console.log(this.ProviderComparisonProvider)
    if (this.ProviderComparisonProvider != undefined) {
      var x = this.ProviderComparisonProvider.filter(c => c.Metric.toLowerCase() == metric.toLowerCase());
      if (x != undefined && x.length > 0) {
        var y;
        if (metricunit == "%")
          y = x.sort((x, y) => x.MetricValue > y.MetricValue ? -1 : 1);
        else
          y = x.sort((x, y) => x.MetricValue > y.MetricValue ? 1 : -1);

        return this.SplitProviderIntoColumns(y);
      }
    }

    return [];
  }

  SplitProviderIntoColumns(providers: any) {
    var providercolumns = [];
    var numpercolumn = 30;

    while (providers.length) {
      providercolumns.push(providers.splice(0, numpercolumn));
    }
    return providercolumns;
  }


}
