<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'casemix'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Patient Case Mix&nbsp;
          <span *ngIf="filters!=undefined && filters?.provider=='All'">
            <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
            {{filters?.facility=="All"?'All Facilities':''}}&nbsp;
            <span *ngIf="filters!=undefined && filters?.surgeService!='All'">{{filters?.surgeService}}</span>&nbsp; {{filters?.surgeService=="All"?'All Services':''}}&nbsp;
            {{filters?.staffRole+'s'}} &nbsp;
          </span>
          <span *ngIf="filters!=undefined && filters?.provider!='All'">
            {{filters?.provider!="All"?filters?.providerName:''}} &nbsp;
          </span>{{totalCases}} Total Cases

        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-body">

  <div class="container-xl">
    <div class="row row-cards" *ngIf="resultset!=undefined && reportmetrics!=undefined">
      <div class="col-xxl-3 col-xl-3 col-md-4 col-sm-6" *ngFor="let f of reportmetrics; index as i;">
        <div class="card">
          <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric-desc"
               (click)="GetMetric(f.MetricName,f.MetricDescription)">
            <h4 class="card-title"  style="cursor:pointer;margin:0px !important">{{f.MetricName}}</h4>


          </div>
          <div class="card-body click-body" style=" text-align: center; padding: 10px"
               *ngIf="resultset!=undefined && f.ViewType=='Piechart'">
            <div *ngIf="filtermetric(f.Metric).length==0" style="text-align:center;">No data found</div>

            <app-pie [container]="'#piechart' + i" [pieData]="filtermetric(f.Metric)" [margin]="margin">
              <div id="{{'piechart'+i}}" data-bs-toggle="modal" (click)="onchartClick(f.MetricName,f.Metric,f.ViewType)"
                   data-bs-target="#modal-metric-zoom"></div>
            </app-pie>

          </div>
          <div class="card-body p-0  click-body" *ngIf="resultset!=undefined && f.ViewType=='Table'">
            <div data-bs-toggle="modal" (click)="onchartClick(f.MetricName,f.Metric,f.ViewType)"
                 data-bs-target="#modal-metric-zoom">
              <div *ngIf="filtermetric(f.Metric).length==0" style="text-align:center;">No data found</div>
              <table class="table card-table table-vcenter casemixgraphic"
                     style="height: 3rem!important;cursor: pointer;overflow-y:auto;zoom:1.25;" *ngIf="filtermetric(f.Metric).length>0">
                <tr>
                  <th>Condition</th>
                  <th># Cases (Prevalence)</th>
                </tr>
                <tbody *ngFor="let c of filtermetric(f.Metric)">
                  <tr>
                    <td>{{c.PieValue }}</td>
                    <td>{{c.PieCount +" ("+c.PiePercent+"%)"}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-body p-0 click-body" *ngIf="resultset!=undefined && f.ViewType=='Plain text'">
            <div data-bs-toggle="modal" (click)="onchartClick(f.MetricName,f.Metric,f.ViewType)"
                 data-bs-target="#modal-metric-zoom">
              <div *ngIf="filtermetric(f.Metric).length==0" style="text-align:center;">No data found</div>
              <div *ngFor="let c of filtermetric(f.Metric)" class="orbisoutcome" style="padding-top:40px !important">
                <div class="orbisproportion" style="zoom:1.5">{{c.PiePercent}}% </div>
                <div class="orbiscount">({{c.PieCount}}/{{filtermetric('Total')[0]?.PieCount}}) </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal modal-blur fade show" id="modal-metric-desc" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header  metrictitle">
        <h5 class="modal-title">Definition of {{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div [innerHtml]="metric"></div>
      </div>
    </div>
  </div>
</div>
<div class="modal modal-blur fade show" id="modal-metric-zoom" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document" style="min-width:50% !important">
    <div class="modal-content">
      <div class="modal-header  metrictitle" *ngIf="chartmetric!=undefined">
        <h5 class="modal-title">
          {{metricName}}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div *ngIf="chartmetric!=undefined">
          <div class="card-body" style=" text-align: center; padding: 10px"
               *ngIf="chartmetricType=='Piechart'">
            <app-pie [container]="'#piechartzoom'+ chartmetric" [pieData]="filtermetric(chartmetric)" [margin]="margin">
              <div id="{{'piechartzoom'+chartmetric}}">
              </div>
            </app-pie>
          </div>
          <div class="card-body p-0 click-body" *ngIf="chartmetricType=='Table'">

            <table class="table card-table table-vcenter casemixgraphic"
                   style="height: 3rem!important;cursor: pointer;overflow-y:auto">
              <tr>
                <th>&nbsp;</th>
                <th># Cases (Prevalence)</th>

              </tr>
              <tbody *ngFor="let c of filtermetric(chartmetric)">
                <tr>
                  <td>{{c.PieValue }}</td>
                  <td>{{(c.PieCount   | number) +" ("+c.PiePercent+"%)"}} </td>

                </tr>


              </tbody>
            </table>
          </div>
          <div class="card-body p-0  click-body" *ngIf="chartmetricType=='Plain text'">
            <div *ngFor="let c of filtermetric(chartmetric)" class="orbisoutcome" style="padding-top:40px !important">
              <div class="orbisproportion">{{c.PiePercent}}% </div>
              <div class="orbiscount"> ({{c.PieCount}}/{{filtermetric('Total')[0]?.PieCount}}) </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        &nbsp;
      </div>
    </div>
  </div>
</div>
