<ng-http-loader [backgroundColor]="'#206bc4'" [filteredUrlPatterns]="[ 'outcomefilter','auditlog','getProviderFilters','getFilters','GetRooms','GetAllProvider','GetStaffRole','GetSurgeService','GetFacility','getUserScreens','getRole','getUserMenu','GetLocation','PostCohort']"></ng-http-loader>
<div class="page">
  <aside class="navbar navbar-vertical navbar-expand-lg navbar-dark" data-bs-theme="dark">

    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark">
        <a href=".">
          <img src="../assets/orbis128.png" alt="Orbis" class="navbar-brand-image" style="filter: none!important;">

        </a>

      </h1>
      <div style="text-align:center;font-size:3rem;">{{appHeader}}</div>
      <div>&nbsp;</div>
      <div class="collapse navbar-collapse" id="navbar-menu">
        <ul class="navbar-nav pt-lg-3">
          <li class="nav-item  dropdown" *ngFor="let h of header">
            <a class="nav-link dropdown-toggle" style="cursor:pointer;" (click)="showorhidemenu(h)">
              <span class="nav-link-icon d-md-none d-lg-inline-block">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                {{h}}
              </span>
            </a>
            <div class="dropdown-menu" [ngStyle]="selectedcategory[h]?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <ng-container *ngFor="let c of GetChildMenu(h)">
                    <a class="dropdown-item menu-text" [routerLink]="['/'+c.AngularRoute]">
                      {{c.DisplayName}}
                    </a>
                  </ng-container>

                </div>
              </div>
            </div>
          </li>
          <!--<li class="nav-item  dropdown" *ngIf="VisibleMenu('OR Utilization')">
            <a class="nav-link dropdown-toggle" (click)="showorhidemenu('ORUtilization')" style="cursor:pointer;">
              <span class="nav-link-icon d-md-none d-lg-inline-block">

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                Utilization
              </span>
            </a>
            <div class="dropdown-menu" [ngStyle]="selectedcategory['ORUtilization']?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <a class="dropdown-item menu-text" [routerLink]="['/or-snapshot']" routerLinkActive="active">
                    Snapshot
                  </a>
                  <a class="dropdown-item menu-text" [routerLink]="['/time-stat']" routerLinkActive="active">
                    Time Stat
                  </a>
                  <a class="dropdown-item menu-text" [routerLink]="['/block-utilization']" routerLinkActive="active">
                    Block Utilization
                  </a>

                </div>

              </div>
            </div>
          </li>
          <li class="nav-item dropdown" *ngIf="VisibleMenu('Performance')">
            <a class="nav-link dropdown-toggle" (click)="showorhidemenu('Performance')" style="cursor:pointer;">
              <span class="nav-link-icon d-md-none d-lg-inline-block">

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                Performance
              </span>

            </a>
            <div class="dropdown-menu" [ngStyle]="selectedcategory['Performance']?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <a class="dropdown-item  menu-text" [routerLink]="['/metric-dashboard']" routerLinkActive="active">
                    Metric Dashboard
                  </a>
                  <a class="dropdown-item  menu-text" [routerLink]="['/distribution-rank']" routerLinkActive="active">
                    Distribution & Rank
                  </a>
                  <a class="dropdown-item  menu-text" [routerLink]="['/provider-comparision']" routerLinkActive="active">
                    Provider Comparision
                  </a>

                </div>

              </div>
            </div>

          </li>
          <li class="nav-item dropdown" *ngIf="VisibleMenu('Financial')">
            <a class="nav-link dropdown-toggle" (click)="showorhidemenu('Financial')" style="cursor:pointer;">
              <span class="nav-link-icon d-md-none d-lg-inline-block">

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                Financial
              </span>

            </a>
            <div class="dropdown-menu " [ngStyle]="selectedcategory['Financial']?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <a class="dropdown-item menu-text " [routerLink]="['/rvu']" routerLinkActive="active">
                    RVU
                  </a>
                </div>

              </div>
            </div>
          </li>


          <li class="nav-item dropdown" *ngIf="VisibleMenu('Surgical Supplies')">
            <a class="nav-link dropdown-toggle" (click)="showorhidemenu('SurgicalSupplies')" style="cursor:pointer;">
              <span class="nav-link-icon d-md-none d-lg-inline-block">

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                Surgical Supplies
              </span>

            </a>
            <div class="dropdown-menu " [ngStyle]="selectedcategory['SurgicalSupplies']?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <a class="dropdown-item menu-text" [routerLink]="['/usage-cost']" routerLinkActive="active">
                    Usage & cost Analytics
                  </a>
                  <a class="dropdown-item menu-text" [routerLink]="['/picklist-optimization']" routerLinkActive="active">
                    Picklist Optimization

                  </a>
                  <a class="dropdown-item menu-text" [routerLink]="['/picklist-standaradization']" routerLinkActive="active">
                    Picklist Standardization
                  </a>
                  <a class="dropdown-item menu-text" [routerLink]="['/demand-forecasts']" routerLinkActive="active">
                    Demand Forecasts
                  </a>

                  <a class="dropdown-item menu-text" [routerLink]="['/scedule-supply']" routerLinkActive="active">
                    Sceduled Supply Usage

                  </a>
                </div>

              </div>
            </div>
          </li>


          <li class="nav-item dropdown" *ngIf="VisibleMenu('Case Mix')">
            <a class="nav-link dropdown-toggle" (click)="showorhidemenu('CaseMix')" style="cursor:pointer;">
              <span class="nav-link-icon d-md-none d-lg-inline-block">

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                Case Mix
              </span>

            </a>
            <div class="dropdown-menu" [ngStyle]="selectedcategory['CaseMix']?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <a class="dropdown-item menu-text" [routerLink]="['/case-mix']" routerLinkActive="active">
                    Patient Case Mix
                  </a>
                  <a class="dropdown-item menu-text" [routerLink]="['/case-log']" routerLinkActive="active">
                    Case Log

                  </a>

                </div>

              </div>
            </div>
          </li>
          <li class="nav-item dropdown" *ngIf="VisibleMenu('OutCome')">
            <a class="nav-link dropdown-toggle" (click)="showorhidemenu('Outcomes')" style="cursor:pointer;">
              <span class="nav-link-icon d-md-none d-lg-inline-block">

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                Outcome
              </span>

            </a>
            <div class="dropdown-menu" [ngStyle]="selectedcategory['Outcomes']?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <a class="dropdown-item  menu-text" [routerLink]="['/patient-outcome']" routerLinkActive="active">
                    Patient Outcome
                  </a>


                </div>

              </div>
            </div>
          </li>
          <li class="nav-item dropdown" *ngIf="VisibleMenu('Workflow')">
            <a class="nav-link dropdown-toggle" (click)="showorhidemenu('Workflow')" style="cursor:pointer;">
              <span class="nav-link-icon d-md-none d-lg-inline-block">

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                Workflow
              </span>

            </a>
            <div class="dropdown-menu" [ngStyle]="selectedcategory['Workflow']?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <a class="dropdown-item  menu-text" [routerLink]="['/workflow']" routerLinkActive="active">
                    Workflow Maps
                  </a>


                </div>

              </div>
            </div>
          </li>
          <li class="nav-item dropdown" *ngIf="VisibleMenu('PeriOp Insight')">
            <a class="nav-link dropdown-toggle" (click)="showorhidemenu('Periop')" style="cursor:pointer;">
              <span class="nav-link-icon d-md-none d-lg-inline-block">

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                PeriOp Insight
              </span>

            </a>
            <div class="dropdown-menu" [ngStyle]="selectedcategory['Periop']?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <a class="dropdown-item  menu-text" [routerLink]="['/periop-quality']" routerLinkActive="active">
                    Quality
                  </a>
                  <a class="dropdown-item  menu-text" [routerLink]="['/periop-operational']" routerLinkActive="active">
                    Operational
                  </a>
                  <a class="dropdown-item  menu-text" [routerLink]="['/periop-outcome']" routerLinkActive="active">
                    Outcome
                  </a>

                </div>

              </div>
            </div>

          </li>
          <li class="nav-item dropdown" *ngIf="VisibleMenu('iQuest')">
            <a class="nav-link dropdown-toggle" (click)="showorhidemenu('iQuest')" style="cursor:pointer;">
              <span class="nav-link-icon d-md-none d-lg-inline-block">

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                iQuest
              </span>

            </a>
            <div class="dropdown-menu " [ngStyle]="selectedcategory['iQuest']?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <a class="dropdown-item menu-text " [routerLink]="['/i-quest']" routerLinkActive="active">
                    iQuest
                  </a>


                </div>

              </div>
            </div>
          </li>



          <li class="nav-item dropdown" *ngIf="VisibleMenu('Block Management')">
            <a class="nav-link dropdown-toggle" (click)="showorhidemenu('BlockManagement')" style="cursor:pointer;">
              <span class="nav-link-icon d-md-none d-lg-inline-block">

                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="4" width="6" height="5" rx="2"></rect><rect x="4" y="13" width="6" height="7" rx="2"></rect><rect x="14" y="4" width="6" height="7" rx="2"></rect><rect x="14" y="15" width="6" height="5" rx="2"></rect></svg>
              </span>
              <span class="nav-link-title">
                Block Management
              </span>

            </a>
            <div class="dropdown-menu " [ngStyle]="selectedcategory['BlockManagement']?{'display': 'block'} : {'display': 'none'}">
              <div class="dropdown-menu-columns">
                <div class="dropdown-menu-column ">
                  <a class="dropdown-item menu-text" [routerLink]="['/overview']" routerLinkActive="active">
                    Overview
                  </a>
                  <a class="dropdown-item menu-text" [routerLink]="['/actions']" routerLinkActive="active">
                    Actions

                  </a>
                  <a class="dropdown-item menu-text" [routerLink]="['/cal-view']" routerLinkActive="active">
                    Calendar View
                  </a>
                  <a class="dropdown-item menu-text" [routerLink]="['/claim-release']" routerLinkActive="active">
                    Claim & Release
                  </a>

                </div>

              </div>
            </div>
          </li>-->


        </ul>

      </div>

    </div>


  </aside>


  <div class="page-wrapper">

    <router-outlet></router-outlet>
  </div>

</div>

