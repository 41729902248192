import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class SimpleBar {
  private config: any = {
    height: 180,
    width: 300,
    barwidth: 70,
    margin_left: 25,
    margin_right: 40,
    margin_top: 40,
    margin_bottom: 40,
    units_label: "Total Costs",
    //barcolor: "#ad530f",
    container: "simplebarcontainer",
    defaultcolor: "#a7b8c6",
    colormap: null,
    highlightcolor: null,
    highlightvalue: null,
    max: undefined,
    yaxis_ticks: 0,
    addline: 0,
    targetline: 80,
    paddingOuter: 0.1,
    paddingInner: 0.2,
    facility: "UWMC",
    clickpopup: "",
    clickfunction: "",
    labeloffset: 20,
    value1style: "simplebarvalue",
    value2style: "simplebarvalue2",
    value3style: "simplebarvalue3"
  };

  constructor(container: string, json: any, configuration: any) {

    this.container = container;
    this.json = json;
    this.configuration = configuration;
    this.configure(this.configuration);
  }

  private container: string;
  private json: any;
  private configuration: any;
  private max: any = undefined;
  private x: any = undefined;
  private y: any = undefined;
  private svg: any = undefined;
  private defaultcolor: any = undefined;
  private line: any = undefined;

  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }
   
    this.defaultcolor = this.config.defaultcolor;
   
    //width = config.barwidth * Object.keys(bardata).length + config.margin_left + config.margin_right;
    //this.config.margin_right = this.config.width - (this.config.barwidth * Object.keys(this.json).length + this.config.margin_left);

    //determine the max mean + std dev for the dates to display 
    if (this.config.max == null) {
      this.max = d3.max(this.json.map((d: any) => { return d.Value; }));
    }
    else {
      this.max = this.config.max;
    }

    //map each metric location on the x-axis
    //console.log(this.config.width,this.config.margin_right)
    //console.log(this.json.map((d: any) => { return d.Category; }))
    //console.log([this.config.margin_left, this.config.width - this.config.margin_right])
    this.x = d3.scaleBand()
      .rangeRound([this.config.margin_left, this.config.width - this.config.margin_right])
      .domain(this.json.map(  (d:any)=> { return d.Category; }))
      .paddingOuter(this.config.paddingOuter)
      .paddingInner(this.config.paddingInner);

    //scale mean to y-axis
    this.y = d3.scaleLinear()
      .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
      .domain([0, this.max]);

    //create line function to connect bars
    this.line = d3.line()
      .x((d: any) => { return this.x(d['Category']) + this.x.bandwidth() / 2; })
      .y((d: any) => { return this.y(d['Value']); });
  }

  Render() {
    this.draw();
  }
  private draw() {

    //create svg to to display bars
    this.svg = d3.select(this.container)
      .append("div")
      .classed(this.config.container, true)
      .append('svg:svg')
      .attr('viewBox', "0 0 " + (this.config.width + 30) + " " + this.config.height)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    //append the x-axis for metric labels
    this.svg.append("g")
      .attr("class", "simplebaraxis")
      .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x))
      .selectAll("text")
      .attr("transform", "rotate(20)")
      .style("text-anchor", "start");;

    //draw the title for y-axis
    this.svg.append("text")
      .attr("class", this.config.value1style)
      .attr("text-anchor", "middle")
      .attr("transform", "translate(10," + ((this.config.height - this.config.margin_bottom) / 2) + ")rotate(-90)")
      .text(this.config.units_label);

    //append the y-axis
    this.svg.append("g")
      .attr("class", "reportaxis")
      .attr("transform", "translate(" + this.config.margin_left + ",0)")
      .call(d3.axisLeft(this.y)
        .tickFormat(d3.format("d"))
        .ticks(this.config.yaxis_ticks)
      );

    //draw bars for values
    this.svg.append("g")
      .selectAll("rect")
      .data(this.json)
      .enter().append("rect")
      .attr("fill", (d: any, i: number) => {
        if (d.Category == this.config.highlightvalue && this.config.highlightcolor != null) { return this.config.highlightcolor }
        if (this.config.colormap == undefined || this.config.colormap == null) { return this.defaultcolor }
        return this.config.colormap[i];
      })
      .attr("x", (d: any) => { return this.x(d.Category); })
      .attr("width", this.x.bandwidth())
      .attr("y", this.config.height - this.config.margin_bottom)
      .attr("height", 0)
      .on("mouseover", function (d) {
        //d3.select(this)
        //  .style("cursor", "pointer")
        //  .attr("fill", "#757472");
      })
      .on("mouseout", function () {
       // d3.select(this).attr("fill", function (d) { return this.config.colormap == undefined || this.config.colormap==null ? this.defaultcolor : this.config.colormap[i]; });
      })
      .on("click", function (d) {
        //if (this.config.clickpopup != "") {
        //  var param = d.LinkID;

        //  //alert(config.facility + "_" + d.LinkID);
        //  //$("#CaseDetailPopup").show("slow");
        //  var popup = $("#" + this.config.clickpopup);
        //  popup.show("slow");

        //  //loadNeuroCaseDetail(config.clickurl, config.facility + "_" + d.LinkID);
        //  loadBarChartClick(this.config.clickfunction, this.config.facility + "_" + d.LinkID);
        //}
      })
      .transition()
      .duration(1500)
      .attr("y", (d: any) => { return this.y(d.Value); })
      .attr("height", (d: any) => { return this.config.height - this.config.margin_bottom - this.y(d.Value); });

    //draw labels for values
    this. svg.append("g")
      .selectAll("text")
      .data(this.json)
      .enter().append("text")
      .attr("class", this.config.value1style)
      .style("text-anchor", "middle")
      .attr("x", (d: any) => { return this.x(d.Category) + this. x.bandwidth() / 2; })
      .attr("y", (d: any) => { return this.y(d.Value) - this.config.labeloffset; })
      .text((d: any) => { return d.Label1; })

    this.svg.append("g")
      .selectAll("text")
      .data(this.json)
      .enter().append("text")
      .attr("class", this.config.value1style2)
      .style("text-anchor", "middle")
      .attr("x", (d: any) => { return this.x(d.Category) + this.x.bandwidth() / 2; })
      .attr("y", (d: any) => { return this.y(d.Value) - 7; })
      .text((d: any) => { return d.Label2 })

    //draw lines connecting the tops of the bars
    if (this.config.addline == 1) {
      var path = this.svg.append("path")
        .datum(this.json)
        .transition().delay(1500)
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-width", 2)
        .style("stroke-dasharray", ("3, 3"))
        .attr("d", this.line);

      //var totalLength = path.node().getTotalLength();
      //alert(totalLength);

      //path
      //    .attr("stroke-dasharray", totalLength + " " + totalLength)
      //    .attr("stroke-dashoffset", totalLength)
      //    .transition()
      //        .duration(1500)
      //        .attr("stroke-dashoffset", 0);

      //add labels to lines
      this.svg.append("g")
        .selectAll("text")
        .data(this.json)
        .enter().append("text")
        .attr("class", this.config.value1style3)
        .style("text-anchor", "middle")
        .attr("x", (d: any) => { return this.x(d.Category) - (this.x.paddingInner() / 2 + this.x.bandwidth() / 2); })
        .attr("y", (d: any) => { return this.y(d.Value - d.LineValue / 2) - 5; })
        .style("opacity", 0)
        .text((d: any) => { return d.LineLabel; })
        .transition()
        .duration(1500)
        .delay(1500)
        .style("opacity", 1);

    }

    //draw a target line if one is specified
    if (this.config.targetline > 0) {
      
      this.svg.append("line")
        .attr("class", "targetline")
        .attr("x1", this.config.margin_left)
        .attr("x2", this.config.width - this.config.margin_right)
        .attr("y1", this.y(this.config.targetline))
        .attr("y2", this.y(this.config.targetline))
        .attr("fill", "none");

      //add target to legend
      this.svg.append("line")
        .attr("class", "targetline")
        .attr("x1", this.config.width - this.config.margin_left + 5)
        .attr("x2", this.config.width - this.config.margin_left + 25)
        .attr("y1", this.config.margin_top)
        .attr("y2", this.config.margin_top)
        .attr("fill", "none");

      //add target text to legend
      this.svg.append('text')
        .attr("class", "linelegend")
        .attr('x', this.config.width - this.config.margin_left+25)
        .attr('y', this.config.margin_top + 5)
        .text("Target");
    }
    //Add legend for each group by value
    if(this.config.categories)
    this.config.categories.forEach((d: any, i: number) => {

      //draw box with color code
      this.svg.append('rect')
        .attr('x', this.config.width - this.config.margin_right + 10)
        .attr('y', this.config.margin_top + 20 * (i + 1) - 8)
        .attr('width', 20)
        .attr('height', 15)
        .attr('stroke-width', 2)
        .attr('fill', this.config.colormap == undefined || this.config.colormap == null ? this.defaultcolor : this.config.colormap[i])
      //draw text of group by value (e.g. Service)
      this.svg.append('text')
        .attr("class", "linelegend")
        .attr('x', this.config.width - this.config.margin_right + 35)
        .attr('y', this.config.margin_top + 20 * (i + 1) + 5)
        //.attr('fill', d)
        .text(d)

    });
  }
}
