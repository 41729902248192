import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class Workflow {

  private config: any = {
    margin_top: 5,
    margin_right: 15,
    margin_bottom: 25,
    margin_left: 80,

    barWidth: 30 * 1000,
    barHeight: 15,
    barPadding: 10,
    showLabels: true
  };

  private numCases: any = undefined;
  private numRooms: any = undefined;
  private rooms: any = undefined;
  private room_y: any = undefined;
  private room_height: any = undefined;
  private width: any = undefined;
  private height: any = undefined;
  private mindate: any = undefined;
  private maxdate: any = undefined;
  private svg: any = undefined;
  private sortGroup: any = undefined;
  private sortSchedule: any = undefined;
  private x: any = undefined;
  private y_labels: any = undefined;
  private y0: any = undefined;
  private y1: any = undefined;
  private rect_height: any = undefined;

  private roomcolors: Array <string> = ["#f4f5f7", "#e8e7e3"];
  private phasecolors: Array <string> = ["#afc3d8", "#d6d19e", "#aca3b7", "#8bba91"];

  private container: string;
  private workflowdata: any;
  private surgeryscheduledata: any;
  private configuration: any;

  constructor(container: string, workflowdata: any, surgeryscheduledata: any, configuration: any) {
    this.container = container;
    this.workflowdata = workflowdata;
    this.surgeryscheduledata = surgeryscheduledata;
    this.configuration = configuration;
    this.configure(this.configuration);

  }

  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    this.numCases = Object.keys(this.workflowdata).length;
    
    var casedate = this.workflowdata[0].CaseDate.split("-");
    var year = parseInt(casedate[0]);
    var month = parseInt(casedate[1]) - 1;
    var day = parseInt(casedate[2]);

    this.mindate = new Date(year, month, day, 7, 0);
    this.maxdate = new Date(year, month, day, 22, 0);

    //document.write("mindate= " + this.mindate + " maxdate= "  + this.maxdate);   

    //Group data by room and then sort each room's data by time of Anes Start
    this.sortGroup = nest()
      .key(function (d: any) {
        return d.Room;
      }).sortKeys(d3.ascending)
      .sortValues(function (a, b) { return (a.InRoom < b.InRoom ? -1: 1); })
      .entries(this.workflowdata);

    this.sortSchedule = nest()
      .key(function (d: any) {
        return d.Room;
      }).sortKeys(d3.ascending)
      .sortValues(function (a, b) { return a - b; })
      .entries(this.surgeryscheduledata);

    //get number of rooms with a case during the day
    this.numRooms = Object.keys(this.sortGroup).length;

    //calculate image width based on the number hours with an active case during the day
    this.width = Math.ceil(Math.abs(this.maxdate - this.mindate) / (1000 * 30)) + this.config.margin_left + this.config.margin_right;
    //this.width = 700;

    //calculate image height based on the number of rooms and the number of cases in each room
    this.height = (this.config.barHeight + this.config.barPadding) * this.numCases + this.config.margin_top + this.config.margin_bottom + this.numRooms * 50;

    //get array of all room names
    this.rooms = values(this.sortGroup).map(function (d) { return d.key; });

    //room y coordinate in the graphic is determined based on the number of cases in rooms that comes before
    this.room_y = values(this.sortGroup).map((d : any) => {
      var numPriorCases = Object.keys(this.workflowdata.filter(function (j) { return j.Room < d.key; })).length;
      var numPriorRooms = Object.keys(this.sortGroup.filter(function (j) { return j.key < d.key; })).length;
      return this.config.margin_top + numPriorCases * (this.config.barHeight + this.config.barPadding) + numPriorRooms * (50);
    });
    
    //room height for sections in the graphic is determined based on the number of cases in the room (add padding to top and bottom)
    this.room_height = values(this.sortGroup).map((d : any) => {
      var numRoomCases = Object.keys(this.workflowdata.filter(function (j: any) { return j.Room == d.key; })).length;
      return numRoomCases * (this.config.barHeight + this.config.barPadding) + 50;      
    });

    //x-axis scales hour of the day
    this.x = d3.scaleTime()
      .range([this.config.margin_left, this.width - this.config.margin_right])
      .domain([this.mindate, this.maxdate]);

    //y-axis includes each room with at least one case in the day
    this.y0 = d3.scaleOrdinal()
      .range(this.room_y)
      .domain(this.rooms);

    //allocate enough space for each room on the y-axis to draw all cases in the room for the day
    this.rect_height = d3.scaleOrdinal()
      .range(this.room_height)
      .domain(this.rooms);

  }
  Render() {
    this.draw();
  }
  private draw() {
    this.svg = d3.select(this.container)
      .append('svg:svg')
      .attr('width', this.width)
      .attr('height', this.height + 30);

    //loop through each operating room to draw sections behind axes and phase times
    this.sortGroup.forEach((d, i) => {
      this.y1 = d3.scaleBand()
        .padding(0.05)
        .range([this.config.barPadding, this.rect_height(d.key)])
        .domain(d.values.map((d: any) => { return d.ProcID; }));

      var roomsSection = this.svg.append("g")
        .attr("transform", "translate(0," + this.y0(d.key) + ")");

      //alternate background color of operating rooms to facilitate distinguishing between rooms
      roomsSection.append("rect")
        .attr("x", 5)
        .attr("width", this.width - 5)
        .attr("height", this.rect_height(d.key))
        .attr("fill", this.roomcolors[i % 2]);
    });

    //append the x-axis - draw grid lines through graphic
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(0," + (this.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x)
        .ticks(d3.timeHour.every(1))
        .tickSize(-this.height)
      );

    //loop through each operating room to draw phase times

    this.sortGroup.forEach((d, i) => {

      //get schedule for this room
      var schedule = this.sortSchedule.reduce(function (result, m) {
        if (m.key == d.key) {
          result.push(m);
        }
        return result;
      }, []);

      //get scale
      this.y1 = d3.scaleBand()
        .padding(0.05)
        .range([this.config.barPadding+30, this.rect_height(d.key)])
        .domain(d.values.map((d: any) => { return d.ProcID; }));

      var roomsSection = this.svg.append("g")
        .attr("transform", "translate(0," + this.y0(d.key) + ")");

      //label the room in the left axis area
      roomsSection.append("text")
        .attr("class", "workflowroomlabel")
        .attr("x", 10)
        .attr("y", this.rect_height(d.key) / 2)
        .text(d.key)
        ;

      if (this.config.showLabels) {
        //Add surgeon name
        roomsSection.append("g")
          .selectAll("g")
          .data(d.values)
          .enter().append("text")
          .attr("x", (d) => { return this.x(new Date(d.InRoom)); })
          .attr("y", (d) => { return this.y1(d.ProcID) + this.config.barHeight + 11; })
          .attr("class", "phasetimetext")
          .style("font-size", 11)
          .text((d) => { return d.Surgeon });
      }

      //lines for scheduled cases
      schedule.forEach((c, i) => {
        roomsSection.append("g")
          .selectAll("g")
          .data(c.values)
          .enter().append("rect")
          .attr("x", (c) => { return this.x(new Date(c.SchedInRoom)); })
          .attr("y", 5)
          .attr("width", (c) => { if (c.SchedInRoom !== null && c.SchedLeaveOR !== null) { return this.x(new Date(c.SchedLeaveOR)) - this.x(new Date(c.SchedInRoom)); } else { return 0; } })
          .attr("height", this.config.barHeight * 2/3)
          .attr("stroke", "#f0eeeb")
          .attr("fill", "#526175"); //"#1c2f59");
      });

      if (this.config.showLabels) {
        schedule.forEach((c, i) => {
          roomsSection.append("g")
            .selectAll("g")
            .data(c.values)
            .enter().append("text")
            .attr("x", (c) => { return this.x(new Date(c.SchedInRoom)); })
            .attr("y", 5 + this.config.barHeight / 2 + 11)
            .attr("class", "phasetimetext")
            .style("font-size", 11)
            .text((c) => { if (c.SchedInRoom !== null) { return c.Surgeon } });
        });
      }

      //rect for Induction Time
      roomsSection.append("g")
        .selectAll("g")
        .data(d.values)
        .enter().append("rect")
        .attr("x", (d) => { return this.x(new Date(d.InRoom)); })
        .attr("y", (d) => { return this.y1(d.ProcID); })
        .attr("width", (d) => { if (d.InRoom !== null && d.LeaveOR !== null) { return this.x(new Date(d.LeaveOR)) - this.x(new Date(d.InRoom)); } else { return 0; } })
        .attr("height", this.config.barHeight)
        .attr("fill", this.phasecolors[0]);

      if (this.config.showLabels) {
        //text for Induction Time
        roomsSection.append("g")
          .selectAll("g")
          .data(d.values)
          .enter().append("text")
          .attr("x", (d) => { return this.x(new Date(d.InRoom)) + 5; })
          .attr("y", (d) => { return this.y1(d.ProcID) + this.config.barHeight - 2; })
          .attr("class", "phasetimetext")
          .style("font-size", 11)
          .style("font-weight", "bold")
          .text((d) => {
            if (d.InRoom !== null) {
              if (d.AnesReady !== null)
                return Math.ceil(Math.abs(new Date(d.AnesReady).getTime() - new Date(d.InRoom).getTime()) / (1000 * 60));
              else if (d.ProcStart !== null)
                return Math.ceil(Math.abs(new Date(d.ProcStart).getTime() - new Date(d.InRoom).getTime()) / (1000 * 60));
              else if (d.ProcEnd !== null)
                return Math.ceil(Math.abs(new Date(d.ProcEnd).getTime() - new Date(d.InRoom).getTime()) / (1000 * 60));
              else if (d.LeaveOR !== null)
                return Math.ceil(Math.abs(new Date(d.LeaveOR).getTime() - new Date(d.InRoom).getTime()) / (1000 * 60));
              else return "";
            }
            else {
              return "";
            }
          });
      }

      //rect for Surgery Prep
      roomsSection.append("g")
        .selectAll("g")
        .data(d.values)
        .enter().append("rect")
        .attr("x", (d) => { if (d.AnesReady !== null && d.LeaveOR !== null) { return this.x(new Date(d.AnesReady)); } else { return 0; } })
        .attr("y", (d) => { return this.y1(d.ProcID); })
        .attr("width", (d) => { if (d.AnesReady !== null && d.LeaveOR !== null) { return this.x(new Date(d.LeaveOR)) - this.x(new Date(d.AnesReady)); } else { return 0; } })
        .attr("height", this.config.barHeight)
        .attr("fill", this.phasecolors[1]);

      if (this.config.showLabels) {
        //text for Surgery Prep
        roomsSection.append("g")
          .selectAll("g")
          .data(d.values)
          .enter().append("text")
          .attr("x", (d) => { if (d.AnesReady !== null && d.LeaveOR !== null) { return this.x(new Date(d.AnesReady)) + 10; } else { return 0; } })
          .attr("y", (d) => { return this.y1(d.ProcID) + this.config.barHeight - 2; })
          .attr("class", "phasetimetext")
          .style("font-size", 11)
          .style("font-weight", "bold")
          .text((d) => {
            if (d.AnesReady !== null) {
              if (d.ProcStart !== null)
                return Math.ceil(Math.abs(new Date(d.ProcStart).getTime() - new Date(d.AnesReady).getTime()) / (1000 * 60));
              else if (d.ProcEnd !== null)
                return Math.ceil(Math.abs(new Date(d.ProcEnd).getTime() - new Date(d.AnesReady).getTime()) / (1000 * 60));
              else if (d.LeaveOR !== null)
                return Math.ceil(Math.abs(new Date(d.LeaveOR).getTime() - new Date(d.AnesReady).getTime()) / (1000 * 60));
              else return "";
            }
            else {
              return "";
            }
          });
      }

      //rect for Operative Time
      roomsSection.append("g")
        .selectAll("g")
        .data(d.values)
        .enter().append("rect")
        .attr("x", (d) => { if (d.ProcStart !== null && d.LeaveOR !== null) { return this.x(new Date(d.ProcStart)); } else { return 0; } })
        .attr("y", (d) => { return this.y1(d.ProcID); })
        .attr("width", (d) => { if (d.ProcStart !== null && d.LeaveOR !== null) { return this.x(new Date(d.LeaveOR)) - this.x(new Date(d.ProcStart)); } else { return 0; } })
        .attr("height", this.config.barHeight)
        .attr("fill", this.phasecolors[2]);

      if (this.config.showLabels) {
        //text for Operative Time
        roomsSection.append("g")
          .selectAll("g")
          .data(d.values)
          .enter().append("text")
          .attr("x", (d) => { if (d.ProcStart !== null && d.LeaveOR !== null) { return this.x(new Date(d.ProcStart)) + 10; } else { return 0; } })
          .attr("y", (d) => { return this.y1(d.ProcID) + this.config.barHeight - 2; })
          .attr("class", "phasetimetext")
          .style("font-size", 11)
          .style("font-weight", "bold")
          .text((d) => {
            if (d.ProcStart !== null) {
              if (d.ProcEnd !== null)
                return Math.ceil(Math.abs(new Date(d.ProcEnd).getTime() - new Date(d.ProcStart).getTime()) / (1000 * 60));
              else if (d.LeaveOR !== null)
                return Math.ceil(Math.abs(new Date(d.LeaveOR).getTime() - new Date(d.ProcStart).getTime()) / (1000 * 60));
              else return "";
            }
            else {
              return "";
            }
          });
      }

      //rect for Emergence
      roomsSection.append("g")
        .selectAll("g")
        .data(d.values)
        .enter().append("rect")
        .attr("x", (d) => { if (d.ProcEnd !== null && d.LeaveOR !== null) { return this.x(new Date(d.ProcEnd)); } else { return 0; } })
        .attr("y", (d) => { return this.y1(d.ProcID); })
        .attr("width", (d) => { if (d.ProcEnd !== null && d.LeaveOR !== null) { return this.x(new Date(d.LeaveOR)) - this.x(new Date(d.ProcEnd)); } else { return 0; } })
        .attr("height", this.config.barHeight)
        .attr("fill", this.phasecolors[3]);

      if (this.config.showLabels) {
        //text for Emergence            
        roomsSection.append("g")
          .selectAll("g")
          .data(d.values)
          .enter().append("text")
          .attr("x", (d) => { if (d.ProcEnd !== null && d.LeaveOR !== null) { return this.x(new Date(d.ProcEnd)) + 5; } else { return 0; } })
          .attr("y", (d) => { return this.y1(d.ProcID) + this.config.barHeight - 2; })
          .attr("class", "phasetimetext")
          .style("font-size", 11)
          .style("font-weight", "bold")
          .text((d) => {
            if (d.ProcEnd !== null) {
              if (d.LeaveOR !== null)
                return Math.ceil(Math.abs(new Date(d.LeaveOR).getTime() - new Date(d.ProcEnd).getTime()) / (1000 * 60));
              else return "";
            }
            else {
              return "";
            }
          });
      }

      //rect for entire case for mouseover
      //roomsSection.append("g")
      //  .selectAll("g")
      //  .data(d.values)
      //  .enter().append("rect")
      //  .attr("x", (d) => { return this.x(new Date(d.InRoom)); })
      //  .attr("y", (d) => { return this.y1(d.ProcID); })
      //  .attr("width", (d) => { if (d.InRoom !== null && d.LeaveOR !== null) { return this.x(new Date(d.LeaveOR)) - this.x(new Date(d.InRoom)); } else { return 0; } })
      //  .attr("height", this.config.barHeight)
      //  .attr("fill", "transparent")
      //  .on("mouseover", function() { d3.select(this).style("cursor", "pointer"); });      

    });
  }
  public update(updateWorkflowData: any, newConfiguration: any) {

    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }

    this.workflowdata = updateWorkflowData;
    d3.select("" + this.container).select("svg").remove();
    this.draw();
  }
}
