import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user: string;

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.user = sessionStorage.getItem("userlogin");
  }

  logout() {
    var obj = {};
    obj['ApplicationId'] = environment.AppId;
    obj['UserLogin'] = sessionStorage.getItem("userlogin");
    obj['ActionType'] = "LOGOUT";
    obj['URI'] = 'ORBIS LOGOUT';
    this.commonService.PostLog(obj).subscribe(d => {
      //sessionStorage.removeItem('userlogin');
      //sessionStorage.removeItem("userrole");
      //sessionStorage.removeItem("usermenu");
      sessionStorage.clear();
      
      window.location.href = environment.loginURL;
    });
  }
}
