<!--<app-periop-filters [reportHeader]="'OutCome Metrics'" [filterHead]="'PONVOccurence'" (onfilter)="OnFilter($event)"></app-periop-filters>-->

<div class="sticky-top" *ngIf="isFilter">
  
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'outcome'" [metric]="metric" [page]="page"
               [reportHeader]="uijson?.reportheader" (onmetricchange)="ChangeMetric($event)"></app-filters>
</div>
<div style="text-align:right; padding-right:15px;" *ngIf="uijson?.legend">
  <!--<ng-container>
    <select class="btn-mini" style="width:120px;height:20px;border-radius:3px;" (change)="LoadChart()" [(ngModel)]="page" *ngIf="isMenu">

      <option [value]="'ponv'">PONV</option>
      <option [value]="'mortality'">Mortality</option>
      <option [value]="'disposition'">Disposition</option>
      <option [value]="'aki'">AKI</option>

      <option [value]="'myocardial'">Myocardial</option>
      <option [value]="'reintubation'">Reintubation</option>
      <option [value]="'hypotension'">Hypotension</option>
      <option [value]="'readmission'">Readmission</option>

      <option [value]="'ssi'">SSI</option>
      <option [value]="'lung'">Lung</option>
      <option [value]="'opioid'">Opioid</option>
      <option [value]="'pulmonary'">Pulmonary</option>

      <option [value]="'los'">LOS</option>
      <option [value]="'pain_score'">Pain Score</option>
      <option [value]="'normothermia'">Normothermia</option>
    </select>
  </ng-container>-->
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <ng-container *ngIf="uijson?.legend">
    <ng-container *ngFor="let legend of uijson?.legend">
      <span style="height: 60px; width: 60px; border-radius: 2px" [ngStyle]="{'background-color':legend?.color}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      {{legend?.text}}&nbsp;&nbsp;&nbsp;
    </ng-container>
  </ng-container>
</div>

<div class="page-body blur">
  <div class="container-xl container-sm container-md">
    <ng-container *ngIf="uijson">
      <div class="row minee" *ngFor="let row of uijson.rows">
        <ng-container *ngFor="let col of row.cols">
          <!--[ngStyle]="{'margin-right':(row.cols.length*4 *-1).toString()+'px','margin-left':(row.cols.length*4 *-1).toString()+'px'}"-->
          <!--First Level-->
          <div [class]="col.class" style="padding-right: 0px !important; padding-left: 0px !important; ">
            <ng-container *ngIf="col.header">
              <div class="periop-widget" [ngStyle]="{'height':col.height,'text-align':'center'}">

                <div class="header card-header widget-new" *ngIf="col.charttype=='widget'">
                  <h4 class="card-title" style="cursor:pointer;">
                    {{col.header}}
                  </h4>
                </div>
                <div class="header card-header widget-new" *ngIf="col.charttype!='widget'">

                  <div style="display:flex;">
                    <div style="width: calc(100% - 65px);text-align:left;padding-left:10px ">
                      <h4 class="card-title" style="cursor:pointer;">
                        {{col.header}}
                        <select style="border:solid #ccc 1px;border-radius:3px;" (change)="TrendQuantityChange(col.datalabel)" [(ngModel)]="trendQuantity[col.datalabel]"
                                *ngIf="uijson?.reportheader=='Opiod' && (col.datalabel=='TrendQuantity' || col.datalabel=='ProcedureType') ">
                          <option value="MMEQty">MME Quantity </option>
                          <option value="FentanylQuantity">Fentanyl Quantity</option>
                          <option value="HydromorphoneQuantity">Hydromorphone Quantity</option>
                          <option value="MorphineQuantity"> Morphine Quantity</option>
                          <option value="RemifentanilQuantity">Remifentanil Quantity</option>
                          <option value="MultiModalAgent">Multi Modal Agent</option>
                        </select>
                      </h4>
                    </div>
                    <div style="width:65px;">
                      <span style="margin-top:10px;position:relative;top:5px" *ngIf="col.is_swich_bar">
                        &nbsp;
                        <span style="position:relative;bottom:5px">
                          #
                        </span>
                        <label class="switch" style="top:-6px">
                          <input [(ngModel)]="switchBar[col.id]" type="checkbox" (change)="SwitchBar(col)">
                          <span class="slider round"></span>
                        </label>
                        <span style="position:relative;bottom:5px">
                          %
                        </span>
                      </span>
                    </div>
                  </div>

                  <!--<h4 class="card-title" style="cursor:pointer;">
            {{col.header}}
            <select style="border:solid #ccc 1px;border-radius:3px;" (change)="TrendQuantityChange()" [(ngModel)]="trendQuantity"
                    *ngIf="uijson?.reportheader=='Opiod' && col.datalabel=='TrendQuantity'">
              <option value="MMEQty">MME Quantity </option>
              <option value="FentanylQuantity">Fentanyl Quantity</option>
              <option value="HydromorphoneQuantity">Hydromorphone Quantity</option>
              <option value="MorphineQuantity"> Morphine Quantity</option>
              <option value="RemifentanilQuantity">Remifentanil Quantity</option>
              <option value="MultiModalAgent">Multi Modal Agent</option>
            </select>


          <span style="margin-top:10px;position:relative;top:5px" *ngIf="col.is_swich_bar">
            &nbsp;
            <span style="position:relative;bottom:5px">
              #
            </span>
            <label class="switch" style="top:-6px">
              <input [(ngModel)]="switchBar[col.id]" type="checkbox" (change)="SwitchBar(col)">
              <span class="slider round"></span>
            </label>
            <span style="position:relative;bottom:5px">
              %
            </span>
          </span>

          </h4>-->




                </div>
                <!--For widgets-->
                <div class="content" *ngIf="col.charttype=='widget'" [ngStyle]="{'color':col?.color}">
                  <span *ngIf="col.datatype=='number'">
                    {{col.data | number}}
                  </span>
                  <span *ngIf="col.datatype=='percentage'">
                    {{col.data}} %
                  </span>
                  <span *ngIf="col.datatype=='string'">
                    {{col.data}}
                  </span>
                </div>


                <!--For Pie-->
                <div style="width:100%;height:100%;" *ngIf="col.charttype=='pie' && col.data">
                  <div style="display: flex; height: 100%;" *ngIf="col.data.is_legend">
                    <div style="width: calc(100% - 75px); height: 100%;">
                      <d3-pie [config]="col.data" *ngIf="col.data" (pieclick)="Select($event,col.datalabel,undefined)" (deselectpie)="DeSelectFilter()">
                        <div [id]="col.id" style="width:97%;height:80%"></div>
                      </d3-pie>
                    </div>
                    <div style="width: auto; height: 100%;margin-top:10px;overflow-y:auto;">
                      <ng-container *ngFor="let c of col.data.chart_data">
                        <div style="width: auto; height: 30px; text-align: center; margin-bottom: 2px; padding-top: 10px; font-weight: bold; color: #fff" [ngStyle]="{'background-color':c.color}">
                          {{c.additional_data[col.data.display_keys[0].display_key]}}
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <ng-container *ngIf="!col.data.is_legend">
                    <d3-pie [config]="col.data" *ngIf="col.data" (pieclick)="Select($event,col.datalabel,undefined)" (deselectpie)="DeSelectFilter()">
                      <div [id]="col.id" style="width:97%;height:80%"></div>
                    </d3-pie>
                  </ng-container>

                </div>

                <!--For Vertical Stacked Bar -->
                <div style="width:100%;height:100%" *ngIf="col.charttype=='vstackedbar' && col.data">

                  <d3-v-bar-stacked [config]="col.data" *ngIf="col.data" (deselectbar)="DeSelectFilters()"
                                    (barclick)="Select($event,col.datalabel)">
                    <div [id]="col.id" style="width:100%;"></div>
                  </d3-v-bar-stacked>
                </div>


                <!--For Horizandal Stacked Bar -->

                <div style="width: 96%; height: 82%; overflow-y: scroll; scrollbar-width: thin;" *ngIf="col.charttype=='hstackedbar' && col.data">
                  <div *ngIf="col.legend!=null">{{col.legend}}</div>
                  <d3-h-bar-stacked [config]="col.data" *ngIf="col.data" (deselectbar)="DeSelectFilters()"
                                    (barclick)="Select($event,col.datalabel)">
                    <div [id]="col.id" style="width:100%;height:80%"></div>
                  </d3-h-bar-stacked>
                </div>

                <!--For Horizandal Bar -->

                <div style="width: 100%; height: 85%; overflow-y: scroll; scrollbar-width: thin;" *ngIf="col.charttype=='hbar' && col.data">
                  <d3-h-bar [config]="col.data" *ngIf="col.data" (deselectbar)="DeSelectFilter()"
                            (barclick)="Select($event,col.datalabel)">
                    <div [id]="col.id" style="width:97%;height:90%"></div>
                  </d3-h-bar>
                </div>


                <!--For Vertical Bar -->

                <div style="width:100%;height:100%" *ngIf="col.charttype=='vbar' && col.data">

                  <d3-v-bar [config]="col.data" *ngIf="col.data" (deselectbar)="DeSelectFilter()"
                            (barclick)="Select($event,col.datalabel)">
                    <div [id]="col.id" style="width:97%;height:90%"></div>
                  </d3-v-bar>
                </div>

                <!--For Trend -->
                <div style="width:100%;height:100%" *ngIf="col.charttype=='line' && col.data">
                  <d3-line [config]="col.data" *ngIf="col.data" (lineclick)="Select($event,col.datalabel)"
                           (singlelineclick)="DeSelectFilter()">
                    <div [id]="col.id" style="width:100%;height:100%"></div>
                  </d3-line>
                </div>

                <!--For Table -->
                <div style="width: 200px; height: 100%; padding: 13px 15px; font-weight: bold;" *ngIf="col.charttype=='smalltable'">
                  <table *ngIf="col.data" style="width:100%">

                    <tr *ngIf="col.is_header_required">
                      <th *ngFor="let h of col.table_header" style="border:1px solid #ccc;padding:3px 5px;">
                        {{h.header}}
                      </th>
                    </tr>

                    <tr *ngFor="let c of col.data"
                        style="border-top: 3px solid #ccc; border-bottom: 3px solid #ccc;font-size:17px; ">
                      <ng-container *ngFor="let h of col.table_header">
                        <td style="padding: 3px 5px; padding-top: 20px; padding-bottom: 20px; " [ngStyle]="{'color':c?.Color}"> {{c[h.key]}}</td>
                      </ng-container>
                    </tr>

                    <!--<tr *ngFor="let c of col.data">
              <ng-container *ngFor="let h of col.table_header">
                <td style="border: 1px solid #ccc; padding: 3px 5px;" [ngStyle]="{'min-width':h.width,'color':c.Color}"> {{c[h.key]}}</td>
              </ng-container>
            </tr>-->


                  </table>

                </div>


              </div>
            </ng-container>

            <ng-container *ngIf="!col.header && col.charttype=='table'">
              <!--For Table -->

              <div *ngIf="col.charttype=='table'">
                <div>
                  <app-common-table [config]="col.data" *ngIf="col.data" (sortEvent)="SortEvent($event)"
                                    (pageEvent)="PageEvent($event)" (filterEvent)="FilterEvent($event)"
                                    (downloadEvent)="DownloadEvent($event)" (emailEvent)="EmailEvent($event)"></app-common-table>
                </div>
              </div>
            </ng-container>


            <!--Nested Level-->
            <div class="row todo" *ngFor="let row1 of col.rows" [ngStyle]="col.rows.length>1 && {'margin-right':'4px'}">

              <ng-container *ngFor="let col1 of row1.cols">
                <div [class]="col1.class" style="padding-right: 0px !important; padding-left: 0px !important; ">
                  <ng-container *ngIf="!col1.header">
                    <div [ngStyle]="{'height':col1.height,'text-align':'center'}">
                      <div class="font-weight-medium">
                        Metric
                      </div>
                      <select class="form-select" [(ngModel)]="selectedLOS" (change)="ChangeLOS();">
                        <option value="HospitalLOS">Hospital LOS</option>
                        <option value="ICULOS">ICU LOS</option>
                      </select>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="col1.header">
                    <div class="periop-widget" [ngStyle]="{'height':col1.height,'text-align':'center'}">

                      <div class="header card-header widget-new" *ngIf="col1.charttype=='widget'">
                        <h4 class="card-title" style="cursor:pointer;">
                          {{col1.header}}
                        </h4>
                      </div>


                      <div class="header card-header widget-new" *ngIf="col1.charttype!='widget'">

                        <div style="display:flex;">
                          <div style="width: calc(100% - 65px);text-align:left;padding-left:10px ">
                            <h4 class="card-title" style="cursor:pointer;">
                              {{col1.header}}

                            </h4>
                          </div>
                          <div style="width:65px;">
                            <span style="margin-top:10px;position:relative;top:5px" *ngIf="col1.is_swich_bar">
                              &nbsp;
                              <span style="position:relative;bottom:5px">
                                #
                              </span>
                              <label class="switch" style="top:-6px">
                                <input [(ngModel)]="switchBar[col1.id]" type="checkbox" (change)="SwitchBar(col1)">
                                <span class="slider round"></span>
                              </label>
                              <span style="position:relative;bottom:5px">
                                %
                              </span>
                            </span>
                          </div>
                        </div>

                      </div>
                      <!--For widgets-->
                      <div class="content" *ngIf="col1.charttype=='widget'" [ngStyle]="{'color':col1?.color}">
                        <span *ngIf="col1.datatype=='number'">
                          {{col1.data | number}}
                        </span>
                        <span *ngIf="col1.datatype=='percentage'">
                          {{col1.data}} <span *ngIf="col1.data">%</span>
                        </span>
                        <span *ngIf="col1.datatype=='string'">
                          {{col1.data}}
                        </span>
                      </div>

                      <!--For Pie-->



                      <div style="width:100%;height:100%;" *ngIf="col1.charttype=='pie' && col1.data">

                        <div style="display: flex; height: 100%;" *ngIf="col1.data.is_legend">
                          <div style="width: calc(100% - 75px); height: 100%;">
                            <d3-pie [config]="col1.data" *ngIf="col1.data" (pieclick)="Select($event,col1.datalabel,undefined)" (deselectpie)="DeSelectFilter()">
                              <div [id]="col1.id" style="width:97%;height:80%"></div>
                            </d3-pie>
                          </div>
                          <div style="width: auto; height: 100%; margin-top: 10px; overflow-y: auto;">
                            <ng-container *ngFor="let c of col1.data.chart_data">
                              <div style="width: auto; height: 30px; text-align: center; margin-bottom: 2px;padding-left:5px;padding-right:5px; padding-top: 10px; font-weight: bold; color: #fff" [ngStyle]="{'background-color':c.color}">
                                {{c.additional_data[col1.data.display_keys[0].display_key]}}
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        <ng-container *ngIf="!col1.data.is_legend">
                          <d3-pie [config]="col1.data" *ngIf="col1.data" (pieclick)="Select($event,col1.datalabel,undefined)" (deselectpie)="DeSelectFilter()">
                            <div [id]="col1.id" style="width:100%;height:100%"></div>
                          </d3-pie>
                        </ng-container>
                      </div>

                      <!--For Vertical Stacked Bar -->
                      <div style="width:100%;height:100%" *ngIf="col1.charttype=='vstackedbar' && col1.data">

                        <d3-v-bar-stacked [config]="col1.data" *ngIf="col1.data" (deselectbar)="DeSelectFilters()"
                                          (singlebarclick)="Select($event,col1.datalabel)">
                          <div [id]="col1.id" style="width:100%;"></div>
                        </d3-v-bar-stacked>
                      </div>


                      <!--For Horizandal Stacked Bar -->

                      <div style="width: 96%; height: 82%; overflow-y: scroll; scrollbar-width: thin;" *ngIf="col1.charttype=='hstackedbar' && col1.data">

                        <d3-h-bar-stacked [config]="col1.data" *ngIf="col1.data" (deselectbar)="DeSelectFilters()"
                                          (barclick)="Select($event,col1.datalabel)">
                          <div [id]="col1.id" style="width:100%;height:80%"></div>
                        </d3-h-bar-stacked>
                      </div>

                      <!--For Horizandal Bar -->


                      <div style="width: 100%; height: 85%; overflow-y: scroll; scrollbar-width: thin;" *ngIf="col1.charttype=='hbar' && col1.data">
                        <d3-h-bar [config]="col1.data" *ngIf="col1.data" (deselectbar)="DeSelectFilters()"
                                  (barclick)="Select($event,col1.datalabel)">
                          <div [id]="col1.id" style="width:97%;height:90%"></div>
                        </d3-h-bar>
                      </div>


                      <!--For Vertical Bar -->

                      <div style="width:100%;height:100%" *ngIf="col1.charttype=='vbar' && col1.data">

                        <d3-v-bar [config]="col1.data" *ngIf="col1.data" (deselectbar)="DeSelectFilters()"
                                  (barclick)="Select($event,col1.datalabel)">
                          <div [id]="col1.id" style="width:97%;height:90%"></div>
                        </d3-v-bar>
                      </div>



                      <!--For Trend -->
                      <div style="width:100%;height:100%" *ngIf="col1.charttype=='line' && col1.data">
                        <d3-line [config]="col1.data" *ngIf="col1.data" (lineclick)="Select($event,col1.datalabel)"
                                 (singlelineclick)="DeSelectFilter()">
                          <div [id]="col1.id" style="width:100%;height:100%"></div>
                        </d3-line>
                      </div>

                      <!--For Table -->
                      <div style="width: 100%; height: 100%; padding-top: 15px;text-align:center;font-weight:bold;" *ngIf="col1.charttype=='smalltable'">
                        <table *ngIf="col1.data" style="width:100%">
                          <tr *ngIf="col1.is_header_required">
                            <th *ngFor="let h of col1.table_header" style="padding:3px 5px;">
                              {{h.header}}
                            </th>
                          </tr>
                          <tr (click)="DeSelectFilter()">
                            <td style="height:2px!important;background-color:#fff;text-align:center" colspan="2"></td>
                          </tr>

                          <tr *ngFor="let c of col1.data; index as i"
                              style="border-top: 3px solid #ccc; border-bottom: 3px solid #ccc;font-size:17px;" [id]="'tr'+i.toString()"
                              (click)="SelectRow(c,col1.datalabel,i)">
                            <ng-container *ngFor="let h of col1.table_header">
                              <td style="padding: 3px 5px; padding-top: 15px; padding-bottom: 15px;cursor:pointer;"
                                  [ngStyle]="{'color':c?.Color}">
                                {{c[h.key]}}
                              </td>
                            </ng-container>
                          </tr>

                        </table>

                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>

          </div>
        </ng-container>
      </div>

    </ng-container>
  </div>


</div>
