import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PercentageBar } from '../PercentageBar';

@Component({
  selector: 'app-percentagebar',
  templateUrl: './percentagebar.component.html',
  styleUrls: ['./percentagebar.component.css']
})
export class PercentagebarComponent implements OnInit {

  @Input() container: string = ''
  @Input() barData: any;
  @Input() margin: any;
  @Output() myOutput: EventEmitter<PercentageBar> = new EventEmitter();

  percentageBar!: PercentageBar;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.percentageBar = new PercentageBar(this.container, this.barData, this.margin);
    this.percentageBar.Render();
    this.myOutput.emit(this.percentageBar);
  }

}
