import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';

@Component({
  selector: 'app-financial-portfolio',
  templateUrl: './financial-portfolio.component.html',
  styleUrls: ['./financial-portfolio.component.css']
})
export class FinancialPortfolioComponent implements OnInit {
  FinancialPortfolio: any;
  FinancialsGraph: any;
  PieData: any;
  filters: Filters;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }
  LoadData() {
    this.FinancialPortfolio = undefined;
    this.FinancialsGraph = undefined;
    this.PieData = undefined;

    this.commonService.GetFinancialPortfolio(this.filters.facility, this.filters.provider, this.filters.surgeService, this.filters.fiscalYear).subscribe(d => {
      this.FinancialPortfolio = d["FinancialPortfolio"];
      this.FinancialsGraph = d["FinancialsGraph"];
      this.PieData = d["PieData"];
  })
}
  OnFilter(e: Filters) {
    this.filters = e;
    this.LoadData();
  }

  GetFinancialPortfolioCategory(metricCategory: string) {
    var x = this.FinancialPortfolio.filter(c => c.MetricCategory.toLowerCase() == metricCategory.toLowerCase());
    if (x != undefined && x.length > 0) {
      return x.sort((x, y) => x.MetricOrder > y.MetricOrder ? 1 : -1);
    }
    else
      return [];
  }
}
