<div class="container" style="margin:0 auto;max-width:100% !important;overflow:hidden;">
  <!--[ngStyle]="bgStyle"-->
  <div class="screen screen_orbis">
    <div class="screen__content">
      <div class="login">
        <div class="versioninfo_orbis">V1.3</div>
        <div style="position: relative; top: -70px; font-size: 40px; font-weight: 600; overflow: hidden;">
          <a href="." style="left:-2px;position:relative">
            <img src="../../" width="110" height="32" alt="Tabler" class="navbar-brand-image" style="margin:unset">
          </a>
        </div>
        <div style="position:relative;top:-40px; font-size:40px; font-weight:600">ORBIS</div>
        <div style="position:relative;top:-20px; font-size:11px; font-weight:400;">Operating Room</div>
        <!--<div class="login__field">
          <i class="login__icon fas fa-user"></i>
          <input type="text" class="login__input" placeholder="User name " [(ngModel)]="username" (keydown)="keyDownFunction($event)">
        </div>
        <div class="login__field">
          <i class="login__icon fas fa-lock"></i>
          <input type="password" class="login__input" placeholder="Password" [(ngModel)]="password" (keydown)="keyDownFunction($event)">
        </div>-->
        <button class="button login__submit login__submit_orbis">
          <span class="button__text">
            Continue
          </span>
          <i class="button__icon fas fa-chevron-right"></i>
        </button>
        <div style="position: relative; bottom: -30px; font-size: 11px; font-weight: 600; color: #eee; text-align: center" class="caption_orbis">Caption Tag</div>
        <div class="copyright_orbis">© 2018-2022 Perimatics LLC. All rights reserved</div>
      </div>
    </div>
    <div class="screen__background">
      <span class="screen__background__shape screen__background__shape4 shape4_bgcolor_orbis"></span>
      <span class="screen__background__shape screen__background__shape3 shape4_bgcolor_orbis"></span>
      <span class="screen__background__shape screen__background__shape2 shape4_bgcolor_orbis"></span>
      <span class="screen__background__shape screen__background__shape1"></span>
    </div>
  </div>
</div>
