import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PerivisionSchedule } from '../PerivisionSchedule';

@Component({
  selector: 'app-perivision-schedule',
  templateUrl: './perivision-schedule.component.html',
  styleUrls: ['./perivision-schedule.component.css']
})
export class PerivisionScheduleComponent implements OnInit {

  @Input() container: string = '';
  @Input() scheduledata: any;
  @Input() gapdata: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<PerivisionSchedule> = new EventEmitter();

  perivision!: PerivisionSchedule;

  constructor() { }

  LoadPerivisionSchedule() {    

    this.perivision = new PerivisionSchedule(this.container, this.scheduledata, this.gapdata, this.configuration);

    this.perivision.Render();
    this.myOutput.emit(this.perivision);
  }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.LoadPerivisionSchedule();
  }

}
