<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'facility-summary'"></app-filters>
</div>

<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          <span *ngIf="filters!=undefined">{{filters.facility}}</span>&nbsp; Summary &nbsp; <span *ngIf="metrics!=undefined">{{metrics[0].MonthTitle}}</span>
        </h2>
      </div>     
    </div>
  </div>
</div>

<div class="col-xl-12 col-md-12 col-sm-12 facilitysummary">
  <table *ngIf="metrics!=undefined">
    <tr *ngFor="let m of metrics">
      <td>
        <div class='facilitysummarymetric'>{{m.Metric}}</div>
        <div class='facilitysummarydesc'>{{m.MetricDescription}}</div>
        <div class='facilitysummarycomp'>Facility target: {{m.FacilityTarget}}%</div>
        <div class='facilitysummarycomp'>National average: {{m.NationalAverage}}%</div>
      </td>
      <td style="text-align: center; background-color: {{m.BGColor}}">
        <div class='facilitysummaryrate'>{{m.Rate}}%</div>
        <div><img src="assets/{{m.Level}}.svg" width="50"/></div>
        <div class='facilitysummarypass'>({{m.Pass}}/{{m.Base}})</div>
      </td>
      <td style="min-width: 400px;">
        <div class='facilitysummarydetailstitle'>{{m.DetailsTitle}}</div>
        <div class='facilitysummarydesc' *ngIf="GetSummaryDetails(m.Metric) != undefined">
          <ul>
            <li *ngFor="let d of GetSummaryDetails(m.Metric)" innerHTML="{{d.Detail}}"></li>
          </ul>
        </div>
        <!--<div class='facilitysummaryrecommend'>Recommendations</div>-->
      </td>           
      <td style="min-width: 200px;">
        <div class='facilitysummarycost'>Est Cost Implications</div>
        <div class='facilitysummarycost'>{{m.Cost | currency : "USD" : "symbol" : "1.0-0"}}</div>
        <div class='facilitysummarydesc'>(Direct cost: compensation, supplies, medications, utilities)</div>

        <div class='facilitysummarycost'>{{m.CostMinutes | number}} Minutes</div>
        <div class='facilitysummarydesc'>({{m.CostMinutesDescr}})</div>
      </td>
    </tr>
  </table>
</div>
