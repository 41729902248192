import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Workflow } from '../Workflow';

@Component({
  selector: 'app-workflowmap',
  templateUrl: './workflowmap.component.html',
  styleUrls: ['./workflowmap.component.css']
})
export class WorkflowmapComponent implements OnInit {

  @Input() container: string = '';
  @Input() viewmode: string = '';
  @Input() workflowData: any;
  @Input() surgeryScheduleData: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<Workflow> = new EventEmitter();

  workflow!: Workflow;

  constructor() { }

  LoadWorkflowMap() {
    if (this.viewmode == 'details')
      this.configuration = { showLabels: true, barHeight: 15, barPadding: 10 };
    else
      this.configuration = { showLabels: false, barHeight: 5, barPadding: 1 };

    this.workflow = new Workflow(this.container, this.workflowData, this.surgeryScheduleData, this.configuration);

    this.workflow.Render();
    this.myOutput.emit(this.workflow);
  }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.LoadWorkflowMap();
  }
  
}
