<header class="navbar  navbar-light sticky-top d-print-none">
  <div class="container-xl">
    <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
      <a href=".">
        <img src="../assets/logo.PNG" width="110" height="32" alt="Tabler" class="navbar-brand-image">
      </a>
    </h1>
    <div class="navbar-nav flex-row order-md-last">
      <div class="nav-item d-none d-md-flex me-3">

      </div>

      <div class="nav-item dropdown">
        <div class="nav-link d-flex lh-1 text-reset p-0">
          <span class="avatar avatar-sm" style="background-image: url(./static/avatars/000m.jpg)"></span>
          <div class="d-none d-xl-block ps-2">
            <div>
              {{user}} <a (click)="logout()" style="cursor:pointer !important">
                (Logout)
              </a>
            </div>
            <!--<div></div>-->
            <div class="mt-1 small ">
              {{lastUpdateddate}}

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</header>

<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Block Requests
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">

  <div class="container-xl">
    <div style="padding-bottom: 5px;">
      <button type="button" class="btn btn-primary" onclick="window.open('https://redcap.iths.org/surveys/?s=HL4DJHATPL','_blank');">
        Submit New Request
      </button>
    </div>

    <div class="card-body-scrollable card-body-scrollable-shadow" *ngIf="blockrequest!=undefined">
      <table class="casemixgraphic fixTableHead ">
        <thead>
          <tr>
            <ng-container *ngFor="let c of columns;">
              <th>
                {{c.name}}
              </th>
            </ng-container>
          </tr>
        </thead>
        <caption *ngIf="blockrequest!=undefined && blockrequest.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
        <tbody>

          <tr *ngFor="let b of blockrequest">
            <td>{{b.RequestID}}</td>
            <td>{{b.RequestDate| date:'MM/dd/yyyy'}} </td>
            <td>{{b.RequesterName}}</td>
            <td>{{b.RequesterEmail}}</td>
            <td>{{b.SurgService}}</td>
            <td>{{b.DayOfWeek}}</td>
            <td>{{b.Frequency}}</td>
            <td>{{b.Justification}}</td>
            <td>{{b.Status}}</td>
            <td>{{b.StatusComment}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
