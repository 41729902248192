<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'glucose-management'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Glucose Management for
          &nbsp;
          <span *ngIf="filters!=undefined && filters?.provider=='All'">
            <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
            {{filters?.facility=="All"?'All Facilities':''}}&nbsp;
            <span *ngIf="filters!=undefined && filters?.surgeService!='All'">{{filters?.surgeService}}</span>&nbsp; {{filters?.surgeService=="All"?'All Services':''}}&nbsp;
            {{filters?.staffRole+'s'}} &nbsp;
          </span>
          <span *ngIf="filters!=undefined && filters?.provider!='All'">
            {{filters?.provider!="All"?filters?.providerName:''}} &nbsp;
          </span>
          <span *ngIf="glucosecohorts">{{GetGlucoseCohortCount('Total Cases')}}</span>   &nbsp; Total Cases
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">
  <div class="container-xl">

    <div>
      Glucose metrics are calculated for inpatient cases only. The count of these cases are under "+ Inpatient" in the Glucose Summary. Each metric may have additional case exclusions which you can view by clicking on the metric title.
      Use the buttons below to toggle (blue indicates include these cases) whether to include patients on gliflozin, diabetic patients, or cases starting during normal work hours (weekdays 7:30am-5pm) vs outside of normal work hours.
    </div>
    <div id="filters">
      <div class="btn-group btn-group-toggle d-flex flex-column flex-md-row" data-toggle="buttons">
        <div class="cat">
          <label>
            <input type="checkbox" id="gliflozinfilter" checked="checked" (click)="LoadData()"><span>gliflozin</span>
          </label>
          <label>
            <input type="checkbox" id="nogliflozinfilter" checked="checked" (click)="LoadData()"><span>no gliflozin</span>
          </label>
        </div>

        <div class="cat">
          <label>
            <input type="checkbox" id="diabetesfilter" checked="checked" (click)="LoadData()"><span>diabetes</span>
          </label>
          <label>
            <input type="checkbox" id="nodiabetesfilter" checked="checked" (click)="LoadData()"><span>no diabetes</span>
          </label>
        </div>

        <div class="cat">
          <label>
            <input type="checkbox" id="normalhoursfilter" checked="checked" (click)="LoadData()"><span>normal hours</span>
          </label>
          <label>
            <input type="checkbox" id="afterhoursfilter" checked="checked" (click)="LoadData()"><span>after hours</span>
          </label>
        </div>
      </div>
    </div>

    <div class="row row-cards" style="padding:10px 0px">

      <ng-container *ngFor="let m of metrics; index as i;">
        <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6">
          <div class="card">
            <div class="card-header metrictitle click-header" style="border:0px !important">
              <h4 class="card-title" style="cursor:pointer;">
                <span data-bs-toggle="modal" data-bs-target="#modal-metric" (click)="GetMetricDescription(m.Metric,m.MetricName)">{{m.MetricName}}</span>
              </h4>

            </div>

            <div class="card-body  click-body" style="text-align: center!important;" *ngIf="m.Metric=='GlucoseSummary'">
              <div class="glucose" id="{{m.Metric}}">
                <table>
                  <tr>
                    <th>Total Cases</th>
                    <td>{{GetGlucoseCohortCount('Total Cases')}}</td>
                  </tr>
                  <tr>
                    <td style="color: #e0e2e5; background-color: #606f7c">+ Inpatient</td>
                    <td>{{GetGlucoseCohortCount('+ Inpatient')}}</td>
                  </tr>
                  <tr>
                    <td style="color: #e0e2e5; background-color: #606f7c">+ Over 90 minutes</td>
                    <td>{{GetGlucoseCohortCount('+ Over 90 minutes')}}</td>
                  </tr>
                </table>

                <table>
                  <tr>
                    <th>{{GetGlucoseCaseCount('GlucoseSummary')}} Cases</th>
                    <th>%</th>
                    <th>#</th>
                  </tr>
                  <tr *ngFor="let g of GetGlucoseMetric('GlucoseSummary')" (click)="GetGlucoseCases('GlucoseSummary', 'Glucose Counts', g.Value)" data-bs-toggle="modal" data-bs-target="#modal-metric-glucosecasedetail">
                    <td style="color: #e0e2e5; background-color: #606f7c">{{g.Value}}</td>
                    <td>{{g.ValuePercent}}</td>
                    <td>{{g.ValueCount}}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="card-body  click-body" style="text-align: center!important;" *ngIf="m.Metric!='GlucoseSummary'">
              <ng-container *ngIf="GetGlucoseCaseCount(m.Metric)>0">
                <div class="glucose" id="{{m.Metric}}">
                  <table>
                    <tr>
                      <th>{{GetGlucoseCaseCount(m.Metric)}} Cases</th>
                      <th>%</th>
                      <th>#</th>
                    </tr>
                    <tr *ngFor="let g of GetGlucoseMetric(m.Metric)" (click)="GetGlucoseCases(m.Metric, m.MetricName, g.Value)" data-bs-toggle="modal" data-bs-target="#modal-metric-glucosecasedetail">
                      <td style="color: #e0e2e5; background-color: {{colormap[g.Value]|| '#606f7c'}}">{{g.Value}}</td>
                      <td>{{g.ValuePercent}}</td>
                      <td>{{g.ValueCount}}</td>
                    </tr>
                  </table>
                </div>

              </ng-container>
              <div *ngIf="GetGlucoseMetric(m.Metric).length<=0" style="text-align:center;">
                No Data Available
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal modal-blur fade show" id="modal-metric" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Definition of {{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div [innerHtml]="metricDesc"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-blur fade show" id="modal-metric-glucosecasedetail" tabindex="-1" style="display: none; "
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered tmodal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">{{metricName}} Cases</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="glucosecases!=undefined">
        <div class="row" style="padding:10px 0px">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <div [innerHtml]="metricDesc"></div>
              </div>
            </div>           
            <div class="btn-group btn-group-toggle d-flex flex-column flex-md-row" data-toggle="buttons">
              <div class="cat" *ngFor="let m of metricoptions">
                <label *ngIf="m.Value==metricValue">
                  <input type="radio" name="metricoption" checked="checked" (click)="GetGlucoseCases(metric, metricName, m.Value)"><span>{{m.Value}}</span>
                </label>
                <label *ngIf="m.Value!=metricValue">
                  <input type="radio" name="metricoption" (click)="GetGlucoseCases(metric, metricName, m.Value)"><span>{{m.Value}}</span>
                </label>
              </div>
            </div>
            <div class="card" style="height: 45vh !important">

              <div class="card-body-scrollable card-body-scrollable-shadow">

                <table class="casemixgraphic fixTableHead">
                  <thead>
                    <tr>
                      <th style="min-width:80px !important;">Case Date</th>
                      <th style="min-width:80px !important;">Room</th>
                      <th style="min-width:150px !important;max-width:150px !important;">Procedure Description</th>
                    </tr>
                  </thead>
                  <caption *ngIf="glucosecases!=undefined && glucosecases.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>

                  <tbody *ngFor="let c of glucosecases">
                    <tr (click)="GetGlucoseCaseDetails(c.ProcID)" class="rowhighlight">
                      <td>{{c.CaseDate | date:'shortDate' }}</td>
                      <td>{{c.Room}}</td>
                      <td>{{c.ProcedureDescription}}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
            <div class="col-md-6">
              <div class="card" style="height: 80vh !important">
                <div class="card-body card-body-scrollable card-body-scrollable-shadow">
                  <h2>Procedure Details</h2>

                  <table class="table card-table table-vcenter" *ngIf="selectedcase!=undefined">

                    <caption *ngIf="selectedcase!=undefined && selectedcase.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
                    <tbody *ngFor="let c of metricfields">
                      <tr *ngIf="c.Field=='CaseDate'">
                        <td class="faildetailfield">{{getORBISClosedCaseFields(c.Field)}}</td>
                        <td>{{getORBISClosedCaseFieldValue(c.Field)| date:'shortDate' }}</td>

                      </tr>
                      <tr *ngIf="c.Field!='CaseDate' && timeComponents.indexOf(c.Field)>-1">

                        <td class="faildetailfield">{{getORBISClosedCaseFields(c.Field)}}</td>

                        <td>{{getORBISClosedCaseFieldValue(c.Field)  | date:'HH:mm' }}</td>
                      </tr>

                      <tr *ngIf="c.Field!='CaseDate' &&  timeComponents.indexOf(c.Field)==-1 && !c.Field.includes('Glucose')">

                        <td class="faildetailfield">{{getORBISClosedCaseFields(c.Field)}}</td>

                        <td>{{getORBISClosedCaseFieldValue(c.Field) }}</td>
                      </tr>

                      <tr *ngIf="c.Field!='CaseDate' &&  timeComponents.indexOf(c.Field)==-1 && c.Field.includes('Glucose')">

                        <td class="faildetailfield">{{getORBISClosedCaseFields(c.Field)}}</td>

                        <td>{{getORBISGlucoseValue(c.Field) }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <h2>Glucose Details</h2>

                  <table class="casemixgraphic fixTableHead" *ngIf="glucosecasedetails!=undefined">
                    <thead>
                      <tr>
                        <th>Event Time</th>
                        <th>Event Name</th>
                        <th>Minutes Elapsed</th>
                      </tr>
                    </thead>
                    <caption *ngIf="glucosecasedetails!=undefined && glucosecasedetails.length == 0" style="text-align:center;font-weight:600"> No Case Details</caption>
                    <tbody>
                      <tr *ngFor="let c of glucosecasedetails">
                        <td>{{c.EventDtTm | date:'HH:mm'}}</td>
                        <td>{{c.EventName}}</td>
                        <td>{{c.MinutesElapsed}}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>-
          </div>
      </div>
    </div>
  </div>
</div>
          
