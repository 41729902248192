import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-block-schedule',
  templateUrl: './block-schedule.component.html',
  styleUrls: ['./block-schedule.component.css']
})
export class BlockScheduleComponent implements OnInit {
  filters: Filters;
  currentTab: TabComponent;

  blocktimes : any;
  blockschedule : any;
  blockschedulerooms: any;
  daysofweek: Array<string> = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  currentdayofweek: string = "Mon";

  constructor(private commonService: CommonService) {
    this.currentTab = new TabComponent();
    this.currentTab.active = true;
    this.currentTab.id = "Mon";
    this.currentTab.title = "Mon";
  }

  ngOnInit(): void {
  }

  OnFilter(e: Filters) {
    this.filters = e;
    this.blocktimes = undefined;
    this.blockschedule = undefined;
    this.blockschedulerooms = undefined;
    this.LoadData();
  }
  OnSelectTab(t: TabComponent) {
    this.currentTab = t;
    this.currentdayofweek = t.id;
    this.LoadData();
  }
  LoadData() {
    this.commonService.GetBlockSchedule(this.filters.facility, this.filters.location, this.filters.year, this.filters.month, this.currentdayofweek).subscribe(d => {
      this.blocktimes = d["blocktimes"];
      this.blockschedule = d["blockschedule"];
      this.blockschedulerooms = d["blockschedulerooms"];
    })
  }

  GetScheduleBlockTime(blocktime: string) {
    var b = this.blockschedule.filter(b => blocktime >= b.BlockStartTime && blocktime < b.BlockStopTime);
    if (b != undefined && b.length > 0) {
      return b.sort((x, y) => x.Room > y.Room ? 1 : -1);
    }
    else {
      return [];
    }
  }

  GetBlockForTimeRoom(blocktime: string, blockroom: string) {
    var b = this.blockschedule.filter(b => blocktime >= b.BlockStartTime && blocktime < b.BlockStopTime && blockroom == b.Room);
    if (b != undefined && b.length > 0) {
      return b.sort((x, y) => x.BlockWeek > y.BlockWeek ? 1 : -1);
    }
    else {
      return [];
    }
  }
}
