export class CommonChartModel {
  width: number = 0;
  height: number = 0;
  margin_left: number = 0;
  margin_right: number = 0;
  margin_top: number = 0;
  margin_bottom: number = 0;
  color_scale: any | undefined = undefined;
  container: string = '';
}
export class DisplayModel {
  display_text: string = '';
  display_key: string = '';
}
