import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class Bar {
  private config: any = {
    height: 400,
    width: 600,
    margin_top: 30,
    margin_right: 115,
    margin_bottom: 60,
    margin_left: 50,
    yrange: "calculate",
    datefield: "CaseDate",
    datefilter: "Day",
    field: "Count",
    metric: "NumCases",
    group_by: "Id",
    group_by_label: "GroupBy",
    categories: [],
    selectedcategory: "",
    ylabel: "",
    xlabel: "",
    target: "0",
    clickpopup: "",
    clickfunction: ""
  };

  private svg: any = undefined;
  private barcolors: Array<any> =
    [
      { barcolor: "#a7b8c6", display: "hide" },
      { barcolor: "#2b506d", display: "hide" },
      { barcolor: "#6b4744", display: "hide" },
      { barcolor: "#547c6f", display: "hide" },
      { barcolor: "#6a6d5e", display: "hide" },
      { barcolor: "#bab3a1", display: "hide" },
      { barcolor: "#12356d", display: "hide" },
      { barcolor: "#676d64", display: "hide" },
      { barcolor: "#938076", display: "hide" },
      { barcolor: "#d1b2ab", display: "hide" },
      { barcolor: "#769675", display: "hide" },
      { barcolor: "#bbd6ba", display: "hide" },
      { barcolor: "#9aa363", display: "hide" },
      { barcolor: "#736f8e", display: "hide" },
      { barcolor: "#0f4368", display: "hide" },
      { barcolor: "#5e2452", display: "hide" },
      { barcolor: "#6c7259", display: "hide" },
      { barcolor: "#997238", display: "hide" },
      { barcolor: "#68999e", display: "hide" },

      { barcolor: "#333399", display: "hide" },
      { barcolor: "#336633", display: "hide" },
      { barcolor: "#999900", display: "hide" },
      { barcolor: "#990099", display: "hide" },
      { barcolor: "#009999", display: "hide" },
      { barcolor: "#333366", display: "hide" },
      { barcolor: "#660000", display: "hide" },
      { barcolor: "#99ff66", display: "hide" },
      { barcolor: "#99cccc", display: "hide" },
      { barcolor: "#000033", display: "hide" },
      { barcolor: "#ffff99", display: "hide" },
      { barcolor: "#cccc33", display: "hide" },
      { barcolor: "#b99cba", display: "hide" },
      { barcolor: "#757ba0", display: "hide" },
      { barcolor: "#57725e", display: "hide" },
      { barcolor: "#b28c7c", display: "hide" },
      { barcolor: "#365587", display: "hide" },
      { barcolor: "#933966", display: "hide" }
    ]

  private mindate: any = undefined;
  private maxdate: any = undefined;
  private minrange: any = undefined;
  private maxrange: any = undefined;
  private x0: any = undefined;
  private y: any = undefined;
  private x1: any = undefined;
  private casedateGroup: any = undefined;
  private locationGroup: any = undefined;
  private showLocation: any = undefined;

  private categoryids: any = undefined;
  private groupByColumn: any = undefined;
  private tickFormat: any;
  private xlabel: any = "Month";
  private json: any = undefined;
  private container: string;
  private configuration: any;
  private filter: any;
  private caseDateGroup: any;

  constructor(container: string, json: any, configuration: any) {
    this.container = container;
    this.json = json;
    this.configuration = configuration;
    this.configure(this.configuration);
  }

  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    this.groupByColumn = this.config.group_by;

    //first date represented in data
    this.mindate = d3.min(this.json, (d: any) => {
      var dat = d[this.config.datefield].split("-");

      return new Date(parseInt(dat[0]), parseInt(dat[1]) - 1, parseInt(dat[1]));
    });

    //last date represented in data
    this.maxdate = d3.max(this.json, (d: any) => {
      var dat = d[this.config.datefield].split("-");
      return new Date(parseInt(dat[0]), parseInt(dat[1]) - 1, parseInt(dat[1]));
    });
    this.x0 = d3.scaleBand()
      .range([this.config.margin_left, this.config.width - this.config.margin_right])

    //scale on x-axis for each service within a date in the dataset
    //bars for all services drawn in a group under each date 
    this.x1 = d3.scaleBand()
      .padding(0.05);

    if (this.config.group_by == "ORBISSurgServiceID" || this.config.group_by == "FacilitySurgServiceID" ||
      this.config.group_by == "ORBISFacilityOptionID") {
      //group data by a time period based on the number of dates in the dataset
      //var countDates = d3.map(json, function (d) { return new Date(parseInt(d.CaseDate.substr(6))); }).size();

      if (this.config.datefilter.toLowerCase() == "day") {
        this.tickFormat = "%m-%d";
        this.xlabel = 'Date';
      }
      else if (this.config.datefilter.toLowerCase() == "week") {
        this.tickFormat = "%m-%d";
        this.xlabel = 'Week';
      }
      else if (this.config.datefilter.toLowerCase() == "month") {
        this.filter = 'Month';
        this.tickFormat = "%m-%Y";
        this.xlabel = 'Month';
      }
      else if (this.config.datefilter.toLowerCase() == "quarter") {

        this.xlabel = 'Quarter';
      }
      else {
        this.tickFormat = "%Y";
        this.xlabel = 'Year';
      }

      //group the data by case date
      //filter to include only records from time period determined by the number of dates (e.g. day, week, month, etc)
      this.caseDateGroup = nest()
        .key((d: any) => {
          return d[this.config.datefield];
        })//.sortKeys(d3.ascending)
        .entries(this.json);

      //group the data by the field specified in the configuration (e.g. Service)
      this.locationGroup = nest()
        .key((d: any) => {
          return d[this.groupByColumn];
        }).sortKeys(d3.ascending)
        .entries(this.json);
    }
    else {
     
      if (this.config.datefilter.toLowerCase() == "day") {
        this.tickFormat = "%m-%d";
        this.xlabel = 'Date';
      }
      else if (this.config.datefilter.toLowerCase() == "week") {
        this.tickFormat = "%m-%d";
        this.xlabel = 'Week';
      }
      else if (this.config.datefilter.toLowerCase() == "month") {
        this.filter = 'Month';
        this.tickFormat = "%m-%Y";
        this.xlabel = 'Month';
      }
      else if (this.config.datefilter.toLowerCase() == "quarter") {

        this.xlabel = 'Quarter';
      }
      else {
        this.tickFormat = "%Y";
        this.xlabel = 'Year';
      }

      //group the data by the field specifying date as specified in the configuration (e.g. CaseMonth)            
      this.caseDateGroup = nest()
        .key((d: any) => {
          return d[this.config.datefield];
        })//.sortKeys(d3.ascending)
        .entries(this.json);

      //group the data by the field specified in the configuration (e.g. Service)
      this.locationGroup = nest()
        .key((d: any) => {
          //return d.SurgServiceID;
          return d[this.groupByColumn];
        }).sortKeys(d3.ascending)
        .entries(this.json);
    }

    //Determine which services to show in bar chart based on user toggles
    if (this.config.group_by != null) {
      this.config.categories.forEach((d: any, i) => {
        if (d[this.config.group_by_label] == this.config.selectedcategory || this.config.selectedcategory == 'SelectAll') {
          this.barcolors[d[this.groupByColumn]].display = "show";
        }
      });
    }
    else {
      this.barcolors[0].display = "show";
    }
  }

  Render() {
    this.draw();
  }
  private draw() {

    if (this.config.group_by != null) {
      //determine groups to show (e.g. selected Services) in the barchart
      var locationFiltered = this.locationGroup.filter((d: any) => { return this.barcolors[d.key].display == "show" });

      this.categoryids = values(locationFiltered).map((d: any) => { return d.key });
      this.x0.domain(values(this.caseDateGroup).map((d: any) => {
        var dat = d.key.split("-");
        var m = parseInt(dat[1]);
        if (m > 12)
          m = m - 12
        if (m == 0)
          m = 1
        return new Date(parseInt(dat[0]), m - 1, parseInt(dat[2]));
      }));
      this.x1.domain(this.categoryids).rangeRound([0, this.x0.bandwidth()]);
    }
    else {
      //scale the bars assuming only one group (i.e. do not need to show bars for each service)
      this.x0.domain(values(this.caseDateGroup).map((d: any) => {
        var dat = d.key.split("-");
        var m = parseInt(dat[1]);
        if (m > 12)
          m = m - 12
        if (m == 0)
          m = 1
        return new Date(parseInt(dat[0]), m - 1, parseInt(dat[2]));
      }));
      this.x1.domain([0]).rangeRound([0, this.x0.bandwidth()]);
    }


    //set range based on if metric is measured in percentage or by the value of some field in the dataset
    if (this.config.yrange == 'percentage') {
      this.minrange = 0;
      this.maxrange = 100;
    }
    else {
      let keys = locationFiltered.map(k => k.key);
      let js = this.json.filter(f => keys.indexOf(f.Id.toString()) > -1);
      this.minrange = 0;
      this.maxrange = d3.max(js, (d: any) => {
        return d[this.config.field];
      });
    }
    //scale data values on the y-axis
    this.y = d3.scaleLinear()
      .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
      .domain([this.minrange, this.maxrange]);

    d3.select(this.container).select("div").remove();
    //Draw the image
    this.svg = d3.select(this.container)
      .append("div")
      .classed("barchartcontainer", true)
      .append('svg:svg')
      .attr('viewBox', "0 0 " + this.config.width + " " + this.config.height)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    var _tooltip = d3.select(this.container)
      .append("div")
      .attr("class", "d3-div")
      .style("display", "none")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("font-weight", "bold")
      .style("padding", "10px")
      .style("background", "#fff")
      .style("color", "#000")
      .style("border-radius", "2px")
     .style("border", "solid #ccc 1px");

    //draw the title for the x-axis
    this.svg.append("text")
      .attr("class", "barchartlabel")
      .attr("text-anchor", "middle")
      .attr("transform", "translate(" + (this.config.margin_left + (this.config.width - this.config.margin_left - this.config.margin_right) / 2) + "," + (this.config.height - 10) + ")")
      .text(this.xlabel);

    //append the x-axis
    this.svg.append("g")
      .attr("class", "reportaxis")
      .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x0)
        .tickFormat((x: any) => {
          if (this.config.datefilter.toLowerCase() == "quarter") {
            return x.getFullYear().toString() + " - Q" + (x.getMonth() + 1).toString();
          }
          else {
            return d3.timeFormat(this.tickFormat)(x);
          }

        })
        .tickPadding(0)
      )
      .selectAll("text")
      .attr("transform", "rotate(40)")
      .style("text-anchor", "start");

    //draw the title for y-axis
    this.svg.append("text")
      .attr("class", "barchartlabel")
      .attr("text-anchor", "middle")
      .attr("transform", "translate(10," + ((this.config.height - this.config.margin_bottom) / 2) + ")rotate(-90)")
      .text(this.config.ylabel);

    //append the y-axis
    this.svg.append("g")
      .attr("class", "reportaxis")
      .attr("transform", "translate(" + this.config.margin_left + ",0)")
      .call(d3.axisLeft(this.y)
        //.ticks(this.maxrange < 10 ? this.maxrange : 10)
        .ticks(this.maxrange < 10 ? 5 : 10)
      );

    //display the bars for each location
    this.caseDateGroup.forEach((d, i) => {
      this.svg.append("g")
        .selectAll("g")
        .data(d.values)
        .enter().append("g")
        .attr("transform", (d: any) => {
          var dat = d[this.config.datefield].split("-");

          var m = parseInt(dat[1]);
          if (m > 12)
            m = m - 12
          if (m == 0)
            m = 1

          return "translate(" + this.x0(new Date(parseInt(dat[0]), m - 1, parseInt(dat[2]))) + ",0)";
        })
        .selectAll("rect")
        .data(d.values)
        .enter().append("rect")
        .filter((v: any) => { return this.barcolors[v[this.groupByColumn]].display == "show" })
        .attr("x", (v: any) => {
          return this.x1(v[this.groupByColumn].toString());
        })

        .attr("width", this.x1.bandwidth())
        .attr("fill", (v: any) => { return this.barcolors[v[this.groupByColumn]].barcolor; })
        .attr("fill-color", (v: any) => { return this.barcolors[v[this.groupByColumn]].barcolor; })
        .on("mouseover", function (v) {

          d3.select(this)
            .style("cursor", "pointer")
            .attr("fill", "#757472");
          _tooltip.style("display", "block").style("opacity", 1);

        })
        .on("mousemove", (d: any, val: any) => {
          let w = this.config.width - this.config.marginleft - this.config.marginright;
          _tooltip.style("top", (d.layerY + 15) + 'px').style("left", (w < d.layerX + 250 ? d.layerX - 100 : d.layerX) + 'px')
            .style("display", "block").style("opacity", 1).style("height", "auto")
            .html(() => {
              return "<div>" + val[this.config.group_by_label].toString() +"<span><b>&nbsp;:&nbsp;"+ val[this.config.field].toString()+"</b></span></div>";
            });
        })
        .on("mouseout", function () {
          var color = d3.select(this).attr("fill-color");
          d3.select(this).attr("fill", color);
          _tooltip.style("display", "block").style("opacity", 0);
        })
        .on("click", (v: any) => {
          if (this.config.clickpopup != "") {
            var dat = d.key.split("-");
            var m = parseInt(dat[1]);
            if (m > 12)
              m = m - 12
            if (m == 0)
              m = 1
            var bardate = new Date(parseInt(dat[0]), m - 1, parseInt(dat[1]));
            var baryear = bardate.getFullYear();
            var barmonth = bardate.getMonth() + 1;
            var elemid = v[this.config.group_by_label] + "_" + this.config.metric;
          }
        })

        .attr("height", 0)
        .attr("y", this.config.height - this.config.margin_bottom)
        .transition()
        .duration(1500)
        .attr("y", (v: any) => { return this.y(v[this.config.field]); })
        .attr("height", (v: any) => { return this.config.height - this.config.margin_bottom - this.y(v[this.config.field]); })

      //add red dashed line for target
      if (parseInt(this.config.target) > 0) {
        this.svg.append("line")
          .attr("class", "targetline")
          .attr("x1", this.config.margin_left)
          .attr("x2", this.config.width - this.config.margin_right)
          .attr("y1", this.y(parseInt(this.config.target)))
          .attr("y2", this.y(parseInt(this.config.target)));

        //add target to legend
        this.svg.append("line")
          .attr("class", "targetline")
          .attr("x1", this.config.width - this.config.margin_right + 10)
          .attr("x2", this.config.width - this.config.margin_right + 30)
          .attr("y1", this.config.margin_top)
          .attr("y2", this.config.margin_top);

        //add target text to legend
        this.svg.append('text')
          .attr("class", "linelegend")
          .attr('x', this.config.width - this.config.margin_right + 35)
          .attr('y', this.config.margin_top + 5)
          .text("Target");
      }
    });

    //Add legend for each group by value
    if (this.config.group_by != null) {
      this.config.categories.forEach((d, i) => {

        //draw box with color code
        this.svg.append('rect')
          .attr('x', this.config.width - this.config.margin_right + 10)
          .attr('y', this.config.margin_top + 20 * (i + 1) - 8)
          .attr('width', 20)
          .attr('height', 15)
          .attr('stroke-width', 2)
          .attr('fill', this.barcolors[d[this.groupByColumn]].barcolor)
          .on("mouseover", () => {

          })
          .on("click", () => {
            //toggle display state of location
            this.barcolors[d[this.groupByColumn]].display = this.barcolors[d[this.groupByColumn]].display == "show" ? "hide" : "show";
            d3.select(this.container + " div").remove();
            this.draw();
          });

        //draw text of group by value (e.g. Service)
        this.svg.append('text')
          .attr("class", "linelegend")
          .attr('x', this.config.width - this.config.margin_right + 35)
          .attr('y', this.config.margin_top + 20 * (i + 1) + 5)
          .attr('fill', this.barcolors[d[this.groupByColumn]].display == "show" ? '#2b4163' : '#babec4')
          .text(d[this.config.group_by_label])
      })
    }
  }

  public update(updateBarChartData: any, newConfiguration: any) {

    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }

    this.json = updateBarChartData;
    d3.select("" + this.container).select("svg").remove();
    this.draw();
  }
}
