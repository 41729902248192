<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'caselog'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Patient Case Log
          &nbsp;
          <span *ngIf="filters!=undefined && filters?.provider=='All'">
            <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
            {{filters?.facility=="All"?'All Facilities':''}}&nbsp;
            <span *ngIf="filters!=undefined && filters?.surgeService!='All'">{{filters?.surgeService}}</span>&nbsp; {{filters?.surgeService=="All"?'All Services':''}}&nbsp;
            {{filters?.staffRole+'s'}} &nbsp;
          </span>
          <span *ngIf="filters!=undefined && filters?.provider!='All'">
            {{filters?.provider!="All"?filters?.providerName:''}} &nbsp;
          </span>{{totalrecords}} Total Cases

          &nbsp;
          &nbsp;
          &nbsp;
          <app-download-dialog (download)="DownloadCallBack($event)" (sendemail)="SendEmailCallBack($event)" [reporttype]="'CaseLog'"></app-download-dialog>
          <!--<button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modal-password">
    Download Excel
  </button>-->
        </h2>

      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-body">

  <div class="container-xl">

    <div class="card" style="max-height: calc(100vh - 260px);" *ngIf="caselogs!=undefined && metricfields!=undefined">
      <div class="card-body-scrollable card-body-scrollable-shadow">

        <table class="casemixgraphic fixTableHead " *ngIf="caselogs!=undefined">


          <thead>
            <tr>
              <ng-container *ngFor="let c of metricfields">
                <th (click)="Sort(c.Field)" style="cursor:pointer;" *ngIf="c.Field!='CaseDate' && c.Field!='InRoom' && c.Field!='LeaveOR' && c.Field!='AnesStart' && c.Field!='Room'">
                  {{getORBISClosedCaseFields(c.Field)}}
                  <span *ngIf="sortObjects[c.Field]!=undefined && sortObjects[c.Field]=='ASC'">
                    <img src="../../assets/sortasc.png" />
                  </span>
                  <span *ngIf="sortObjects[c.Field]!=undefined && sortObjects[c.Field]=='DESC'">
                    <img src="../../assets/sortdesc.png" />
                  </span>
                </th>
                <th (click)="Sort(c.Field)" *ngIf="c.Field=='CaseDate' || c.Field=='InRoom' || c.Field=='LeaveOR' || c.Field=='AnesStart' || c.Field=='Room'" style="cursor: pointer; min-width: 70px !important">
                  {{getORBISClosedCaseFields(c.Field)}}
                  <span *ngIf="sortObjects[c.Field]!=undefined && sortObjects[c.Field]=='ASC'">
                    <img src="../../assets/sortasc.png" />
                  </span>
                  <span *ngIf="sortObjects[c.Field]!=undefined && sortObjects[c.Field]=='DESC'">
                    <img src="../../assets/sortdesc.png" />
                  </span>
                </th>

              </ng-container>
            </tr>
          </thead>
          <caption *ngIf="caselogs!=undefined && caselogs.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
          <tbody>
            <tr *ngFor="let c of caselogs">
              <ng-container *ngFor="let d of metricfields">

                <td *ngIf="d.Field!='CaseDate' && d.Field!='InRoom' && d.Field!='LeaveOR' && d.Field!='AnesStart' && d.Field!='Room'">
                  {{c[d.Field] }}
                </td>
                <td *ngIf="d.Field=='Room'">
                  {{c[d.Field] }}
                </td>
                <td *ngIf="d.Field=='CaseDate' ">{{c[d.Field] | date:'MM/dd/yyyy' }}</td>
                <td *ngIf="d.Field=='InRoom' || d.Field=='LeaveOR'">
                  {{
c[d.Field] |  date:'MM/dd/yyyy h:mm a'
                  }}
                </td>
                <td *ngIf="d.Field=='AnesStart'">
                  {{
c[d.Field] | date:'h:mm a'
                  }}
                </td>


              </ng-container>
            </tr>


          </tbody>

        </table>

      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-pagination [RecordsPerPage]="100" [TotalRecords]="totalrecords" (pageEvent)="PageEvent($event)" [CurrentPage]="page.currentPage">
          this.reportName == "caselog"
        </app-pagination>
      </div>
    </div>

  </div>

</div>

<!--<button id="openModalButton" [hidden]="true" data-bs-toggle="modal" data-bs-target="#modal-confirm-download">Open Modal</button>
<div class="modal modal-blur fade show" id="modal-confirm-download" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="width: 500px;height:50px;overflow-y:auto">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Data download attestation</h5>
        <button type="button" class="btn-close" id="closeModalButton" data-bs-dismiss="modal" aria-label="Close" (click)=" isAgreed = false;"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;">
        <div class="card">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div style="padding: 7px;">
              <div>
                <p>
                  Raw data elements from ORBIS contain ePHI and downloading them requires acknowledgement of responsibility for correct use.
                </p>
                <p>
                  By downloading, you confirm that you will take appropriate precautions to avoid misuse, including but not limited to:
                </p>
                <div>
                  <ul>
                    <li>Avoiding distribution of elements of ePHI from this file</li>
                    <li>Compliance with Institutional standards for device security on any device to which a file is downloaded or retained</li>
                    <li>Avoiding sharing images or screens of the file directly with ePHI elements left unobscured</li>
                  </ul>
                </div>
              </div>
              <hr style="margin:3px;" />
              <div style="text-align: center;font-weight:bold;font-size:19px;">
                <input style="zoom: 1.5; border: 2px solid gray;" class="form-check-input" type="checkbox" [(ngModel)]="isAgreed">&nbsp;<span style="top:4px;position:relative;">Agree</span>   <br />  <br />
                <button type="button" class="btn btn-link" [disabled]="!isAgreed" (click)="CheckPasswordAgreeAndDownload(false)">
                  Download
                </button>
                <button type="button" class="btn btn-link" [disabled]="!isAgreed" (click)="CheckPasswordAgreeAndDownload(true)">
                  Email
                </button>
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal modal-blur fade show" id="modal-password" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Set downloaded file password</h5>

        <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="text-align:center;">
        <input type="password" [(ngModel)]="password" placeholder="Set downloaded file password" style="width:250px;height:33px;" />&nbsp;&nbsp;
        <button type="button" class="btn btn btn-link" (click)="CheckPasswordDownload(false)">
          Download
        </button>
        <button type="button" class="btn btn btn-link" (click)="CheckPasswordDownload(true)">
          Email
        </button>-->
<!--<button type="button" aria-label="Download" (click)="Download()">Download</button>-->
<!--</div>
    </div>
  </div>
</div>-->
