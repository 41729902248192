import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters'

@Component({
  selector: 'app-case-mix',
  templateUrl: './case-mix.component.html',
  styleUrls: ['./case-mix.component.css']
})
export class CaseMixComponent implements OnInit {
  filters: Filters;
  reportmetrics: any;
  resultset; any;
  metric: any;
  metricName: any;
  chartmetricType: any;
  chartmetric: any;
  selectedmetric: any;
  margin: any = { height: 140, margin_top: 20, margin_left: 40, margin_bottom: 30 };
  heightWidth: any;
  totalCases: number;

  constructor(private commonService: CommonService) {
  }
  ngAfterViewInit(): void {
  }
  ngOnInit(): void {
  }
  GetMetric(metricname: string, metric: string) {
    this.metricName = metricname;
    this.metric = metric;
  }
  onchartClick(metricname: string, metric: string, charttype: string) {
    this.metricName = metricname;
    this.chartmetric = undefined;
    this.chartmetricType = undefined;

    this.commonService.GetMetricDescription(metric).subscribe(d => {
      this.chartmetric = metric;
      this.chartmetricType = charttype;
    });
  }
  filtermetric(metric: any) {
    return this.resultset.filter(c => c.Metric.toLowerCase() == metric.toLowerCase());
  }
  OnFilter(e: Filters) {
    this.filters = e;
    this.resultset = undefined;
    this.reportmetrics = undefined;
    this.totalCases = 0;
    this.commonService.GetCaseMix(this.filters.facility, this.filters.location, this.filters.surgeService, this.filters.staffRole, this.filters.provider, this.filters.fromDate, this.filters.toDate).subscribe(d => {
      this.resultset = d["piechartdata"];
      this.reportmetrics = d["reportmetrics"];
      this.totalCases = d["totalcases"];
    });
  }
}
