import { DisplayModel } from "./commonmodel";

export class HBarConfig {
  width: number = 0;
  height: number = 0;
  margin_left: number = 200;
  margin_right: number = 0;
  margin_top: number = 0;
  margin_bottom: number = 0;
  color_scale: any | undefined = undefined;
  container: string = '';
  chart_data: HbarData[] = [];
  xlabel: string = '';
  ylabel: string = '';
  bar_height: number = 0;
  axis_position: 'top' | 'bottom' = "bottom";
  tick_size: number = 5;
  tooltip_keys: DisplayModel[] = [];
  is_bar_text: boolean = true;
  is_tower_chart: boolean = false;
  format: string = "";
  is_linear_color: boolean = false;
  is_rotate_xaxis: boolean = false;
  is_crop_xaxis_length: number = 9999;
  bar_text_size: number;
}
export class HbarData {
  key: string = '';
  data: HData[] = [];

}
export class HData {
  key: string = '';
  value: number = 0;
  color: string = "";
  additional_data: any = {};
  /* For Stacked Bar charts */
  startvalue: number = 0;
  /* For single bar */
  header: string = "";
}
