<div>
  <div class="row">
    <div class="col-7" id="barDiv">

    </div>
  </div>
  <div class="sticky-top">
    <app-filters (onfilter)="OnFilter($event)" [reportName]="'metric'"></app-filters>
  </div>
  <div class="container-xl">
    <!-- Page title -->
  </div>
  <div class="page-body">

    <div class="container-xl">



      <div class="card" style="max-height: calc(100vh - 260px);" *ngIf="metricComparisonData!=undefined && selectedMetrics!=undefined && selectedMetrics.length>0 && metricComparisonGroupedData">
        <div>

          <div class="row">
            <div class="col-4" style="padding: 0px !important">
              <table>
                <tr>
                  <td style="min-width:150px;height:70px;">

                    <div class="row">
                      <div class="col-xl-6 col-md-6 col-sm-12" style="margin-left:-14px">
                        <div class="font-weight-medium" style="font-size:17px;">
                          <b>Metric Comparison</b>
                        </div>
                        <div class="text-muted" style="margin-top:3px">
                          <select class="form-select comp-type" [(ngModel)]="selectedMetricCategory" (change)="GetMetrics()" style=" padding: 9px 10px !important;">
                            <option value="Operational" selected>Operational</option>
                            <option value="Quality">Quality</option>
                            <option value="Outcome">Outcome</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-xl-6 col-md-6 col-sm-12">
                        <div class="font-weight-medium">
                          &nbsp;
                        </div>
                        <!--<div class="font-weight-medium">
                          Metrics
                        </div>-->
                        <div class="text-muted" style="margin-top:3px">
                          <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal-select-filters" style="height: 44px; font-size: 15px;">Display Customization</button>
                          <!--<ng-multiselect-dropdown [placeholder]="'Select Metric'"
                                                   [settings]="metricsMultiSettings"
                                                   [data]="metrics"
                                                   [(ngModel)]="selectedMetrics"
                                                   (onSelect)="OnSelect($event)" (onDeSelect)="OnDeSelect($event)">
                          </ng-multiselect-dropdown>-->
                        </div>
                      </div>





                    </div>




                  </td>
                </tr>
                <tbody>
                  <ng-container *ngFor="let r of selectedMetrics | filter:'selected':true | orderBy: 'DefaultOrder' ">
                    <tr *ngIf="availableMetrics.indexOf(r.Metric)>-1" style="height:50px;">
                      <th style="border-bottom: #fff solid 1px !important; background-color: #e0e2e5; color: #2b4163; font-size: 19px ">
                        <span data-bs-toggle="modal" data-bs-target="#modal-metric" style="position: relative; top: -10px; left: -10px;" (click)="GetMetricDescription(r.Metric,r.MetricName)"><img src="./assets/info.png" style="width:16px;height:16px;cursor:pointer;" /></span>
                        &nbsp; &nbsp;{{ metricComparisonGroupedData[r.Metric][0].MetricName}}
                      </th>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="col-8 card-body-scrollable card-body-scrollable-shadow" style="padding: 0px !important">
              <table class="casemixgraphic fixTableHead " style="width:auto;">
                <thead>
                  <tr>
                    <th *ngFor="let h of headers | filter:'selected':true  | orderBy: 'Header'" style="height: 70px; font-size: 18px; text-align: center; min-width: 220px !important; width: 220px !important">
                      {{h.Header}}
                    </th>
                  </tr>
                </thead>

                <tbody class="card-body-scrollable card-body-scrollable-shadow" style="height:100px!important;">
                  <ng-container *ngFor="let r of selectedMetrics | filter:'selected':true | orderBy: 'DefaultOrder'">
                    <tr *ngIf="availableMetrics.indexOf(r.Metric)>-1" style="height:50px;">
                      <ng-container *ngFor="let d of metricComparisonGroupedData[r.Metric] | filter:'selected':true  | orderBy: 'Header' ;let i=index">
                        <td (click)="GetInsights(r.Metric,r.MetricName,d.Header,d.Title)" data-bs-toggle="modal" data-bs-target="#modal-bar"
                            style="cursor: pointer; min-width: 220px !important; width: 220px !important; padding: 0px " title="{{d.Title}}">
                          <div class="row" style="margin: 5px;   border-radius: 5px;" [style.background-color]="d.BcColor">
                            <!--&#013;-->
                            <div class="col-10" style="text-align: center; padding-top: 14px; font-size: 22px; height: 40px;font-weight:600">{{d.RoundValue}}</div>
                            <div class="col-2" style="text-align: right; padding-right: 5px; padding-top: 5px">
                              <span *ngIf="d.Level!='none'">
                                <img src="assets/{{d.Level}}.svg" style="width: 30px; height: 30px;">
                              </span>
                              <!--<span *ngIf="d.Level=='level'">
      <img src="assets/right.svg" style="width: 30px; height: 30px;">
    </span>
    <span *ngIf="d.Level=='down'">
      <img src="assets/down.svg" style="width: 30px; height: 30px; ">
    </span>
    <span *ngIf="d.Level=='up'">
      <img src="assets/up.svg" style="width: 30px; height: 30px; ">
    </span>-->
                            </div>
                          </div>
                        </td>
                      </ng-container>

                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
 

<div class="modal modal-blur fade show blur" id="modal-metric" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Definition of {{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div [innerHtml]="metric"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-blur fade show blur" id="modal-select-filters" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <table class="casemixgraphic fixTableHead ">
              <tr>
                <th>Metrics</th>
              </tr>
              <ng-container *ngIf="selectedMetrics">
                <tr *ngFor="let m of selectedMetrics | orderBy: 'DefaultOrder' ">
                  <td>
                    <input type="checkbox"
                           [(ngModel)]="m.selected" /> `&nbsp;
                    {{m.MetricName}}
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
          <div class="col-6">
            <table class="casemixgraphic fixTableHead ">
              <tr>
                <th>
                  <span *ngIf="filters && filters?.comptype=='service'">
                    Surgery Services
                  </span>
                  <span *ngIf="filters && filters?.comptype=='facility'">
                    Facilities
                  </span>
                </th>
              </tr>
              <tr *ngFor="let h of headers">
                <td>
                  <input type="checkbox" (change)="onFilterHeader()"
                         [(ngModel)]="h.selected" /> `&nbsp;
                  {{h.Header}}
                </td>
              </tr>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<div class="modal modal-blur fade show blur" id="modal-bar" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document" style="min-width:80vw !important">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title" *ngIf="filters">
          Metric : <span style="font-weight:600;color:blue;">
            {{metricName}}
          </span>&nbsp;
          Period :
          <span style="font-weight:600;color:blue;">
            {{filters.fromDate}}
          </span>
          to
          <span style="font-weight:600;color:blue;">
            {{filters.toDate}}
          </span>&nbsp;

          <span *ngIf="filters && filters?.comptype=='service'">
            Facility :  <span style="font-weight:600;color:blue;">
              {{filters.facility}}
            </span>&nbsp;
            Service :  <span style="font-weight:600;color:blue;">
              {{header}}
            </span>
          </span>
          <span *ngIf="filters && filters?.comptype=='facility'">
            Service :  <span style="font-weight:600;color:blue;">
              {{filters.surgeService}}
            </span>&nbsp;
            Facility :  <span style="font-weight:600;color:blue;">
              {{header}}
            </span>
          </span>
          &nbsp;<span style="font-weight: bold; color: #ff0000;" [innerHtml]="title">
          </span>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p-tabs>
          <p-tab [tabTitle]="'Case Wise Trends'">

            <div style="text-align: center !important" *ngIf="fullbarData!=undefined">
              <app-bar [container]="'#bar11'" [barData]="fullbarData" [configuration]="configuration">
                <div id="bar11" style="width:550px; margin:0 auto"></div>
              </app-bar>
            </div>
          </p-tab>
          <p-tab [tabTitle]="'Insights'">
            <div *ngIf="insightsData" style="height:400px; overflow-y: auto;">
              <app-dynamic-recomendations [recomendations]="insightsData" [recomendationString]="undefined" [recomendationFilter]="insightsFilter"></app-dynamic-recomendations>
            </div>
          </p-tab>
        </p-tabs>
      </div>
    </div>
  </div>
</div>
