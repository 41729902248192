import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Gauge } from '../d3charts/Gauge';
import { Filters } from '../Filters'
import { Page } from '../Page';
import * as d3 from 'd3';

@Component({
  selector: 'app-orsnapshot',
  templateUrl: './orsnapshot.component.html',
  styleUrls: ['./orsnapshot.component.css']
})
export class OrsnapshotComponent implements OnInit {
  casecancelScale: any = d3.scaleOrdinal(["#9b3228", "#708748", "#514263", "#537dc1", "#c67d29", "#2dbee2", "#806a93", "#206d14", "#9d4ca3", "#5e99a8", "#a04c03", "#030668", "#7c6601", "#513839"]);;

  metricDataRevision: string = "";
  metricCode: string;
  insightsData: any;
  // insightsFilter: any;
  metricOrder: any;
  filters: Filters;
  caseVolume: Array<any>;
  failedCasesPercentage: Array<any>;
  cmm: Array<any>;
  metric: string
  metricName: string;
  insightsHeader: string;
  value: number = 50;
  value1: number = 50;
  gauge!: Gauge;
  caseVolumeTotal: number;
  totalrecords: number = 0;
  //totalrecords5Day: number = 0;
  is5Day: string = 'sameday';
  page: Page;
  metricid: string;
  sortObjects: any = {};
  sortColumn: string = 'CaseDate';
  sortOrder: string = 'ASC';
  timeComponents: Array<string> = ['SchedSurgDtTm', 'InRoom', 'ProcStart', 'ProcEnd', 'LeaveOR', 'AnesStart', 'AnesEnd']
  barData: any;
  margin: any = { height: 140, margin_top: 20, margin_left: 40, margin_bottom: 30 };
  fullbarData: any;
  failedcases: any;
  metricfields: any;
  ORBISClosedCaseFields: any;
  ORBISClosedCase: any;
  ORBISFailDetails: any;
  ORBISClosedCaseMetric: any;
  Allcases: any;
  caseCancellation: any;
  caseCancellationPie: Array<any>;

  caseCancellation5Day: any;
  caseCancellationPie5Day: Array<any>;
  caseCancellation3Day: any;
  caseCancellationPie3Day: Array<any>;

  caseCancellationDetails: Array<any>;

  bucket: Array<string> = [];
  category: Array<string> = [];
  service: Array<string> = [];
  //Filters
  caseNumber: string = "";
  selectedBucket: string = "All";
  selectedCategory: string = "All";
  selectedService: string = "All";
  failedDetails: string = "";
  cost: number;
  selectedCondition: string = "=";
  cancelDate: string = "";
  dos: string = "";
  cancelReason: string = "";
  cancelComment: string = "";

  //caseCancellationDetails5Day: Array<any>;
  turnOver: Array<any>;
  turnoverThreshold: number;
  configuration: any =
    {
      metric: 'NumCases',
      datefield: 'CaseDate',
      datefilter: 'Week',
      xlabel: 'Week',
      field: 'Count',
      group_by: 'Id',
      group_by_label: 'GroupBy',
      categories: undefined,
      selectedcategory: "All",
      ylabel: '% Cases'
    };


  configDayBar: any = { height: 250, width: 400, margin_top: 30, margin_left: 40, units_label: "Utilization", max: 100, yaxis_ticks: 5, targetline: 80 }
  utilDayData: Array<any>;
  //  = [
  //  { Category: "Monday", Value: 20, Label2: "20%" },
  //  { Category: "Tuesday", Value: 34, Label2: "34%" },
  //  { Category: "Wednesday", Value: 45, Label2: "45%" },
  //  { Category: "Thursday", Value: 78, Label2: "78%" },
  //  { Category: "Friday", Value: 52, Label2: "52%" }
  //];
  barWidth: string;

  constructor(private commonService: CommonService) {
    this.page = new Page();
    this.page.currentPage = 1;
    this.page.recordsPerPage = 100;
  }
  PageEvent(e: Page) {
    this.page = e;
    this.GetAllCases();
  }
  ngAfterViewInit(): void {

    // this.barWidth = document.getElementById("barDiv").offsetWidth.toString() + 'px';
  }
  ngOnInit(): void {


  }
  CloseCaseCancel() {
    this.caseNumber = "";
    this.selectedBucket = "All";
    this.selectedCategory = "All";
    this.selectedService = "All";
    this.failedDetails = "";
    this.cost = undefined;
    this.selectedCondition = "=";
    this.cancelDate = "";
    this.dos = "";
    this.cancelReason = "";
    this.cancelComment = "";
  }
  SearchCaseCancel() {
    this.GetCaseCancellation(this.is5Day, this.metricName, false);
  }

  OnFilter(e: Filters) {
    this.filters = e;
    this.caseVolume = undefined;
    this.failedCasesPercentage = undefined;
    this.barData = undefined;
    this.cmm = undefined;
    this.caseVolumeTotal = undefined;
    this.page.currentPage = 1;
    this.caseCancellation = undefined;
    this.caseCancellationPie = undefined;
    this.utilDayData = undefined;
    this.caseCancellation5Day = undefined;
    this.caseCancellationPie5Day = undefined;
    this.caseCancellation3Day = undefined;
    this.caseCancellationPie3Day = undefined;

    if (e.surgeService != 'All') {
      this.GetTurnoverThresholdSingle();
    }
    this.commonService.GetReportData(e.facility, e.location, (e.isRoomSelectAll ? "All" : e.rooms.join("@")), e.surgeService, e.fromDate, e.toDate).subscribe(d => {

      this.caseVolume = d["CaseVolume"];
      this.failedCasesPercentage = d["FailedCasesPercentage"];
      this.barData = d["ScheduleVsActual"];
      this.cmm = d["CMM"];
      let metricOrder = d["MetricOrder"];
      this.caseCancellation = d["CaseCancelationPercentage"];
      this.caseCancellationPie = d["CaseCancelation"];
      this.utilDayData = d["UtilizationByDay"];

      this.caseCancellation5Day = d["CaseCancelationPercentage5Day"];
      this.caseCancellationPie5Day = d["CaseCancelation5Day"];

      this.caseCancellation3Day = d["CaseCancelationPercentage3Day"];
      this.caseCancellationPie3Day = d["CaseCancelation3Day"];

      this.caseVolumeTotal = 0
      this.caseVolume.map(d => { this.caseVolumeTotal += d.LocationCount });

      /* Pie chart color scale */
      let pieArray = this.caseCancellationPie.map(p => p.PieValue).concat(this.caseCancellationPie3Day.map(p => p.PieValue)).concat(this.caseCancellationPie5Day.map(p => p.PieValue))
      let resultDomain = pieArray.filter((item, idx) => pieArray.indexOf(item) === idx)
      this.casecancelScale.domain(resultDomain);

      let obj = {};
      for (var j = 0; j < metricOrder.length; j++) {
        let m = metricOrder[j];
        obj[m.Metric] = { 'MetricOrder': m.MetricOrder, 'DataRevision': m.DataRevision, 'MetricName': m.MetricName };

      }
      this.metricOrder = obj;

      setTimeout(c => {
        var div = document.getElementById('main');
        var dvCh = div.children;

        for (var j = 0; j < metricOrder.length; j++) {
          for (var i = 0; i < dvCh.length; i++) {
            var ch = dvCh[i];
            if (ch.id == 'id_' + (j + 1).toString()) {

              ch.classList.remove('hide');
              div.appendChild(ch);
              break;
            }

          }
        }


        if (document.getElementById("barDiv"))
          this.barWidth = (document.getElementById("barDiv").offsetWidth + 100).toString() + 'px';
      }, 0);
    });
  }
  GetDataRevision(metric: string) {
    this.metricDataRevision = this.metricOrder[metric].DataRevision;
  }
  GetGauge(g: Gauge) {
    this.gauge = g;
  }
  GetCMM(metricName: string) {

    var v = this.cmm.filter(c => c.Metric.toLowerCase() == metricName.toLowerCase());
    if (v.length > 0) {
      return v;
    }
    else {
      return null;
    }
  }
  GetMetricBar(metric: string, metricName: string) {
    this.metricName = metricName;
    this.fullbarData = undefined;
    this.configuration.categories = undefined;

    if (metric.toLowerCase() == "utilization" || metric.toLowerCase() == "staticutilization") {
      this.commonService.GetUtilizationMetricBar(this.filters.location, this.filters.fromDate, this.filters.toDate, metric).subscribe(d => {
        this.fullbarData = d["CaseVolumeBar"];
        this.configuration.selectedcategory = this.fullbarData.length > 0 ? this.fullbarData[0]['GroupBy'] : "";
        var catagories = [];

        for (var i = 0; i < this.fullbarData.length; i++) {
          var fil = catagories.filter(f => f.GroupBy == this.fullbarData[i].GroupBy);
          if (fil.length == 0) {
            catagories.push({ "Id": this.fullbarData[i].Id, "GroupBy": this.fullbarData[i].GroupBy })
          }
        }
        this.configuration.datefield = 'DOS';
        this.configuration.datefilter = this.fullbarData[0].DateInterval;
        this.configuration.xlabel = this.fullbarData[0].DateInterval;
        this.configuration.ylabel = "% Utilization";
        this.configuration.categories = catagories;
      });
    }
    else {
      this.commonService.GetMetricBar(this.filters.facility, this.filters.location, (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")), this.filters.fromDate, this.filters.toDate, metric, this.filters.surgeService, "All").subscribe(d => {

        this.fullbarData = d["CaseVolumeBar"];
        this.configuration.selectedcategory = this.fullbarData.length > 0 ? this.fullbarData[0]['GroupBy'] : "";
        var catagories = [];
        for (var i = 0; i < this.fullbarData.length; i++) {
          var fil = catagories.filter(f => f.GroupBy == this.fullbarData[i].GroupBy);
          if (fil.length == 0) {
            catagories.push({ "Id": this.fullbarData[i].Id, "GroupBy": this.fullbarData[i].GroupBy })
          }
        }

        this.configuration.datefilter = this.fullbarData[0].DateInterval;
        this.configuration.xlabel = this.fullbarData[0].DateInterval;
        this.configuration.datefield = 'CaseDate';
        if (metric.toLowerCase() == "numcases") {
          this.configuration.ylabel = "Count";
        }
        else {
          this.configuration.ylabel = "% Cases";
        }
        this.configuration.categories = catagories;
      });
    }
  }
  GetMetricDescription(metric: string, metricName: string) {
    this.metricName = metricName;
    this.metric = undefined;
    this.commonService.GetMetricDescription(metric).subscribe(d => {
      this.metric = d;
    })
  }

  GetTurnoverThresholds() {
    this.turnOver = undefined;
    this.commonService.GetTurnoverThresholds(this.filters.facility, this.filters.surgeService, this.filters.location).subscribe(d => {
      this.turnOver = d["TurnoverThresholds"];
    })
  }
  GetTurnoverThresholdSingle() {
    this.turnoverThreshold = undefined;
    this.commonService.GetTurnoverThresholds(this.filters.facility, this.filters.surgeService, this.filters.location).subscribe(d => {
      this.turnoverThreshold = d["TurnoverThresholds"][0]?.TurnoverThreshold;
    })
  }

  GetFailedCase(metric: string, metricName: string) {
    this.metricCode = metric;
    this.metricName = metricName;
    this.failedcases = undefined;
    this.metricfields = undefined;
    this.metricid = metric;
    this.commonService.GetFailedCases(this.filters.facility, this.filters.location, (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")), this.filters.surgeService, this.filters.staffRole, this.filters.fromDate, this.filters.toDate, metric).subscribe(d => {
      this.failedcases = d["casedetails"].map(d => {
        if (d.Adhoc) {
          d.Adhoc = JSON.parse(d.Adhoc);
        }
        return d;
      });
      this.metric = d["orbismetric"][0]?.MetricDescription;
      this.metricfields = d["orbismetricfield"];
      this.ORBISClosedCaseFields = d["orbisClosedcaseFielddetails"];
      this.ORBISClosedCase = d["closedcases"];
      this.ORBISFailDetails = d["OrbisFaildetails"];
      this.ORBISClosedCaseMetric = d["closedcasemetricdetails"];
    })
  }


  Sort(f) {
    this.sortColumn = f;
    this.sortOrder = this.sortObjects[f] == "ASC" ? "DESC" : "ASC";

    this.sortObjects[f] = this.sortOrder;
    Object.keys(this.sortObjects).forEach(k => {
      if (k != f) {
        this.sortObjects[k] = undefined;
      }
    });
    this.page.currentPage = 1;
    this.GetAllCases();
  }

  getORBISClosedCaseFields(fieldname: string) {
    return this.ORBISClosedCaseFields.filter(c => c.Field == fieldname)[0].FieldName;
  }
  getORBISClosedCaseFieldValue(fieldname: string) {
    if (this.ORBISClosedCase != undefined && this.ORBISClosedCase != null && this.ORBISClosedCase.length > 0)
      return this.ORBISClosedCase[0][fieldname];
    else
      return null;
  }
  GetOrder(metric: string) {
    let m = this.metricOrder.filter(m => m.Metric.toLowerCase() == metric.toLowerCase());
    if (m.length > 0) {
      let id = this.metricOrder.filter(m => m.Metric.toLowerCase() == metric.toLowerCase())[0]?.MetricOrder.toString();
      return 'id_' + id;
    } else {
      return undefined;
    }
  }
  getMaxCountRowsFailcase() {
    return Math.max(...this.ORBISFailDetails.map(o => o.RowIndex), 0);
  }
  getMaxCountColumnsFailcase() {
    return Math.max(...this.ORBISFailDetails.map(o => o.ColumnIndex), 0);
  }

  getMaxCountcolumnvalues(column) {
    var x = this.ORBISFailDetails.filter(f => f.ColumnIndex == column);
    return Math.max(...x.map(c => c.ColumnName), 0)
  }
  getMaxrowcolumnvalues(column) {
    var x = this.ORBISFailDetails.filter(f => f.ColumnIndex == column);
    return Math.max(...x.map(c => c.ColumnName), 0)
  }
  GetcaseDetail(ProcID: string) {

    this.commonService.GetcaseDetail(this.metricid, ProcID).subscribe(d => {
      this.ORBISClosedCase = d["closedcases"];
      this.ORBISFailDetails = d["OrbisFaildetails"];
      this.ORBISClosedCaseMetric = d["closedcasemetricdetails"];
    })
  }

  GetAllCases() {
    this.Allcases = undefined;
    this.totalrecords = 0;


    this.commonService.GetAllcases(this.filters.facility, this.filters.location, (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")), this.filters.surgeService, this.filters.fromDate, this.filters.toDate, this.page.currentPage, this.page.recordsPerPage, this.sortColumn, this.sortOrder).subscribe(d => {
      this.metricfields = d["OrbisMetricField"];

      this.totalrecords = d["AllCases"][0]['TotalRecords'];
      this.Allcases = d["AllCases"];
    });
  }

  GetCaseCancellation(is5Day: string, metricName: string, isInitialLoad: boolean = true) {
    this.is5Day = is5Day;
    this.totalrecords = 0;
    this.caseCancellationDetails = undefined;
    this.metricName = metricName;
    //let cDate = this.cancelDate.split('/');
    //let dosD = this.dos.split('/')

    if (is5Day == '5day') {
      this.commonService.GetCaseCancellation5Day(this.filters.facility, this.filters.location, this.filters.surgeService,
        this.filters.fromDate, this.filters.toDate, this.page.currentPage, this.page.recordsPerPage,
        (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")),
        this.caseNumber, this.cancelDate && this.cancelDate != "" ? this.formatDate(this.cancelDate) : "", this.dos && this.dos != "" ? this.formatDate(this.dos) : "",
        this.selectedBucket, this.selectedCategory, this.failedDetails, this.selectedService, this.selectedCondition, this.cost, this.cancelReason, this.cancelComment).subscribe(d => {
          this.caseCancellationDetails = d["CaseCancelation"];
          this.totalrecords = this.caseCancellationDetails.length > 0 ? this.caseCancellationDetails[0]["Total"] : 0;
          if (isInitialLoad) {
            this.bucket = d["bucket"];
            this.category = d["category"];
            this.service = d["service"];
          }
        });
    }
    else if (is5Day == 'sameday') {
      this.commonService.GetCaseCancellation(this.filters.facility, this.filters.location, this.filters.surgeService,
        this.filters.fromDate, this.filters.toDate, this.page.currentPage, this.page.recordsPerPage,
        (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")),
        this.caseNumber, this.cancelDate && this.cancelDate != "" ? this.formatDate(this.cancelDate) : "", this.dos && this.dos != "" ? this.formatDate(this.dos) : "",
        this.selectedBucket, this.selectedCategory, this.failedDetails, this.selectedService, this.selectedCondition, this.cost, this.cancelReason, this.cancelComment)
        .subscribe(d => {
          this.caseCancellationDetails = d["CaseCancelation"];
          this.totalrecords = this.caseCancellationDetails.length > 0 ? this.caseCancellationDetails[0]["Total"] : 0;
          if (isInitialLoad) {
            this.bucket = d["bucket"];
            this.category = d["category"];
            this.service = d["service"];
          }
        });
    }
    else {

      this.commonService.GetCaseCancellation3Day(this.filters.facility, this.filters.location, this.filters.surgeService,
        this.filters.fromDate, this.filters.toDate, this.page.currentPage, this.page.recordsPerPage,
        (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")),
        this.caseNumber, this.cancelDate && this.cancelDate != "" ? this.formatDate(this.cancelDate) : "", this.dos && this.dos != "" ? this.formatDate(this.dos) : "",
        this.selectedBucket, this.selectedCategory, this.failedDetails, this.selectedService, this.selectedCondition, this.cost, this.cancelReason, this.cancelComment).subscribe(d => {
          this.caseCancellationDetails = d["CaseCancelation"];
          this.totalrecords = this.caseCancellationDetails.length > 0 ? this.caseCancellationDetails[0]["Total"] : 0;
          if (isInitialLoad) {
            this.bucket = d["bucket"];
            this.category = d["category"];
            this.service = d["service"];
          }
        });
    }
  }
  private formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  PageEventCaseCancel(e: Page) {
    this.page = e;
    this.GetCaseCancellation(this.is5Day, this.metricName);
  }

  //GetUtilInights(metricName: string) {
  //  this.metricName = metricName;
  //  this.insightsData = undefined;
  //  this.commonService.GetUtilInights(this.filters.facility, this.filters.location, (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")), this.filters.surgeService, this.filters.fromDate, this.filters.toDate).subscribe(d => {
  //    this.insightsData = d;

  //  });
  //}

  //GetCaseCancelInights(metricName: string) {
  //  this.metricName = metricName;
  //  this.insightsData = undefined;
  //  this.commonService.GetCaseCancelInights(this.filters.facility, this.filters.location, (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")), this.filters.surgeService, this.filters.fromDate, this.filters.toDate).subscribe(d => {
  //    this.insightsData = d;
  //  });
  //}
  GetInsights(metric: string, metricName: string, insightsHeader: string) {
    this.metricName = metricName;
    this.insightsData = undefined;
    this.insightsHeader = insightsHeader;
    if (metric.toLowerCase() == 'utilization') {
      this.commonService.GetUtilInights(this.filters.facility, this.filters.location, (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")), this.filters.surgeService, this.filters.fromDate, this.filters.toDate, metric).subscribe(d => {
        this.insightsData = d;

      });
    }
    else {
      this.commonService.GetORSnapshotInsights(this.filters.facility, this.filters.location, (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")), this.filters.surgeService, this.filters.fromDate, this.filters.toDate, metric).subscribe(d => {
        this.insightsData = d;

      });
    }
  }
}

