import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { Filters } from '../../Filters';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnDestroy, OnInit {

  @Output() onmetricchange: EventEmitter<string> = new EventEmitter();
  @Output() onfilter: EventEmitter<any> = new EventEmitter();
  @Input({ required: true }) reportName: string = ''
  @Input() metric: string = ''
  @Input() reportHeader: string = ''
  @Input() page: string = ''

  menus:any= {
    outcome: [
      { value: "ponv", text: "PONV" },
      { value: "pain_score", text: "Pain Score​" },
      { value: "opioid", text: "Opioid" },
      { value: "reintubation", text: "Reintubation" },
      { value: "aki", text: "AKI" },
      { value: "myocardial", text: "Myocardial Injury​" },
      { value: "ssi", text: "SSI" },
      { value: "pulmonary", text: "Pulmonary complications​" },
      { value: "los", text: "LOS" },
      { value: "readmission", text: "Readmission​" },
      { value: "mortality", text: "Mortality" },
      { value: "disposition", text: "Disposition" }
    ],
    quality: [
      { value: "hypotension", text: "Hypotension" },
      { value: "normothermia", text: "Normothermia​" },
      { value: "lung", text: "Lung" }]
  }
  report: any = {};
  isLoader: boolean = false;
  isChanged: boolean = false;
  selectedSurgeService: string;
  selectedProvider: any;
  selectedFacility: string;
  selectedStaffRole: string;
  fromDate: any;
  toDate: any;
  caseDate: any;
  selectedMonth: string;
  selectedYear: string;
  selectedQuarter: string = "q1";
  selectedLocation: string;
  isSelectAll: boolean = true;
  fiscalYears: any;
  selectedFiscalYear: string;

  facilities: Array<string>
  surgeServices: Array<string>;
  locations: Array<string>;
  staffRoles: Array<string>;
  providers: Array<any>;
  rooms: Array<string>
  selectedDateRange: string = "custom";
  providerMultiSettings: IDropdownSettings = {};
  roomMultiSettings: IDropdownSettings = {};
  selectedMultiProvider: Array<any> = [];
  selectedRooms: Array<any> = [];
  selectedMetric: string = 'All';
  currentYear: number;
  months: Array<any>;
  quarters: Array<any>;
  user: string;
  lastUpdateddate: string;
  notificationmessage: string;
  metrics: Array<any> = [];
  selectedCompType: string = "facility";
  //page: string ;

  constructor(private commonService: CommonService, private toastr: ToastrService) {

    if (sessionStorage.getItem("outcomemetric")) {
      this.page = sessionStorage.getItem("outcomemetric");
    }
    
    this.providerMultiSettings = {
      singleSelection: false,
      idField: 'UserLogin',
      textField: 'StaffName',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };

    this.roomMultiSettings = {
      singleSelection: false,
      idField: 'UserLogin',
      textField: 'StaffName',
      itemsShowLimit: 1,
      enableCheckAll: true,
      allowSearchFilter: true
    };

    var lastMonth = new Date();
    lastMonth.setDate(1);
    lastMonth.setHours(-1);

    var firstDate = new Date();
    firstDate.setDate(1);
    firstDate.setHours(-1)
    firstDate.setDate(1);

    this.fromDate = firstDate;
    this.toDate = lastMonth;
    this.caseDate = firstDate;

    setTimeout(() => {
      let mn = (new Date()).getMonth();
      this.currentYear = (new Date()).getFullYear();
      if (mn != 0) {
        this.selectedYear = this.currentYear.toString();
        this.selectedMonth = ((new Date()).getMonth()).toString();
      }
      else {
        this.selectedYear = (this.currentYear - 1).toString();
        this.selectedMonth = "12";
      }
    });
    //this.currentYear = (new Date()).getFullYear();
    //this.selectedYear = this.currentYear.toString();
    //this.selectedMonth = ((new Date()).getMonth()).toString();
    this.selectedQuarter = "q" + (Math.ceil(((new Date()).getMonth() + 1) / 3)).toString();

    this.SetFilters();
    this.isLoader = true;
    forkJoin([
      this.commonService.GetStaffRole()
    ])
      .subscribe(([r]) => {
        this.isLoader = false;
        if (!this.selectedStaffRole || this.selectedStaffRole == "")
          this.selectedStaffRole = r[0];

        var isexistStaffRole = r.filter(t => t == this.selectedStaffRole);
        if (isexistStaffRole.length == 0) {
          this.selectedStaffRole = r[0];
        }

        this.staffRoles = r;
        // Get Facility
        this.isLoader = true;
        this.commonService.
          GetFacility(this.report.staffrole == true ? this.selectedStaffRole : 'All', this.reportName)
          .subscribe(f => {
            this.isLoader = false;
            if (!this.selectedFacility || this.selectedFacility == "") {

              var montlake = f.filter(t => t.toLocaleUpperCase().replace(/ /g, '').toLocaleLowerCase() == "montlake");
              if (montlake.length == 0)
                this.selectedFacility = f[0];
              else
                this.selectedFacility = montlake[0];
            }
            this.facilities = f;
            //if (this.reportName == 'blockutil') {
            //  this.facilities = ["All"].concat(this.facilities);
            //}
            var isexist = f.filter(t => t == this.selectedFacility);
            if (isexist.length == 0) {
              this.selectedFacility = f[0];
            }
            this.isLoader = true;
            let funcCallback = () => {
              this.commonService.GetRooms((this.report.facility == true ? this.selectedFacility : "All"), (this.report.location == true ? this.selectedLocation : "All")).subscribe(r => {
                this.isLoader = false;
                this.rooms = r;
                this.selectedRooms = this.rooms;
                this.CheckFilters();
                this.OnFilter();
              });
            }
            this.GetLocation(funcCallback);
            this.GetMetrics();
            // Get Surge Service
            //this.commonService.GetSurgeService((this.report.facility == true ? this.selectedFacility : "All"),
            //  (this.report.staffrole == true ? this.selectedStaffRole : "All"), this.reportName).subscribe(sur => {
            //  var isexist = sur.filter(t => t == this.selectedSurgeService);
            //  if (isexist.length == 0) {
            //    this.selectedSurgeService = sur[0];
            //  }
            //  if (!this.selectedSurgeService || this.selectedSurgeService == "")
            //    this.selectedSurgeService = sur[0];

            //  this.surgeServices = sur;
            //  var obj = new Filters();
            //  this.SetFromToDate(obj);

            //  this.commonService.GetProvider((this.report.facility == true ? this.selectedFacility : "All"), (this.report.staffrole == true ? this.selectedStaffRole : "All"), (this.report.surgeservice == true ? this.selectedSurgeService : "All"), obj.fromDate, obj.toDate).subscribe(s => {
            //    if (this.report.multiprovider == true) {
            //      this.providers = s?.filter(p => p.UserLogin.toLowerCase() != "all");
            //    }
            //    else {
            //      this.providers = s;
            //    }

            //    if (!this.selectedProvider || this.selectedStaffRole == "")
            //      this.selectedProvider = s[0]?.UserLogin;

            //    if (!this.selectedMultiProvider || this.selectedMultiProvider.length == 0)
            //      this.selectedMultiProvider = this.providers;
            //    this.CheckFilters();
            //    this.OnFilter();
            //  });
            //});

          });

      });

    this.GetMonths();
    this.GetQuarters();
    this.GetFiscalYears();
  }

  ChangeMetric() {
    if (this.onmetricchange) {
      sessionStorage.setItem("outcomemetric", this.page);
      this.onmetricchange.emit(this.page);
    }
  }
  ChangeCompType() {
    this.isChanged = true;
    if (this.selectedCompType == "facility") {
      this.report.facility = false;
      this.report.surgeservice = true;
      this.selectedFacility = "All";
      this.GetLocation();
      this.GetSurgeServices();
    }
    else {
      this.report.facility = true;
      this.report.surgeservice = false;
      this.selectedSurgeService = "All";
      this.GetLocation();
      this.GetSurgeServices();
    }
  }
  FiltersChanged() {
    //let nodeList = document.querySelectorAll(".blur");
    //for (let i = 0; i < nodeList.length; i++) {
    //  nodeList[i].classList.add("blurred");
    //}
    this.isChanged = true;
  }
  ChangeDateRange() {
    setTimeout(() => {
      if (this.selectedDateRange == "custom") {
        this.report.month = false;
        this.report.year = false;
        this.report.customdaterange = true;
        this.report.quarter = false;
      }
      else if (this.selectedDateRange == "month") {
        this.report.month = true;
        this.report.year = true;
        this.report.customdaterange = false;
        this.report.quarter = false;
      }
      else if (this.selectedDateRange == "quarter") {
        this.report.month = false;
        this.report.year = true;
        this.report.customdaterange = false;
        this.report.quarter = true;
      }
      else {
        this.report.month = false;
        this.report.year = true;
        this.report.customdaterange = false;
        this.report.quarter = false;
      }
    });
  }
  CheckFilters() {
    if (this.facilities.indexOf(this.selectedFacility) < 0)
      this.selectedFacility = "";
    if (this.surgeServices.indexOf(this.selectedSurgeService) < 0)
      this.selectedSurgeService = "";
    if (this.providers.filter(p => { return p.UserLogin == this.selectedProvider }).length == 0)
      this.selectedProvider = "";


    this.selectedMultiProvider = this.providers.filter((n) => {
      return this.selectedMultiProvider.filter(p => { return p.UserLogin == n.UserLogin }).length > 0;
    });
    if (this.selectedMultiProvider.length != this.providers.length) {
      this.selectedMultiProvider = this.selectedMultiProvider.filter(p => {
        return p.UserLogin != "All";
      });
    }
  }
  SetFilters() {
    if (sessionStorage.getItem("filters")) {
      setTimeout(() => {
        var obj = JSON.parse(sessionStorage.getItem("filters"));
        this.selectedCompType = obj.comptype;
        this.selectedFacility = obj.facility;
        this.selectedSurgeService = obj.surgeService
        this.selectedStaffRole = obj.staffRole;
        this.selectedProvider = obj.provider;
        this.selectedMultiProvider = obj.multiproviderObject;
        this.selectedRooms = obj.rooms;
        this.selectedLocation = obj.location.replace(/@@/gi, '&');
        this.selectedQuarter = obj.quarter;
        if (obj.fromDate) {
          let from = obj.fromDate.split("-")
          this.fromDate = [from[1], from[2], from[0]].join('/');
          this.fromDate = new Date(this.fromDate);
        }
        if (obj.toDate) {
          let to = obj.toDate.split("-");
          this.toDate = [to[1], to[2], to[0]].join('/');
          this.toDate = new Date(this.toDate);
        }
        if (obj.caseDate) {
          let caseDate = obj.caseDate?.split("-");
          this.caseDate = [caseDate[1], caseDate[2], caseDate[0]].join('/');
          this.caseDate = new Date(this.caseDate);
        }
        this.selectedYear = obj.year;
        this.selectedMonth = obj.month;
        this.selectedDateRange = obj.dateRange;
        this.isSelectAll = obj.isSelectAll == undefined ? true : obj.isSelectAll;
        this.selectedFiscalYear = obj.fiscalYear;
      });
    }

    this.ChangeDateRange();

  }
  GenerateMonth(m) {
    this.months = [];
    var monthsNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    for (var i = 1; i <= m; i++) {
      this.months.push({ "value": i.toString(), "name": monthsNames[i - 1] });
    }
  }
  GenerateQuartes(m) {
    this.quarters = [];
    for (var i = 1; i <= Math.ceil(m / 3); i++) {
      this.quarters.push({ "value": "q" + i.toString(), "name": "Q" + i.toString() });
    }
  }
  GetMonths() {
    this.selectedMonth = "1";
    if ((new Date()).getFullYear().toString() == this.selectedYear) {
      this.GenerateMonth(((new Date()).getMonth() + 1));
    }
    else {
      this.GenerateMonth(12);
    }
  }
  GetQuarters() {
    this.selectedQuarter = "q1";
    if ((new Date()).getFullYear().toString() == this.selectedYear) {
      this.GenerateQuartes(((new Date()).getMonth() + 1));
    }
    else {
      this.GenerateQuartes(12);
    }
  }
  SelectAll() {
    if (this.isSelectAll) {
      this.providerMultiSettings.limitSelection = 100000;
      this.selectedMultiProvider = this.providers;

    }
    else {
      this.selectedMultiProvider = [];
      this.providerMultiSettings.limitSelection = 25;
    }
  }
  GetFacilities() {
    this.isLoader = true;
    this.commonService.GetFacility(this.report.staffrole == true ? this.selectedStaffRole : 'All', this.reportName).subscribe(f => {
      this.isLoader = false;
      if (!this.selectedFacility || this.selectedFacility == "") {

        var vmMain = f.filter(t => t.toLocaleUpperCase().replace(/ /g, '') == "VMMAIN");
        if (vmMain.length == 0)
          this.selectedFacility = f[0];
        else
          this.selectedFacility = vmMain[0];
      }
      this.facilities = f;
      //if (this.reportName == 'blockutil') {
      //  this.facilities = ["All"].concat(this.facilities);
      //}
      var isexist = f.filter(t => t == this.selectedFacility);
      if (isexist.length == 0) {
        this.selectedFacility = f[0];
      }
      //this.GetSurgeons();
      this.GetSurgeServices();
    });
  }
  GetSurgeServices(initialLoad: any = false) {
    this.isLoader = true;
    this.commonService.GetSurgeService((this.report.facility == true ? this.selectedFacility : "All"), (this.report.staffrole == true ? this.selectedStaffRole : "All"), this.reportName, (this.report.location == true ? this.selectedLocation : "All")).subscribe(s => {
      this.surgeServices = s;
      this.isLoader = false;
      var isexist = s.filter(t => t == this.selectedSurgeService);
      if (isexist.length == 0) {
        this.selectedSurgeService = s[0];
      }
      this.GetProvider(initialLoad);
    });
  }
  GetMetrics() {
    this.isLoader = true;
    this.metrics = undefined;
    this.commonService.GetMetrics(this.formatDate(this.fromDate), this.formatDate(this.toDate), this.selectedFacility).subscribe(m => {
      this.metrics = m;
      this.isLoader = false;
      this.selectedMetric = 'All';
    });
  }
  GetLocation(initialLoad: any = false) {
    if (this.reportName == 'blockutil' && this.selectedFacility == "All") {
      this.OnFilter();
      return;
    }
    this.isLoader = true;
    this.commonService.GetLocation((this.report.facility == true ? this.selectedFacility : "All"),
      (this.report.staffrole == true ? this.selectedStaffRole : 'All'),
      (this.report.surgeservice == true && this.selectedSurgeService ? this.selectedSurgeService : "All"), this.reportName).subscribe(l => {
        this.locations = l;
        this.isLoader = false;
        var isexist = l.filter(t => t == this.selectedLocation);
        if (isexist.length == 0) {

          var mainpavi = l.filter(t => t.toLocaleUpperCase().replace(/ /g, '').toLocaleLowerCase() == "main+pavilion");
          if (mainpavi.length == 0)
            this.selectedLocation = l[0];
          else
            this.selectedLocation = mainpavi[0];

          // this.selectedLocation = l[0];
        }
        this.GetRooms();
        this.GetSurgeServices(initialLoad);
      });
  }
  GetRooms() {
    this.isLoader = true;
    this.commonService.GetRooms((this.report.facility == true ? this.selectedFacility : "All"), (this.report.location == true ? this.selectedLocation : "All")).subscribe(r => {
      this.rooms = r;
      this.isLoader = false;
      this.selectedRooms = this.rooms;
    });
  }
  GetFiscalYears() {
    this.isLoader = true;
    this.commonService.GetFiscalYears().subscribe(y => {
      this.isLoader = false;
      this.fiscalYears = y;
      this.selectedFiscalYear = y[y.length - 1];
    });
  }
  GetStaffRole() {
    this.isLoader = true;
    this.commonService.GetStaffRole().subscribe(s => {
      this.isLoader = false;
      this.staffRoles = s;
      if (this.report.surgeservice == true)
        this.selectedStaffRole = s[0];
      else
        this.selectedStaffRole = 'All';
      //if (this.reportName == "caselog")
      //  this.report.surgeservice = false;
      this.GetProvider()
    });
  }
  //GetSurgeons() {
  //  this.commonService.GetSurgeService((this.report.facility == true ? this.selectedFacility : "All"), (this.report.staffrole == true ? this.selectedStaffRole : "All"), this.reportName).subscribe(s => {
  //    this.surgeServices = s;

  //    this.selectedSurgeService = this.surgeServices[0];
  //    this.GetProvider();
  //  });

  //}
  GetProvider(initialLoad: any = false) {
    var obj = new Filters();
    this.SetFromToDate(obj);
    this.isLoader = true;
    this.commonService.GetProvider((this.report.facility == true ? this.selectedFacility : "All"), (this.report.staffrole == true || this.reportName == "financial-portfolio" || this.reportName == "workflow" ? this.selectedStaffRole : "All"), (this.report.surgeservice == true ? this.selectedSurgeService : "All"), obj.fromDate, obj.toDate).subscribe(s => {
      this.isLoader = false;
      if (this.report.multiprovider == true) {
        this.providers = s?.filter(p => p.UserLogin.toLowerCase() != "all");
      }
      else {
        this.providers = s;
      }
      if (!this.report.multiprovider) {
        var isexist = s.filter(t => t.UserLogin == this.selectedProvider);
        if (isexist.length == 0 && s.length > 0) {

          this.selectedProvider = s[0]["UserLogin"];
        }
      }
      else {
        this.selectedMultiProvider = this.providers;
      }
      if (initialLoad) {
        initialLoad();
      }
      //    if (!this.selectedProvider || this.selectedStaffRole == "")
      //      this.selectedProvider = s[0]?.UserLogin;

      //    if (!this.selectedMultiProvider || this.selectedMultiProvider.length == 0)
      //      this.selectedMultiProvider = this.providers;
      //this.selectedProvider = s[0]["UserLogin"];
      //this.selectedMultiProvider = this.providers;
    });
  }

  ngOnDestroy() {
    sessionStorage.removeItem("outcomemetric");
  }
  ngOnInit(): void {

    this.InitFilters();
    this.user = sessionStorage.getItem("userlogin");
    this.lastUpdateddate = sessionStorage.getItem("lastUpdateddate");



  }
  InitFilters() {
    this.ChangeDateRange();
    this.commonService.GetNotificationMessage().subscribe(d => {
      //this.user = d;
      this.notificationmessage = d["NotificationMessage"];
    });
    setTimeout(() => {
      if (this.reportName.toLocaleLowerCase() == "orsnapshot") {
        this.report.daterange = true;
        this.report.facility = true;
        this.report.surgeservice = true;
        this.report.provider = false;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = true;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      } else if (this.reportName == "casemix" || this.reportName == "caselog" || this.reportName == "outcome") {
        this.report.daterange = true;
        this.report.facility = true;
        this.report.provider = true;
        this.report.staffrole = true;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = false;
        this.report.casedate = false;
        this.report.metric = false;
        this.report.surgeservice = true;
        this.report.comptype = false;
      }
      else if (this.reportName == 'workflow') {
        this.report.daterange = false;
        this.report.year = false;
        this.report.month = false;
        this.report.casedate = true;
        this.report.facility = true;
        this.report.staffrole = false;
        this.report.surgeservice = true;
        this.report.provider = true;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = false;
        this.report.fiscalyear = false;
        this.selectedStaffRole = "Surgeon";
        this.report.metric = false;
        this.report.comptype = false;
      }
      else if (this.reportName == 'two-room-surgeons') {
        this.report.daterange = true;
        this.report.year = false;
        this.report.month = false;
        this.report.casedate = false;
        this.report.facility = false;
        this.report.staffrole = false;
        this.report.surgeservice = false;
        this.report.provider = false;
        this.report.multiprovider = false;
        this.report.location = false;
        this.report.room = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      }
      else if (this.reportName == "blockutil") {
        this.report.daterange = true;
        this.report.facility = true;
        this.report.surgeservice = true;
        this.report.provider = false;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      }
      else if (this.reportName == "metric") {
        if (this.selectedDateRange == "custom") {
          this.selectedDateRange = "month";
          this.ChangeDateRange();
        }
        if (this.selectedCompType == "facility") {
          this.report.facility = false;
          this.report.surgeservice = true;
          this.selectedFacility = "All";
        }
        else {
          this.report.facility = true;
          this.report.surgeservice = false;
          this.selectedSurgeService = "All";
        }
        this.report.comptype = true;
        this.report.daterange = true;
        this.report.provider = false;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
      }
      else if (this.reportName == "block-schedule") {
        this.report.daterange = false;
        this.report.year = true;
        this.report.month = true;
        this.report.facility = true;
        this.report.staffrole = false;
        this.report.surgeservice = false;
        this.report.provider = false;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      }
      else if (this.reportName == "timestat") {
        this.report.daterange = true;
        this.report.facility = true;
        this.report.surgeservice = true;
        this.report.provider = false;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      }
      else if (this.reportName == "ruv") {
        this.report.daterange = true;
        this.report.facility = true;
        this.report.surgeservice = true;
        this.report.provider = true;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      }

      else if (this.reportName == "recomendations") {
        this.report.daterange = true;
        this.report.facility = true;
        this.report.surgeservice = false;
        this.report.provider = false;
        this.report.multiprovider = true;
        this.report.location = false;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = true;
        this.report.comptype = false;
      }
      else if (this.reportName == 'metricdashboard') {
        this.report.daterange = true;
        this.report.facility = true;
        this.report.staffrole = true;
        this.report.surgeservice = true;
        this.report.provider = true;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      }
      else if (this.reportName == "distrank") {
        if (this.selectedDateRange == "custom") {
          this.selectedDateRange = "month";
          this.ChangeDateRange();
        }

        this.report.facility = true;
        this.report.surgeservice = true;
        this.report.location = false;
        this.report.daterange = true;
        this.report.staffrole = true;
        this.report.provider = true;
        this.report.daterange = true;
        this.report.multiprovider = false;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      }
      else if (this.reportName == "providerreport") {
        if (this.selectedDateRange == "custom") {
          this.selectedDateRange = "month";
          this.ChangeDateRange();
        }
        this.report.facility = true;
        this.report.surgeservice = true;
        this.report.daterange = true;

        this.report.staffrole = true;
        this.report.provider = false;
        this.report.daterange = true;
        this.report.multiprovider = false;
        this.report.location = false;
        this.report.multiprovider = true;
        this.report.metric = false;
      }
      else if (this.reportName == "provider") {
        this.report.daterange = true;
        this.report.facility = true;
        this.report.staffrole = true;
        this.report.surgeservice = true;
        this.report.provider = false;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      }
      else if (this.reportName == "financial-portfolio") {
        this.report.daterange = false;
        this.report.year = false;
        this.report.month = false;
        this.report.facility = true;
        this.report.staffrole = false;
        this.report.surgeservice = true;
        this.report.provider = true;
        this.report.multiprovider = false;
        this.report.location = false;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = true;
        this.selectedStaffRole = "Surgeon";
        this.report.metric = false;
        this.report.comptype = false;
      }
      else if (this.reportName == "glucose-management") {
        this.report.daterange = true;
        this.report.year = false;
        this.report.month = false;
        this.report.facility = true;
        this.report.staffrole = true;
        this.report.surgeservice = true;
        this.report.provider = true;
        this.report.multiprovider = false;
        this.report.location = true;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      }
      else if (this.reportName == "case-volume-forecast") {
        this.report.daterange = false;
        this.report.year = false;
        this.report.month = false;
        //this.fromDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        //this.toDate = new Date(new Date().setDate(new Date().getDate() + 8 * 7));
        this.report.facility = true;
        this.report.surgeservice = true;
        this.report.provider = false;
        this.report.multiprovider = false;
        //this.report.location = true;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
      }
      else if (this.reportName == 'facility-summary') {
        this.report.daterange = false;
        this.report.year = false;
        this.report.month = false;
        this.report.facility = true;
        this.report.staffrole = false;
        this.report.surgeservice = false;
        this.report.provider = false;
        this.report.multiprovider = false;
        this.report.location = false;
        this.report.room = false;
        this.report.casedate = false;
        this.report.fiscalyear = false;
        this.report.metric = false;
        this.report.comptype = false;
      }
    })
  }
  ChangeStaffRole() {
    //if ((this.selectedStaffRole.toLocaleLowerCase() == "surgeon") || this.reportName == "metricdashboard" || this.reportName == "distrank" || this.reportName == "provider") {
    //  this.report.surgeservice = true;
    //}
    //else {
    //  this.report.surgeservice = false;
    //}
  }
  SetFromToDate(obj) {
    if (this.report.daterange == true) {
      if (this.selectedDateRange == "custom") {
        obj.fromDate = this.formatDate(this.fromDate);
        obj.toDate = this.formatDate(this.toDate);
      }
      else if (this.selectedDateRange == "month") {
        var lastDate = new Date(parseInt(this.selectedYear), parseInt(this.selectedMonth), 0);
        obj.fromDate = this.selectedYear + "-" + this.selectedMonth + "-01";
        obj.toDate = this.selectedYear + "-" + this.selectedMonth + "-" + (lastDate.getDate());
      }
      else if (this.selectedDateRange == "quarter") {
        if (this.selectedQuarter == "q1") {
          obj.fromDate = this.selectedYear + "-01" + "-01";
          obj.toDate = this.selectedYear + "-03" + "-31";
        }
        else if (this.selectedQuarter == "q2") {
          obj.fromDate = this.selectedYear + "-04" + "-01";
          obj.toDate = this.selectedYear + "-06" + "-30";
        }
        else if (this.selectedQuarter == "q3") {
          obj.fromDate = this.selectedYear + "-07" + "-01";
          obj.toDate = this.selectedYear + "-09" + "-30";
        }
        else {
          obj.fromDate = this.selectedYear + "-10" + "-01";
          obj.toDate = this.selectedYear + "-12" + "-31";
        }
      }
      else {
        obj.fromDate = this.selectedYear + "-01" + "-01";
        obj.toDate = this.selectedYear + "-12" + "-31";
      }
    }
    else {
      obj.fromDate = this.formatDate(this.fromDate);
      obj.toDate = this.formatDate(this.toDate);
    }
  }
  DateChanged(arg) {
    this.FiltersChanged();
  }
  OnFilter() {
    //let nodeList = document.querySelectorAll(".blur");
    //for (let i = 0; i < nodeList.length; i++) {
    //  nodeList[i].classList.remove("blurred");
    //}

    this.isChanged = false;
    var obj = new Filters();
    obj.comptype = this.selectedCompType;
    obj.facility = this.report.facility == true ? this.selectedFacility : "All";
    obj.surgeService = this.report.surgeservice == true ? this.selectedSurgeService : "All";
    obj.staffRole = this.report.staffrole == true ? this.selectedStaffRole : "All";
    obj.provider = this.report.provider == true ? this.selectedProvider : "All";//.UserLogin;
    obj.multiprovider = this.selectedMultiProvider.map(p => { return p.UserLogin });
    obj.rooms = this.selectedRooms.map(p => { return p });
    obj.multiproviderObject = this.selectedMultiProvider;
    obj.location = this.selectedLocation.replace(/&/gi, '@@');
    obj.isProviderSelectAll = (this.selectedMultiProvider.length == this.providers.length ? true : false);
    obj.isRoomSelectAll = (this.selectedRooms.length == this.rooms.length ? true : false);
    this.SetFromToDate(obj);
    obj.caseDate = this.formatDate(this.caseDate);
    obj.year = this.selectedYear;
    obj.month = this.selectedMonth;
    obj.dateRange = this.selectedDateRange
    obj.isSelectAll = this.isSelectAll;
    obj.quarter = this.selectedQuarter;
    obj.fiscalYear = this.selectedFiscalYear;
    obj.metric = this.selectedMetric;
    sessionStorage.setItem("filters", JSON.stringify(obj));

    setTimeout(() => {
      let p = this.providers.filter(f => f.UserLogin == this.selectedProvider)
      if (p.length > 0) {
        obj.providerName = p[0].StaffName;
      }
      else {
        obj.providerName = undefined;
      }
    }, 300);
   // this.ChangeMetric();
    this.onfilter.emit(obj);
  }
  private formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  logout() {
    if (environment.IsProd) {
      var obj = {};
      obj['ApplicationId'] = environment.AppId;
      obj['UserLogin'] = sessionStorage.getItem("userlogin");
      obj['ActionType'] = "LOGOUT";
      obj['URI'] = 'ORBIS LOGOUT';
      this.commonService.PostLog(obj).subscribe(d => {
        //sessionStorage.removeItem('userlogin');
        //sessionStorage.removeItem("userrole");
        //sessionStorage.removeItem("usermenu");
        sessionStorage.clear();

        window.location.href = environment.loginURL;
      });
    }
    else {
      window.location.href = environment.loginURL;
    }
  }
}
