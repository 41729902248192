import * as d3 from "d3";
import { Component, Input, OnInit } from '@angular/core';
import { PieData, PieModel } from '../../d3components/models/piemodel';
import { DisplayModel } from '../../d3components/models/commonmodel';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { OutcomeService } from '../outcome.service';
import { ChartService } from '../../d3components/chart.service';
import { LineStyle, MarkerStyle } from '../../d3components/models/linemodel';
import { ColumnModel, Page, TableModel } from '../../d3components/models/table';
import { HData } from '../../d3components/models/hbarmodel';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { Filters } from '../../Filters';

@Component({
  selector: 'app-common-page',
  templateUrl: './common-page.component.html',
  styleUrls: ['./common-page.component.css']
})
export class CommonPageComponent implements OnInit {

  @Input() metric: string = ''
  @Input() filterHead: string = ''

  switchBar: any = {};
  uijson: any;
  chartjson: any;
  outputdata: any;
  currentData: any;
  @Input() page: string;
  @Input() filter: Filters;
  filterApi: Filters;
  pagination: Page;
  sortObject: any;
  filterObject: any;
  @Input() isFilter: boolean = true;
  @Input() isMenu: boolean = false;

  //trendQuantity: string = "MMEQty";
  trendQuantity: any = {};// "MMEQty";
  selectedRow: number = undefined;
  selectedBar: any;
  selectedLOS: any = 'HospitalLOS';

  constructor(private route: ActivatedRoute, private http: HttpClient, private outCome: OutcomeService
    , private chartService: ChartService, private toastr: ToastrService) {
    this.trendQuantity["TrendQuantity"] = "MMEQty";
    this.trendQuantity["ProcedureType"] = "MMEQty";
  }

  ChangeMetric(m: string) {
    this.page = m;
    this.LoadChart();
  }
  DownloadEvent(e: any) {
    //this.DeSelectFilter(null);
    this.filter.tableSwitch = e['switch'];
    this.DownloadData(1, 50000000, "DateofSurgery", "ASC", e['filters'], "download");
  }
  EmailEvent(e: any) {
    //this.DeSelectFilter(null);
    this.filter.tableSwitch = e['switch'];
    this.DownloadData(1, 50000000, "DateofSurgery", "ASC", e['filters'], "email");
  }
  ChangeLOS() {
    this.OnFilter(this.filter);
  }
  FilterEvent(e: any) {
    //this.DeSelectFilter(null);
    this.filterObject = e['filters'];
    this.filter.tableSwitch = e['switch'];
    let table = this.GetTableConfig();
    if (table && table.default_no_of_records && table.default_sort_column && table.default_sort_order) {
      this.GetTableData(1, table.default_no_of_records, table.default_sort_column, table.default_sort_order, this.filterObject, ["Table"]);
    }
    else {
      this.GetTableData(1, 50, "DateofSurgery", "ASC", this.filterObject, ["Table"]);
    }

  }
  SortEvent(e: any) {
    //this.DeSelectFilter(null);
    this.filter.tableSwitch = e['switch'];
    this.sortObject = e;
    let table = this.GetTableConfig();
    if (table && table.default_no_of_records && table.default_sort_column && table.default_sort_order) {
      this.GetTableData(1, table.default_no_of_records, e.key, e.order, this.filterObject, ["Table"]);
    }
    else {
      this.GetTableData(1, 50, e.key, e.order, this.filterObject, ["Table"]);
    }
  }
  PageEvent(e: Page) {
    //this.DeSelectFilter(null);
    this.pagination = e['page'];
    this.filter.tableSwitch = e['switch'];
    if (this.sortObject) {
      this.GetTableData(this.pagination.currentPage, this.pagination.recordsPerPage, this.sortObject.key, this.sortObject.order, this.filterObject, ["Table"]);
    }
    else {
      let table = this.GetTableConfig();
      this.GetTableData(this.pagination.currentPage, this.pagination.recordsPerPage, table ? table.default_sort_column : "DateofSurgery", table ? table.default_sort_order : "ASC", this.filterObject, ["Table"]);
    }
    //this.GetData(e.currentPage, e.recordsPerPage, ["Table"]);
  }


  GetTableConfig() {
    let keys = Object.keys(this.chartjson);
    let table = undefined;
    for (var i = 0; i < keys.length; i++) {
      let c = this.chartjson[keys[i]];
      if (c.charttype == "table") {
        table = c;
      }
    }
    return table;
  }
  OnFilter(e: Filters) {
    this.switchBar = {};
    this.filter = e;
    this.filter.metric = this.selectedLOS;
    this.filterApi = this.filter;
    this.filterObject = undefined;
    if (this.page) {
      this.LoadChart();
    }
    else {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.page = params.get('page');
        if (this.page) {
          this.LoadChart();
        }
      });
    }
  }
  public LoadChart() {
    this.switchBar = {};
    if (sessionStorage.getItem("outcomemetric")) {
      this.page = sessionStorage.getItem("outcomemetric");
    }
    forkJoin([
      this.http.get(`./assets/config-jsons/${this.page}-ui.json`),
      this.http.get(`./assets/config-jsons/${this.page}-chart.json`)
    ]).subscribe(([uijson, chartjson]) => {


      setTimeout(() => {
        const nodeList = document.querySelectorAll(".periop-widget");
        for (let i = 0; i < nodeList.length; i++) {
          let t = (nodeList[i].clientHeight - nodeList[i].querySelector(".widget-new").clientHeight) / 2 - 7;
          let c = nodeList[i].querySelector(".content");
          if (c) {
            c['style'].paddingTop = t.toString() + 'px';
          }
        }
      }, 100);


      setTimeout(() => {
        this.uijson = uijson;
        this.chartjson = chartjson;

        /** Clear previously selected*/
        let k = Object.keys(this.chartjson);
        for (var i = 0; i < k.length; i++) {
          let d = this.chartjson[k[i]].filter;
          if (d) {
            for (var j = 0; j < d.length; j++) {
              if (this.filter[d[j]]) {
                this.filter[d[j]] = null;
              }
            }
          }
        }
        let table = this.GetTableConfig();
        if (table && table.default_no_of_records && table.default_sort_column && table.default_sort_order) {
          this.GetData(1, table.default_no_of_records, table.default_sort_column, table.default_sort_order);
        }
        else {
          this.GetData(1, 50, "DateofSurgery", "ASC");
        }

      });
    });
  }
  ngOnInit(): void {
    if (this.page && this.filter) {
      this.filter.metric = this.selectedLOS;
      this.filterApi = this.filter;
      this.LoadChart();
    }
  }

  SwitchBar(col: any) {

    if (this.switchBar[col.id]) {
      for (var i = 0; i < this.chartjson[col.datalabel].ykey.length; i++) {
        this.chartjson[col.datalabel].ykey[i] = this.chartjson[col.datalabel].ykey[i] + 'Percentage';
      }
      this.chartjson[col.datalabel].format = "%";
    }
    else {
      for (var i = 0; i < this.chartjson[col.datalabel].ykey.length; i++) {
        this.chartjson[col.datalabel].ykey[i] = this.chartjson[col.datalabel].ykey[i].replace("Percentage", "");
      }
      this.chartjson[col.datalabel].format = undefined;
    }
    this.Datatransform(this.currentData, [col.datalabel], true)

  }
  private DownloadData(currentPage: number, recordsPerPage: number, sortColumn: string, sortOrder: string, filterObject: Array<any>, type: string) {
    if (filterObject && filterObject.length > 0) {
      this.filter.filterObject = JSON.stringify(filterObject);
    }
    else {
      this.filter.filterObject = "All";
    }

    this.outCome.DownloadData(this.page, currentPage.toString(), recordsPerPage.toString(), sortColumn, sortOrder, this.filter, type)
      .subscribe(fileName => {
        if (type == "download") {
          window.open(environment.apiURL + "periops/outcome/download/" + this.page + "/" + fileName, '_blank').focus();
        }
        else {
          if (fileName == "NO") {
            this.toastr.warning("Email is not configured");
          }
          else {
            this.toastr.success(`Email sent to ${fileName}. It can take up to 5 minutes for email delivery to complete.`);
          }
        }
      });
  }


  private GetTableData(currentPage: number, recordsPerPage: number, sortColumn: string, sortOrder: string, filterObject: Array<any>,
    crossfilterobjects: Array<string> = []) {
    if (filterObject && filterObject.length > 0) {
      this.filter.filterObject = JSON.stringify(filterObject);
    }
    else {
      this.filter.filterObject = "All";
    }
    this.outCome.GetTable(this.page, currentPage.toString(), recordsPerPage.toString(), sortColumn, sortOrder, this.filter)
      .subscribe(d => {
        this.Datatransform(d, crossfilterobjects)

      });
  }

  private GetData(currentPage: number, recordsPerPage: number, sortColumn: string, sortOrder: string, crossfilterobjects: Array<string> = [], id: string = undefined) {
    this.outCome.GetDashboard(this.page, currentPage.toString(), recordsPerPage.toString(), sortColumn, sortOrder, this.filter)
      .subscribe(d => {
        if (id) {
          let i = id.split("@@")[0];
          let v = id.split("@@")[1];


          d3.selectAll("[id^='" + i + "']")
            .selectAll(".mousehover-bars")
            .style("opacity", (b: any) => {

              if (b && b.key && b.key.split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "") == v) {
                return 0;
              }
              else {
                return 0.7;
              }
            });


          d3.selectAll("[id^='" + i + "']")
            .selectAll(".mousehover-paths")
            .style("opacity", (b: any) => {
              //  console.log(b)
              if (b && b.data && b.data.key && b.data.key.split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "") == v) {
                return 1;

              }
              else {
                return 0.3;
              }
            })

          //.each((child: any) => {
          //if
          //})
        }
        this.currentData = d;
        this.Datatransform(d, crossfilterobjects)
      });
  }

  Datatransform(d: any, crossfilterobjects: Array<string> = [], isSwitchBarEvent: boolean = false) {
    if (crossfilterobjects.length == 0) {
      this.outputdata = JSON.parse(JSON.stringify(d));
    }

    let keys = Object.keys(this.chartjson)
      .filter(f => {
        return crossfilterobjects.length == 0 || crossfilterobjects.indexOf(f) > -1;
      })
    for (var j = 0; j < keys.length; j++) {
      let i = j;
      let config = this.chartjson[keys[i]];
      let pos = config.position.split(',');

      if (config.charttype == "pie") {
        let pieConfig = this.PieConfig(d[keys[i]], config);
        if (pos.length == 2) {
          let dynamicData = this.uijson['rows'][parseInt(pos[0])].cols[parseInt(pos[1])];
          dynamicData.data = undefined;
          setTimeout(() => {
            dynamicData.data = pieConfig;
          });
        }
        else {
          let dynamicData = this.uijson['rows'][parseInt(pos[0])]
            .cols[parseInt(pos[1])]
          ['rows'][parseInt(pos[2])]
            .cols[parseInt(pos[3])];

          dynamicData.data = undefined;

          setTimeout(() => {
            dynamicData.data = pieConfig;
          })


        }

      }
      else if (config.charttype == "widget") {
        if (pos.length == 2) {
          let dynamicData = this.uijson['rows'][parseInt(pos[0])].cols[parseInt(pos[1])];

          dynamicData.data = undefined;
          setTimeout(() => {
            dynamicData.data = d[keys[i]][0][config.key];
          });
        }
        else {
          let dynamicData = this.uijson['rows'][parseInt(pos[0])]
            .cols[parseInt(pos[1])]
          ['rows'][parseInt(pos[2])]
            .cols[parseInt(pos[3])];

          dynamicData.data = undefined;

          setTimeout(() => {

            dynamicData.data = d[keys[i]][0][config.key];

          });
        }
      }
      if (config.charttype == "vstackedbar" || config.charttype == "hstackedbar" || config.charttype == "hbar" || config.charttype == "vbar") {
        let barConfig = this.BarConfig(d[keys[i]], config);

        if (pos.length == 2) {
          let dynamicData = this.uijson['rows'][parseInt(pos[0])].cols[parseInt(pos[1])];
          dynamicData.data = undefined;
          setTimeout(() => {
            dynamicData.data = barConfig;
            if (dynamicData['id'] && dynamicData['switch_bar_default_value'] && !isSwitchBarEvent) {
              this.switchBar[dynamicData['id']] = dynamicData['switch_bar_default_value'];
            }
          });
        }
        else {

          let dynamicData = this.uijson['rows'][parseInt(pos[0])]
            .cols[parseInt(pos[1])]
          ['rows'][parseInt(pos[2])]
            .cols[parseInt(pos[3])];
          dynamicData.data = undefined;

          setTimeout(() => {
            dynamicData.data = barConfig;
            if (dynamicData['id'] && dynamicData['switch_bar_default_value'] && !isSwitchBarEvent) {
              this.switchBar[dynamicData['id']] = dynamicData['switch_bar_default_value'];
            }
          });

        }

      }
      if (config.charttype == "line") {

        let lineConfig = this.LineConfig(d[keys[i]], config);

        if (pos.length == 2) {
          let dynamicData = this.uijson['rows'][parseInt(pos[0])].cols[parseInt(pos[1])];

          dynamicData.data = undefined;

          setTimeout(() => {
            dynamicData.data = lineConfig;
          });
        }
        else {

          let dynamicData = this.uijson['rows'][parseInt(pos[0])]
            .cols[parseInt(pos[1])]
          ['rows'][parseInt(pos[2])]
            .cols[parseInt(pos[3])];

          dynamicData.data = undefined;

          setTimeout(() => {
            dynamicData.data = lineConfig;
          });
        }

      }
      if (config.charttype == "smalltable") {
        if (pos.length == 2) {
          let dynamicData = this.uijson['rows'][parseInt(pos[0])].cols[parseInt(pos[1])];
          dynamicData.data = undefined;
          setTimeout(() => {
            dynamicData.data = d[keys[i]];
            dynamicData.table_header = config.table_header;
          });
        }
        else {
          let dynamicData = this.uijson['rows'][parseInt(pos[0])]
            .cols[parseInt(pos[1])]
          ['rows'][parseInt(pos[2])]
            .cols[parseInt(pos[3])];
          dynamicData.data = undefined;

          setTimeout(() => {
            dynamicData.data = d[keys[i]];
            dynamicData.table_header = config.table_header;
          });
        }

      }
      if (config.charttype == "table") {

        //let tableConfig =
        let tableConfig = new TableModel();
        // tableConfig.width = "130%";
        tableConfig.is_switch = config.is_switch;
        tableConfig.is_downloadable = config.is_downloadable;
        tableConfig.is_pagination = config.is_pagination;
        tableConfig.default_sort_column = config.default_sort_column;
        tableConfig.default_sort_order = config.default_sort_order?.toLowerCase();
        tableConfig.columns = config.columns as Array<ColumnModel>;

        for (var ij = 0; ij < tableConfig.columns.length; ij++) {
          let v = tableConfig.columns[ij];
          if (v.filter_type == "string") {
            if (d[v.data_key]) {
              let f = d[v.data_key].map(ff => ff.FilterValue);
              f.unshift("All")
              v.filter_texts = f;
            }
          }
        }
        if (d[keys[i]].length > 0) {
          tableConfig.total_records = d[keys[i]][0]["TotalRecords"];
        }
        else {
          tableConfig.total_records = 0;
        }
        let c = config.columns.filter(f => f.column_type == "date").map(f => f.data_key);
        tableConfig.table_data = d[keys[i]].map(d => {
          /************** Converting date string into Date Object **************/
          for (var i = 0; i < c.length; i++) {
            let dt = d[c];
            if (dt && (typeof dt) == "string") {
              let c = dt.split("-");
              d[c] = new Date(parseInt(c[0]), (parseInt(c[1]) - 1), parseInt(c[2].slice(0, 2)));
            }
            return d;
          }

        });

        if (pos.length == 2) {
          let dynamicData = this.uijson['rows'][parseInt(pos[0])].cols[parseInt(pos[1])];
          //dynamicData.data = undefined;

          setTimeout(() => {
            dynamicData.data = tableConfig;
          });
        }
        else {

          let dynamicData = this.uijson['rows'][parseInt(pos[0])]
            .cols[parseInt(pos[1])]
          ['rows'][parseInt(pos[2])]
            .cols[parseInt(pos[3])];

          //dynamicData.data = undefined;

          setTimeout(() => {
            dynamicData.data = tableConfig;
          });

        }

      }
    }
  }
  SelectRow(e: any, dataLabel: string, p: number = undefined) {
    if (p != undefined) {
      if (this.selectedRow == p) {
        this.DeSelectFilter(null);
      }
      else {
        let obj = {};
        obj["data"] = e;
        obj["id"] = e["PONVProphylaxis"];
        this.Select(obj, dataLabel, p);
        this.selectedRow = p;
      }
    }
  }
  Select(clickData: any, dataLabel: string, p: number = undefined) {

    this.DeSelectFilter(null);

    setTimeout(() => {
      for (var i = 0; i < 2; i++) {
        let d = document.getElementById('tr' + i.toString());
        if (p == i) {
          if (d)
            d.style.opacity = "1";
        }
        else {
          if (d)
            d.style.opacity = "0.3";
        }
      }
    }, 1000);

    if (this.selectedBar != clickData["id"]) {
      this.selectedBar = clickData["id"]

      let e = clickData["data"];
      /** Clear previously selected*/
      let k = Object.keys(this.chartjson);
      for (var i = 0; i < k.length; i++) {
        let d = this.chartjson[k[i]].filter;
        if (d) {
          for (var j = 0; j < d.length; j++) {
            if (this.filter[d[j]]) {
              this.filter[d[j]] = null;
            }
          }
        }
      }

      let c = this.chartjson[dataLabel];
      this.filterObject = undefined;

      // filter: Array<string>, dbFilter: Array<any>, crossfilterobjects: Array<string>
      for (var i = 0; i < c.filter.length; i++) {
        if (c.dbfilters[c.filter[i]] && c.dbfilters[c.filter[i]] != "") {
          this.filter[c.filter[i]] = e[c.dbfilters[c.filter[i]]];
        }
        else {
          this.filter[c.filter[i]] = dataLabel;
        }
      }
      let table = this.GetTableConfig();

      if (table && table.default_no_of_records && table.default_sort_column && table.default_sort_order) {
        this.GetData(1, table.default_no_of_records, table.default_sort_column, table.default_sort_order, c.crossfilterobjects, clickData["id"]);
      }
      else {
        this.GetData(1, 50, "DateofSurgery", "ASC", c.crossfilterobjects, clickData["id"]);
      }
    }
    else {
      this.selectedBar = undefined;
    }
  }
  DeSelectFilters() {
    this.filter = JSON.parse(JSON.stringify(this.filterApi));
    this.filterObject = undefined;
    this.Datatransform(this.outputdata, [])
  }
  DeSelectFilter($event) {
    for (var i = 0; i < 2; i++) {
      let d = document.getElementById('tr' + i.toString());
      if (d)
        d.style.opacity = "1";
    }
    this.DeSelectFilters();
  }
  private LineConfig(data: any, config: any) {

    let style = config.style as Array<LineStyle>;
    let markerstyle = config.marker as Array<MarkerStyle>;
    let tooltip_keys = config.tooltip_keys as Array<DisplayModel>;
    let additional_data = config.additional_data as Array<string>;

    //console.log(tooltip_keys)
    let lineConfig = this.chartService.GeneratePlainLineData(data, config.xkey, config.ykey,
      config.tooltipkey, style, markerstyle, additional_data);
    lineConfig.margin_bottom = config.margin_bottom;
    lineConfig.margin_right = config.margin_right;
    lineConfig.margin_left = config.margin_left;
    lineConfig.margin_top = config.margin_top;
    lineConfig.container = config.container;
    lineConfig.xaxistype = config.xaxistype;
    lineConfig.zoomtype = config.zoomtype;
    lineConfig.tick_size_y = config.tick_size_y;
    lineConfig.is_line_text = config.is_line_text;
    lineConfig.xlabel = config.xlabel;
    lineConfig.ylabel = config.ylabel;
    lineConfig.height = config.height;
    lineConfig.format = config.format;
    lineConfig.tooltip_keys = tooltip_keys;
    return lineConfig;
  }
  private BarConfig(data: any, config: any) {
    let arr = data.map(f => f[config.groupkey]);
    let hbarConfig = null;
    let color = null;

    if (config.is_nongrouping) {
      color = config.color;
      hbarConfig = this.chartService.GeneratePlainBarData(data, config.xkey, config.ykey,
        color, config.ytext ? config.ytext : [], config.additional_data);
    }
    else {
      color = this.GetColorArray(arr, config.color, config.ytext);
      hbarConfig = this.chartService.GenerateBarData(data, config.xkey, config.ykey,
        color, config.ytext, config.groupkey, config.additional_data);
    }
    //"color": [ "#bd86ad", "#ff9d97", "#ffaa84" ],
    if (config.ytext && config.ytext) {
      hbarConfig.color_scale = d3.scaleOrdinal()
        .domain(config.ytext)
        .range(config.color);
    }
    if (config.is_linear_color) {
      if (config.color.length == 2) {
        let v = d3.extent(data.filter(f => f[config.ykey[0]] > 0).map(f => f[config.ykey[0]])).map(a => parseInt(a));
        hbarConfig.color_scale = d3.scaleLinear().domain(v)
          .range(config.color);
      }
      else {
        let v1 = data.filter(f => f[config.ykey[0]] > 0).map(f => f[config.ykey[0]]).map(a => parseInt(a))
        let v = d3.extent(v1).map(a => parseInt(a));
        let m = d3.median(v1);
        hbarConfig.color_scale = d3.scaleLinear().domain([v[0], m, v[1]])
          .range(config.color);
      }
    }

    //hbarConfig.color_scale bar_text_size
    hbarConfig.bar_text_size = config.bar_text_size;
    hbarConfig.is_rotate_xaxis = config.is_rotate_xaxis;
    hbarConfig.is_crop_xaxis_length = config.is_crop_xaxis_length;

    hbarConfig.is_linear_color = config.is_linear_color;
    hbarConfig.margin_bottom = config.margin_bottom;
    hbarConfig.margin_right = config.margin_right;
    hbarConfig.margin_left = config.margin_left;
    hbarConfig.margin_top = config.margin_top;
    hbarConfig.bar_height = config.bar_height;
    hbarConfig.tick_size = config.tick_size;
    hbarConfig.container = config.container;
    hbarConfig.axis_position = config.axis_position;
    hbarConfig.format = config.format;
    hbarConfig.height = config.height;
    hbarConfig.is_tower_chart = config.is_tower_chart;


    /* Tool tip purpose */
    for (var i = 0; i < config?.tooltip?.length; i++) {
      let dd = new DisplayModel();
      dd.display_text = config.tooltip[i]["display_text"];
      dd.display_key = config.tooltip[i]["display_key"];
      hbarConfig.tooltip_keys.push(dd);
    }
    return hbarConfig;
  }

  GetColorArray(arr: Array<string>, chartsColor: any, group: Array<string>) {
    if (arr.indexOf(group[0]) > -1 && arr.indexOf(group[0]) > -1) {
      return [chartsColor[group[0]], chartsColor[group[1]]];
    }
    else if (arr.indexOf(group[0]) > -1) {
      return [chartsColor[group[0]]];
    }
    else if (arr.indexOf(group[1]) > -1) {
      return [chartsColor[group[1]]];
    }
  }

  private PieConfig(data: any, config: any) {

    let pieconfig = new PieModel();
    pieconfig.container = config.container;
    pieconfig.margin_right = config.margin_right;
    pieconfig.margin_left = config.margin_left;
    pieconfig.margin_top = config.margin_top;
    pieconfig.margin_bottom = config.margin_bottom;
    pieconfig.chart_type = config.chart_type;
    pieconfig.is_legend = config.is_legend;
    pieconfig.is_display_text = config.is_display_text;

    if (config['display_key']) {
      let t = new DisplayModel();
      t.display_text = "tooltext";
      t.display_key = "valuetext";
      pieconfig.display_keys.push(t);
    }

    for (var i = 0; i < data.length; i++) {
      let n = new PieData();
      n.key = data[i][config.key];
      n.value = data[i][config.value];
      n.color = config.color[data[i][config.key]];
      n.additional_data.tooltext = "";
      n.additional_data.valuetext = data[i][config.display_key];
      pieconfig.chart_data.push(n);
    }
    return pieconfig;
  }

  TrendQuantityChange(dataLable: string) {
    if (dataLable == "TrendQuantity") {
      this.chartjson[dataLable].ykey = [this.trendQuantity[dataLable]];
      let lineConfig = this.LineConfig(this.outputdata[dataLable], this.chartjson[dataLable]);
      this.uijson["rows"][1].cols[0].data = undefined;
      setTimeout(() => {
        this.uijson["rows"][1].cols[0].data = lineConfig;
      });
    }
    else {
      this.chartjson[dataLable].ykey = [this.trendQuantity[dataLable]];
      let barConfig = this.BarConfig(this.outputdata[dataLable].slice().sort((a, b) => b[this.trendQuantity[dataLable]] - a[this.trendQuantity[dataLable]]), this.chartjson[dataLable]);
      this.uijson["rows"][2].cols[0].data = undefined;
      setTimeout(() => {
        this.uijson["rows"][2].cols[0].data = barConfig;
      });
    }
  }
}
