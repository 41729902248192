export class PeriopOutcomeFilter {
  public fromDate: string;
  public toDate: string;
  public month: string;
  public year: string;
  public quarter: string;
  public dateRange: string;
  public facility: string;

  public multiAnesthesiologist: Array<string>;
  public multiAnesthesiologistObject: Array<any>;

  public multiCRNAResident: Array<string>;
  public multiCRNAResidentObject: Array<any>;

  public multiCRNA: Array<string>;
  public multiCRNAObject: Array<any>;

  public multiResident: Array<string>;
  public multiResidentObject: Array<any>;

  public prophylaxis: string;
  public gender: string;
  public asacode: string;
  public accutekidneyinjury: string;
  public lungventilation: string;
  public postopregional: string;
  public postoperativenormothermia: string;
  public activewarning: string;
  public admittype: string;
  public dispositiontype: string;
  public nauseaDeduction: string;
  public ponvnausea: string;
  public riskcount: number;
  public riskfactor: string;
  public durationgroup: string;
  public procedureType: string;
  public isAnesSelectAll: boolean;
  public isCRNAResSelectAll: boolean;
  public isResidentSelectAll: boolean;
  public isCRNASelectAll: boolean;

  public fromDateNew: string;
  public toDateNew: string;
  public filterObject: string;
}

export const Filter_Options = {
  PONVOccurence: {
    facility: true, prophylaxis: true, anesthesiologist: true,
    crnaresident: true
  },
  IntraOperative: {
    gender: true, facility: true, asacode: true, accutekidneyinjury: true,
    anesthesiologist: true, crnaresident: true
  },
  LungProtective: {
    facility: true, asacode: true, lungventilation: true,
    anesthesiologist: true, crnaresident: true
  },
  PainScore: {
    facility: true, postopregional:true, anesthesiologist: true, crnaresident: true
  },
  PONormothermia: {
    facility: true, postoperativenormothermia: true, activewarming: true,
    anesthesiologist: true, crnaresident: true
  }
};
