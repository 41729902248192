import { Injectable } from '@angular/core';
import { HBarConfig, HData, HbarData } from './models/hbarmodel';
import { LineConfig, LineModel, LineStyle, LineValues, MarkerStyle } from './models/linemodel';
//import { HBarConfig, HData, HbarData } from './d3components/models/hbarmodel';
//import { LineConfig, LineModel, LineStyle, LineValues, MarkerStyle } from './d3components/models/linemodel';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  private _groupBy: any = function (xs: Array<any>, key: string) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  constructor() {
  }

  GeneratePlainBarData(data: Array<any>, xKey: string, yKey: Array<string>,
    barColor: Array<string>,
    yText: Array<string> = [],
    additionalData: Array<string> = []): HBarConfig {

    let hbarConfig = new HBarConfig();
    yText = yText.length == 0 ? yKey : yText;
    for (var i = 0; i < data.length; i++) {
      let v = new HbarData();
      v.key = data[i][xKey];
      for (var j = 0; j < yKey.length; j++) {
        let hData = new HData();
        hData.additional_data = {};
        hData.color = barColor[j];
        hData.key = yText[j];
        hData.value = data[i][yKey[j]];
        for (var k = 0; k < additionalData.length; k++) {
          hData.additional_data[additionalData[k]] = data[i][additionalData[k]]
        }
        v.data.push(hData);
      }
      hbarConfig.chart_data.push(v);
    }
    return hbarConfig;
  }

  GenerateBarData(data: Array<any>, xKey: string, yKey: string,
    barColor: Array<string>,
    yText: Array<string>,
    additionalData: Array<string> = []): HBarConfig {

    let hbarConfig = new HBarConfig();

    let groupedData = this._groupBy(data, xKey);
    for (let key of Object.keys(groupedData)) {
      let subObjArr = groupedData[key];
      let v = new HbarData();
      v.key = key;
      for (var i = 0; i < subObjArr.length; i++) {

        let hData = new HData();
        hData.additional_data = {};
        hData.color = barColor[i];
        hData.key = yText[i];
        hData.value = subObjArr[i][yKey];
        for (var k = 0; k < additionalData.length; k++) {
          hData.additional_data[additionalData[k]] = subObjArr[i][additionalData[k]]
        }
        v.data.push(hData);
      }
      hbarConfig.chart_data.push(v);
    }
    return hbarConfig;
  }

  GeneratePlainLineData(data: Array<any>, xKey: string, yKey: Array<string>,
    lineTypes: Array<string>,
    lineStyle: Array<LineStyle>,
    markerStyle: Array<MarkerStyle>,
    additionalData: Array<string> = []): LineConfig {
    let lineConfig = new LineConfig();
    let lineModels = [];
    for (var j = 0; j < yKey.length; j++) {
      let lm = new LineModel();
      lm.key = lineTypes[j];
      lm.linestyle = lineStyle[j];
      lm.markerstyle = markerStyle[j];
      for (var i = 0; i < data.length; i++) {
        let d = new LineValues();
        d.value = data[i][yKey[j]];
        d.xaxis = data[i][xKey];
        for (var k = 0; k < additionalData.length; k++) {
          d.additional_data[additionalData[k]] = data[i][additionalData[k]]
        }
        lm.values.push(d);
      }
      lineModels.push(lm);
    }
    lineConfig.data = lineModels;
    return lineConfig;
  }

  GenerateLineData(data: Array<any>, xKey: string, yKey: string,
    lineTypeKey: string,
    lineTypes: Array<string>,
    lineStyle: Array<LineStyle>,
    markerStyle: Array<MarkerStyle>,
    additionalData: Array<string> = []): LineConfig {
    let lineConfig = new LineConfig();

    let lineModels = [];
    let groupedData = this._groupBy(data, lineTypeKey);

    let j = 0;
    for (let key of Object.keys(groupedData)) {

      let lm = new LineModel();
      lm.key = lineTypes[j];
      lm.linestyle = lineStyle[j];
      lm.markerstyle = markerStyle[j];
      j++;

      let subObjArr = groupedData[key];
      for (var i = 0; i < subObjArr.length; i++) {
        let d = new LineValues();
        d.value = subObjArr[i][yKey];
        d.xaxis = subObjArr[i][xKey];
        for (var k = 0; k < additionalData.length; k++) {
          d.additional_data[additionalData[k]] = subObjArr[i][additionalData[k]]
        }
        lm.values.push(d);
      }
      //}
      lineModels.push(lm);
    }
    lineConfig.data = lineModels;
    return lineConfig;
  }

}
