import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Occupancy } from '../Occupancy';

@Component({
  selector: 'app-occupancy',
  templateUrl: './occupancy.component.html',
  styleUrls: ['./occupancy.component.css']
})
export class OccupancyComponent implements OnInit {

  @Input() container: string = ''
  @Input() occupied: any;
  @Input() allocated: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<Occupancy> = new EventEmitter();
  occ!: Occupancy;


  constructor() { }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {

    if (this.allocated) {
      var data = [
        {
          roomtype: "allocated", roomcount: this.allocated
        },
        {
          roomtype: "occupied", roomcount: this.occupied
        }
      ];
    }
    else {
      var data = [
        {
          roomtype: "occupied", roomcount: this.occupied
        }
      ];
    }
    this.occ = new Occupancy(this.container, data, this.configuration);
    this.occ.Render();
    this.myOutput.emit(this.occ);
  }

}
