<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'ruv'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          RVU for&nbsp;
          <span *ngIf="filters!=undefined && filters?.provider=='All'">
            <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
            {{filters?.facility=="All"?'All Facilities':''}}&nbsp;
            <span *ngIf="filters!=undefined && filters?.surgeService!='All'">{{filters?.surgeService}}</span>&nbsp; {{filters?.surgeService=="All"?'All Services':''}}&nbsp;
            Anesthesiologists &nbsp;
          </span>
          <span *ngIf="filters!=undefined && filters?.provider!='All'">
            {{filters?.provider!="All"?filters?.providerName:''}} &nbsp;
          </span>{{totalrecords}} Total Cases &nbsp;
          &nbsp;
          &nbsp;
          <!--&nbsp;
  <span *ngIf="filters!=undefined && filters.providerName!='All'">{{filters?.providerName}}</span> &nbsp;
  <span *ngIf="filters!=undefined && filters.providerName=='All'">All Anesthesiologists</span>&nbsp;{{totalrecords}}&nbsp; Total Cases &nbsp; &nbsp; &nbsp;-->

          <span class="subtitle">Total Units: {{totalunits}} &nbsp; Avg Units: {{avgunits}}</span>

          &nbsp;
          &nbsp;
          &nbsp;
          <app-download-dialog (download)="DownloadCallBack($event)" (sendemail)="SendEmailCallBack($event)" [reporttype]="'RVU'"></app-download-dialog>
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-body">

  <div class="container-xl">

    <p-tabs (onSelect)="OnSelectTab($event)">
      <p-tab [tabTitle]="'Case Level View'" [id]="'CaseLeve'">
        <div class="filter-apply">
          <button type="button" class="btn btn-success" (click)="TableFilter()" [disabled]="!isFilterChanged">Apply Filter Changes</button>&nbsp;&nbsp;
          <span *ngIf="isFilterChanged" style="color:orangered">
            Filter conditions have changed - the previous data may be incorrect. Please click 'Apply' to update the dashboard.
          </span>
        </div>
        <div class="card" style="max-height: calc(100vh - 260px);" *ngIf="RVU!=undefined">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <table class="casemixgraphic fixTableHead " *ngIf="RVU!=undefined">

              <thead>
                <tr>
                  <ng-container *ngFor="let c of columns;">
                    <th *ngIf="c.name!='Anesthesiologist'&& c.name!='Procedure Description' " (click)="Sort(c.fil)" style="cursor:pointer;">
                      {{c.name}}
                      <span *ngIf="sortObjects[c.fil]!=undefined && sortObjects[c.fil]=='ASC'">
                        <img src="../../assets/sortasc.png" />
                      </span>
                      <span *ngIf="sortObjects[c.fil]!=undefined && sortObjects[c.fil]=='DESC'">
                        <img src="../../assets/sortdesc.png" />
                      </span>
                    </th>

                    <th *ngIf="c.name=='Procedure Description'" (click)="Sort(c.fil)" style="cursor:pointer;min-width:250px!important;">
                      {{c.name}}
                      <span *ngIf="sortObjects[c.fil]!=undefined && sortObjects[c.fil]=='ASC'">
                        <img src="../../assets/sortasc.png" />
                      </span>
                      <span *ngIf="sortObjects[c.fil]!=undefined && sortObjects[c.fil]=='DESC'">
                        <img src="../../assets/sortdesc.png" />
                      </span>
                    </th>
                    <th *ngIf="c.name=='Anesthesiologist'" (click)="Sort(c.fil)" style="cursor:pointer;min-width:150px!important;">
                      {{c.name}}
                      <span *ngIf="sortObjects[c.fil]!=undefined && sortObjects[c.fil]=='ASC'">
                        <img src="../../assets/sortasc.png" />
                      </span>
                      <span *ngIf="sortObjects[c.fil]!=undefined && sortObjects[c.fil]=='DESC'">
                        <img src="../../assets/sortdesc.png" />
                      </span>
                    </th>
                  </ng-container>
                </tr>

                <tr>
                  <td *ngFor="let c of columns;">
                    <div *ngIf="c.isfilter && c.type=='text'">
                      <input type="text" [(ngModel)]="filtersAll[c.fil]" (ngModelChange)="this.filterChange.next($event)" style="width:100%;height:29px" />
                    </div>
                    <div *ngIf="c.isfilter && c.type=='ddl'">

                      <select class="form-select" [(ngModel)]="filtersAll[c.fil]" (ngModelChange)="this.filterChange.next($event)">
                        <option *ngFor="let r of c.filterdata" [value]="r">{{r}}</option>
                      </select>
                    </div>
                    <div *ngIf="c.isfilter && c.type=='number'">
                      <div class="row">
                        <div class="col">
                          <select class="form-select" style="width:70px" [(ngModel)]="filtersAll[c.fil+'_num']" (ngModelChange)="this.filterChange.next($event)">
                            <option [value]="'='" selected>=</option>
                            <option [value]="'>'">></option>
                            <option [value]="'<'"><</option>
                          </select>
                        </div>
                        <div class="col">
                          <input type="number" (ngModelChange)="this.filterChange.next($event)" style="width:40px;height:29px" [(ngModel)]="filtersAll[c.fil]" />
                        </div>
                      </div>

                    </div>
                  </td>
                </tr>

                <!--<tr>
                  <td></td>
                  <td>
                    <input type="text" [(ngModel)]="filtersAll[c.fil]" (ngModelChange)="this.filterChange.next($event)" style="width:100%;height:29px" />
                  </td>
                  <td> <input type="text" [(ngModel)]="filtersAll[c.fil]" (ngModelChange)="this.filterChange.next($event)" style="width:100%;height:29px" /></td>
                  <td><input type="text" [(ngModel)]="filtersAll[c.fil]" (ngModelChange)="this.filterChange.next($event)" style="width:100%;height:29px" /></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="row">
                      <div class="col">
                        <select class="form-select" style="width:70px" [(ngModel)]="filtersAll[c.fil]" (ngModelChange)="this.filterChange.next($event)">
                          <option [value]="'='" selected>Equal to</option>
                          <option [value]="'>'">Greater than</option>
                          <option [value]="'<'">Less than</option>
                        </select>
                      </div>
                      <div class="col">
                        <input type="number" (ngModelChange)="this.filterChange.next($event)" style="width:40px;height:29px" [(ngModel)]="filtersAll[c.fil]" />
                      </div>
                    </div>
                  </td>
                </tr>-->
              </thead>
              <caption *ngIf="RVU!=undefined && RVU.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
              <tbody>

                <tr *ngFor="let c of RVU">
                  <td>{{c.CaseDate| date:'MM/dd/yyyy'}} </td>
                  <td>{{c.AnesthesiologistPrimary}}</td>
                  <td>{{c.ProcedureDescription}}</td>
                  <td>{{c.AnesCPT}}</td>
                  <td>{{c.ProcedureRVU}}</td>
                  <td>{{c.AnesStart| date:'HH:mm'}}</td>
                  <td>{{c.AnesEnd| date:'HH:mm'}}</td>
                  <td>{{c.TimeRVU}}</td>
                  <td>{{c.ASA}}</td>
                  <td>{{c.ASA_RVU}}</td>
                  <td>{{c.EmergencyRVU}}</td>
                  <td>{{c.Age}}</td>
                  <td>{{c.AgeRVU}}</td>
                  <td style="text-align: right; font-weight: bold;">{{c.TotalRVU}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" *ngIf="RVU!=undefined && RVU.length > 0">
          <div class="col-12">
            <app-pagination [RecordsPerPage]="100" [TotalRecords]="totalrecords" (pageEvent)="PageEvent($event)" [CurrentPage]="page.currentPage">
            </app-pagination>
          </div>
        </div>
      </p-tab>
      <p-tab [tabTitle]="'Summary View'" [id]="'Summary'">
        <div class="filter-apply">
          <button type="button" class="btn btn-success" (click)="TableFilter()" [disabled]="!isFilterChangedSummary">Apply Filter Changes</button>&nbsp;&nbsp;
          <span *ngIf="isFilterChangedSummary" style="color:orangered">
            Filter conditions have changed - the previous data may be incorrect. Please click 'Apply' to update the dashboard.
          </span>
          <!--<a (click)="TableFilter()">Apply</a>-->
        </div>
        <div class="card" style="max-height: calc(100vh - 260px);" *ngIf="RVUSummary!=undefined">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <table class="casemixgraphic fixTableHead " *ngIf="RVUSummary!=undefined">

              <thead>
                <tr>
                  <ng-container *ngFor="let c of columnsSummary;">
                    <th *ngIf="c.name!='Anesthesiologist' && c.fil!='ProcedureDescription'" (click)="SortSummary(c.fil)" style="cursor:pointer;">
                      {{c.name}}
                      <span *ngIf="sortObjectsSummary[c.fil]!=undefined && sortObjectsSummary[c.fil]=='ASC'">
                        <img src="../../assets/sortasc.png" />
                      </span>
                      <span *ngIf="sortObjectsSummary[c.fil]!=undefined && sortObjectsSummary[c.fil]=='DESC'">
                        <img src="../../assets/sortdesc.png" />
                      </span>
                    </th>
                    <th *ngIf="c.name=='Anesthesiologist'" (click)="SortSummary(c.fil)" style="cursor:pointer;min-width:150px!important;">
                      {{c.name}}
                      <span *ngIf="sortObjectsSummary[c.fil]!=undefined && sortObjectsSummary[c.fil]=='ASC'">
                        <img src="../../assets/sortasc.png" />
                      </span>
                      <span *ngIf="sortObjectsSummary[c.fil]!=undefined && sortObjectsSummary[c.fil]=='DESC'">
                        <img src="../../assets/sortdesc.png" />
                      </span>
                    </th>
                  </ng-container>
                </tr>
                <tr>
                  <ng-container *ngFor="let c of columnsSummary;">

                    <td *ngIf="c.fil!='ProcedureDescription'">
                      <div *ngIf="c.isfilter && c.type=='text'">
                        <input type="text" [(ngModel)]="filtersAllSummary[c.fil]" (ngModelChange)="this.filterChange.next($event)" style="width:100%;height:29px" />
                      </div>
                      <div *ngIf="c.isfilter && c.type=='ddl'">

                        <select class="form-select" [(ngModel)]="filtersAllSummary[c.fil]" (ngModelChange)="this.filterChange.next($event)">
                          <option *ngFor="let r of c.filterdata" [value]="r">{{r}}</option>
                        </select>
                      </div>
                      <div *ngIf="c.isfilter && c.type=='number'">
                        <div class="row">
                          <div class="col">
                            <select class="form-select" style="width:70px" [(ngModel)]="filtersAllSummary[c.fil+'_num']" (ngModelChange)="this.filterChange.next($event)">
                              <option [value]="'='" selected>=</option>
                              <option [value]="'>'">></option>
                              <option [value]="'<'"><</option>
                            </select>
                          </div>
                          <div class="col">
                            <input type="number" (ngModelChange)="this.filterChange.next($event)" style="width:40px;height:29px" [(ngModel)]="filtersAllSummary[c.fil]" />
                          </div>
                        </div>

                      </div>
                    </td>
                  </ng-container>
                </tr>
              </thead>
              <caption *ngIf="RVUSummary!=undefined && RVUSummary.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
              <tbody>

                <tr *ngFor="let c of RVUSummary">
                  <td>{{c.AnesthesiologistPrimary}}</td>
                  <td>{{c.CaseCount}}</td>
                  <td>{{c.ProcedureRVU}}</td>
                  <td>{{c.TimeRVU}}</td>
                  <td>{{c.ASA}}</td>
                  <td>{{c.ASA_RVU}}</td>
                  <td>{{c.EmergencyRVU}}</td>
                  <td>{{c.AgeRVU}}</td>
                  <td style="text-align: right; font-weight: bold;">{{c.TotalRVU}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" *ngIf="RVUSummary!=undefined && RVUSummary.length > 0">
          <div class="col-12">
            <app-pagination [RecordsPerPage]="500" [TotalRecords]="totalrecordsSummary" (pageEvent)="PageEventSummary($event)" [CurrentPage]="pageSummary.currentPage">
            </app-pagination>
          </div>
        </div>

      </p-tab>
    </p-tabs>
  </div>
</div>

