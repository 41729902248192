import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BarLine } from '../BarLine';

@Component({
  selector: 'app-barline',
  templateUrl: './barline.component.html',
  styleUrls: ['./barline.component.css']
})
export class BarlineComponent implements OnInit {

  @Input() container: string = ''
  @Input() barData: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<BarLine> = new EventEmitter();
  @Input() screen: string;

  barline!: BarLine;

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    if (!this.configuration) {
      this.configuration = {};
      this.configuration.screen = this.screen;
    }
    else {
      this.configuration.screen = this.screen;
    }

    this.barline = new BarLine(this.container, this.barData, this.configuration);
    this.barline.Render();
    this.myOutput.emit(this.barline);
  }
}
