import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService, private router: Router, private commonService: CommonService) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (sessionStorage.getItem("token") == null || sessionStorage.getItem("token") == undefined) {
      window.location.href = environment.loginURL;
    } //TODO
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    const authReq = request.clone({
      headers: new HttpHeaders({
        'token':  sessionStorage.getItem("token"),//TODO
        'userlogin': userlogin
      })
    });
    
    if (authReq.url.indexOf(environment.auditLogURL) == -1 && authReq.url.toLowerCase().indexOf("filter/") == -1 && authReq.url.toLowerCase().indexOf("userroles/") == -1 && environment.IsLog) {
      var obj = {};
      obj['ApplicationId'] = environment.AppId;
      obj['UserLogin'] = sessionStorage.getItem("userlogin");
      obj['ActionType'] = "INFO";
      obj['URI'] = authReq.url;
      obj['Description'] = JSON.stringify(request.body);
      this.commonService.PostLog(obj).subscribe(d => {

      });
    }
    return next.handle(authReq)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';

          if (error.error instanceof ErrorEvent) {

          }
          else {
            if (error.status == 500) {

              if (authReq.url.indexOf(environment.auditLogURL) == -1) {
                var obj = {};
                obj['ApplicationId'] = environment.AppId;
                obj['UserLogin'] = sessionStorage.getItem("userlogin");
                obj['ActionType'] = "ERROR";
                obj['URI'] = authReq.url;
                obj['Description'] = error.message;
                this.commonService.PostLog(obj).subscribe(d => {
                });
              }

              this.toastr.error("Server Error");
            }
            if (error.status == 401) {
              window.location.href = environment.loginURL;
            }
          }
          return throwError(errorMessage);
        })
      )
  }
}
