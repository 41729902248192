<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'case-volume-forecast'"></app-filters>
</div>

<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Case Volume Forecast for&nbsp;<span *ngIf="filters!=undefined && filters?.facility!='All'">{{filters?.facility}}&nbsp;</span><span *ngIf="filters!=undefined && filters?.surgeService!='All'">{{filters?.surgeService}}</span>&nbsp;{{filters?.surgeService=="All"?'All Services':''}}
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">

  <div class="container-xl">

    <div class="col-md-12" *ngIf="forecastDateStart!=undefined && forecastDateEnd!=undefined">      
      <div class="card">
        <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric">
          <h4 class="card-title" style="cursor:pointer;">
            Case Volume Forecast for {{forecastDateStart | date:'MM/dd/yyyy'}} to {{forecastDateEnd | date:'MM/dd/yyyy' }}
          </h4>
        </div>
        <div *ngIf="forecastByService!=undefined">
          <app-simple-bar [container]="'#serviceBar'" [barData]="forecastByService" [configuration]="configServiceBar">
            <div id="serviceBar"></div>
          </app-simple-bar>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric">
          <h4 class="card-title" style="cursor:pointer;">Trend</h4>
        </div>
        <div class="card-body p-0 click-body" data-bs-toggle="modal" *ngIf="volumeByWeek!=undefined">
          <app-linegraph [container]="'#linegraph'" [lineData]="volumeByWeek">
            <div id="linegraph" style="width: 97%;margin-top: 50px;"></div>
          </app-linegraph>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card">
        <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric">
          <h4 class="card-title" style="cursor:pointer;">Weekly Forecast (95% CI)</h4>
        </div>

        <div class="card" *ngIf="volumeDetail!=undefined">
          <div class="card-body-scrollable card-body-scrollable-shadow">

            <table class="casemixgraphic fixTableHead " *ngIf="volumeDetail!=undefined">
              <thead>
                <tr>
                  <th>Surgical Service</th>
                  <th *ngFor="let d of uniqueDates">{{d | date: 'MM/dd/yyyy'}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let s of uniqueServices">
                  <td>{{s}}</td>
                  <td *ngFor="let d of uniqueDates"><span *ngFor="let f of GetForecastTableData(d, s)">{{ f.Forecast }} CI: [{{ f.LowerInt}}, {{f.UpperInt}}]</span></td>
                </tr>
              </tbody> 

            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
