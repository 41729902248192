<header class="navbar  navbar-light sticky-top d-print-none">
  <div class="container-xl">
    <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
      <a href=".">
        <img src="../assets/logo.PNG" width="110" height="32" alt="Tabler" class="navbar-brand-image">
      </a>
    </h1>
    <div class="navbar-nav flex-row">
      <div class="nav-item d-none d-md-flex me-3">

      </div>

      <div class="nav-item dropdown">
        <div class="nav-link d-flex lh-1 text-reset p-0">

          <div class="d-xl-block ps-2">

            <!--<div></div>-->
            <div class="mt-1 small p-0" style="color:#000;font-weight:600;font-size:16px;">
              {{reportHeader}}

            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="navbar-nav flex-row order-md-last">
      <div class="nav-item d-none d-md-flex me-3">

      </div>

      <div class="nav-item dropdown">
        <div class="nav-link d-flex lh-1 text-reset p-0">
          <span class="avatar avatar-sm" style="background-image: url(./static/avatars/000m.jpg)"></span>
          <div class="d-none d-xl-block ps-2">
            <div>
              user <a style="cursor:pointer !important">
                (Logout)
              </a>
            </div>
            <!--<div></div>-->
            <div class="mt-1 small ">
              lastupdate

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</header>
<!--Filter starts-->
<div class="container-xl  navbar-light sticky-top" style="max-width:100% !important;background: #f5f7fb;" *ngIf="filtersOptions">
  <div class="row" *ngIf="isLoader==true" style=" background-color: #fff; opacity: 0.5; height: 90px; z-index: 10000; position: absolute; position: absolute; top: 0px; text-align: center; width: 97%; padding-top: 15px;">
    <div class="col-12">
      <img src="../../assets/loader.gif" style="opacity:1!important;" />
    </div>
  </div>


  <div class="row" style="padding:16px 0px 7px 0px;margin:0px auto;z-index:1000">
    <div class="row align-items-center">
      <!--Date Range SElection-->
      <div class="col">
        <div class="font-weight-medium" *ngIf="filteroptions.daterange">
          Date Filter
        </div>
        <div class="text-muted">

          <select class="form-select" [(ngModel)]="selectedDateRange" (change)="ChangeDateRange();FiltersChanged();GetProviders();">
            <option value="custom">Custom Date Range</option>
            <option value="month">Month / Year</option>
            <option value="quarter">Quarter</option>
            <option value="year">Year</option>
          </select>
        </div>
      </div>
      <!--Custom Date range Start date-->
      <div class="col" *ngIf="filteroptions.customdaterange && filteroptions.daterange">
        <div class="font-weight-medium">
          Start Date
        </div>
        <div class="text-muted">
          <input type="text" class="form-control" [(ngModel)]="fromDate" bsDatepicker (ngModelChange)="DateChanged($event)"
                 [maxDate]="toDate" [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
        </div>
      </div>
      <!--Custom Date range End date-->
      <div class="col" *ngIf="filteroptions.customdaterange && filteroptions.daterange">
        <div class="font-weight-medium">
          End Date
        </div>
        <div class="text-muted">
          <input type="text" class="form-control" [(ngModel)]="toDate" bsDatepicker (ngModelChange)="DateChanged($event)"
                 [minDate]="fromDate" [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
        </div>
      </div>
      <!--Year Selection-->
      <div class="col" *ngIf="filteroptions.year && filteroptions.daterange">
        <div class="font-weight-medium">
          Year
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedYear" (change)="selectedMonth='1';selectedQuarter = 'q1';GetMonths();GetQuarters();GetProviders();FiltersChanged();">
            <option value="{{currentYear-1
                }}">
              {{currentYear-1}}
            </option>
            <option value="{{currentYear}}">{{currentYear}}</option>

          </select>
        </div>
      </div>
      <!--Quarter Selection-->
      <div class="col" *ngIf="filteroptions.quarter && filteroptions.daterange">
        <div class="font-weight-medium">
          Quarter
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedQuarter" (change)="GetProviders();FiltersChanged();">
            <option *ngFor='let q of quarters' [value]="q.value">{{q.name}}</option>
          </select>
        </div>
      </div>
      <!--Month Selection-->
      <div class="col" *ngIf="filteroptions.month && filteroptions.daterange">
        <div class="font-weight-medium">
          Month
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedMonth" (change)="GetProviders();FiltersChanged();">
            <option *ngFor='let m of months' [value]="m.value">{{m.name}}</option>
          </select>
        </div>
      </div>

      <!--Role Selection-->
      <div class="col" *ngIf="filtersOptions.facility">
        <div class="font-weight-medium">
          Facility
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedFacility" (change)="GetProviders();FiltersChanged();">
            <option *ngFor='let r of facilities' [value]="r">{{r}}</option>
          </select>
        </div>
      </div>


      <div class="col" *ngIf="filtersOptions.prophylaxis">
        <div class="font-weight-medium">
          Prophylaxis
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedProphylaxis" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'Compliant'">Compliant</option>
            <option [value]="'Non Compliant'">Non Compliant</option>
          </select>
        </div>
      </div>


      <div class="col" *ngIf="filtersOptions.gender">
        <div class="font-weight-medium">
          Gender
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedGender" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'Male'">Male</option>
            <option [value]="'Female'">Female</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="filtersOptions.asacode">
        <div class="font-weight-medium">
          ASA Code
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedASACode" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'1'">1</option>
            <option [value]="'2'">2</option>
            <option [value]="'3'">3</option>
            <option [value]="'4'">4</option>
            <option [value]="'5'">5</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="filtersOptions.kidneyinjury">
        <div class="font-weight-medium">
          Accute Kidney Injury
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedKidneyInjury" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'Yes'">Yes</option>
            <option [value]="'No'">No</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="filtersOptions.lungventilation">
        <div class="font-weight-medium">
          Lung Productive Ventilation
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedLungVentilation" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'Complaint'">Complaint</option>
            <option [value]="'Non Complaint'">Non Complaint</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="filtersOptions.postopregionalblock">
        <div class="font-weight-medium">
          PostOp Regional block
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedPostopRegional" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'Yes'">Yes</option>
            <option [value]="'No'">No</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="filtersOptions.normothermia">
        <div class="font-weight-medium">
          Postoperative normothermia
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedPostoperativeNormothermia" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'Complaint'">Complaint</option>
            <option [value]="'Non Complaint'">Non Complaint</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="filtersOptions.activewarning">
        <div class="font-weight-medium">
          Active Warning
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedActiveWarning" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'Yes'">Yes</option>
            <option [value]="'No'">No</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="filtersOptions.admittype">
        <div class="font-weight-medium">
          Admit Type
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedAdmitType" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'Surgery Admits'">Surgery Admits</option>
            <option [value]="'Non Surgery Admits'">Non Surgery Admits</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="filtersOptions.dispositiontype">
        <div class="font-weight-medium">
          Disposition Type
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedDispositionType" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'Expired'">Expired</option>
            <option [value]="'Home'">Home</option>
            <option [value]="'ICU'">ICU</option>
            <option [value]="'Inpatient rehab'">Inpatient Rehab</option>
            <option [value]="'Long term care'">Long term care</option>
            <option [value]="'Other'">Other</option>
            <option [value]="'SNF'">SNF</option>
          </select>
        </div>
      </div>


      <!--Multi select Anes-->
      <div class="col" style="min-width:200px !important;padding-left:20px" *ngIf="filtersOptions.anesthesiologist">
        <div class="font-weight-medium">
          <span>Anesthesiologist </span><span style="float:right">
            <!--<input class="form-check-input" type="checkbox" [(ngModel)]="isAnesSelectAll" (change)="SelectAnesAll();FiltersChanged();">&nbsp;
            <span>All</span>-->
          </span>
        </div>
        <div class="text-muted" *ngIf="anesthesiologists">
          <ng-multiselect-dropdown [placeholder]="'Select Anesthesiologists'"
                                   [settings]="providerMultiSettings"
                                   [data]="anesthesiologists"
                                   [(ngModel)]="selectedMultiAnes"
                                   [disabled]="isAnesSelectAll"
                                   (onSelect)="onSelectAnesthesiologist($event)"
                                   (onSelectAll)="onSelectAnesthesiologist($event)"
                                   (onDeSelect)="onSelectAnesthesiologist($event)"
                                   (onDeSelectAll)="onSelectAnesthesiologist($event)">
            
          </ng-multiselect-dropdown>


        </div>
      </div>

      <!--Multi select Resident-->
      <div class="col" style="min-width:200px !important;padding-left:20px" *ngIf="filtersOptions.resident">
        <div class="font-weight-medium">
          <span>Resident </span><span style="float:right">
            <!--<input class="form-check-input" type="checkbox" [(ngModel)]="isresidentSelectAll" (change)="SelectResidentAll();FiltersChanged();">&nbsp;
            <span>All</span>-->
          </span>
        </div>
        <div class="text-muted">
          <ng-multiselect-dropdown [placeholder]="'Select Residents'"
                                   [settings]="providerMultiSettings"
                                   [data]="residents"
                                   [(ngModel)]="selectedMultiResident"
                                   [disabled]="isresidentSelectAll">

          </ng-multiselect-dropdown>


        </div>
      </div>


      <!--Multi select CRNA-->
      <div class="col" style="min-width:200px !important;padding-left:20px" *ngIf="filtersOptions.crna">
        <div class="font-weight-medium">
          <span>CRNA </span><span style="float:right">
            <!--<input class="form-check-input" type="checkbox" [(ngModel)]="iscrnaSelectAll" (change)="SelectCRNAAll();FiltersChanged();">&nbsp;
            <span>All</span>-->
          </span>
        </div>
        <div class="text-muted">
          <ng-multiselect-dropdown [placeholder]="'Select CRNAs'"
                                   [settings]="providerMultiSettings"
                                   [data]="crnas"
                                   [(ngModel)]="selectedMultiCRNA"
                                   [disabled]="iscrnaSelectAll">

          </ng-multiselect-dropdown>


        </div>
      </div>

      <!--Multi select CRNA Resident-->
      <div class="col" style="min-width:200px !important;padding-left:20px" *ngIf="filtersOptions.crnaresident">
        <div class="font-weight-medium">
          <span>CRNA/Resident </span><span style="float:right">
            <!--<input class="form-check-input" type="checkbox" [(ngModel)]="iscrnaresSelectAll" (change)="SelectCRNAResidentAll();FiltersChanged();">&nbsp;
            <span>All</span>-->
          </span>
        </div>
        <div class="text-muted" *ngIf="crnaresidents">
          <ng-multiselect-dropdown [placeholder]="'Select CRNA/Residents'"
                                   [settings]="providerMultiSettings"
                                   [data]="crnaresidents"
                                   [(ngModel)]="selectedMultiCRNAResident"
                                   [disabled]="iscrnaSelectAll"
                                   (onSelect)="onSelectCRNAResident($event)"
                                   (onSelectAll)="onSelectCRNAResident($event)"
                                   (onDeSelect)="onSelectCRNAResident($event)"
                                   (onDeSelectAll)="onSelectCRNAResident($event)">

          </ng-multiselect-dropdown>


        </div>
      </div>

      <div class="col">
        <div class="font-weight-medium">
          &nbsp;
        </div>
        <div class="text-muted">
          <button type="button" class="btn btn-primary" (click)="OnFilter()">
            Apply
          </button>
        </div>
      </div>

    </div>
  </div>


</div>
