import { Component, EventEmitter, Output, Input, HostListener } from '@angular/core';

import { LineConfig, LineModel } from '../models/linemodel';
import { LineChart } from './Line';

@Component({
  selector: 'd3-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.css']
})
export class LineComponent {

  @Input() config: LineConfig = new LineConfig();
  @Output() lineclick: EventEmitter<any> = new EventEmitter();
  @Output() singlelineclick: EventEmitter<LineModel> = new EventEmitter();
  @Output() deselectline: EventEmitter<any> = new EventEmitter();
  //@Output() lineout = new EventEmitter<LineChart>();

  private _line: LineChart | undefined;

  ngAfterViewInit(): void {
    this._line = new LineChart();
    this._line.config = this.config;
    if (this.lineclick.observers.length > 0) {
      this._line.lineclick = (d: any) => {
        this.lineclick.emit(d);
      }
    }
    if (this.deselectline.observers.length > 0) {
      this._line.deselectline = () => {
        this.deselectline.emit();
      }
    }

    if (this.singlelineclick.observers.length > 0) {
      this._line.singlelineclick = (d: LineModel) => {
        this.singlelineclick.emit(d);
      }
    }
    let d = document.getElementById(this._line.config.container.replace("#", ""));
    if (d) {
      this._line.config.width = d.offsetWidth;// - this._line.config.margin_left - this._line.config.margin_right;
     // this._line.config.height = d.offsetHeight;// - this._line.config.margin_top - this._line.config.margin_bottom;
      this._line.Render();
    }


  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this._line) {
      let d = document.getElementById(this._line.config.container.replace("#", ""));
      if (d) {
        this._line.config.width = d.offsetWidth;// - this._line.config.margin_left - this._line.config.margin_right;
       // this._line.config.height = d.offsetHeight;// - this._line.config.margin_top - this._line.config.margin_bottom;
        this._line.Render();
      }
    }
  }

}
