<!--<div class="sticky-top">
  <app-header></app-header>
</div>
<div class="container-xl container-sm container-md">
  <iframe class="O_iframe" src="https://powerbi.dignityhealth.org/reports/powerbi/PeriOpInsights/TST/PeriOp%20Quality/Outcome%20Metrics?rs:embed=true" frameborder="0" allowfullscreen="true" style="width: 100%; height: 99.5vh;"></iframe>
</div>-->
<div style="overflow-x:hidden">
  <app-common-page [page]="'ponv'" [isFilter]="true" [isMenu]="true"  [metric]="'outcome'"></app-common-page>
</div>

