import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit {
  Workflow: any;
  SurgerySchedule: any;
  totalrecords: number = 0;
  viewmode: string = "details";
  filters: Filters;

  columns: Array<any> = [
    { "name": "CaseDate" },
    { "name": "ProcID" },
    { "name": "Room" },
    { "name": "InRoom" },
    { "name": "AnesReady" },
    { "name": "ProcStart" },
    { "name": "ProcEnd" },
    { "name": "LeaveOR" },
    { "name": "Surgeon" }
  ];

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }

  OnFilter(e: Filters) {

    this.filters = e;
    this.LoadData();
  }

  LoadData() {
    this.Workflow = undefined;
    this.SurgerySchedule = undefined;
    this.totalrecords = undefined;

    this.commonService.GetWorkflow(this.filters.facility, this.filters.location, this.filters.provider, this.filters.surgeService, this.filters.caseDate).subscribe(d => {
      this.Workflow = d["Workflow"];
      this.SurgerySchedule = d["SurgerySchedule"]
      if (this.Workflow != undefined && this.Workflow != null && this.Workflow.length > 0)
        this.totalrecords = d["Workflow"][0]['TotalRecords'];
    })
  }

  ChangeView() {
    if (this.viewmode == "details")
        this.viewmode = "overview";  
    else
        this.viewmode = "details";
  }
}
