import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FinancialsGraph } from '../FinancialsGraph';

@Component({
  selector: 'app-financialsgraph',
  templateUrl: './financialsgraph.component.html',
  styleUrls: ['./financialsgraph.component.css']
})
export class FinancialsgraphComponent implements OnInit {

  @Input() container: string = '';
  @Input() financialsdata: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<FinancialsGraph> = new EventEmitter();

  financialsgraph!: FinancialsGraph;

  constructor() { }

  LoadFinancialsGraph() {   
    this.configuration = { sectionwidth: 100 };
    this.financialsgraph = new FinancialsGraph(this.container, this.financialsdata, this.configuration);

    this.financialsgraph.Render();
    this.myOutput.emit(this.financialsgraph);
  }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.LoadFinancialsGraph();
  }
}
