import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Filter_Options, PeriopOutcomeFilter } from './PeriopOutcomeFilter';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forkJoin } from 'rxjs';
import { OutcomeService } from '../outcome.service';

@Component({
  selector: 'app-periop-filters',
  templateUrl: './periop-filters.component.html',
  styleUrls: ['./periop-filters.component.css']
})
export class PeriopFiltersComponent implements OnInit {
  @Output() onfilter: EventEmitter<PeriopOutcomeFilter> = new EventEmitter();
  @Input({ required: true }) reportHeader: string = ''
  @Input({ required: true }) filterHead: string = ''

  isLoader: boolean = false;
  filteroptions: any = {};
  isChanged: boolean = false;

  selectedDateRange: string = "custom";
  currentYear: number;
  selectedYear: string;
  selectedMonth: string;
  selectedQuarter: string;
  fromDate: any;
  toDate: any;
  months: Array<any>;
  quarters: Array<any>;
  dayCategories: Array<any>;
  facility: Array<string>;

  providerMultiSettings: IDropdownSettings = {};

  anesthesiologists: Array<any> = [];
  selectedMultiAnes: Array<any> = [];
  //isAnesSelectAll: boolean = false;

  crnaresidents: Array<any> = [];
  selectedMultiCRNAResident: Array<any> = [];
  //iscrnaresSelectAll: boolean = false;

  residents: Array<any> = [];
  selectedMultiResident: Array<any> = [];
  //isresidentSelectAll: boolean = false;

  crnas: Array<any> = [];
  selectedMultiCRNA: Array<any> = [];
  //iscrnaSelectAll: boolean = false;

  selectedFacility: string = 'All';
  selectedProphylaxis: string = 'All';
  selectedGender: string = 'All';
  selectedASACode: string = 'All';
  selectedKidneyInjury: string = 'All';
  selectedLungVentilation: string = 'All';
  selectedPostopRegional: string = 'All';
  selectedPostoperativeNormothermia: string = 'All';
  selectedActiveWarning: string = 'All';
  selectedAdmitType: string = 'All';
  selectedDispositionType: string = 'All';

  facilities: Array<string> = ["All", "SJHMC", "BNC"];

  filtersOptions: any;

  constructor(private _outcomeService: OutcomeService) {

    this.providerMultiSettings = {
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      singleSelection: false,
      idField: 'ProviderId',
      textField: 'ProviderName',
      itemsShowLimit: 1,
      enableCheckAll: true,
      allowSearchFilter: true
    };
  }
  SetDefaultFilters() {

    /************** Set last Month as default date *********/
    var lastMonth = new Date();
    lastMonth.setDate(1);
    lastMonth.setHours(-1);

    var firstDate = new Date();
    firstDate.setDate(1);
    firstDate.setHours(-1)
    firstDate.setDate(1);

    this.fromDate = new Date(2023, 8, 12);// firstDate;
    this.toDate = new Date(2024, 0, 7);// lastMonth;

    /************** Set default year and quarter *********/
    let mn = (new Date()).getMonth();

    if (mn != 0) {
      this.selectedYear = this.currentYear.toString();
      this.selectedMonth = ((new Date()).getMonth()).toString();
    }
    else {
      this.selectedYear = (this.currentYear - 1).toString();
      this.selectedMonth = "12";
    }
    this.selectedQuarter = "q" + (Math.ceil(((new Date()).getMonth() + 1) / 3)).toString();
    // this.ChangeDateRange();
  }

  ChangeDateRange() {
    //setTimeout(() => {
    if (this.selectedDateRange == "custom") {
      this.filteroptions.month = false;
      this.filteroptions.year = false;
      this.filteroptions.customdaterange = true;
      this.filteroptions.quarter = false;
    }
    else if (this.selectedDateRange == "month") {
      this.filteroptions.month = true;
      this.filteroptions.year = true;
      this.filteroptions.customdaterange = false;
      this.filteroptions.quarter = false;
    }
    else if (this.selectedDateRange == "quarter") {
      this.filteroptions.month = false;
      this.filteroptions.year = true;
      this.filteroptions.customdaterange = false;
      this.filteroptions.quarter = true;
    }
    else {
      this.filteroptions.month = false;
      this.filteroptions.year = true;
      this.filteroptions.customdaterange = false;
      this.filteroptions.quarter = false;
    }
    //});
  }


  SetFromToDate(obj) {
    if (this.filteroptions.daterange == true) {
      if (this.selectedDateRange == "custom") {
        obj.fromDate = this.FormatDate(this.fromDate);
        obj.toDate = this.FormatDate(this.toDate);
      }
      else if (this.selectedDateRange == "month") {
        var lastDate = new Date(parseInt(this.selectedYear), parseInt(this.selectedMonth), 0);
        obj.fromDate = this.selectedYear + "-" + this.selectedMonth + "-01";
        obj.toDate = this.selectedYear + "-" + this.selectedMonth + "-" + (lastDate.getDate());
      }
      else if (this.selectedDateRange == "quarter") {
        if (this.selectedQuarter == "q1") {
          obj.fromDate = this.selectedYear + "-01" + "-01";
          obj.toDate = this.selectedYear + "-03" + "-31";
        }
        else if (this.selectedQuarter == "q2") {
          obj.fromDate = this.selectedYear + "-04" + "-01";
          obj.toDate = this.selectedYear + "-06" + "-30";
        }
        else if (this.selectedQuarter == "q3") {
          obj.fromDate = this.selectedYear + "-07" + "-01";
          obj.toDate = this.selectedYear + "-09" + "-30";
        }
        else {
          obj.fromDate = this.selectedYear + "-10" + "-01";
          obj.toDate = this.selectedYear + "-12" + "-31";
        }
      }
      else {
        obj.fromDate = this.selectedYear + "-01" + "-01";
        obj.toDate = this.selectedYear + "-12" + "-31";
      }
    }
    else {
      obj.fromDate = this.FormatDate(this.fromDate);
      obj.toDate = this.FormatDate(this.toDate);
    }
  }

  private FormatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  /*Apply Button Click*/
  OnFilter(isEmit: boolean = true) {
    this.isChanged = false;
    let obj = this.FormatFiltersForEmit();

    obj.multiAnesthesiologist = this.selectedMultiAnes.map(p => { return p.ProviderId });
    obj.multiAnesthesiologistObject = this.selectedMultiAnes;
    obj.isAnesSelectAll = (this.selectedMultiAnes.length == this.anesthesiologists.length && this.anesthesiologists.length > 0 ? true : false);

    obj.multiCRNA = this.selectedMultiCRNA.map(p => { return p.ProviderId });
    obj.multiCRNAObject = this.selectedMultiCRNA;
    obj.isCRNASelectAll = (this.selectedMultiCRNA.length == this.crnas.length && this.crnas.length > 0 ? true : false);

    obj.multiResident = this.selectedMultiResident.map(p => { return p.ProviderId });
    obj.multiResidentObject = this.selectedMultiResident;
    obj.isResidentSelectAll = (this.selectedMultiResident.length == this.residents.length && this.residents.length > 0 ? true : false);

    obj.multiCRNAResident = this.selectedMultiCRNAResident.map(p => { return p.ProviderId });
    obj.multiCRNAResidentObject = this.selectedMultiCRNAResident;
    obj.isCRNAResSelectAll = (this.selectedMultiCRNAResident.length == this.crnaresidents.length && this.crnaresidents.length > 0 ? true : false);

    sessionStorage.setItem("periopoutcomefilters", JSON.stringify(obj));
    if (isEmit) {
      this.onfilter.emit(obj);
    }
  }

  FormatFiltersForEmit(): PeriopOutcomeFilter {
    let obj = new PeriopOutcomeFilter();

    //obj.multiprovider = this.selectedMultiProvider.map(p => { return p.UserLogin });

    /*preparing selected filters emit to the page*/
    this.SetFromToDate(obj);
    obj.year = this.selectedYear;
    obj.month = this.selectedMonth;
    obj.dateRange = this.selectedDateRange
    obj.quarter = this.selectedQuarter;
    obj.facility = this.selectedFacility;

    obj.prophylaxis = this.selectedProphylaxis;
    obj.gender = this.selectedGender;
    obj.asacode = this.selectedASACode;
    obj.accutekidneyinjury = this.selectedKidneyInjury;
    obj.lungventilation = this.selectedLungVentilation;
    obj.postopregional = this.selectedPostopRegional;
    obj.postoperativenormothermia = this.selectedPostoperativeNormothermia;
    obj.activewarning = this.selectedActiveWarning;
    obj.admittype = this.selectedAdmitType;
    obj.dispositiontype = this.selectedDispositionType;

    return obj;
  }


  LoadFiltersFromSession() {
    // setTimeout(() => {
    var obj = JSON.parse(sessionStorage.getItem("periopoutcomefilters")) as PeriopOutcomeFilter;

    this.selectedQuarter = obj.quarter;
    if (obj.fromDate) {
      let from = obj.fromDate.split("-")
      this.fromDate = [from[1], from[2], from[0]].join('/');
      this.fromDate = new Date(this.fromDate);
    }
    if (obj.toDate) {
      let to = obj.toDate.split("-");
      this.toDate = [to[1], to[2], to[0]].join('/');
      this.toDate = new Date(this.toDate);
    }
    this.selectedYear = obj.year;
    this.selectedMonth = obj.month;
    this.selectedDateRange = obj.dateRange;

    //this.isAnesSelectAll = obj.isAnesSelectAll == undefined ? true : obj.isAnesSelectAll;
    this.selectedMultiAnes = obj.multiAnesthesiologist;


    //this.iscrnaresSelectAll = obj.isCRNAResSelectAll == undefined ? true : obj.isCRNAResSelectAll;
    this.selectedMultiCRNAResident = obj.multiCRNAResidentObject;

    //this.isresidentSelectAll = obj.isResidentSelectAll == undefined ? true : obj.isResidentSelectAll;
    this.selectedMultiResident = obj.multiResidentObject;

    //this.iscrnaSelectAll = obj.isCRNASelectAll == undefined ? true : obj.isCRNASelectAll;
    this.selectedMultiCRNA = obj.multiCRNAObject;

    this.selectedProphylaxis = obj.prophylaxis;
    this.selectedGender = obj.gender;
    this.selectedASACode = obj.asacode;
    this.selectedKidneyInjury = obj.accutekidneyinjury;
    this.selectedLungVentilation = obj.lungventilation;
    this.selectedPostopRegional = obj.postopregional;
    this.selectedPostoperativeNormothermia = obj.postoperativenormothermia;
    this.selectedActiveWarning = obj.activewarning;
    this.selectedAdmitType = obj.admittype;
    this.selectedDispositionType = obj.dispositiontype;


    //this.ChangeDateRange();

    // });


  }


  ngOnInit(): void {

    let f = Filter_Options;
    this.filtersOptions = f[this.filterHead];

    this.currentYear = (new Date()).getFullYear();
    /* Load Months & Quarters */

    if (sessionStorage.getItem("periopoutcomefilters")) {
      this.LoadFiltersFromSession();
    }
    else {
      this.SetDefaultFilters();
    }

    this.ChangeDateRange();
    this.GetMonths();
    this.GetQuarters();
    /* below  variable is for control if the page is not having date filfilters we should put false based on page*/
    this.filteroptions.daterange = true;
    this.GetProviders(() => {
      this.OnFilter();
    });


  }
  GetProviders(callBack: any = null) {
    this.isLoader = true;
    let filters = new PeriopOutcomeFilter();
    filters.fromDate = this.fromDate;
    filters.toDate = this.toDate;
    filters.facility = this.selectedFacility;
    filters.prophylaxis = this.selectedProphylaxis;
    filters.multiCRNAResident = ['All'];
    filters.multiAnesthesiologist = ['All'];

    //forkJoin([
    //  this._outcomeService.GetAnesthesiologist(filters),
    //  this._outcomeService.GetCRNAResident(filters)
    //]).subscribe(([anes, crna]) => {
    //  this.isLoader = false;
    //  this.anesthesiologists = anes["Anesthesiologist"].map(a => {
    //    let obj = {};
    //    obj['ProviderId'] = a.Name;
    //    obj['ProviderName'] = a.Name;
    //    return obj;
    //  })
    //  this.selectedMultiAnes = [];// this.anesthesiologists;

    //  this.crnaresidents = crna["CRNA"].map(a => {
    //    let obj = {};
    //    obj['ProviderId'] = a.Name;
    //    obj['ProviderName'] = a.Name;
    //    return obj;
    //  })
    //  this.selectedMultiCRNAResident = [];// this.crnaresidents;
    //  /** For Emitting the selected filters */
    //  if (callBack) {
    //    callBack();
    //  }

    //})
  }

  onSelectAnesthesiologist() {
    this.GetCRNAResident(true);
  }
  onSelectCRNAResident() {
    this.GetAnesthesiologist(true);
  }
  GetAnesthesiologist(isEvent: boolean=false) {
    let filters = new PeriopOutcomeFilter();
    filters.fromDate = this.fromDate;
    filters.toDate = this.toDate;
    filters.facility = this.selectedFacility;
    filters.prophylaxis = this.selectedProphylaxis;
    filters.multiCRNAResident = this.selectedMultiCRNAResident.map(p => { return p.ProviderId });
    this.isLoader = true;
    //this._outcomeService.GetAnesthesiologist(filters).subscribe(anes => {
    //  this.isLoader = false;
    //  setTimeout(() => {
    //    this.anesthesiologists = anes["Anesthesiologist"].map(a => {
    //      let obj = {};
    //      obj['ProviderId'] = a.Name;
    //      obj['ProviderName'] = a.Name;
    //      return obj;
    //    })
    //    /* this.selectedMultiAnes = [];// this.anesthesiologists;*/
    //    if (!isEvent) {
    //      this.selectedMultiAnes = this.anesthesiologists;
    //    }
    //    //this.isAnesSelectAll = true;
    //  })
    //})
  }
  GetCRNAResident(isEvent: boolean = false) {
    let filters = new PeriopOutcomeFilter();
    filters.fromDate = this.fromDate;
    filters.toDate = this.toDate;
    filters.facility = this.selectedFacility;
    filters.prophylaxis = this.selectedProphylaxis;
    filters.multiAnesthesiologist = this.selectedMultiAnes.map(p => { return p.ProviderId });

    this.isLoader = true;
    //this._outcomeService.GetCRNAResident(filters).subscribe(crna => {
    //  this.isLoader = false;
    //  setTimeout(() => {
    //    this.crnaresidents = crna["CRNA"].map(a => {
    //      let obj = {};
    //      obj['ProviderId'] = a.Name;
    //      obj['ProviderName'] = a.Name;
    //      return obj;
    //    })
    //    //this.selectedMultiCRNAResident = [];// this.crnaresidents;
    //    if (!isEvent) {
    //      this.selectedMultiCRNAResident = this.crnaresidents;
    //    }
       
    //    //this.iscrnaresSelectAll = true;
    //  });
    //})
  }

  GenerateMonth(m) {
    this.months = [];
    var monthsNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    for (var i = 1; i <= m; i++) {
      this.months.push({ "value": i.toString(), "name": monthsNames[i - 1] });
    }
  }
  GenerateQuartes(m) {
    this.quarters = [];
    for (var i = 1; i <= Math.ceil(m / 3); i++) {
      this.quarters.push({ "value": "q" + i.toString(), "name": "Q" + i.toString() });
    }
  }
  GetMonths() {
    //this.selectedMonth = "1";
    if ((new Date()).getFullYear().toString() == this.selectedYear) {
      this.GenerateMonth(((new Date()).getMonth() + 1));
    }
    else {
      this.GenerateMonth(12);
    }
  }
  GetQuarters() {
    //this.selectedQuarter = "q1";
    if ((new Date()).getFullYear().toString() == this.selectedYear) {
      this.GenerateQuartes(((new Date()).getMonth() + 1));
    }
    else {
      this.GenerateQuartes(12);
    }
  }

  FiltersChanged() {
    this.isChanged = true;
  }

  //GetAnesthesiologists() {

  //}
  //GetCRNAs() {

  //}
  //GetResidents() {

  //}
  //GetCRNAResidents() {

  //}
  SelectAnesAll() {
    //if (this.isAnesSelectAll) {
    //  this.providerMultiSettings.limitSelection = 100000;
    //  this.selectedMultiAnes = this.anesthesiologists;

    //}
    //else {
    //  this.selectedMultiAnes = [];
    //  this.providerMultiSettings.limitSelection = 25;
    //}
  }
  SelectCRNAAll() {
    //if (this.iscrnaSelectAll) {
    //  this.providerMultiSettings.limitSelection = 100000;
    //  this.selectedMultiCRNA = this.crnas;

    //}
    //else {
    //  this.selectedMultiCRNA = [];
    //  this.providerMultiSettings.limitSelection = 25;
    //}
  }

  SelectResidentAll() {
    //if (this.isresidentSelectAll) {
    //  this.providerMultiSettings.limitSelection = 100000;
    //  this.selectedMultiResident = this.residents;

    //}
    //else {
    //  this.selectedMultiResident = [];
    //  this.providerMultiSettings.limitSelection = 25;
    //}
  }
  SelectCRNAResidentAll() {
    //if (this.iscrnaresSelectAll) {
    //  this.providerMultiSettings.limitSelection = 100000;
    //  this.selectedMultiCRNAResident = this.crnaresidents;
    //}
    //else {
    //  this.selectedMultiCRNAResident = [];
    //  this.providerMultiSettings.limitSelection = 25;
    //}
  }

  /*Date Change event*/
  DateChanged(arg) {
    this.GetProviders();
    this.FiltersChanged();
  }
}
