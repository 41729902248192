import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BarCompare } from '../BarCompare';

@Component({
  selector: 'app-bar-compare',
  templateUrl: './bar-compare.component.html',
  styleUrls: ['./bar-compare.component.css']
})
export class BarCompareComponent implements OnInit {

  @Input() container: string = ''
  @Input() barData: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<BarCompare> = new EventEmitter();

  barCompare!: BarCompare;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.barCompare = new BarCompare(this.container, this.barData, this.configuration);
    this.barCompare.Render();
    this.myOutput.emit(this.barCompare);
  }

}
