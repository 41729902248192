import * as d3 from "d3";
import { HBarChart } from "../h-bar/HBar";
import { HBarConfig, HbarData, HData } from "../models/hbarmodel";

export class HStackedBarChart {

  private _svg: any;
  private _tooltip: any;
  private _tooltipX: any;
  private _config: HBarConfig = new HBarConfig();
  private _xscaleouter: any;
  private _yscale: any;
  private _colorscale: any;
  private _xaxis: any;
  private _yaxis: any;
  barclick: any;
  singlebarclick: any;
  deselectbar: any;

  private isBarSelected: boolean = false;

  get config(): HBarConfig {
    return this._config;
  }
  set config(value: HBarConfig) {
    this._config = value;
  }
  public Render() {

    /* Generate for Stacked Bar */
    for (var i = 0; i < this._config.chart_data.length; i++) {
      let subData = this._config.chart_data[i].data;
      for (var j = 0; j < subData.length; j++) {
        if (j == 0) {
          subData[j].startvalue = 0;
        }
        else {
          subData[j].startvalue = subData[j - 1].startvalue + subData[j - 1].value;
        }
      }
    }
    this.ClearCharts();
    this.InitiateTooltip();
    this.ConfigureScales();
    this.DrawAxisTexts();
    this.DrawAxis();
    this.DrawChart();
  }
  private ClearCharts() {

    //let _width = (this._config.width + (this._config.margin_left + this._config.margin_right));
    //let _height = (this._config.height + (this._config.margin_top + this._config.margin_bottom));

    d3.select(this._config.container).selectAll("*").remove();
    this._svg = d3.select(this._config.container).append("svg")
      .on("click", () => {
        if (!this.isBarSelected) {
          this._svg.selectAll(".bars").style("opacity", 1);
          this._svg.selectAll(".mousehover-bars").style("opacity", 0);
          if (this.deselectbar) {
            this.deselectbar();
          }
        }
        this.isBarSelected = false;
      })
      .attr("width", this._config.width)
      .attr("height", this._config.height)
      .append("g").attr("class", "main")
      .attr("transform", `translate(${this._config.margin_left},${this._config.margin_top})`);

    this._tooltip = d3.select(this._config.container)
      .append("div")
      .attr("class", "d3-tooltip-new")
      .style("display", "none")
      .style("opacity", 0)
      .style("position", "absolute")
  }
  private InitiateTooltip() {


    //this._tooltipX = this._svg
    //  .append("g")
    //  .attr("class", "d3-tooltip-line")
    //  .style("opacity", 0)

    //this._tooltipX.append("rect")
    //  .attr("x", 0)
    //  .attr("y", -10)
    //  .attr("height", 30)
    //  .style("stroke", "lightgray")
    //  .style("stroke-width", "1px")
    //  .style("fill", "lightgray");
    //this._tooltipX.append("text")
    //  .attr("x", 2)
    //  .attr("y", 10)
    //  .style("font-weight", "bold")
    //  .style("fill", "gray");
  }

  private ConfigureScales() {
    /************** Creating X SCale ***********/
    let outerArray = this._config.chart_data.map((d: HbarData) => { return d.key; });
    let innerArray = this._config.chart_data[0].data.map(f => f.key);

    this._xscaleouter = d3.scaleBand()
      .domain(outerArray)
      .range([0, this._config.height - this._config.margin_top - this._config.margin_bottom]);

    //this._xscaleinner = d3.scaleBand()
    //  .domain(innerArray)
    //  .range([0, this._xscaleouter.bandwidth()]);

    /************** Creating Y SCale ***********/

    let innerValue = this._config.chart_data.map((d: HbarData) => { return d.data.reduce((sum, item) => sum + item.value, 0) });
    let max = d3.extent(innerValue)[1];

    this._yscale = d3.scaleLinear()
      .domain([max ?? 0, 0])
      .range([this._config.width - this._config.margin_left - this._config.margin_right, 0]);

    //alert(this._config.width - this._config.margin_left - this._config.margin_right)
    //alert(this._yscale(25) + this._yscale(35) + this._yscale(40))
    /************** Creating Color SCale ***********/

    this._colorscale = d3.scaleOrdinal()
      .domain(innerArray)
      .range(this._config.chart_data[0].data.map(f => f.color));
    //.range(this._config. linestyle.map((m: any) => m.color));




  }

  private DrawAxisTexts() {
    let axisTexts = this._svg.attr("class", "axistexts").attr("font-weight", "bold");

    axisTexts.append("g")
      .attr("transform", `translate(${(this._config.width - this._config.margin_left - this._config.margin_right) / 2},${this._config.height + this._config.margin_top + this._config.margin_bottom - 10})`)
      .append("text")
      .attr("text-anchor", "middle")
      .text(() => { return this._config.xlabel; });

    axisTexts.append("g")
      .attr("transform", `translate(${-this._config.margin_left + 15},${100})rotate(-90)`)
      .append("text")
      .attr("text-anchor", "middle")
      .text(() => { return this._config.ylabel; });

  }


  private DrawAxis() {
    /*********************** Create Y axis ***************/
    if (this.config.axis_position == 'top') {
      this._yaxis = this._svg.append("g")
        .attr("class", "y-axis")
        .attr("transform", `translate(0,${0})`)
        .call(d3.axisTop(this._yscale)
          .ticks(this.config.tick_size));
    } else {
      this._yaxis = this._svg.append("g")
        .attr("class", "y-axis")
        //.attr("clip-path", "url(#clip)")
        .attr("transform", `translate(0,${this._config.height - this._config.margin_top - this._config.margin_bottom})`)
        .call(d3.axisBottom(this._yscale)
          .ticks(this.config.tick_size));
    }
    /*********************** Create X axis ***************/
    this._xaxis = this._svg.append("g")
      .attr("class", "x-axis")
      //.attr("clip-path", "url(#clipy)")
      .call(d3.axisLeft(this._xscaleouter));

    this._xaxis.selectAll("text")
    //Commented out
    //.on("click", (d2: any, d3: any) => {
    //  let hdata = this._config.chart_data.filter(s => s.key == d3);
    //  if (this.barclick) {
    //    this.barclick(hdata[0]);
    //  }
    //})

    //.on("mouseover", (d: any) => {
    //  this._tooltip.style("display", "block").style("opacity", 1);
    //})
    //.on("mousemove", (d2: any, d3: any) => {
    //  let hdata = this._config.chart_data.filter(s => s.key == d3);
    //  this.Tooltip(d2, hdata[0].data, hdata[0].key);
    //})
    //.on("mouseout", (d: any) => {
    //  this._tooltip.style("display", "block").style("opacity", 0);
    //});


    //console.log(this._xaxis.selectAll("text"))
    //this._xaxis.selectAll("text")
    //  .each((d2: any) => {
    //    console.log(d2)
    //  })

  }

  private DrawChart() {
    let gap = 9
    //let groupLength = this._config.chart_data[0].data.length;
    this._svg.append("defs").append("clipPath")
      .attr("id", this._config.container.replace("#", "") + "-clip-hsbar")
      .append("rect")
      .attr("x", 0)
      .attr("y", -10)
      /*.attr("width", this._config.width - this._config.margin_left - this._config.margin_right)*/
      .attr("width", this._config.width)
      .attr("height", this._config.height - this._config.margin_top - this._config.margin_bottom);


    let g = this._svg.selectAll(".group-container")
      .data(this._config.chart_data)
      .enter()
      .append("g")
      // .attr("clip-path", "url(#clip-hsbar)")
      .attr("clip-path", "url(#" + this._config.container.replace("#", "") + "-clip-hsbar)")
      .attr("transform", (d: HbarData) => {
        return `translate(0,${this._xscaleouter(d.key)})`
      })
      .attr("class", "group-container");

    g.append("rect")
      .attr("height", this._xscaleouter.bandwidth())
      .attr("width", this._config.width - this._config.margin_left - this._config.margin_right)
      .style("fill", (d: any, i: number) => { return i % 2 == 0 ? "lightgray" : "#fff" })
      .style("opacity", 0.1)

    g.append("line")
      .attr("x1", 0)
      .attr("x2", this._config.width - this._config.margin_left - this._config.margin_right)
      .style("stroke", "gray")
      .style("opacity", 0.1)

    /*********************** Hiding axis lines ***************/
    this._svg.selectAll(".tick").selectAll("line").style("opacity", 0.2);
    this._svg.selectAll(".domain").style("opacity", 0.1);



    /*********************** Draw bars ***************///+ (groupLength*3)
    let bargroup = g.append("g")
      .attr("transform", `translate(0,${gap / 3})`)

    bargroup.selectAll(".bars")
      .data((d: HbarData) => {
        return d.data.map((hd: HData) => {
          hd.header = d.key;
          return hd;
        });
      })
      .enter()
      .append("rect")
      .attr("class", "bars")
      .attr("id", (d: HData) => {
        return this._config.container.replace("#", "") + (d.header + d.key).split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
      })
      .attr("height", this._xscaleouter.bandwidth() - gap)
      .attr("x", (d: HData) => {
        return this._yscale(d.startvalue)
      })
      .attr("y", (d: HData) => {
        return this._xscaleouter(d.key)
      })
      .attr("width", (d: HData) => {
        return this._yscale(d.value) - 1
      })
      .style("fill", (d: HData) => {
        return d.color;
      })
      .on("click", (d: any, val: HData) => {
        if (this.singlebarclick) {
          this.isBarSelected = true;
          this.ClickDisable(val);

          this.singlebarclick(val);
        }
      })
      .on("mousemove", (d: any, d1: HData) => {
        this.Tooltip(d, [d1], d1.header, d1.key);
      })
      .on("mouseout", (d: any) => {
        this._tooltip.style("display", "block").style("opacity", 0);
      });

    /************* Bar Text **********************/

    bargroup.selectAll(".h-stacked-bar-text")
      .data((d: HbarData) => {
        return d.data;
      })
      .enter()
      .append("text")
      .attr("class", "h-stacked-bar-text")
      .attr("transform", (d: HData) => {
        return `translate(${this._yscale(d.startvalue) + (this._yscale(d.value) / 2 - 7)},${((this._xscaleouter.bandwidth() - gap) / 2) + 3})`
      })
      .attr("text-anchor", "start")
      .text((d: HData) => {
        return d.value.toString() + "%";
      });//.attr("fill", "#fff");//.attr("stroke-width", "#fff")


    /************* mouserover rect **********************/
    g.append("rect")
      .attr("height", this._xscaleouter.bandwidth())
      .attr("class", "mousehover-bars")
      .attr("width", this._config.width - this._config.margin_left - this._config.margin_right)
      .style("opacity", 0)
      .style("fill", "#fff")
      .attr("id", (d: HbarData) => {
        return this._config.container.replace("#", "") + (d.key).split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
      })
      .on("mouseover", (d: any) => {
        this._tooltip.style("display", "block").style("opacity", 1);
      })
      .on("click", (d: any, val: HbarData) => {

        if (this.barclick) {
          this.isBarSelected = true;
          this.barclick(val);
          this.ClickBulkDisable(val);
        }

      })
      .on("mousemove", (d: any, d1: HbarData) => {
        this.Tooltip(d, d1.data, d1.key);
      })
      .on("mouseout", (d: any) => {
        this._tooltip.style("display", "block").style("opacity", 0);
      });

  }
  private Tooltip(d: any, d1: HData[], header: string, singleKey: string = "") {
    let w = this._config.width - this._config.margin_left - this._config.margin_right;
    this._tooltip.style("top", (d.layerY + 15) + 'px').style("left", (w < d.layerX + 250 ? d.layerX - 100 : d.layerX) + 'px')
      .style("display", "block").style("opacity", 1).style("height", "auto")
      .html(() => {
        let html = "";
        /*For group Bar charts*/
        if (d1.length > 1) {
          html += `<div class="header">${header}</div>`;
          html += "<div class='body'>";
          for (var i = 0; i < d1.length; i++) {
            let data = d1[i];
            html += this.SingleBar(data, data.key);
            if (this.config.tooltip_keys && this.config.tooltip_keys.length > 0 && data.additional_data) {
              html += "<div class='separater'></div>"
            }
          }
        }
        else {
          html += `<div class="header">${header}</div>`;
          /*For single Bar charts*/
          let data = d1[0];
          html += "<div class='body'>" + this.SingleBar(data, singleKey);
        }
        if (this.config.tooltip_keys && this.config.tooltip_keys.length > 0 && d1[0].additional_data) {
          for (var j = 0; j < this.config.tooltip_keys.length; j++) {
            let g = this.config.tooltip_keys[j];
            html += `<div class="additional-text" style="padding:5px;">${g.display_text} : ${d1[0].additional_data[g.display_key]}</div>`
          }
        }
        return html + "</div>";
      });
  }
  /* Tooltip single bar */
  private SingleBar(data: HData, key: string) {
    let html = "";
    //for (var i = 0; i < d1.data.length; i++) {
    //let data = d1.data[i];
    if (data && data.value) {
      html += `<div style="display:flex;text-align:center;">
                            <div style="background-color:${data.color};" class="marker"></div>
                            <div class="marker-text">${key}:</div>
                            <div class="marker-value">${data.value}</div>
                         </div>`;

      //if (this.config.tooltip_keys && this.config.tooltip_keys.length > 0 && data.additional_data) {
      //  for (var j = 0; j < this.config.tooltip_keys.length; j++) {
      //    let g = this.config.tooltip_keys[j];
      //    html += `<div class="additional-text" style="padding:5px;">${g.display_text} : ${data.additional_data[g.display_key]}</div>`
      //  }
      //}

      // }
    }
    return html;
  }
  ClickBulkDisable(head: HbarData) {
    let h = this._config.container.replace("#", "") + (head.key).split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");

    this._svg.selectAll(".mousehover-bars").each((child: any) => {
      let h1 = this._config.container.replace("#", "") + (child.key).split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
      if (h == h1) {
        d3.select("#" + h1).style("opacity", 0);
      }
      else {
        d3.select("#" + h1).style("opacity", 0.7);
      }
    })
  }

  ClickDisable(head: HData) {
    let h = this._config.container.replace("#", "") + (head.header + head.key).split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
    this._svg.selectAll(".bars").each((child: any) => {
      let h1 = this._config.container.replace("#", "") + (child.header + child.key).split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
      if (h == h1) {
        d3.select("#" + h1).style("opacity", 1);
      }
      else {
        d3.select("#" + h1).style("opacity", 0.3);
      }
    })
  }
}
