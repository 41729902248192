import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-failed-cases',
  templateUrl: './failed-cases.component.html',
  styleUrls: ['./failed-cases.component.css']
})
export class FailedCasesComponent implements OnInit {
  @Input()
  metric: string;
  @Input()
  metricfields: any;
  @Input()
  ORBISClosedCaseFields: any;
  @Input()
  ORBISClosedCase: any;
  @Input()
  ORBISFailDetails: any;
  @Input()
  ORBISClosedCaseMetric: any;
  @Input()
  metricCode: string
  @Input()
  failedcases: any;

  filterText: any = {};
  adFilter: any;
  sortObject: any = {};
  filterChange: any = new Subject<string>();
  adhocKeys: Array<string>;
  metricfieldsLeft: any = [];
  metricfieldsRight: any = [];
  timeComponents: Array<string> = ['SchedSurgDtTm', 'InRoom', 'ProcStart', 'ProcEnd', 'LeaveOR', 'AnesStart', 'AnesEnd']
  caseDate: string = "All";
  room: string = "All";
  widths: any = { Magnitude: '70px', 'Delay Reason': '200px' };
  failedcasesFiltered: any;
  caseDateCollection: Array<string>;
  roomCollection: Array<string>;
  procedureDesc: string = "";

  constructor(private commonService: CommonService, public datepipe: DatePipe) {
    this.filterChange.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.FilterData();
      });
  }
  KeyUp() {
    this.FilterData();
  }
  FilterData() {
    this.failedcasesFiltered = undefined;
    let condition = [];

    this.failedcasesFiltered = this.failedcases.filter(p => {
      debugger;
      console.log((p.Adhoc));
      if (p.Adhoc && this.adhocKeys.length > 0) {
        for (var i = 0; i < this.adhocKeys.length; i++) {

          for (var j = 0; j < p.Adhoc.length; j++) {

            //Adhoc
            if (!this.filterText[this.adhocKeys[i]]) {
              condition[i] = true;
            }
            else if (this.adFilter[this.adhocKeys[i]] != 'number') {
              condition[i] = (this.filterText[this.adhocKeys[i]] &&
                (p.Adhoc[j][this.adhocKeys[i]].toString().toLowerCase().split(' ').join('').includes(this.filterText[this.adhocKeys[i]].toLowerCase().split(' ').join(''))
                  || this.filterText[this.adhocKeys[i]].split(' ').join('') == "" || this.filterText[this.adhocKeys[i]].toLowerCase().split(' ').join('') == "all")
              );
            }
            //number
            else {
              let dynamiccondition = this.filterText[this.adhocKeys[i] + '_Condition'];
              let txt = parseInt(this.filterText[this.adhocKeys[i]]);
              let val = parseInt(p.Adhoc[j][this.adhocKeys[i]]);
              dynamiccondition = (!dynamiccondition ? '=' : dynamiccondition);
              if (!txt || (txt && txt?.toString().toLowerCase().split(' ').join('') == "")) {
                condition[i] = true;
              }
              else if (dynamiccondition == '=') {
                condition[i] = val == txt;
              } else if (dynamiccondition == '>') {
                condition[i] = val > txt;
              }
              else {
                condition[i] = val < txt;
              }
            }

          }
        }

      }
      else {

        return (p.Room.toLowerCase().split(' ').join('').includes(this.room.toLowerCase().split(' ').join('')) || this.room.split(' ').join('') == ""
          || this.room == 'All')
          && (this.datepipe.transform(p.CaseDate, 'shortDate').split(' ').join('').includes(this.caseDate.toLowerCase().split(' ').join(''))
            || this.caseDate.split(' ').join('') == "" || this.caseDate == 'All')
          && (p.ProcedureDescription.toLowerCase().split(' ').join('').includes(this.procedureDesc.toLowerCase().split(' ').join(''))
            || this.procedureDesc.split(' ').join('') == "");

      }
      condition[i] = (p.Room.toLowerCase().split(' ').join('').includes(this.room.toLowerCase().split(' ').join('')) || this.room.split(' ').join('') == "" || this.room == 'All')
        && (this.datepipe.transform(p.CaseDate, 'shortDate').split(' ').join('').includes(this.caseDate.toLowerCase().split(' ').join(''))
          || this.caseDate.split(' ').join('') == "" || this.caseDate == 'All')
        && (p.ProcedureDescription.toLowerCase().split(' ').join('').includes(this.procedureDesc.toLowerCase().split(' ').join(''))
          || this.procedureDesc.split(' ').join('') == "");

      return condition.filter(f => !f).length == 0;
    });
    //this.failedcasesFiltered = failedcasesFiltered.map((d, i) => {
    //  d.isActive = i == 0;
    //  return d;
    //})
    //this.failedcasesFiltered = this.failedcases.filter(p => {
    //  return (p.Room.toLowerCase().split(' ').join('').includes(this.room.toLowerCase().split(' ').join('')) || this.room.split(' ').join('') == "" || this.room == 'All')
    //    && (this.datepipe.transform(p.CaseDate, 'shortDate').split(' ').join('').includes(this.caseDate.toLowerCase().split(' ').join(''))
    //      || this.caseDate.split(' ').join('') == "" || this.caseDate == 'All')
    //    && (p.ProcedureDescription.toLowerCase().split(' ').join('').includes(this.procedureDesc.toLowerCase().split(' ').join('')) || this.procedureDesc.split(' ').join('') == "")
    //});
  }
  ngOnInit(): void {
    let onlyUnique = (value, index, array) => {
      return array.indexOf(value) === index;
    }
    let af = this.failedcases.filter(t => t.FilterType);
    if (af && af.length > 0) {
      this.adFilter = JSON.parse(af[0].FilterType);
    }

    this.roomCollection = this.failedcases.map(f => f.Room).filter(onlyUnique);
    this.caseDateCollection = this.failedcases.map(f => this.datepipe.transform(f.CaseDate, 'shortDate')).filter(onlyUnique);

    this.roomCollection.unshift("All");
    this.caseDateCollection.unshift("All");

    let is_numeric = (str) => {
      return /^\d+$/.test(str);
    }



    if (this.failedcases && this.failedcases.length > 0 && this.failedcases[0].Adhoc && this.failedcases[0].Adhoc.length > 0) {


      this.adhocKeys = Object.keys(this.failedcases[0].Adhoc[0]);
    }
    else {
      this.adhocKeys = [];
    }
    for (var i = 0; i < this.adhocKeys.length; i++) {
      if (this.adFilter) {
        if (this.adFilter[this.adhocKeys[i]] == 'select') {
          this.adFilter[this.adhocKeys[i] + '_F'] =
            this.failedcases.map(m => {
              if (m.Adhoc) {
                return m.Adhoc.map(ad => {
                  return ad[this.adhocKeys[i]];
                });
              }
            }).flat(1).filter(onlyUnique);
          this.adFilter[this.adhocKeys[i] + '_F'].unshift("All");
        }
      }
    }

    this.failedcases = this.failedcases.map(f => {
      for (var i = 0; i < this.adhocKeys.length; i++) {
        if (f.Adhoc) {
          let isNum = f.Adhoc.map(d => d[this.adhocKeys[i]]).filter(f => !is_numeric(f));
          if (isNum.length == 0) {
            f[this.adhocKeys[i] + '_sort'] = f.Adhoc.reduce((sum, tax) => {
              return sum + tax[this.adhocKeys[i]];
            }, 0);
          } else {
            f[this.adhocKeys[i] + '_sort'] = f.Adhoc.map(d => d[this.adhocKeys[i]]).toString();
          }
        }
        else {
          f[this.adhocKeys[i] + '_sort'] = 0;

        }


      }
      return f;
    });
    this.failedcasesFiltered = this.failedcases.map((d, i) => {
      d.isActive = d.ProcID == this.ORBISClosedCase[0]?.ProcID;
      return d;
    });
    //this.failedcasesFiltered = failedcasesFiltered.map((d, i) => {
    //  d.isActive = i == 0;
    //  return d;
    //})

    for (var i = 0; i < this.metricfields.length; i++) {
      if (i % 2 == 0) {
        this.metricfieldsLeft.push(this.metricfields[i]);
      }
      else {
        this.metricfieldsRight.push(this.metricfields[i]);
      }
    }
  }

  getWidth(k: string) {
    return this.widths[k];
  }
  getORBISClosedCaseFields(field: string) {
    return this.ORBISClosedCaseFields.filter(c => c.Field == field)[0]?.FieldName;
  }
  getORBISClosedCaseFieldValue(fieldname: string) {
    return this.ORBISClosedCase.length > 0 ? this.ORBISClosedCase[0][fieldname] : "";
  }
  getMaxCountRowsFailcase() {
    return Math.max(...this.ORBISFailDetails.map(o => o.RowIndex), 0);
  }

  getMaxCountcolumnvalues(column) {
    var x = this.ORBISFailDetails.filter(f => f.ColumnIndex == column);
    return Math.max(...x.map(c => c.ColumnName), 0)
  }
  GetcaseDetail(ProcID: string) {
    this.failedcasesFiltered = this.failedcasesFiltered.map((d, i) => {
      d.isActive = d.ProcID == ProcID;
      return d;
    })

    this.commonService.GetcaseDetail(this.metricCode, ProcID).subscribe(d => {
      this.ORBISClosedCase = d["closedcases"];
      this.ORBISFailDetails = d["OrbisFaildetails"];
      this.ORBISClosedCaseMetric = d["closedcasemetricdetails"];
    })
  }


  Sort(col: string) {
    /********************* To find previous is asc or desc *******************************/
    if (this.sortObject[col]) {
      this.sortObject[col] = this.sortObject[col] == 'asc' ? 'desc' : 'asc';
    }
    else {
      this.sortObject[col] = 'asc';
    }
    this.failedcasesFiltered = this.commonService.Sort(this.failedcasesFiltered, col, this.sortObject[col]);
    //this.failedcasesFiltered = failedcasesFiltered.map((d, i) => {
    //  d.isActive = i == 0;
    //  return d;
    //})
  }

}
