import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';
import { Page } from '../Page';
import { TabComponent } from '../tab/tab.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ruv',
  templateUrl: './ruv.component.html',
  styleUrls: ['./ruv.component.css']
})
export class RuvComponent implements OnInit {
  RVU: any;
  RVUSummary: any;
  RVUMaster: any;
  RVUSummaryMaster: any;
  isFilterChanged: boolean = false;
  isFilterChangedSummary: boolean = false;

  totalrecords: number = 0;
  totalunits: number = 0;
  avgunits: number = 0;
  page: Page;
  sortColumn: string = 'CaseDate';
  sortOrder: string = 'ASC';
  filters: Filters;
  sortObjects: any = {};

  filterChange: any = new Subject<string>();
  filtersAll: any = {};
  filtersAllSummary: any = {};
  currentTab: TabComponent;

  totalrecordsSummary: number = 0;
  pageSummary: Page;
  sortColumnSummary: string = 'AnesthesiologistPrimary';
  sortOrderSummary: string = 'ASC';
  filtersSummary: Filters;
  sortObjectsSummary: any = {};

  columns: Array<any> = [
    { "name": "Case Date", "fil": "CaseDate" },
    { "name": "Anesthesiologist", "fil": "AnesthesiologistPrimary", "type": "text", "isfilter": true },
    { "name": "Procedure Description", "fil": "ProcedureDescription", "type": "text", "isfilter": true },
    { "name": "CPT", "fil": "AnesCPT" },
    { "name": "CPT RVU", "fil": "ProcedureRVU" },
    { "name": "Anes Start", "fil": "AnesStart" },
    { "name": "Anes End", "fil": "AnesEnd" },
    { "name": "Time RVU", "fil": "TimeRVU", "type": "number", "isfilter": true },
    { "name": "ASA", "fil": "ASA", "type": "ddl", "isfilter": true },
    { "name": "ASA RVU", "fil": "ASA_RVU", "type": "number", "isfilter": true },
    { "name": "Emergency RVU", "fil": "EmergencyRVU", "type": "number", "isfilter": true },
    { "name": "Age", "fil": "Age", "type": "number", "isfilter": true },
    { "name": "Age RVU", "fil": "AgeRVU", "type": "number", "isfilter": true },
    { "name": "Total RVU", "fil": "TotalRVU", "type": "number", "isfilter": true }
  ];


  columnsSummary: Array<any> = [
    { "name": "Anesthesiologist", "fil": "AnesthesiologistPrimary", "type": "text", "isfilter": true },
    { "name": "Case Count", "fil": "CaseCount", "type": "number", "isfilter": true },
    { "name": "CPT RVU", "fil": "ProcedureRVU" },
    { "name": "Time RVU", "fil": "TimeRVU", "type": "number", "isfilter": true },
    { "name": "Mean ASA", "fil": "ASA", "type": "number", "isfilter": true },
    { "name": "ASA RVU", "fil": "ASA_RVU", "type": "number", "isfilter": true },
    { "name": "Emergency RVU", "fil": "EmergencyRVU", "type": "number", "isfilter": true },
    { "name": "Age RVU", "fil": "AgeRVU", "type": "number", "isfilter": true },
    { "name": "Total RVU", "fil": "TotalRVU", "type": "number", "isfilter": true }
  ];

  constructor(private commonService: CommonService, private toastr: ToastrService) {
    this.page = new Page();
    this.page.currentPage = 1;
    this.page.recordsPerPage = 100;

    this.pageSummary = new Page();
    this.pageSummary.currentPage = 1;
    this.pageSummary.recordsPerPage = 500;

    this.filterChange.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        if (this.currentTab.id == "CaseLeve") {
          this.isFilterChanged = true;
        } else {
          this.isFilterChangedSummary = true;
        }
      });
  }
  CheckUpdatedFilters(filter: any) {
    return Object.keys(filter).length == 0;
  }
  TableFilter() {
    if (this.currentTab.id == "CaseLeve") {
      let query = this.FormSQLQuery(this.columns, this.filtersAll);

      this.LoadData(query);
      //this.RVU = this.FilterData(this.columns, this.RVUMaster);
    } else {
      let query = this.FormSQLQuery(this.columnsSummary, this.filtersAllSummary);
      this.LoadDataSummary(query);
      //this.RVUSummary = this.FilterData(this.columnsSummary, this.RVUSummaryMaster);
    }
  }
  FormSQLQuery(columns: Array<any>, filter: any) {
    let keys = Object.keys(filter);
    let query = "";
    if (keys.length > 0) {
      for (var i = 0; i < keys.length; i++) {
        let k = keys[i];
        let col = columns.filter(c => c.fil == k)[0];
        if (col && filter[k] && filter[k].toString().split(' ').join('') != '') {
          if (col.type == "text") {
            query += (query == "" ? "" : " AND ") + k + " like '%" + filter[k] + "%'"
          }
          else {
            query += (query == "" ? "" : " AND ") + k + (!filter[k + "_num"] ? "=" : filter[k + "_num"]) + filter[k].toString();
          }
        }
      }
    }
    return query;
  }

  ngOnInit(): void {
  }
  PageEvent(e: Page) {
    this.page = e;
    this.filtersAll = {};
    this.LoadData("");
  }
  PageEventSummary(e: Page) {
    this.pageSummary = e;
    this.filtersAllSummary = {};
    this.LoadDataSummary("");
  }
  LoadData(query: string) {
    this.RVU = undefined;
    this.RVUMaster = undefined;
    this.totalrecords = 0;
    this.totalunits = 0;
    this.avgunits = 0;
    this.isFilterChanged = false;
    this.commonService.GetRVU(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, this.page.currentPage, this.page.recordsPerPage, this.sortColumn, this.sortOrder, query).subscribe(d => {
      this.RVU = d["RVU"];
      this.RVUMaster = d["RVU"];
      if (this.RVU != undefined && this.RVU != null && this.RVU.length > 0)
        this.totalrecords = d["RVU"][0]['TotalRecords'];
      this.totalunits = d["RVU"][0]['TotalUnits'];
      this.avgunits = d["RVU"][0]['AvgUnits'];

      if (this.currentTab.id == "CaseLeve") {
        this.SetDropDownFilter(this.RVUMaster, this.columns);
      } else {
        this.SetDropDownFilter(this.RVUSummaryMaster, this.columnsSummary);
      }
      //console.log(this.columns)
    })
  }
  LoadDataSummary(query: string) {
    this.RVUSummary = undefined;
    this.RVUSummaryMaster = undefined;
    this.totalrecordsSummary = 0;
    //this.totalunits = 0;
    //this.avgunits = 0;
    this.isFilterChangedSummary = false;
    this.commonService.GetRVUSummary(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, this.pageSummary.currentPage, this.pageSummary.recordsPerPage, this.sortColumnSummary, this.sortOrderSummary, query).subscribe(d => {
      this.RVUSummary = d["RVUSummary"];
      this.RVUSummaryMaster = d["RVUSummary"];
      if (this.RVUSummary != undefined && this.RVUSummary != null && this.RVUSummary.length > 0)
        this.totalrecordsSummary = d["RVUSummary"][0]['TotalRecords'];
      if (this.currentTab.id == "CaseLeve") {
        this.SetDropDownFilter(this.RVUMaster, this.columns);
      } else {
        this.SetDropDownFilter(this.RVUSummaryMaster, this.columnsSummary);
      }
      //console.log(this.columns)
      //this.totalunits = d["RVUSummary"][0]['TotalUnits'];
      //this.avgunits = d["RVUSummary"][0]['AvgUnits'];
    })
  }
  OnFilter(e: Filters) {
    this.filters = e;
    this.page.currentPage = 1;
    this.pageSummary.currentPage = 1;
    this.filtersAll = {};
    this.filtersAllSummary = {};
    this.LoadData("");
    this.LoadDataSummary("");
  }
  //OnFilterSummary(e: Filters) {

  //  this.filtersSummary = e;
  //  this.pageSummary.currentPage = 1;
  //  this.LoadDataSummary();
  //}

  Sort(f) {
    this.sortColumn = f;
    this.sortOrder = this.sortObjects[f] == "ASC" ? "DESC" : "ASC";

    this.sortObjects[f] = this.sortOrder;
    Object.keys(this.sortObjects).forEach(k => {
      if (k != f) {
        this.sortObjects[k] = undefined;
      }
    });
    this.page.currentPage = 1;
    this.LoadData("");
  }

  SortSummary(f) {
    this.sortColumnSummary = f;
    this.sortOrderSummary = this.sortObjectsSummary[f] == "ASC" ? "DESC" : "ASC";

    this.sortObjectsSummary[f] = this.sortOrderSummary;
    Object.keys(this.sortObjectsSummary).forEach(k => {
      if (k != f) {
        this.sortObjectsSummary[k] = undefined;
      }
    });
    this.pageSummary.currentPage = 1;
    this.LoadDataSummary("");
  }
  OnSelectTab(t: TabComponent) {
    this.currentTab = t;
    this.RVU = this.RVUMaster
    this.RVUSummary = this.RVUSummaryMaster;
    if (this.currentTab.id == "CaseLeve") {
      this.SetDropDownFilter(this.RVUMaster, this.columns);
    } else {
      this.SetDropDownFilter(this.RVUSummaryMaster, this.columnsSummary);
    }
    //console.log(this.columns);
  }
  DownloadCallBack(password: string) {
    if (this.currentTab.id == "CaseLeve") {
      this.commonService.DownloadRVU(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, password);
    } else {
      this.commonService.DownloadRVUSummary(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, password);
    }
  }

  SendEmailCallBack(password: string) {
    if (this.currentTab.id == "CaseLeve") {
      this.commonService.EmailRVU(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, password)
        .subscribe(res => {
          if (res == "NO") {
            this.toastr.warning("Email is not configured");
          }
          else {
            this.toastr.success(`Email sent to ${res}. It can take up to 5 minutes for email delivery to complete.`);
          }
        });
    } else {
      this.commonService.EmailRVUSummary(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, password)
        .subscribe(res => {
          if (res == "NO") {
            this.toastr.warning("Email is not configured");
          }
          else {
            this.toastr.success(`Email sent to ${res}. It can take up to 5 minutes for email delivery to complete.`);
          }
        });
    }
  }

  SetDropDownFilter(data: Array<any>, columns: Array<any>) {
    if (data) {
      let onlyUnique = (value, index, array) => {
        return array.indexOf(value) === index && value;
      }
      for (var i = 0; i < columns.length; i++) {
        if (columns[i].type == "ddl") {
          let d = data.map(k => k[columns[i].fil]);
          columns[i].filterdata = d.filter(onlyUnique);
          columns[i].filterdata.unshift("All");
        }
      }
    }
  }
}
