<div *ngIf="config && config.is_pagination || config.is_downloadable"
     style=" background: #e3e3e3; border: 1px solid #ccc; padding: 2px; color: #000; height: 35px; text-align: right ">
  <div class="row">
    <div class="col-3" style="padding-top:9px;" *ngIf="config && config.is_switch">
      <span>
        All cases
      </span> &nbsp;
      <label class="switch" style="top:-6px">
        <input [(ngModel)]="switch" type="checkbox" (change)="Apply()">
        <span class="slider round"></span>
      </label>
      &nbsp;<span>
  Focus cases
</span>


    </div>
    <div class="col">
      <div style="color:red;text-align:left;padding-top:3px;font-weight:bold" *ngIf="isChanged">
        Table filters were updated but have not yet been applied to the data below. Please click "Apply" to update.
      </div>
    </div>
      <div class="col-3" style="padding-right:20px;padding-top:2px;">
        <button type="button" class="btn btn-primary" (click)="Apply()" style="height: 25px; background-color: #a6a6a6">
          Apply Table Filters
        </button>&nbsp;&nbsp;
        <!--<a (click)="Apply()" style="text-decoration:underline;cursor:pointer;" title="Apply" *ngIf="config.is_pagination">
        <img src="../../assets/apply.png" style="width:20px;height:20px;" />&nbsp;Apply
      </a>&nbsp;&nbsp;-->
        <app-download-dialog (download)="DownloadCallBack($event)" (sendemail)="SendEmailCallBack($event)" [reporttype]="'PeriopOutcome'"></app-download-dialog>

      </div>
    </div>

    <!--<a (click)="Download()" style="text-decoration:underline;cursor:pointer;" title="Download" *ngIf="config.is_downloadable">
     <img src="../../assets/download.png" style="width:20px;height:20px;" /> #a6a6a6
   </a>-->
    &nbsp;&nbsp;&nbsp;
  </div>
<div class="card" style="max-height: calc(100vh - 260px);">
  <div *ngIf="config && headers" class="card-body-scrollable card-body-scrollable-shadow" style=" scrollbar-width: thin;">
    
    <table class="casemixgraphic fixTableHead-1" [ngStyle]="{'width': config.width }">
      <thead>
        <tr>
          <ng-container *ngFor="let c of objectKeys(headers)">
            <th class="sortable-header"
                [ngClass]="{'sortable-column-pointer': headers[c].length==1 && headers[c][0].is_soratable, 'sortable-column-text' : headers[c].length==1 && !headers[c][0].is_soratable }"
                style="background-color:#a6a6a6;"
                [ngStyle]="{ 'width': headers[c][0].width,'min-width':headers[c][0].min_width,'max-width':headers[c][0].max_width,'padding':'0px'}" [attr.colspan]="headers[c].length">
              <div (click)="Sort(headers[c])" style="text-align: center; padding-top: 5px" [ngStyle]="{'border-bottom':headers[c].length>1?'1px solid #ccc':'0px solid #ccc'}">
                <span >{{c}}</span>
                <span  *ngIf="headers[c].length==1 && headers[c][0].is_soratable && !sortObject[headers[c][0].data_key]"
                      [ngClass]="{'sortable-icon': headers[c].length==1 && headers[c][0].is_soratable && !sortObject[headers[c][0].data_key] }">                


                  <img src="../../../assets/sort-asc.png" style="width: 15px; height: 15px; cursor: pointer; "/>
                </span>

                <span  *ngIf="headers[c].length==1 && headers[c][0].is_soratable">



                  <img src="../../../assets/sort-desc.png" style="width: 15px; height: 15px; cursor: pointer;"
                       *ngIf="(sortObject[headers[c][0].data_key] && sortObject[headers[c][0].data_key]=='desc')" />


                  <img src="../../../assets/sort-asc.png" style="width: 15px; height: 15px; cursor: pointer; "
                       *ngIf="(sortObject[headers[c][0].data_key] && sortObject[headers[c][0].data_key]=='asc')" />
                </span>
              </div>
              <!--For Nested Cols (cols span)-->
              <ng-container *ngIf="headers[c].length>1">
                <div style="display:flex;">
                  <ng-container *ngFor="let w of headers[c]">
                    <div [ngStyle]="{'width':w.width_header_split,'text-align':'center','border-right':'1px solid #ccc'}">
                      {{w.header}}
                      <span *ngIf="w.is_soratable">
                        <img src="../../../assets/sort-desc.png" style="width: 15px; height: 15px; cursor: pointer;"
                             (click)="Sort(w)" *ngIf="(sortObject[w.data_key] && sortObject[w.data_key]=='desc')" />
                        <img src="../../../assets/sort-asc.png" style="width: 15px; height: 15px; cursor: pointer; "
                             (click)="Sort(w)" *ngIf="(sortObject[w.data_key] && sortObject[w.data_key]=='asc')" />
                      </span>
                      <div *ngIf="isFilterAvailable">
                        <div *ngIf="w.filter_type == 'freetext'">
                          <input type="text" style="width: 100%; height: 20px; background-color: #ccc; border: 0px solid #2b4163 " [(ngModel)]="filterText[w.data_key]" [placeholder]="''+w.header+'..'" (keyup)="this.filterChange.next($event)" />
                        </div>
                        <div *ngIf="w.filter_type == 'number'" style="text-align: center;">
                          <div style="display:flex;text-align:center!important;width:100%">
                            <div style="width:100% !important;">
                              <span>
                                <select class="btn-mini" (change)="this.filterChange.next($event)" [(ngModel)]="filterCondition[w.data_key]"
                                        style="width: 50%; height: 20px; border-left: 0px; border-right: 1px solid #000; border-bottom: 0px; border-top: 0px ">
                                  <option [value]="'All'">All</option>
                                  <option [value]="'='">Equal to</option>
                                  <option [value]="'>'">Greater than</option>
                                  <option [value]="'<'">Less than</option>&nbsp;
                                </select>
                              </span>
                              <span>
                                <input type="number" [(ngModel)]="filterNumber[w.data_key]" [placeholder]="''+w.header+'..'"
                                       (keyup)="this.filterChange.next($event)" style="width: 50%; height: 20px; border: 0px solid #2b4163; background-color: #ccc; " />
                              </span>
                            </div>

                          </div>
                        </div>

                        <div *ngIf="w.filter_type == 'date'" style="text-align: center;">
                          <div style="display:flex;text-align:center;width:100%">
                            <div style="width: 100% !important;">
                              <span>
                                <input placeholder="From Date" type="text" style="height: 20px; width: 50%; border: 0px solid #2b4163; background-color: #ccc; " [(ngModel)]="filterDateFrom[w.data_key]" bsDatepicker (ngModelChange)="this.filterChange.next($event)"
                                       [maxDate]="filterDateTo[w.data_key]" [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
                              </span>
                              <span>
                                <input placeholder="To Date" type="text" style="height: 20px; width: 50%; border: 0px solid #2b4163; background-color: #ccc; " [(ngModel)]="filterDateTo[w.data_key]" bsDatepicker (ngModelChange)="this.filterChange.next($event)"
                                       [minDate]="filterDateFrom[w.data_key]" [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
                              </span>
                              </div>
                            </div>
                          </div>


                        <div *ngIf="w.filter_type == 'string'">
                          <select class="btn-mini" (change)="this.filterChange.next($event)" [(ngModel)]="filterText[w.data_key]"
                                  style="width: 100%; height: 20px; border: 0px">
                            <option *ngFor="let r of w.filter_texts" [value]="r">{{r}}</option>
                          </select>
                        </div>
                      </div>




                    </div>
                  </ng-container>
                </div>
              </ng-container>

              <div *ngIf="isFilterAvailable && headers[c].length==1">
                <div *ngIf="headers[c][0].filter_type == 'freetext'">
                  <input type="text" style="width: 100%; height: 20px; background-color: #ccc; border: 0px solid #2b4163 " [(ngModel)]="filterText[headers[c][0].data_key]" [placeholder]="''+headers[c][0].header+'..'" (keyup)="this.filterChange.next($event)" />
                </div>
                <div *ngIf="headers[c][0].filter_type == 'number'" style="text-align:center;">
                  <div style="display:flex;text-align:center!important;width:100%">
                    <div style="width: 100% !important;">
                      <span>
                        <select class="btn-mini" (change)="this.filterChange.next($event)" [(ngModel)]="filterCondition[headers[c][0].data_key]"
                                style="width: 50%; height: 20px; border-left: 0px; border-right: 1px solid #000; border-bottom: 0px; border-top: 0px">
                          <option [value]="'All'">All</option>
                          <option [value]="'='">Equal to</option>
                          <option [value]="'>'">Greater than</option>
                          <option [value]="'<'">Less than</option>&nbsp;
                        </select>
                      </span>
                      <span>
                        <input type="number" [(ngModel)]="filterNumber[headers[c][0].data_key]" [placeholder]="''+headers[c][0].header+'..'"
                               (keyup)="this.filterChange.next($event)" style="width: 50%; height: 20px; border: 0px solid #2b4163; background-color: #ccc; " />
                      </span>
                    </div>
                  </div>
                </div>

                <div *ngIf="headers[c][0].filter_type == 'date'" style="text-align: center;">
                  <div style="display:flex;text-align:center;width:100%">
                    <div style="width: 100% !important;">
                      <span>
                        <input placeholder="From Date" type="text" style="height: 20px; width: 50%; border: 0px solid #2b4163; background-color: #ccc; " [(ngModel)]="filterDateFrom[headers[c][0].data_key]" bsDatepicker (ngModelChange)="this.filterChange.next($event)"
                               [maxDate]="filterDateTo[headers[c][0].data_key]" [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
                    </span>
                    <span>
                      <input placeholder="To Date" type="text" style="height: 20px; width: 50%; border: 0px solid #2b4163; background-color: #ccc; " [(ngModel)]="filterDateTo[headers[c][0].data_key]" bsDatepicker (ngModelChange)="this.filterChange.next($event)"
                             [minDate]="filterDateFrom[headers[c][0].data_key]" [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
                    </span>
                      </div>
                  </div>
                </div>


                <div *ngIf="headers[c][0].filter_type == 'string'">
                  <select class="btn-mini" (change)="this.filterChange.next($event)" [(ngModel)]="filterText[headers[c][0].data_key]"
                          style="width: 100%; height: 20px; border: 0px">
                    <option *ngFor="let r of headers[c][0].filter_texts" [value]="r">{{r}}</option>
                  </select>
                </div>
              </div>
            </th>
          </ng-container>
        </tr>
        <!--<tr *ngIf="isFilterAvailable">
          <th *ngFor="let c of config?.columns | orderBy : 'order'" style="text-align: center;">

          </th>
        </tr>-->
      </thead>
      <caption *ngIf="config!=undefined && dataFiltered && dataFiltered.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
      <tbody>
        <tr *ngFor="let c of dataFiltered">
          <ng-container *ngFor="let c1 of config.columns | orderBy : 'order'">
            <td [attr.rowspan]="c[c1.data_key+'_rowspan']" [ngStyle]="{'width':c1.width_data_split}" style="text-align:center;">

              <span *ngIf="c1.column_type=='date'">
                {{c[c1.data_key] | date: c1.date_format}}
              </span>
              <span *ngIf="c1.column_type=='number'"> {{c[c1.data_key] | number}}</span>
              <span *ngIf="c1.column_type=='string'"> {{c[c1.data_key] }}</span>
            </td>
          </ng-container>
        </tr>
      </tbody>
      <tfoot>
        <tr *ngIf="config.is_pagination">
          <td colspan="100">
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
<div style="border:solid #ccc 1px;border-radius:5px;">
  <div class="row">
    <div class="col-7">
      <app-pagging [RecordsPerPage]="50" [TotalRecords]="config.total_records"
                   (pageEvent)="PageEvent($event)" [CurrentPage]="page.currentPage">
      </app-pagging>
    </div>
    <div class="col-5" style="text-align:right;padding-top:20px;padding-right:20px">
      <span *ngIf="dataFiltered">  Records Shown in Current Tab : &nbsp; <span style="font-weight:bold;">{{dataFiltered?.length}}</span></span>
      &nbsp; &nbsp; &nbsp; &nbsp;All Table Records : &nbsp; <span style="font-weight:bold;">{{config?.total_records}}</span>
    </div>
  </div>
  
  
</div>




