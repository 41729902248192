import { Component, EventEmitter, Output, ViewChild, ElementRef, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.css']
})
export class DownloadDialogComponent {
  @Output() download = new EventEmitter<string>();
  @Output() sendemail = new EventEmitter<string>();
  @Input() reporttype: string;

  password: string = "Not";
  @ViewChild('closeModal') private closeModal: ElementRef;
  isAgreed: boolean = false;// this is for enable disable download button inside Agree click

  constructor(private toastr: ToastrService, private commonService: CommonService) {
  }

  async GetConfirmedUser(send: EventEmitter<string>, ths: any) {

    //if (ths.password.split(' ').join('') == "") {
    //  ths.toastr.error("please enter the password");
    //  return;
    //}
    ths.isAgreed = false;
    //var d = await ths.commonService.GetUsersConfirmation("DOWNLOADAGREE");
    //if (d["user"] && d["user"].length > 0) {
    //  send.emit(ths.password);
    //  ths.password = undefined;
    //}
    //else {
    document.getElementById("openModalButton").click();
    //}
    ths.closeModal.nativeElement.click();
  }


  async SetConfirmedUser(send: EventEmitter<string>, ths: any, eventType: string) {
    ths.isAgreed = false;
    document.getElementById("closeModalButton").click();
    await ths.commonService.SetUsersConfirmation("DOWNLOADAGREE", ths.reporttype, eventType);
    send.emit(ths.password);
    ths.password = undefined;
  }

  Download() {
    this.GetConfirmedUser(this.download, this);
  }
  SendEmail() {
    this.GetConfirmedUser(this.sendemail, this);
  }
  AgreeAndDownload() {
    if (this.download)
      this.SetConfirmedUser(this.download, this, "Download");
  }
  AgreeAndSendEmail() {
    if (this.sendemail)
      this.SetConfirmedUser(this.sendemail, this, "Email");
  }
}
