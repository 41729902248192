<header class="navbar  navbar-light sticky-top d-print-none">
  <div class="container-xl">
    <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
      <a href=".">
        <img src="../assets/logo.PNG" width="110" height="32" alt="Tabler" class="navbar-brand-image">
      </a>
    </h1>
    <div class="navbar-nav flex-row">
      <div class="nav-item d-none d-md-flex me-3">

      </div>

      <div class="nav-item dropdown">
        <div class="nav-link d-flex lh-1 text-reset p-0">

          <div class="d-xl-block ps-2">

            <!--<div></div>-->
            <div class="mt-1 small p-0" style="color:#000;font-weight:600;font-size:16px;">
              {{reportHeader}}

            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="navbar-nav flex-row order-md-last">
      <div class="nav-item d-none d-md-flex me-3">

      </div>

      <div class="nav-item dropdown">
        <div class="nav-link d-flex lh-1 text-reset p-0">
          <span class="avatar avatar-sm" style="background-image: url(./static/avatars/000m.jpg)"></span>
          <div class="d-none d-xl-block ps-2">
            <div>
              user <a style="cursor:pointer !important">
                (Logout)
              </a>
            </div>
            <!--<div></div>-->
            <div class="mt-1 small ">
              lastupdate

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</header>
<!--Filter starts-->
<div class="container-xl  navbar-light sticky-top" style="max-width:100% !important;background: #f5f7fb;">
  <div class="row" *ngIf="isLoader==true" style=" background-color: #fff; opacity: 0.5; height: 60px; z-index: 10000; position: absolute; position: absolute; top: 0px; text-align: center; width: 97%; padding-top: 15px;">
    <div class="col-12">
      <img src="../../assets/loader.gif" style="opacity:1!important;" />
    </div>
  </div>

  <div class="row" style="padding:16px 0px 7px 0px;margin:0px auto;z-index:1000">
    <div class="row align-items-center">
      <!--Date Range SElection-->
      <div class="col">
        <div class="font-weight-medium" *ngIf="filteroptions.daterange">
          Date Filter
        </div>
        <div class="text-muted">

          <select class="form-select" [(ngModel)]="selectedDateRange" (change)="ChangeDateRange();FiltersChanged();GetProviders();">
            <option value="custom">Custom Date Range</option>
            <option value="month">Month / Year</option>
            <option value="quarter">Quarter</option>
            <option value="year">Year</option>
          </select>
        </div>
      </div>
      <!--Custom Date range Start date-->
      <div class="col" *ngIf="filteroptions.customdaterange && filteroptions.daterange">
        <div class="font-weight-medium">
          Start Date
        </div>
        <div class="text-muted">
          <input type="text" class="form-control" [(ngModel)]="fromDate" bsDatepicker (ngModelChange)="DateChanged($event)"
                 [maxDate]="toDate" [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
        </div>
      </div>
      <!--Custom Date range End date-->
      <div class="col" *ngIf="filteroptions.customdaterange && filteroptions.daterange">
        <div class="font-weight-medium">
          End Date
        </div>
        <div class="text-muted">
          <input type="text" class="form-control" [(ngModel)]="toDate" bsDatepicker (ngModelChange)="DateChanged($event)"
                 [minDate]="fromDate" [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
        </div>
      </div>
      <!--Year Selection-->
      <div class="col" *ngIf="filteroptions.year && filteroptions.daterange">
        <div class="font-weight-medium">
          Year
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedYear" (change)="selectedMonth='1';selectedQuarter = 'q1';GetMonths();GetQuarters();GetProviders();FiltersChanged();">
            <option value="{{currentYear-1
                }}">
              {{currentYear-1}}
            </option>
            <option value="{{currentYear}}">{{currentYear}}</option>

          </select>
        </div>
      </div>
      <!--Quarter Selection-->
      <div class="col" *ngIf="filteroptions.quarter && filteroptions.daterange">
        <div class="font-weight-medium">
          Quarter
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedQuarter" (change)="GetProviders();FiltersChanged();">
            <option *ngFor='let q of quarters' [value]="q.value">{{q.name}}</option>
          </select>
        </div>
      </div>
      <!--Month Selection-->
      <div class="col" *ngIf="filteroptions.month && filteroptions.daterange">
        <div class="font-weight-medium">
          Month
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedMonth" (change)="GetProviders();FiltersChanged();">
            <option *ngFor='let m of months' [value]="m.value">{{m.name}}</option>
          </select>
        </div>
      </div>

      <!--Role Selection-->
      <div class="col">
        <div class="font-weight-medium">
          Staff Role
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedStaffRole" (change)="GetProviders();FiltersChanged();">
            <option *ngFor='let r of roles' [value]="r">{{r}}</option>
          </select>
        </div>
      </div>

      <!--Site Selection-->
      <div class="col">
        <div class="font-weight-medium">
          Site
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedSite" (change)="GetProviders();FiltersChanged();">
            <option *ngFor='let s of sites' [value]="s">{{s}}</option>
          </select>
        </div>
      </div>

      <!-- Hours Type -->
      <!--<div class="col">
        <div class="font-weight-medium">
          Hours Type
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedHoursType" (change)="GetProviders();FiltersChanged();">
            <option [value]="'All'">All</option>
            <option [value]="'prime'">Prime</option>
            <option [value]="'non prime'">Non Prime</option>
          </select>
        </div>
      </div>-->

      <!--Week Day/ Ween End Selection-->
      <div class="col">
        <div class="font-weight-medium">
          Weekday/Weekend
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedDayCategory" (change)="GetProviders();FiltersChanged();">
            <option *ngFor='let d of dayCategories' [value]="d.Day">{{d.DayText}}</option>
          </select>
        </div>
      </div>
      <!--Multi select provider-->
      <div class="col" style="min-width:250px !important;padding-left:20px">
        <div class="font-weight-medium">
          <span>Provider </span><span style="float:right">
            <input class="form-check-input" type="checkbox" [(ngModel)]="isSelectAll" (change)="SelectAll();FiltersChanged();">&nbsp;
            <span>All</span>
          </span>
        </div>
        <div class="text-muted">
          <ng-multiselect-dropdown [placeholder]="'Select Providers'"
                                   [settings]="providerMultiSettings"
                                   [data]="providers"
                                   [(ngModel)]="selectedMultiProvider"
                                   [disabled]="isSelectAll">

          </ng-multiselect-dropdown>


        </div>
      </div>

      <div class="col">
        <div class="font-weight-medium">
          &nbsp;
        </div>
        <div class="text-muted">
          <button type="button" class="btn btn-primary" (click)="OnFilter()">
            Apply
          </button>
        </div>
      </div>

    </div>
  </div>

</div>
