import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class Occupancy {

  config: any = {
    height: 150,
    width: 150,
    margin_left: 10,
    margin_right: 10,
    margin_top: 20,
    margin_bottom: 50,
    max: 15
  };

  width: any = undefined;
  height: any = undefined;
  //max: any = undefined;
  x: any = undefined;
  y: any = undefined;
  svg: any = undefined;

  private roomdata: any = undefined;
  private container: string;
  private configuration: any;
  barcolors: any = ["#bbc3ce", "#8a95a3"];

  constructor(container: string, roomData: any, configuration: any) {

    this.container = container;
    this.roomdata = roomData;
    this.configuration = configuration;
    this.configure(this.configuration);
  }

  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    //get max room count over the occupied and allocated room count data
    //this.max = 15;

    //map allocated or occupied room count bar to location on the x-axis
    this.x = d3.scaleBand()
      .rangeRound([this.config.margin_left, this.config.width - (this.config.margin_left + this.config.margin_right)])
      .domain(this.roomdata.map((d) => { return d.roomtype; }));

    //scale mean room counts to y-axis
    this.y = d3.scaleLinear()
      .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
      .domain([0, this.config.max])
  }

  Render() {
    this.draw();
  }
  private draw() {
    //create svg to display bars
    this.svg = d3.select(this.container)
      .append("div")
      .classed("roomchartcontainer", true)
      .append('svg:svg')
      .attr('viewBox', "0 0 " + this.config.width + " " + this.config.height)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    //append the x-axis with labels for allocated and occupied
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x)
        .tickSize(0)
      )
      .selectAll("text")
      .attr("transform", "rotate(40)")
      .style("text-anchor", "start");

    //draw bars indicating mean room count
    this.svg.append("g")
      .selectAll("rect")
      .data(this.roomdata)
      .enter().append("rect")
      .attr("fill", (d, i) => { return this.barcolors[i]; })
      .attr("x", (d) => { return this.x(d.roomtype); })
      .attr("width", (d) => { return this.x.bandwidth(); })
      .attr("y", this.config.height -  this.config.margin_bottom )
      .attr("height", 0)
      .attr("stroke-width", 2)
      .attr("stroke", "#fff")
      .transition()
      .duration(1500)
      .attr("y", (d) => { return this.y(d.roomcount) - this.config.margin_bottom; })
      .attr("height", (d) => {
        return this.config.height- this.y(d.roomcount);
      });

    //draw text of mean room count above bar
    this.svg.append("g")
      .selectAll("text")
      .data(this.roomdata)
      .enter().append("text")
      .attr("class", "phasetimetext")
      .attr("x", (d) => { return this.x(d.roomtype) + this.x.bandwidth() / 2; })
      .attr("y", (d) => { return this.y(d.roomcount) - this.config.margin_bottom - 5; })
      .style("text-anchor", "middle")
      .text((d) => { return d.roomcount; })
  }

  public update(updateBarChartData: any, newConfiguration: any) {

    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }

    this.roomdata = updateBarChartData;
    d3.select("" + this.container).select("svg").remove();
    this.draw();
  }
}
