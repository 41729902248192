import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CaseLogComponent } from './case-log/case-log.component';
import { CaseMixComponent } from './case-mix/case-mix.component';
import { DistributionRankComponent } from './distribution-rank/distribution-rank.component';
import { MetricDashboardComponent } from './metric-dashboard/metric-dashboard.component';
import { OrsnapshotComponent } from './orsnapshot/orsnapshot.component';
import { PatientOutcomeComponent } from './patient-outcome/patient-outcome.component';
import { WorkflowComponent } from './workflow/workflow.component';
import { TwoRoomSurgeonsComponent } from './two-room-surgeons/two-room-surgeons.component';
import { ProvidercompComponent } from './providercomp/providercomp.component';
import { FinancialPortfolioComponent } from './financial-portfolio/financial-portfolio.component';
import { RuvComponent } from './ruv/ruv.component';
import { TimestatComponent } from './timestat/timestat.component';
import { AuthGuardService as AuthGuard } from '../app/auth-guard.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { IQuestComponent } from './i-quest/i-quest.component';
import { PeriopOperationalComponent } from './periop-operational/periop-operational.component';
import { PeriopOutcomeComponent } from './periop-outcome/periop-outcome.component';
import { PeriopQualityComponent } from './periop-quality/periop-quality.component';
import { GlucoseManagementComponent } from './glucose-management/glucose-management.component';
import { DemandForecastsComponent } from './demand-forecasts/demand-forecasts.component';
import { PicklistOptimizationComponent } from './picklist-optimization/picklist-optimization.component';
import { BlockutilizationComponent } from './blockutilization/blockutilization.component';
import { BlockScheduleComponent } from './block-schedule/block-schedule.component';
import { BlockRequestComponent } from './block-request/block-request.component';
import { UsageCostComponent } from './usage-cost/usage-cost.component';
import { PicklistStandardizationComponent } from './picklist-standardization/picklist-standardization.component';
import { ScheduleSupplyComponent } from './schedule-supply/schedule-supply.component';
import { BmOverviewComponent } from './bm-overview/bm-overview.component';
import { BmActionsComponent } from './bm-actions/bm-actions.component';
import { BmCalendarViewComponent } from './bm-calendar-view/bm-calendar-view.component';
import { BmClaimReleaseComponent } from './bm-claim-release/bm-claim-release.component';
import { HomeComponent } from './home/home.component';
import { DefaultComponent } from './default/default.component';
import { ProviderReportComponent } from './provider-report/provider-report.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';
import { MetricComparisonComponent } from './metric-comparison/metric-comparison.component';
import { PeriopExecutiveComponent } from './periop-executive/periop-executive.component';
import { CaseVolumeForecastComponent } from './case-volume-forecast/case-volume-forecast.component';
import { FacilitySummaryComponent } from './facility-summary/facility-summary.component';
import { ProductivityReportComponent } from './productivity-reports/productivity-report/productivity-report.component';
import { UnutilizedReportComponent } from './productivity-reports/unutilized-report/unutilized-report.component';
import { CommonPageComponent } from './periop-outcome-metrics/common-page/common-page.component';
import { PerivisionComponent } from './perivision/perivision.component';

const routes: Routes = [
  { path: 'default', component: DefaultComponent },
   { path: '', component: OrsnapshotComponent, canActivate: [AuthGuard] },
  { path: 'or-snapshot', component: OrsnapshotComponent, canActivate: [AuthGuard] },
  { path: 'case-mix', component: CaseMixComponent, canActivate: [AuthGuard]  },
  { path: 'time-stat', component: TimestatComponent, canActivate: [AuthGuard]  },
  { path: 'case-log', component: CaseLogComponent, canActivate: [AuthGuard] },
  { path: 'financial-portfolio', component: FinancialPortfolioComponent, canActivate: [AuthGuard] },
  { path: 'rvu', component: RuvComponent, canActivate: [AuthGuard] },
  { path: 'distribution-rank', component: DistributionRankComponent, canActivate: [AuthGuard]  },
  { path: 'patient-outcome', component: PatientOutcomeComponent, canActivate: [AuthGuard] },
  { path: 'workflow', component: WorkflowComponent, canActivate: [AuthGuard] },
  { path: 'two-room-surgeons', component: TwoRoomSurgeonsComponent, canActivate: [AuthGuard] },
  { path: 'metric-dashboard', component: MetricDashboardComponent, canActivate: [AuthGuard]  },
  { path: 'provider-comparision', component: ProvidercompComponent, canActivate: [AuthGuard] },
  { path: 'periop-executive', component: PeriopExecutiveComponent, canActivate: [AuthGuard] },
  { path: 'periop-quality', component: PeriopQualityComponent, canActivate: [AuthGuard] },
  { path: 'periop-outcome', component: PeriopOutcomeComponent, canActivate: [AuthGuard] },
  { path: 'periop-operational', component: PeriopOperationalComponent, canActivate: [AuthGuard] },
  { path: 'glucose-management', component: GlucoseManagementComponent, canActivate: [AuthGuard] },
  { path: 'provider-report', component: ProviderReportComponent, canActivate: [AuthGuard] },

  { path: 'metric-comparison', component: MetricComparisonComponent, canActivate: [AuthGuard] },
  { path: 'usage-cost', component: UsageCostComponent, canActivate: [AuthGuard] },
  { path: 'picklist-optimization', component: PicklistOptimizationComponent, canActivate: [AuthGuard] },
  { path: 'picklist-standaradization', component: PicklistStandardizationComponent, canActivate: [AuthGuard] },
  { path: 'demand-forecasts', component: DemandForecastsComponent, canActivate: [AuthGuard] },
  { path: 'scedule-supply', component: ScheduleSupplyComponent, canActivate: [AuthGuard] },


  { path: 'overview', component: BmOverviewComponent, canActivate: [AuthGuard] },
  { path: 'actions', component: BmActionsComponent, canActivate: [AuthGuard] },
  { path: 'cal-view', component: BmCalendarViewComponent, canActivate: [AuthGuard] },
  { path: 'claim-release', component: BmClaimReleaseComponent, canActivate: [AuthGuard] },
 

  { path: 'i-quest', component: IQuestComponent, canActivate: [AuthGuard] },
  { path: 'un-authorized', component: UnauthorizedComponent },
  { path: 'block-utilization', component: BlockutilizationComponent, canActivate: [AuthGuard] },
  { path: 'block-schedule', component: BlockScheduleComponent, canActivate: [AuthGuard] },
  { path: 'block-request', component: BlockRequestComponent, canActivate: [AuthGuard] },

  { path: 'recomendation', component: RecommendationsComponent, canActivate: [AuthGuard] },
  { path: 'case-volume-forecast', component: CaseVolumeForecastComponent, canActivate: [AuthGuard] },
  { path: 'facility-summary', component: FacilitySummaryComponent, canActivate: [AuthGuard] },
  ,{
    path: 'productivity',
    children: [
      { path: 'productivity-summary', component: ProductivityReportComponent },
      { path: 'unutilized-summary', component: UnutilizedReportComponent }
    ]
  }
  , {
    path: 'periop-outcome',
    children: [     
      { path: 'common-page/:page', component: CommonPageComponent }
    ]
  },
  { path: 'perivision', component: PerivisionComponent, canActivate: [AuthGuard] }
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
