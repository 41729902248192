import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { CommonService } from './common.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private service: CommonService, private router: Router,
    private acRoute: ActivatedRoute, private rr: ActivatedRoute) {
  }

  async canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      //TODO
    //return true;
    if (sessionStorage.getItem("token") == null || sessionStorage.getItem("token") == undefined) {
      window.location.href = environment.loginURL;
    }
    if (sessionStorage.getItem("usermenu") == null || sessionStorage.getItem("usermenu") == undefined) {
      var p = await this.service.GetUserMenu();
      sessionStorage.setItem("usermenu", p);
    }

    var paths = JSON.parse(sessionStorage.getItem("usermenu"))["child"].map(a => { return "/" + a.AngularRoute });
    var role = sessionStorage.getItem("userrole");


    //var paths = <Array<string>>await this.service.GetPaths();
    //var role = <string>await this.service.GetRole();

    if (state.url == "/" || state.url == "" || state.url == "\\") {
      if (role == "memberlogin") {
        this.router.navigate(['metric-dashboard']);
      }
      else {
        this.router.navigate(['or-snapshot']);
      }
    }
    else if (paths.indexOf(state.url) < 0) {
      this.router.navigate(['un-authorized']);
      return true;
    }
    else {
      return true;
    }
    return true;
  }
}
