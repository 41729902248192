import * as d3 from 'd3';
import { nest } from 'd3-collection';
import { Page } from '../Page';

export class LineGraph {
  private config: any = {
    height: 350,
    width: 900,
    margin_left: 50,
    margin_right: 10,
    margin_top: 20,
    margin_bottom: 80,
    axisclass: "linegraphaxis",
    key: "DOS",
    linecolors:
    {
      "ActualVolume": "#318085",
      "Forecast": "#e3bd42",
      "LowerInt": "#9c6211",
      "UpperInt": "#9c6211"
    },

    linestyle:
    {
      "ActualVolume": "0,0",
      "Forecast": "0,0",
      "LowerInt": "2,3",
      "UpperInt": "2,3"
    },

    metriclabels:
    {
      "ActualVolume": "Actual Volume",
      "Forecast": "Forecast",
      "LowerInt": "Lower Int",
      "UpperInt": "Upper Int"
    }
  };

  private _bisectDate: any;
  private maxcases: any = undefined;
  private x: any = undefined;  
  private y: any = undefined;
  private xdates: any = undefined;
  private svg: any = undefined;
  private line: any = undefined;
  private metrics: any = undefined;
  private _tooltip: any;

  private barcolors: Array<string> = ["#bbc3ce", "#8a95a3"];

  private container: string;
  private linedata: any;
  private configuration: any;
  private ext: any;

  constructor(container: string, linedata: any, configuration: any) {

    this.container = container;
    this.linedata = linedata;
    this.configuration = configuration;
    this.configure(this.configuration);
  }

  private ToolTip(rect: any) {

    if (this.config.key == "DOS") {
      rect
        .attr("x", this.config.margin_left)
        .attr("y", this.config.margin_top)
        .attr("width", this.config.width - this.config.margin_left - this.config.margin_right)
        .attr("height", this.config.height - this.config.margin_top - this.config.margin_bottom)
        .style("fill", "lightgray")
        .style("opacity", 0)
        .on("mouseover", (d: any) => {
          this._tooltip.style("display", "block").style("opacity", 1);
        })
        .on("mouseout", (d: any) => {
          this._tooltip.style("display", "block").style("opacity", 0);
          this.svg.selectAll(".tooltip-line").remove();
        })
        .on("mousemove", (d: any) => {

          this.svg.selectAll(".tooltip-line").remove();

          let x0 = this.x.invert(d3.pointer(event)[0]);
          let b = this._bisectDate(this.linedata, x0, 0);

          let s = this.linedata[b - 1];
          if (s) {
            let tooltipData = this.linedata.filter(f => f[this.config.key] == s[this.config.key]);

            if (tooltipData.length > 0) {
              let gtool = this.svg.append("g")
                .attr("class", "tooltip-line");
              gtool.append("line")
                .attr("x1", this.x(s.date))
                .attr("y1", this.config.margin_top)
                .attr("x2", this.x(s.date))
                .attr("y2", this.config.height - this.config.margin_bottom)
                .style("stroke-width", 1)
                .style("stroke", "gray");

              gtool.selectAll(".tooltip-circle")
                .data(tooltipData)
                .enter()
                .append("circle")
                .attr("class", "tooltip-circle")
                .attr("cx", (r: any) => {
                  return this.x(s.date);
                })
                .attr("cy", (r: any) => {
                  return this.y(r.Value);
                })
                .attr("r", 3)
                .style("stroke-width", 1)
                .style("fill", "#fff")
                .style("stroke", "gray");

              let w = this.config.width - this.config.margin_left - this.config.margin_right;
              let html = "";

              html += tooltipData[0].Comment + "</br>";
              this._tooltip.style("top", (d.layerY + 15) + 'px').style("left", (w < d.layerX + 280 ? d.layerX - 280 : d.layerX + 30) + 'px')
                .style("display", "block").style("opacity", 1).style("height", "auto")
                .html(() => {
                  return html;
                });
            }

          }

        });
    }
    else {
      rect
        .attr("x", this.config.margin_left)
        .attr("y", this.config.margin_top)
        .attr("width", this.config.width - this.config.margin_left - this.config.margin_right)
        .attr("height", this.config.height - this.config.margin_top - this.config.margin_bottom)
        .style("fill", "lightgray")
        .style("opacity", 0)
        .on("mouseover", (d: any) => {
          this._tooltip.style("display", "block").style("opacity", 1);
        })
        .on("mouseout", (d: any) => {
          this._tooltip.style("display", "none");
          this.svg.selectAll(".tooltip-line").remove();
        })
        .on("mousemove", (d: any) => {

          this.svg.selectAll(".tooltip-line").remove();

          let s = d3.scaleQuantize().domain(this.x.range()).range(this.x.domain())(d3.pointer(event)[0]);            

          if (s) {            

            let tooltipData = this.linedata.filter(f => f[this.config.key] == s);                   

            //alert(JSON.stringify(tooltipData[0]));

            if (tooltipData.length > 0) {
              let gtool = this.svg.append("g")
                .attr("class", "tooltip-line");
              gtool.append("line")
                .attr("x1", this.x(s))
                .attr("y1", this.config.margin_top)
                .attr("x2", this.x(s))
                .attr("y2", this.config.height - this.config.margin_bottom)
                .style("stroke-width", 1)
                .style("stroke", "gray");

              gtool.selectAll(".tooltip-circle")
                .data(tooltipData)
                .enter()
                .append("circle")
                .attr("class", "tooltip-circle")
                .attr("cx", (r: any) => {
                  return this.x(s);
                })
                .attr("cy", (r: any) => {
                  return this.y(r.Value);
                })
                .attr("r", 3)
                .style("stroke-width", 1)
                .style("fill", "#fff")
                .style("stroke", "gray");

              let html = "";

              html += tooltipData[0].Comment + "</br>";
              this._tooltip.style("top", (d.layerY + 120) + 'px').style("left", d.layerX + 30 + 'px')
                .style("display", "block").style("opacity", 1).style("height", "auto")
                .html(() => {
                  return html;
                });
            }

          }

        });

      
    }
    
  }


  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    //alert(JSON.stringify(this.linedata));
    //alert(JSON.stringify(this.config.linecolors));
    //alert(JSON.stringify(this.config.linestyle));

    //identify max number of rooms, allocated or occupied to use as the upper range of the y-axis
    this.maxcases = d3.max(this.linedata.map((d) => { return d.Value }));
    this.maxcases = this.maxcases * 1.2;


    this.linedata = this.linedata.map((d) => {
      if (this.config.key == "DOS") {
        var dat = d.DOS.split("-");
        var m = parseInt(dat[1]);
        if (m > 12)
          m = m - 12
        if (m == 0)
          m = 1
        d.date = new Date(parseInt(dat[0]), m - 1, parseInt(dat[2]));
            
        return d;
      }
      else
      {        
        return d;
      }            
    });

    if (this.config.key == "DOS") {
      this.xdates = this.linedata.map(d => d.date);
      //this.xdates = this.xdates.filter((date, i, self) =>
      //  self.findIndex(d => d.getTime() === date.getTime()) === i      //)      
    }
    else {
      this.xdates = this.linedata.map(d => d[this.config.key]);
    }        

    this.ext = d3.extent(this.xdates, function (d: any) { return d; }) as Array<any>;    

    //scale indicating time of day
    if (this.config.key == "DOS") {
      this.x = d3.scaleTime()
        .range([this.config.margin_left, this.config.width - (this.config.margin_left + this.config.margin_right)])
        .domain(this.ext)
    }
    else {
      this.x = d3.scaleBand()
        .rangeRound([this.config.margin_left, this.config.width - (this.config.margin_left + this.config.margin_right)])
        .domain(this.linedata.map((d) => { return d[this.config.key]; }))
        .paddingOuter(0.2)
        .paddingInner(0.2);     
    }

    //scale for bars indicating count of rooms
    this.y = d3.scaleLinear()
      .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
      .domain([0, this.maxcases])

    //line function for line graph indicating allocated rooms
    this.line = d3.line()
      .x((d) => {
        if (this.config.key == "DOS") {
          var dat = d['DOS'].split("-");

          var m = parseInt(dat[1]);
          if (m > 12)
            m = m - 12
          if (m == 0)
            m = 1

          return this.x(new Date(parseInt(dat[0]), m - 1, parseInt(dat[2])));
        }
        else {
          return this.x(d[this.config.key]);
        }
        
      })
      .y((d) => { return this.y(d['Value']); });

    //group the data by category
    this.metrics = nest()
      .key((d: any) => {
        return d['Metric'];
      })
      .entries(this.linedata);
  }

  Render() {
    this.draw();
  }
  private draw() {



    //create svg to to display bars
    this.svg = d3.select(this.container)
      .append("div")
      .classed("linegraphcontainer", true)
      .append('svg:svg')
      .attr('viewBox', "0 0 " + this.config.width + " " + this.config.height)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    //append the x-axis
    if (this.config.key == "DOS") {
      this.svg.append("g")
        .attr("class", this.config.axisclass)
        .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
        .call(d3.axisBottom(this.x)
          .tickSize(0)
          .tickFormat((x: any) => { return d3.timeFormat("%b %Y")(x); })
          .tickValues(this.xdates.filter((d) => {
            var day = d.getDate(); return day <= 7;            
          }
          )))

        .selectAll("text")
        .attr("transform", "rotate(20)")
        .style("text-anchor", "start");
    }
    else {
      this.svg.append("g")
        .attr("class", this.config.axisclass)
        .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
        .call(d3.axisBottom(this.x)
          .ticks(d3.timeHour.every(1))
          .tickSize(-(this.config.height-(this.config.margin_top + this.config.margin_bottom)))
        );
    }

    //append the y-axis
    this.svg.append("g")
      .attr("class", this.config.axisclass)
      .attr("transform", "translate(" + this.config.margin_left + ",0)")
      .call(d3.axisLeft(this.y)
        .ticks(3)
        .tickSize(this.config.margin_left - this.config.width));

    //draw lines indicating count of allocated rooms
    this.metrics.forEach((d: any, i: number) => {

      this.svg
        //append("g")
        //.attr("transform", "translate(" + (20) + ",0)")
        .append("path")
        .datum(d.values)
        .attr("fill", "none")
        .attr("stroke", (d: any) => { return this.config.linecolors[d[0].Metric] })
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-width", 2)
        .style("stroke-dasharray", (d: any) => { return this.config.linestyle[d[0].Metric] })
        .attr("d", this.line)
      //.append("title")
      //.text((d: any) => { return d[0].Comment; });
    })

    this._tooltip = d3.select(this.container)
      .append("div")
      .attr("class", "d3-div")
      .style("display", "none")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("font-weight", "bold")
      .style("padding", "10px")
      .style("background", "rgba(0, 0, 0, 0.8)")
      .style("color", "#fff")
      .style("border-radius", "2px");


    //draw text with label
    this.metrics.forEach((d: any, i: number) => {
      this.svg.append("g")
        .selectAll("text")
        .data(d.values.filter((d, i) => { return (i + 1) % 2 })) //only show every other label
        .enter().append("text")
        .attr("class", "linegraphlabel")
        .attr("x", (d) => {
          if (this.config.key == "DOS") {
            var dat = d['DOS'].split("-");

            var m = parseInt(dat[1]);
            if (m > 12)
              m = m - 12
            if (m == 0)
              m = 1

            return this.x(new Date(parseInt(dat[0]), m - 1, parseInt(dat[2])));
          }
          else {
            return this.x(d[this.config.key]);
          }
        })
        .attr("y", (d) => { return this.y(d.Value) - 8; })
        .style("text-anchor", "middle")
        .text((d) => { return d.Value; })
    })

    //Add legend for each group by value
    var legendlength = 5



    this.metrics.forEach((d: any, i: number) => {

      //draw box with color code
      this.svg.append('circle')
        .attr('cx', legendlength * 4)
        .attr('cy', 5)
        .attr('r', 3)
        .style('fill', this.config.linecolors[d.key])

      //draw text of group by value
      this.svg.append('text')
        .attr("class", "linegraphlabel")
        .attr('x', legendlength * 4 + 10)
        .attr('y', 10)
        .text(this.config.metriclabels[d.key])

      legendlength = legendlength + this.config.metriclabels[d.key].length + 10;

    });
    this._bisectDate = d3.bisector((d: any) => {
      return d.date;
    }).left;

    this.ToolTip(this.svg.append("rect"));
  }
}
