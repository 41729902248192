import * as d3 from "d3";
import { HBarConfig, HbarData, HData } from "../models/hbarmodel";

export class VBarChart {

  private _svg: any;
  private _tooltip: any;
  private _tooltipX: any;
  private _config: HBarConfig = new HBarConfig();
  private _xscaleouter: any;
  private _xscaleinner: any;
  private _yscale: any;
  //private _colorscale: any;
  private _xaxis: any;
  private _yaxis: any;
  private _isSingleBar: boolean | undefined;
  private isBarSelected: boolean = false;
  deselectbar: any;
  private selectedBar: any;

  barclick: any;
  singlebarclick: any;
  get config(): HBarConfig {
    return this._config;
  }
  set config(value: HBarConfig) {
    this._config = value;
  }
  public Render() {
    this._isSingleBar = this.config.chart_data[0].data.length == 1;
    this.ClearCharts();
    this.InitiateTooltip();
    this.ConfigureScales();
    this.DrawAxisTexts();
    this.DrawAxis();
    this.DrawChart();
  }
  private ClearCharts() {

    //let _width = (this._config.width - (this._config.margin_left + this._config.margin_right));
    //let _height = (this._config.height - (this._config.margin_top + this._config.margin_bottom));

    d3.select(this._config.container).selectAll("*").remove();
    this._svg = d3.select(this._config.container).append("svg")
      .on("click", () => {
        if (!this.isBarSelected) {
          this._svg.selectAll(".bars").style("opacity", 1);
          this.selectedBar = undefined;
          if (this.deselectbar) {
            this.deselectbar();
          }
        }
        this.isBarSelected = false;
      })
      .attr("width", this._config.width)
      .attr("height", this._config.height)
      .append("g").attr("class", "main")
      .attr("transform", `translate(${this._config.margin_left},${this._config.margin_top})`);

    this._tooltip = d3.select(this._config.container)
      .append("div")
      .attr("class", "d3-tooltip-new")
      .style("z-index", "10000")
      .style("display", "none")
      .style("opacity", 0)
      .style("position", "absolute")
  }
  private InitiateTooltip() {


    //this._tooltipX = this._svg
    //  .append("g")
    //  .attr("class", "d3-tooltip-line")
    //  .style("opacity", 0)

    //this._tooltipX.append("rect")
    //  .attr("x", 0)
    //  .attr("y", -10)
    //  .attr("height", 30)
    //  .style("stroke", "lightgray")
    //  .style("stroke-width", "1px")
    //  .style("fill", "lightgray");
    //this._tooltipX.append("text")
    //  .attr("x", 2)
    //  .attr("y", 10)
    //  .style("font-weight", "bold")
    //  .style("fill", "gray");
  }

  private ConfigureScales() {
    /************** Creating X SCale ***********/
    let outerArray = this._config.chart_data.map((d: HbarData) => { return d.key; });
    let innerArray = this._config.chart_data[0].data.map(f => f.key);

    this._xscaleouter = d3.scaleBand()
      .padding(0.1)
      .domain(outerArray)
      .range([0, this._config.width - this._config.margin_left - this._config.margin_right]);

    this._xscaleinner = d3.scaleBand()
      .domain(innerArray)
      .range([0, this._xscaleouter.bandwidth()]);

    /************** Creating Y SCale ***********/

    let arrays = this._config.chart_data.map((d: HbarData) => { return d.data.map(f => f.value); });
    let innerValue: number[] = []
    arrays.map((v: number[]) => {
      v.map((v1: number) => {
        innerValue.push(v1);
      });
    });

    let max = d3.extent(innerValue.map(m => m as number))[1];
    let max1 = (max ? max : 0) as number;
    this._yscale = d3.scaleLinear()
      .domain([0, max1])
      .range([this._config.height - this._config.margin_top - this._config.margin_bottom, 0]);

    //let innerValue = this._config.chart_data.map((d: HbarData) => { return d.data.map(f => f.value); }).flat(1);
    //let max = d3.extent(innerValue)[1];
    //this._yscale = d3.scaleLinear()
    //  .domain([max ?? 0, 0])
    //  .range([this._config.width - this._config.margin_left - this._config.margin_right, 0]);

    /************** Creating Color SCale ***********/

    //this._colorscale = d3.scaleOrdinal()
    //  .domain(innerArray)
    //  .range(this._config.chart_data[0].data.map(f => f.color));
    //.range(this._config. linestyle.map((m: any) => m.color));




  }

  private DrawAxisTexts() {
    let axisTexts = this._svg.attr("class", "axistexts").attr("font-weight", "bold");

    axisTexts.append("g")
      .attr("transform", `translate(${(this._config.width - this._config.margin_left - this._config.margin_right) / 2},${this._config.height - this._config.margin_bottom + 10})`)
      .append("text")
      .attr("text-anchor", "middle")
      .text(() => { return this._config.xlabel; });

    axisTexts.append("g")
      .attr("transform", `translate(${-this._config.margin_left + 27},${100})rotate(-90)`)
      .append("text")
      .attr("text-anchor", "middle")
      .text(() => { return this._config.ylabel; });

  }


  private DrawAxis() {
    /*********************** Create Y axis ***************/
    function make_y_gridlines(y: any, tick: number) {
      return d3.axisLeft(y)
        .ticks(tick)
    }

    this._yaxis = this._svg.append("g")
      .attr("class", "y-axis")
      //.attr("clip-path", "url(#clip)")
      //.attr("transform", `translate(0,${this._config.height - this._config.margin_top - this._config.margin_bottom})`)
      .call(make_y_gridlines(this._yscale, this.config.tick_size)
        .tickSize(-this._config.width + (this._config.margin_right + this._config.margin_left))
        //.tickFormat(this._config.yaxisformat)
      )
    //.call(d3.axisLeft(this._yscale)
    //.ticks(this.config.tick_size));
    this._yaxis.selectAll("text").text((d) => {
      return d + (this._config.format ? this._config.format : "");
    });

    /*********************** Grid lines Rect ***************/
    this._yaxis.selectAll("g").selectAll(".gridline-rect").remove();
    let f = this._yaxis.selectAll("g")._groups[0]//.select("line");
    let r = d3.select(f[0]).attr("transform").split(',')[1].replace(")", "");
    let r1 = d3.select(f[1]).attr("transform").split(',')[1].replace(")", "");
    let h = parseInt(r) - parseInt(r1)

    for (var i = 0; i < f.length; i++) {
      if (i % 2 == 1) {
        let g = d3.select(f[i]);
        let w = g.select("line").attr("x2");
        g.append("rect").attr("class", "gridline-rect").attr("width", w).attr("height", h).style("fill", "lightgray")
          // .attr("transform", g.attr("transform"))
          .style("opacity", 0.2)
      }

    }


    /*********************** Create Grid Lines ***************/
    this._svg.selectAll(".tick").selectAll("line").style("opacity", 0.1);//.style("stroke","red");
    this._svg.selectAll(".domain").style("opacity", 0);

    /*********************** Create X axis ***************/
    this._xaxis = this._svg.append("g")
      .attr("transform", `translate(0,${this._config.height - this._config.margin_top - this._config.margin_bottom})`)
      .attr("class", "x-axis")
      //.attr("clip-path", "url(#clipy)")
      .call(d3.axisBottom(this._xscaleouter));

    if (this.config.is_rotate_xaxis) {
      this._xaxis.selectAll("text")
        .attr("y", 15)
        .attr("x", 9)
        .attr("dy", ".35em")
        // .style("fill", "red")
        .attr("transform", "rotate(30)")
        .style("text-anchor", "start");
    }

    this._xaxis.selectAll("text")
      .style("cursor", "pointer")
      .on("click", (e: any, t: any) => {
        let val = this.config.chart_data.filter(f => f.key == t);
        if (this.barclick && val.length > 0) {

          if (this.selectedBar == val[0].key) {
            this._svg.selectAll(".bars").style("opacity", 1);
            this._svg.selectAll(".mousehover-bars").style("opacity", 0);
            this.selectedBar = undefined;
            this.isBarSelected = false;
            if (this.deselectbar) {
              this.deselectbar();
            }
          } else {
            this.selectedBar = val[0].key;
            this.isBarSelected = true;

            let obj = {}
            obj["data"] = val[0];
            obj["id"] = this._config.container.replace("#", "") + "@@" + (val[0].key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "");

            this.barclick(obj);
            // this.barclick(val[0]);
            this.ClickBulkDisable(val[0]);
          }

        }
      });
  }



  private DrawChart() {

    let gap = 3
    //let groupLength = this._config.chart_data[0].data.length;
    this._svg.append("defs").append("clipPath")
      .attr("id", this._config.container.replace("#", "") + "-clip-hbar")
      .append("rect")

      .attr("x", 0)
      .attr("y", 0)
      /*.attr("width", this._config.width - this._config.margin_left - this._config.margin_right)*/
      .attr("width", this._config.width)
      .attr("height", this._config.height - this._config.margin_top - this._config.margin_bottom)


    let g = this._svg.selectAll(".group-container")
      .data(this._config.chart_data)
      .enter()
      .append("g")
      .attr("clip-path", "url(#" + this._config.container.replace("#", "") + "-clip-hbar)")
      .attr("transform", (d: HbarData) => {
        return `translate(${this._xscaleouter(d.key)},0)`
      })
      .attr("class", "group-container");


    /*********************** Hiding axis lines ***************/
    this._svg.selectAll(".tick").selectAll("line").style("opacity", 0.2);
    this._svg.selectAll(".domain").style("opacity", 0.1);

    /*********************** Draw bars ***************///+ (groupLength*3)
    let bargroup = g
      .append("g")
      .attr("transform", `translate(0,${gap / 3})`)

    bargroup.selectAll(".bars")
      .data((d: HbarData) => {
        return d.data.map((hd: HData) => {
          hd.header = d.key;
          return hd;
        });
      })
      .enter()
      .append("rect")
      .attr("class", "bars")
      //.attr("id", (d: HData) => {
      //  return this._config.container.replace("#", "") + (d.header + d.key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "");
      //})
      .attr("height", (d: HData) => {
        return this._yscale(0) - this._yscale(d.value) + 2
      })
      .attr("x", (d: HData) => {
        return this._xscaleinner(d.key)
      })
      .attr("y", (d: HData) => {
        return this._yscale(d.value)
      })
      .attr("width", (d: HData) => {
        return this._xscaleinner.bandwidth()
      })
      .attr("stroke", "#fff")
      .attr("stroke-width", 1)
      .style("fill", (d: HData) => {
        //return d.color;
        if (this.config.color_scale) {
          if (this.config.is_linear_color)
            return this.config.color_scale(d.value);
          else
            return this.config.color_scale(d.key);
        }
        else
          return d.color;
      })
      .attr("rx", 3)
      .attr("ry", 3)
      .on("click", (d: any, val: HData) => {
        if (this.singlebarclick) {
          if (this.selectedBar == val.header) {
            this.selectedBar = undefined;
            this._svg.selectAll(".bars").style("opacity", 1);
            this.selectedBar = undefined;
            if (this.deselectbar) {
              this.deselectbar();
            }
            this.isBarSelected = false;
          }
          else {
            this.selectedBar = val.header;
            this.isBarSelected = true;
            this.ClickDisable(val);
            this.singlebarclick(val);
          }
        }
      })
      .on("mouseover", (d: any) => {
        this._tooltip.style("display", "block").style("opacity", 1);
      })
      .on("mousemove", (d: any, d1: HData) => {
        //this.Tooltip(d, [d1], d1.header, this._isSingleBar == true ? "" : d1.key);
        this.Tooltip(d, [d1], d1.header);
      })
      .on("mouseout", (d: any) => {
        this._tooltip.style("display", "block").style("opacity", 0);
      });


    /************* mouserover rect **********************/
    g.append("rect")
      .attr("height", this._config.height - this._config.margin_top - this._config.margin_bottom)
      .attr("class", "mousehover-bars")
      .attr("width", this._xscaleouter.bandwidth())
      .style("opacity", 0)
      .style("fill", "#fff")
      .attr("id", (d: HbarData) => {
        return this._config.container.replace("#", "") + (d.key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "");
      })

      .on("mouseover", (d: any, d1: HbarData) => {
        this._tooltip.style("display", "block").style("opacity", 1);
        this.Tooltip(d, d1.data, d1.key);
      })
      .on("click", (d: any, val: HbarData) => {
        //console.log(this._config.container.replace("#", "") + (val.key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, ""))
        if (this.barclick) {
          if (this.selectedBar == val.key) {
            this._svg.selectAll(".bars").style("opacity", 1);
            this._svg.selectAll(".mousehover-bars").style("opacity", 0);
            this.selectedBar = undefined;
            this.isBarSelected = false;
            if (this.deselectbar) {
              this.deselectbar();
            }
          } else {
            this.selectedBar = val.key;
            this.isBarSelected = true;

            let obj = {}
            obj["data"] = val;
            obj["id"] = this._config.container.replace("#", "") + "@@" + (val.key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "");

            this.barclick(obj);
            this.ClickBulkDisable(val);
          }
        }
      })
      .on("mousemove", (d: any, d1: HbarData) => {
        this.Tooltip(d, d1.data, d1.key);
      })
      .on("mouseout", (d: any) => {
        this._tooltip.style("display", "block").style("opacity", 0);
      });

    /************* Bar Text **********************/



    let btext = bargroup.selectAll(".bar-text")
      .data((d: HbarData) => {
        return d.data;
      })
      .enter()
      .append("text")
      .on("click", (d: any, val: HData) => {
        if (this.singlebarclick) {
          if (this.selectedBar == val.header) {
            this.selectedBar = undefined;
            this._svg.selectAll(".bars").style("opacity", 1);
            this.selectedBar = undefined;
            if (this.deselectbar) {
              this.deselectbar();
            }
            this.isBarSelected = false;
          }
          else {
            this.selectedBar = val.header;
            this.isBarSelected = true;
            this.ClickDisable(val);
            this.singlebarclick(val);
          }
        }
      })
      .attr("class", "v-stacked-bar-text")
      .attr("transform", (d: HData) => {
        return `translate(${(this._xscaleinner(d.key) + this._xscaleinner.bandwidth() / 2)},${this._yscale(d.value + d.startvalue) + ((this._yscale(d.startvalue) - this._yscale(d.value + d.startvalue)) / 2) + 3})`
      })
      .attr("text-anchor", "middle")
      .text((d: HData) => {
        return this._yscale(0) - this._yscale(d.value) < 20 ? "" : d.value.toString() + (this._config.format ? this._config.format : "");
      });//.attr("fill", "#fff");//.attr("stroke-width", "#fff")

    if (this.config.bar_text_size) {
      btext.style("font-size", this.config.bar_text_size.toString() + "px").style("font-weight", "bold")
    }

  }

  private Tooltip(d: any, d1: HData[], headerKey: string) {
    let w = this._config.width - this._config.margin_left - this._config.margin_right;
    this._tooltip.style("top", (d.layerY + 30) + 'px')
      .style("left", (w < d.layerX + 250 ? d.layerX - 100 : d.layerX) + 'px')
      .style("display", "block").style("opacity", 1).style("height", "auto")
      .html(() => {
        let html = "";
        /*For group Bar charts*/
        if (d1.length > 1) {
          html += `<div class="header">${headerKey}</div>`;
          html += "<div class='body'>";
          for (var i = 0; i < d1.length; i++) {
            let data = d1[i];
            html += this.SingleBar(data, data.key);

            if (this.config.tooltip_keys && this.config.tooltip_keys.length > 0
              && d1[i].additional_data && this.config.tooltip_keys.length >= i) {
              //for (var j = 0; j < this.config.tooltip_keys.length; j++) {
              for (var ij = 0; ij < this.config.tooltip_keys.length; ij++) {
                //let g = this.config.tooltip_keys[i];
                let g = this.config.tooltip_keys[ij];
                if (d1[i].additional_data[g.display_key]) {
                  html += `<div class="additional-text" style="padding:5px;">${g.display_text} : ${d1[i].additional_data[g.display_key]}</div>`;
                }
              }
            }

            if (this.config.tooltip_keys && this.config.tooltip_keys.length > 0 && data.additional_data) {
              html += "<div class='separater'></div>"
            }
          }
        }
        else {
          html += `<div class="header">${headerKey}</div>`;
          /*For single Bar charts*/
          let data = d1[0];
          html += "<div class='body'>" + this.SingleBar(data, data.key);


          if (this.config.tooltip_keys && this.config.tooltip_keys.length > 0 && d1[0].additional_data) {
            for (var j = 0; j < this.config.tooltip_keys.length; j++) {
              let g = this.config.tooltip_keys[j];
              html += `<div class="additional-text" style="padding:5px;">${g.display_text} : ${d1[0].additional_data[g.display_key]}
            ${(this._config.format ? this._config.format : "")}</div>`
            }
          }
        }
        return html + "</div>";
      });
  }
  /* Tooltip single bar */
  private SingleBar(data: HData, key: string) {
    let html = "";

    //for (var i = 0; i < d1.data.length; i++) {
    //let data = d1.data[i];
    if (data && data?.value != null && data?.value != undefined) {
      html += `<div style="display:flex;text-align:center;">
                            <div style="background-color:${this.config.color_scale ? this.config.color_scale(this.config.is_linear_color ? data.value : data.key) : data.color};" class="marker"></div>
                            <div class="marker-text">${key == "" ? "" : key + ":"}</div>
                            <div class="marker-value">${data.value}${(this._config.format ? this._config.format : "")}</div>
                         </div>`;



      // }
    }
    return html;
  }
  ClickBulkDisable(head: HbarData) {
    let h = this._config.container.replace("#", "") + (head.key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "");
    this._svg.selectAll(".mousehover-bars").each((child: any) => {
      let h1 = this._config.container.replace("#", "") + (child.key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "");
      if (h == h1) {
        d3.select("#" + h1).style("opacity", 0);
      }
      else {
        d3.select("#" + h1).style("opacity", 0.7);
      }
    })
  }
  ClickDisable(head: HData) {
    let h = this._config.container.replace("#", "") + (head.header + head.key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "");
    this._svg.selectAll(".bars").each((child: any) => {
      let h1 = this._config.container.replace("#", "") + (child.header + child.key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "");
      if (h == h1) {
        d3.select("#" + h1)
          .style("opacity", 1);
      }
      else {
        d3.select("#" + h1)
          .style("opacity", 0.2);
      }
    })
  }
}
