<div #screen>
  <div class="row">
    <div class="col-7" id="barDiv">

    </div>
  </div>
  <div class="sticky-top">
    <app-filters (onfilter)="OnFilter($event)" [reportName]="'provider'"></app-filters>
  </div>
  <div class="container-xl">
    <!-- Page title -->
    <div class="page-header d-print-none">
      <div class="row g-2 align-items-center">
        <div class="col-10">
          <!-- Page pre-title -->
          <div class="page-pretitle">

          </div>
          <h2 class="page-title">
            Provider Comparison for&nbsp;<span *ngIf="filters?.isSelectAll">All</span>&nbsp;<span *ngIf="filters!=undefined"> {{filters?.staffRole}}s&nbsp; {{filters?.staffRole=="All"?'Services':''}}</span>

            &nbsp;
            &nbsp;
            &nbsp;
            <app-download-dialog (download)="DownloadCallBack($event)" (sendemail)="SendEmailCallBack($event)" [reporttype]="'ProviderComparison'"></app-download-dialog>
          </h2>
        </div>
        <div class="col-2" style="text-align:right;">
          <a title="Download Image" (click)="downloadImage()" style="cursor:pointer;"
             aria-label="Download Image">
            <!-- Download SVG icon from http://tabler-icons.io/i/file-download -->
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="12" y1="11" x2="12" y2="17" /><polyline points="9 14 12 17 15 14" /></svg> Image
          </a>
        </div>
        <!-- Page title actions -->
        <!--<div class="col-12 col-md-auto ms-auto d-print-none">
          <div class="btn-list">
          </div>
        </div>-->
      </div>
    </div>
  </div>
  <div class="page-body">

    <div class="container-xl">

      <div class="row">
        <div class="col-xl-2 col-md-4 col-sm-12">
          <div class="font-weight-medium">
            Metric Category
          </div>
          <div class="text-muted">
            <select class="form-select" [(ngModel)]="selectedMetricCategory" (change)="GetProviderComparisonMetrics()" style=" padding: 9px 10px !important;">
              <option value="Operational" selected>Operational</option>
              <option value="Quality">Quality</option>
            </select>
          </div>
        </div>

        <div class="col-xl-8 col-md-8 col-sm-12">
          <div class="font-weight-medium">
            Metrics
          </div>
          <div class="text-muted">

            <ng-multiselect-dropdown [placeholder]="'Select Metric'"
                                     [settings]="metricsMultiSettings"
                                     [data]="ProviderComparisonMetrics"
                                     [(ngModel)]="ProviderComparisonSelectedMetrics"
                                     (onSelect)="OnSelect($event)" (onDeSelect)="OnDeSelect($event)">

              <!--(ngModelChange)="getchangeofMetrics(ProviderComparisonSelectedMetrics);"-->

            </ng-multiselect-dropdown>
          </div>
        </div>
      </div>
      <br />
      <div *ngIf="ProviderComparisonSelectedMetrics != undefined">

        <div *ngFor="let m of ProviderComparisonSelectedMetrics; trackBy:identify">
          <div class="row">
            <div class="card-header metrictitle"><h3 class="card-title">{{m.MetricName}}</h3></div>


          </div>
          <div class="row" style="padding-top:5px;">
            <div *ngIf="(GetProviderComparisonTableData(m.Metric))?.length>0" class="col-7">
              <div class="row">
                <div class="col">
                  <table class="casemixgraphic">
                    <thead>
                      <tr>
                        <th> </th>
                        <th *ngFor="let s of GetProviderComparisonTableData(m.Metric)">{{s.DateWindow}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{{GetMetricUnit(m.Metric)}}</th>                        
                        <td *ngFor="let s of GetProviderComparisonTableData(m.Metric)">{{s.MetricValue}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div *ngIf="isChartView==true">
                    <app-simple-bar [container]="'#providerCompBar' + m.Metric"
                                    [barData]="GetProviderComparisonBarData(m.Metric, m.MetricUnit)"
                                    [configuration]="configProviderCompBar"
                                    *ngIf="GetProviderComparisonBarData(m.Metric, m.MetricUnit)?.length>0">
                      <div id="{{'providerCompBar'+m.Metric}}" [ngStyle]="{'width': barWidth}" class="test"></div>
                    </app-simple-bar>
                  </div>
                </div>
              </div>

            </div>
            <div class="metricdescription col-5" [innerHtml]="GetMetricDescription(m.Metric)"></div>
          </div>
          <div class="row">


            <ng-container *ngIf="metricTable">

              <div class="providerrankcolumn col-6" *ngFor="let providerlist of metricTable[m.Metric]">
                <table class="casemixgraphic">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>{{GetMetricUnit(m.Metric)}}</th>
                      <th>Denom</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let p of providerlist">
                      <td>{{p.StaffOrService}}</td>
                      <td>{{p.MetricValue}}</td>
                      <td>{{p.Denom}}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

