// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //production: false,
  //IsLog: true,
  //IsProd: false,
  //IquestDashBoardURL: "http://localhost:4300/dashboard/",


  //apiURL: "http://localhost:49779/api/",
  //auditLogURL: "https://orbis.tch-dom.local:9990/api/",
  //AppId: "29993D74-B6BB-4C77-B2FF-10755A93459C",
  //loginURL: "http://dc1vtperimatic1:9990/login?appid=29993D74-B6BB-4C77-B2FF-10755A93459C",


  /*Prod Env*/
  apiURL: "/api/",
  loginURL: "https://th-perimatics-orbis-ad-login-test-hjhqb4efeecqbagc.canadacentral-01.azurewebsites.net/login?appid=29993d74-b6bb-4c77-b2ff-10755a93459c",
  AppId: "29993d74-b6bb-4c77-b2ff-10755a93459c",
  IsLog: true,

  IsProd: true,
  production: true,
  auditLogURL: "https://th-perimatics-orbis-ad-login-test-hjhqb4efeecqbagc.canadacentral-01.azurewebsites.net/api/",
  IquestDashBoardURL: "https://th-perimatics-orbis-hffhcqbyb7bpcpbf.canadaeast-01.azurewebsites.net/dashboard/"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
