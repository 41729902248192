import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bm-overview',
  templateUrl: './bm-overview.component.html',
  styleUrls: ['./bm-overview.component.css']
})
export class BmOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
