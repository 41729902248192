import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class Line {

    private config: any = {
        width: 210,
        height: 120,
        margin_top: 25,
        margin_right: 20,
        margin_bottom: 25,
        margin_left: 40,
        chartlabel: "ORBIS Report",
        yrange: "percentage",
        field: "PassPercent",

        minrange: 0,
        maxrange: 100,

        transitionMs: 750,
        barColorFn: d3.interpolateHsl(d3.rgb('#cc1c16'), d3.rgb('#62ce48'))
    };

    private svg: any = undefined;

    private mincount: any = undefined;
    private maxcount: any = undefined;
    private xScale: any = undefined;
    private yScale: any = undefined;
    private filter: any = undefined;

    private container: string;
    private json: any;
    private configuration: any;
    private scalevalue: any;
    private pointer: any;

    constructor(container: string, json: any, configuration: any, scalevalue: any) {
        this.container = container;
        this.json = json;
        this.configuration = configuration;
        this.scalevalue = scalevalue;
        this.configure(this.configuration);
    }


    private configure(configuration: any) {
        var prop = undefined;
        for (prop in configuration) {
            this.config[prop] = configuration[prop];
        } 
        this.mincount = 0;

        //get the max number of cases meeting any pass percentage for high end of range
        this.maxcount = d3.max(this.json, (d) => {
            return d['NumCases'];
        });
        
        //scale pass percentage (0 to 100) to x-axis
        this.xScale = d3.scaleLinear()
            .range([this.config.margin_left, this.config.width - this.config.margin_right])
            .domain([this.config.minrange, this.config.maxrange]);

        //scale case counts to y-axis
        this.yScale = d3.scaleLinear()
            .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
            .domain([this.mincount, this.maxcount]);
    }

    Render() {
        this.draw();
    }
    private draw() {

        //create svg to draw the line graph
        this.svg = d3.select(this.container)
            .append("div")
            .classed("linegraphcontainer", true)
            //.style("height", this.config.height + "px")
            .append('svg:svg')
            .attr('viewBox', "0 0 " + this.config.width + " " + this.config.height)
            .attr('preserveAspectRatio', "xMinYMin meet")
            .classed("svg-content-responsive", true);

        //append the x-axis
        this.svg.append("g")
            .attr("class", "reportaxis")
            .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
            .call(d3.axisBottom(this.xScale)
                .ticks(5));

        //append the y-axis
        this.svg.append("g")
            .attr("class", "reportaxis")
            .attr("transform", "translate(" + this.config.margin_left + ",0)")
            .call(d3.axisLeft(this.yScale)
                .ticks(2));

        //define area to draw under the curve
        var area = d3.area()
            .x((d) => { return this.xScale(d['PassPercent']); })
            .y0(this.config.height - this.config.margin_bottom)
            .y1((d) => { return this.yScale(d['NumCases']); })
            .curve(d3.curveBasis);

        //apply a gradient to represent pass percent - red for lower values and green for higher values
        this.svg.append("linearGradient")
            .attr("id", this.container + "gradient")
            .attr("gradientUnits", "userSpaceOnUse")
            .attr("x1", this.xScale(0)).attr("y1", 0)
            .attr("x2", this.xScale(100)).attr("y2", 0)
            .selectAll("stop")
            .data([
                { offset: "0%", color: "#cc1c16" },
                { offset: "50%", color: "#f4e242" },
                { offset: "100%", color: "#62ce48" }
            ])
            .enter().append("stop")
            .attr("offset", (d) => { return d.offset; })
            .attr("stop-color", (d) => { return d.color; });

        //draw the area under the curve
        this.svg.append('path')
            .attr('d', area(this.json))
            .attr('fill', 'url(#' + this.container + 'gradient)');

        //if scalevalue is specified, draw a line with pointer indicating point on the distribution
        if (this.scalevalue >= 0) {
            this.pointer = this.svg.append('g')
                .attr('class', 'scaleline');

            this.pointer.append('line')
                .attr('x1', this.config.margin_left)
                .attr('y1', this.config.height - this.config.margin_bottom)
                .attr('x2', this.config.margin_left)
                .attr('y2', this.config.margin_top);

            var lineData = [[this.config.margin_left - 5, this.config.margin_top - 10], [this.config.margin_left + 5, this.config.margin_top - 10], [this.config.margin_left, this.config.margin_top]];

            var lineFunction = d3.line()

            this.pointer.append('path')
                .data([lineData])
                .attr('d', lineFunction)
                .attr('class', 'scalepointer');

            //move pointer from y-axis to end point in the distribution
            this.animate(this.scalevalue === undefined ? 0 : this.scalevalue, undefined);

            //draw label with value
            this.svg.append('text')
                .attr("class", "simplebarvalue")
                .style("text-anchor", "middle")
                .attr("x", this.xScale(this.scalevalue))
                .attr("y", this.config.margin_top - 15)
                .text(this.scalevalue + '%')
        }

    }
    private animate(scalevalue: any, newConfiguration: any) {
        if (newConfiguration !== undefined) {
            this.configure(newConfiguration);
        }

        var moveto = this.xScale(scalevalue) - this.config.margin_left;

        this.pointer.transition()
            .duration(this.config.transitionMs)
            .attr('transform', 'translate(' + moveto + ')');
    }
    public update(json: any, newConfiguration: any) {
        if (newConfiguration !== undefined) {
            this.configure(newConfiguration);
        }

        this.json = json;
        d3.select("" + this.container).select("svg").remove();
        this.draw();
    }
}
