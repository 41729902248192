import { DisplayModel } from "./commonmodel";

export class LineConfig {
  height: number = 500;
  width: number = 1000;
  margin_top: number = 10;
  margin_bottom: number = 50;
  margin_left: number = 70;
  margin_right: number = 10;
  data: Array<LineModel> = [];
  container: string = "";
  tick_size_y: number = 5;
  tick_size_x: number = 5;
  is_line_text: boolean = false;
  xlabel: string = "x Label";
  ylabel: string = "y Label";
  xaxistype: "number" | "datetime" | "string" = "number";
  zoomtype: "brush" | "zoom" | "none" = "brush";
  yaxisformat: any = null;
  tooltip_keys: DisplayModel[] = [];
  datetimeformat: string = "%b %d";
  format: string = "";
  //markerstyle: Array<MarkerStyle> = [new MarkerStyle()];
  //linestyle: Array<LineStyle> = [new LineStyle()];

  //lineselection: any;
}
export class LineStyle {
  style: "none" | "curvestepafter" | "curvestep" | "curevestepbefore" = "none";
  linestyle: "none" | "dashdot" | "dashed" | "dotted" = "none";
  color: string = "steelblue";
  width: number = 1;
}
export class MarkerStyle {
  shape: "none" | "rect" | "circle" | "triangle" | "plus" | "diamond" = "none";
  size: number = 7;
  stroke: string = "steelblue";
  fill: string = "#fff";
}
export class LineModel {
  key: string = "";
  values: Array<LineValues> = [];
  linestyle: LineStyle = new LineStyle();
  markerstyle: MarkerStyle = new MarkerStyle();
}

export class LineValues {
  value: number = 0;
  xaxis: any | number;
  additional_data: any = {};
  isdummyvalue: boolean = false;

  y1: number = 0;
  y2: number = 0;
  key: string = "";
}
