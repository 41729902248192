import * as d3 from 'd3';


export class Pie {

  private container: string | undefined;
  private piechartdata: any;
  private configuration: any;
  private scale: any;
  private color: any | undefined;
  private pie: any | undefined;
  private path: any | undefined;
  private label: any | undefined;
  private label2: any | undefined;
  private svg: any | undefined;
  private g: any | undefined;
  private l: any | undefined;
  private highlightoffset: any | undefined;

  private config: any = {
    width: 240,
    height: 150,
    radius: 60,
    margin_left: 7,
    margin_right: 100,
    margin_top: 15,
    margin_bottom: 15,
    piemetric: "PatientAge",
    highlight: "",
    roundpercent: false,
    colormap: {},
    showlegend: true
  };

  constructor(container: string, piechartdata: any, configuration: any, scale: any) {
    this.scale = scale;
    let h = piechartdata.length * 25;
    if (h > this.config.height) {
      this.config.height = h;
    }
    this.container = container;
    this.piechartdata = piechartdata;
    this.configuration = configuration;
    this.configure(this.configuration);
  }
  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    //array of colors to use in the pie chart
    this.color = this.scale ?? d3.scaleOrdinal(["#9b3228", "#708748", "#514263", "#537dc1", "#c67d29", "#2dbee2", "#806a93", "#206d14", "#9d4ca3", "#5e99a8", "#a04c03", "#030668", "#7c6601", "#513839"]);

    //generate the pie function based on the payer percentage
    this.pie = d3.pie()
      .sort(null)
      .value((d: any) => { return d.PiePercent });

    //generate the arc function for each piece of the pie
    this.path = d3.arc()
      .outerRadius(this.config.radius)
      .innerRadius(0);

    //generate the arc function for the label in each piece of the pie
    this.label = d3.arc()
      .outerRadius(this.config.radius - 25)
      .innerRadius(this.config.radius - 25);

    this.label2 = d3.arc()
      .outerRadius(this.config.radius + 10)
      .innerRadius(this.config.radius + 10);

    this.highlightoffset = d3.arc()
      .outerRadius(20)
      .innerRadius(20)
  }

  Render() {
    this.draw();
  }

  private draw() {

    this.svg = d3.select("" + this.container)
      .append("div")
      .classed("piechartcontainer", true)
      .append('svg:svg')
      .attr('viewBox', "0 0 " + this.config.width + " " + this.config.height)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    //move center of pie to middle of the svg
    this.g = this.svg.append("g")
      .attr("transform", "translate(" + (this.config.margin_left + this.config.radius) + "," + (this.config.radius + this.config.margin_top) + ")");

    //create the arcs for the each pie piece
    var arc = this.g.selectAll(".arc")
      .data(this.pie(this.piechartdata))
      .enter().append("g")
      .attr("class", "arc")
      .attr("transform", (d: any) => { if (d.data.PieValue == this.config.highlight) { return "translate(" + this.highlightoffset.centroid(d) + ")"; } else { return "translate(0,0)"; } });

    //color each piece with a different color
    arc.append("path")
      .attr("class", (d: any) => { if (d.data.PieValue == this.config.highlight) { return "piecharthighlight"; } else { return "piechartpath" } })
      .attr("fill", (d: any) => {  return this.color(d.data.PieValue); })
      // .attr("d", this.path)
      .transition()
      .delay((d: any, i: number) => { return i * 300; })
      .duration(300)
      .attrTween('d', (d: any) => {
        var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return (t: any) => {
          d.endAngle = i(t);
          return this.path(d);
        }
      });

    //add the percentage label to each piece
    //arc.append("text")
    //  .attr("class", "piechartlabel1")
    //  .attr("transform", (d: any) => { return "translate(" + this.label.centroid(d) + ")"; })
    //  .attr("dy", "0.35em")
    //  .text((d: any) => { if (d.data.PiePercent < (3 / this.config.radius * 100)) return ""; else return (this.config.roundpercent ? Math.round(d.data.PiePercent) : d.data.PiePercent) + "%"; })

    //arc.append("text")
    //  .attr("class", "piechartlabel2")
    //  .attr("transform", (d: any) => { return "translate(" + this.label2.centroid(d) + ")"; })
    //  .attr("dy", "0.35em")
    //  .text((d: any) => { if (d.data.PiePercent > 0 && d.data.PiePercent < (3 / this.config.radius * 100)) return (this.config.roundpercent ? Math.round(d.data.PiePercent) : d.data.PiePercent) + "%"; else return ""; })

    //Add legend
    if (this.config.showlegend) {
      this.l = this.svg.append("g")
        .attr("transform", "translate(" + (this.config.width - this.config.margin_right - 6) + "," + this.config.margin_top + ")");

      this.l.selectAll("rect")
        .data(this.piechartdata)
        .enter().append("rect")
        .attr("x", 0)
        .attr("y", (d: any, i: number) => { return i * 22; })
        .attr("fill", (d: any) => { return this.color(d.PieValue); })
        .attr("width", 35)
        .attr("height", 20);

      this.l.selectAll("text")
        .data(this.piechartdata)
        .enter().append("text")
        .attr("x", 37)
        .attr("y", (d: any, i: number) => { return i * 22 + 13; })
        .attr("class", "linelegend")
        .text((d: any) => { return d.PieValue; });

      this.l.selectAll(".piechartlabel1")
        .data(this.piechartdata)
        .enter().append("text")
        .attr("class", "piechartlabel1")
        .attr("text-anchor", "middle")
        .attr("x", 16)
        .attr("y", (d: any, i: number) => { return i * 22 + 13; })
        .text((d: any) => {
          return (this.config.roundpercent ? Math.round(d.PiePercent) : d.PiePercent) + "%";
        });
    }
  }

  public update(updatePieChartData: any, newConfiguration: any) {

    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }

    this.piechartdata = updatePieChartData;
    d3.select("" + this.container).select("svg").remove();
    this.draw();
  }
}
