import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';

@Component({
  selector: 'app-case-volume-forecast',
  templateUrl: './case-volume-forecast.component.html',
  styleUrls: ['./case-volume-forecast.component.css']
})
export class CaseVolumeForecastComponent implements OnInit {
  forecastDates: any;
  forecastByService: any;
  volumeByWeek: any;
  volumeDetail: any;

  forecastDateStart: Date;
  forecastDateEnd: Date;
  uniqueDates: any;
  uniqueServices: any;
  filters: Filters;
  sortObjects: any = {};
  sortColumn: string = 'CaseDate';
  sortOrder: string = 'ASC';

  configServiceBar: any;

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
  }

  OnFilter(e: Filters) {
    this.filters = e;

    this.forecastDates = undefined;
    this.forecastByService = undefined;
    this.volumeByWeek = undefined;
    this.volumeDetail = undefined;
    this.uniqueDates = undefined;
    this.uniqueServices = undefined;

    this.configServiceBar = { height: 230, width: 750, margin_top: 40, margin_right: 30, margin_left: 40, yaxis_ticks: 3, margin_bottom: 60, units_label: "", targetline: 0, value1style: "forecastlabel", labeloffset: 10, container: "forecastcontainer", highlightvalue: e.surgeService, highlightcolor: "#c97f16"};

    this.commonService.GetCaseVolumeForecast(e.facility, e.location, e.surgeService).subscribe(d => {
      this.forecastDates = d["forecastDates"];
      this.forecastByService = d["forecastByService"];
      this.volumeByWeek = d["volumeByWeek"];
      this.volumeDetail = d["volumeDetail"];

      this.forecastDateStart = this.forecastDates.length > 0 ? this.forecastDates[0]["ForecastDateStart"] : undefined;
      this.forecastDateEnd = this.forecastDates.length > 0 ? this.forecastDates[0]["ForecastDateEnd"] : undefined;

      this.uniqueDates = [...new Set(this.volumeDetail.map(d => d.DOS))].sort();
      this.uniqueServices = [...new Set(this.volumeDetail.map(d => d.SurgService))].sort();
    })   
  }

  GetForecastTableData(DOS: Date, SurgService: string) {

    if (this.volumeDetail != undefined) {
      var x = this.volumeDetail.filter(v => v.DOS == DOS && v.SurgService == SurgService);
      if (x != undefined && x.length > 0) {
        return x;
      }
    }
    return [];
  }
}
