import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Filters } from '../Filters';
import { CommonService } from '../common.service';
import { Page } from '../Page';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-case-log',
  templateUrl: './case-log.component.html',
  styleUrls: ['./case-log.component.css']
})
export class CaseLogComponent implements OnInit {
  caselogs: any;
  ORBISClosedCaseFields: any;
  ORBISClosedCase: any;
  metricfields: any;
  filters: Filters;
  totalrecords: number = 0;
  page: Page;
  //isAgreed: boolean = false;
  sortObjects: any = {};
  sortColumn: string = 'CaseDate';
  sortOrder: string = 'ASC';
  // password: string = "";
  //useragree: string;
  //emailresult: string;
  //@ViewChild('closeModal') private closeModal: ElementRef;

  constructor(private commonService: CommonService, private toastr: ToastrService) {
    this.page = new Page();
    this.page.currentPage = 1;
    this.page.recordsPerPage = 100;
  }

  ngOnInit(): void {
  }
  PageEvent(e: Page) {
    this.page = e;
    this.LoadData();
  }
  LoadData() {
    this.caselogs = undefined;
    this.ORBISClosedCaseFields = undefined;
    this.metricfields = undefined;
    this.totalrecords = 0;
    this.commonService.GetCaseLog(this.filters.facility, this.filters.location, this.filters.surgeService, this.filters.staffRole, this.filters.provider, this.filters.fromDate, this.filters.toDate, this.page.currentPage, this.page.recordsPerPage, this.sortColumn, this.sortOrder).subscribe(d => {
      this.caselogs = d["caselogs"];
      this.ORBISClosedCaseFields = d["ORBISClosedCaseFields"];
      this.metricfields = d["MetricFields"];
      if (this.caselogs != undefined && this.caselogs != null && this.caselogs.length > 0)
        this.totalrecords = d["caselogs"][0]["TotalRecords"];
    });
  }
  OnFilter(e: Filters) {
    this.filters = e;
    this.page.currentPage = 1;
    this.LoadData();
  }
  getORBISClosedCaseFields(field: string) {
    return this.ORBISClosedCaseFields.filter(c => c.Field == field)[0].FieldName;
  }
  getORBISClosedCaseFieldValue(fieldname: string) {
    return this.ORBISClosedCase[0][fieldname];
  }
  Sort(f) {
    var field = this.ORBISClosedCaseFields.filter(c => c.Field == f)[0].Field;
    this.sortColumn = field;
    this.sortOrder = this.sortObjects[f] == "ASC" ? "DESC" : "ASC";
    this.sortObjects[f] = this.sortOrder;
    Object.keys(this.sortObjects).forEach(k => {
      if (k != f) {
        this.sortObjects[k] = undefined;
      }
    });

    this.metricfields.filter(c => c.Field == f)[0].sortOrder = this.sortOrder;

    this.metricfields = this.metricfields;
    this.page.currentPage = 1;
    this.LoadData();
  }

  DownloadCallBack(password: string) {
    this.commonService.DownloadCaseLog(this.filters.facility, this.filters.location, this.filters.surgeService, this.filters.staffRole, this.filters.provider, this.filters.fromDate, this.filters.toDate, password);
  }
  SendEmailCallBack(password: string) {
    this.commonService.EmailCaseLog(this.filters.facility, this.filters.location, this.filters.surgeService, this.filters.staffRole, this.filters.provider, this.filters.fromDate, this.filters.toDate, password)
      .subscribe(res => {
        if (res == "NO") {
          this.toastr.warning("Email is not configured");
        }
        else {
          this.toastr.success(`Email sent to ${res}. It can take up to 5 minutes for email delivery to complete.`);
        }
      });
  }

  //async SendEmail() {

  //  var response = await this.commonService.EmailCaseLog(this.filters.facility, this.filters.location, this.filters.surgeService, this.filters.staffRole, this.filters.provider, this.filters.fromDate, this.filters.toDate, password);
  //  //if (response) {
  //  //  this.emailresult = response.toString();
  //  //}    
  //}  

  //async GetConfirmedUser() {
  //  var response = await this.commonService.GetUsersConfirmation("DOWNLOADAGREE");
  //  if (response) {
  //    this.useragree = response.user;
  //  } 
  //}

  //async SetConfirmedUser() {
  //  await this.commonService.SetUsersConfirmation("DOWNLOADAGREE");

  //  var response = await this.commonService.GetUsersConfirmation("DOWNLOADAGREE");
  //  if (response) {
  //    this.useragree = response.user;
  //  }
  //}

  //CheckPasswordDownload(email: boolean) {

  //  if (this.password.split(' ').join('') == "") {
  //    this.toastr.error("please enter the password");
  //    return;
  //  }
  //  this.isAgreed = false;

  //  this.Download(email);
  //}


  //async Download(email: boolean) {    
  //  await this.GetConfirmedUser();

  //  if (this.useragree && this.useragree.length > 0) {

  //    if (email) {
  //      this.toastr.success("Generating Excel File");
  //      await this.SendEmail();
  //      if (this.emailresult.startsWith("Error")) {
  //        this.toastr.error(this.emailresult);
  //      }
  //      else {
  //        this.toastr.success(this.emailresult);
  //      }          
  //    }
  //    else {
  //      this.commonService.DownloadCaseLog(this.filters.facility, this.filters.location, this.filters.surgeService, this.filters.staffRole, this.filters.provider, this.filters.fromDate, this.filters.toDate, this.password);
  //    }
  //    this.password = "";
  //  }
  //  else {
  //    document.getElementById("openModalButton").click();
  //  }          

  //  this.closeModal.nativeElement.click();

  //}

  //CheckPasswordAgreeAndDownload(email: boolean) {
  //  if (this.password.split(' ').join('') == "") {
  //    this.toastr.error("please enter the password");
  //    return;
  //  }
  //  this.isAgreed = false;
  //  document.getElementById("closeModalButton").click();    
  //  this.AgreeAndDownload(email);
  //}

  //async AgreeAndDownload(email: boolean) {   

  //  await this.SetConfirmedUser();

  //  if (this.useragree && this.useragree.length > 0) {      

  //    if (email) {
  //    this.toastr.success("Generating Excel File");
  //      await this.SendEmail();
  //      if (this.emailresult.startsWith("Error")) {
  //        this.toastr.error(this.emailresult);
  //      }
  //      else {
  //        this.toastr.success(this.emailresult);
  //      }
  //    }
  //    else {
  //      this.commonService.DownloadCaseLog(this.filters.facility, this.filters.location, this.filters.surgeService, this.filters.staffRole, this.filters.provider, this.filters.fromDate, this.filters.toDate, this.password);
  //    }
  //    this.password = "";
  //  }     
  //}
}

