<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'workflow'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Workflow for &nbsp; <span *ngIf="filters!=undefined && filters.providerName!='All'">{{filters?.providerName}}</span> &nbsp;<span *ngIf="filters!=undefined && filters.providerName=='All'">All Surgeons</span>&nbsp;{{totalrecords}}&nbsp; Total Cases
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">
  <div class="container-xl">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-0 click-body" data-bs-toggle="modal" *ngIf="Workflow!=undefined">
          <button type="button" class="btn btn-primary" *ngIf="viewmode=='details'" (click)="ChangeView()">Overview</button>
          <button type="button" class="btn btn-primary" *ngIf="viewmode=='overview'" (click)="ChangeView()">Details</button>
          <div class="row">
            <div class="col-12" style="overflow-x: scroll">
              <app-workflowmap *ngIf="viewmode=='details'" [container]="'#workflowmap'" [viewmode]="viewmode" [workflowData]="Workflow" [surgeryScheduleData]="SurgerySchedule">
                <div id="workflowmap" style="margin-top: 50px;"></div>
              </app-workflowmap>

              <app-workflowmap *ngIf="viewmode=='overview'" [container]="'#workflowmap2'" [viewmode]="viewmode" [workflowData]="Workflow" [surgeryScheduleData]="SurgerySchedule">
                <div id="workflowmap2" style="margin-top: 50px;"></div>
              </app-workflowmap>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


