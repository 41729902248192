import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class TimeLine {

  private config: any = {
    margin_left: 60,
    margin_right: 20,
    margin_top: 20,
    margin_bottom: 50,
    bar_padding: 20,
    barWidth: 800,
    barHeight: 25
  };

  private width: any = undefined;
  private height: any = undefined;
  private x: any = undefined;
  private y: any = undefined;
  private svg: any = undefined;
  private view: any = undefined;
  private phases: Array<string>;

  private phasecolors: Array<string> = ["url(#hash4_4)", "#afc3d8", "#d6d19e", /*"#bca69c"*/ "#aca3b7", "#8bba91"];


  private container: string;
  private phasedata: any;
  private configuration: any;

  constructor(container: string, phasedata: any, configuration: any) {
    this.container = container;
    this.phasedata = phasedata;
    this.configuration = configuration;
    this.configure(this.configuration);

  }


  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    //get max total minutes among mean and median phase times to use for the y-axis scale
    this.phases = Object.keys(this.phasedata[0]).filter(f => f != "aggregate");// .map(f => { return f.metric; });// ["InRoomDelayTime", "InductionTime", "SurgeryPrepTime", "OperativeTime", "EmergenceTime"];
    var maxminutes = 0;

    this.phasedata.forEach((d, i) => {
      var totalminutes = 0;

      for (var j = 0; j < this.phases.length; j++) {

        totalminutes += d[this.phases[j]];
      } if (totalminutes > maxminutes) {
        maxminutes = totalminutes;
      }
    });

    //round up to nearest 20 minutes for graphic range        
    var maxrange = Math.ceil(maxminutes / 20) * 20;

    //calculate width and height of graphic based on configuration settings
    this.width = this.config.barWidth + this.config.margin_left + this.config.margin_right;

    //height for bars for mean and median plus padding at the top and bottom
    this.height = (this.config.barHeight + this.config.bar_padding * 2) * 2 + this.config.margin_top + this.config.margin_bottom;

    //map phase duration to width in the x-axis
    this.x = d3.scaleLinear()
      .range([this.config.margin_left, this.config.margin_left + this.config.barWidth])
      .domain([0, maxrange]);

    //map timeline for mean and median to space in the y-axis
    this.y = d3.scaleBand()
      .rangeRound([0, this.height - this.config.margin_bottom])
      .domain(this.phasedata.map(function (d) { return d.aggregate; }))
      .paddingOuter(0.5)
      .paddingInner(0.5);
  }

  Render() {
    this.draw();
  }
  private draw() {

    //create svg to fit both mean and median timelines
    this.svg = d3.select(this.container)
      .append("div")
      .classed("phasecontainer", true)
      .append('svg:svg')
      .attr('viewBox', "0 0 " + this.width + " " + this.height)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    //create pattern for hash marks for In Room Delay time
    var defs = this.svg.append("defs")
    var pattern = this.svg.append("pattern")
      .attr('id', 'hash4_4')
      .attr('width', 8)
      .attr('height', 8)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('patternTransform', 'rotate(60)');

    pattern.append("rect")
      .attr('width', 2)
      .attr('height', 8)
      .attr('transform', 'translate(0,0)')
      .attr('fill', '#d0d6e0');

    //append the x-axis - draw grid lines through graphic
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(0," + (this.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x)
        .tickSize(this.config.margin_bottom - this.height)
      );

    //append the y-axis
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(" + this.config.margin_left + ",0)")
      .call(d3.axisLeft(this.y)
        .tickSize(0)
      )
      .selectAll("text")
      .style("font-size", "16px");

    //draw the rects for each phase duration - stack them so they appear as a timeline rather than a bar chart
    // var phases = this.phasedata.map(f => { return f.metric; });// ["InRoomDelayTime", "InductionTime", "SurgeryPrepTime", "OperativeTime", "EmergenceTime"];
    var stack = d3.stack().keys(this.phases);
    var series = stack(this.phasedata);

    //draw the rects
    this.svg.append("g")
      .selectAll("g")
      .data(series)
      .enter().append("g")
      .attr("fill", (d, i) => { return this.phasecolors[i]; })
      .selectAll("rect")
      .data((d) => { return d; })
      .enter().append("rect")
      .attr("x", (d) => { return this.x(d[0]); })
      .attr("y", (d) => { return this.y(d.data.aggregate); })
      .attr("width", (d) => { if (d[1] > d[0]) return this.x(d[1]) - this.x(d[0]); return 0; })
      .attr("height", this.y.bandwidth());

    //draw labels with number of minutes in each rect
    this.svg.append("g")
      .selectAll("g")
      .data(series)
      .enter().append("g")
      .selectAll("text")
      .data((d) => { return d; })
      .enter().append("text")
      .attr("class", "phasetimetext")
      .attr("x", (d) => { return this.x(d[0]) + (this.x(d[1]) - this.x(d[0])) / 2; })
      .attr("y", (d) => { return this.y(d.data.aggregate) + this.y.bandwidth() / 2 + 5; })
      .style("text-anchor", "middle")
      .text((d) => { if (d[1] > d[0]) return d[1] - d[0]; return ""; });

    //draw label on the x-axis to specify Time (Min)
    this.svg.append("text")
      .attr("class", "phasetimelinelegend")
      .attr("x", this.width - 100)
      .attr("y", this.height - this.config.margin_bottom + 40)
      .text("Time (Min)");
  }

  public update(updateBarChartData: any, newConfiguration: any) {

    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }

    this.phasedata = updateBarChartData;
    d3.select("" + this.container).select("svg").remove();
    this.draw();
  }
}
