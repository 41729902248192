import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-table',
  templateUrl: './block-table.component.html',
  styleUrls: ['./block-table.component.css']
})
export class BlockTableComponent implements OnInit {

  constructor() { }
  @Input() data: Array<any> = [];

  ngOnInit(): void {
  }

  GetBackGroundColor(key: string, i: number, isApply: boolean) {
    if (key == "Total") {
      return { 'background-color': '#d8d5ad' };
    }
    else if (i % 2 == 0 && isApply) {
      return { 'background-color': '#bcb9b3' };
    }
    else if (isApply) {
      return { 'background-color': '#c2cfd6' };
    }
    else if (i % 2 == 0) {
      return { 'background-color': '#e8e7e3' };
    }
    else {
      return { 'background-color': '#f4f5f7' };
    }
  }
}
