import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class BarCompare {

  private config: any = {
    height: 400,
    width: 600,
    margin_top: 30,
    margin_right: 125,
    margin_bottom: 50,
    margin_left: 50,
    max: 100,
    yaxis_ticks: 0,
    container: "",
    defaultcolor: "#a7b8c6",
    colormap: {},
    categories: [],
    ylabel: "",
    xlabel: "",
    target: "80"
  };

  private svg: any = undefined;

  private barcolors: Array<any> =
    [
      { barcolor: "#a7b8c6", display: "hide" },
      { barcolor: "#2b506d", display: "hide" },
      { barcolor: "#6b4744", display: "hide" },
      { barcolor: "#547c6f", display: "hide" }
    ]

  private minrange: any = undefined;
  private maxrange: any = undefined;
  private x0: any = undefined;
  private y: any = undefined;
  private x1: any = undefined;
  private categoryGroup: any = undefined;
  private sliceGroup: any = undefined;

  private categoryids: any = undefined;
  private container: string;
  private json: any;
  private configuration: any;

  constructor(container: string, json: any, configuration: any) {

    this.container = container;
    this.json = json;
    this.configuration = configuration;
    this.configure(this.configuration);
  }

  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    //scale on x-axis for each category in the dataset
    this.x0 = d3.scaleBand()
      .range([this.config.margin_left, this.config.width - this.config.margin_right])

    //scale on x-axis for each slice within a category in the dataset
    //bars for all slices drawn in a category under each date 
    this.x1 = d3.scaleBand()
      .padding(0.15);

    //group the data by category
    this.categoryGroup = nest()
      .key((d: any) => {
        return d['Category'];
      })
      .entries(this.json);

    //group the data by group
    this.sliceGroup = nest()
      .key((d: any) => {
        return d['Slice'];
      })
      .entries(this.json);

    this.minrange = 0;

    this.maxrange = d3.max(this.json, (d: any) => {
      return d['Value'];
    });

    if (this.maxrange < this.config.max) {
      this.maxrange = this.config.max;
    }
    //scale data values on the y-axis
    this.y = d3.scaleLinear()
      .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
      .domain([this.minrange, this.maxrange])
  }

  Render() {
    this.x0.domain(values(this.categoryGroup).map(  (d:any)=> { return d['key']; }));
    this.x1.domain(values(this.sliceGroup).map((d: any) => { return d['key']; })).rangeRound([0, this.x0.bandwidth()]);
    //x0.domain(categoryGroup.map(function (d) { return d.Category; }))
    //x1.domain(d3.keys(sliceGroup)).rangeRound([0, x0.bandwidth()]);

    //Draw the image
    this.svg = d3.select(this.container)
      .append("div")
      .classed(this.config.container, true)
      .append('svg:svg')
      .attr('viewBox', "0 0 " + this.config.width + " " + this.config.height)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    //draw the title for the x-axis
    this.svg.append("text")
      .attr("class", "barchartlabel")
      .attr("text-anchor", "middle")
      .attr("transform", "translate(" + (this.config.margin_left + (this.config.width - this.config.margin_left - this.config.margin_right) / 2) + "," + (this.config.height - 10) + ")")
      .text(this.config.xlabel);

    //append the x-axis
    this.svg.append("g")
      .attr("class", "simplebaraxis")
      .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x0))
      .selectAll("text")
      .attr("transform", "rotate(20)")
      .style("text-anchor", "start");

    //draw the title for y-axis
    this.svg.append("text")
      .attr("class", "simplebarvalue")
      .attr("text-anchor", "middle")
      .attr("transform", "translate(10," + ((this.config.height - this.config.margin_bottom) / 2) + ")rotate(-90)")
      .text(this.config.ylabel);

    //append the y-axis
    this.svg.append("g")
      .attr("class", "reportaxis")
      .attr("transform", "translate(" + this.config.margin_left + ",0)")
      .call(d3.axisLeft(this.y)
        .tickFormat(d3.format("d"))
        .ticks(this.config.yaxis_ticks)
      );

    //display the bars for each location   
    //alert(JSON.stringify(caseDateGroup));
    this.categoryGroup.forEach((d: any, i: number) => {
      this.svg.append("g")
        .selectAll("g")
        .data(d.values)
        .enter().append("g")
        .attr("transform", (d: any) => { return "translate(" + this.x0(d.Category) + ",0)"; })
        .selectAll("rect")
        .data(d.values)
        .enter().append("rect")

        .attr("x", (d: any) => { return this.x1(d.Slice); })
        .attr("y", this.config.height - this.config.margin_bottom)
        .attr("width", this.x1.bandwidth())
        .attr("height", 0)
        .attr("fill", (d: any,i:number) => { return this.config.colormap && this.config.colormap.length > 0 ? this.config.colormap[i] : this.config.defaultcolor; })
        .transition()
        .duration(1500)
        .attr("y", (d: any) => { return this.y(d.Value); })
        .attr("height", (d: any) => { return this.config.height - this.config.margin_bottom - this.y(d.Value); });
    });

    this.categoryGroup.forEach((d: any, i: number) => {
      this.svg.append("g")
        .selectAll("g")
        .data(d.values)
        .enter().append("g")
        .attr("transform", (d: any) => { return "translate(" + this.x0(d.Category) + ",0)"; })
        .selectAll("text")
        .data(d.values)
        .enter().append("text")
        .attr("class", "simplebarvalue")
        .style("text-anchor", "middle")
        .style("font-size", "12px")
        .attr("x", (d: any) => { return this.x1(d.Slice) + (this.x1.paddingInner() + this.x1.bandwidth() / 2); })
        .attr("y", (d: any) => { return this.y(d.Value) - 10; })

        .style("opacity", 0)
        .text((d: any) => { return d.Label1; })
        .transition()
        .duration(1200)
        .delay(1200)
        .style("opacity", 1);
    });

    //add red dashed line for target
    if (parseInt(this.config.target) > 0) {
      this.svg.append("line")
        .attr("class", "targetline")
        .attr("x1", this.config.margin_left)
        .attr("x2", this.config.width - this.config.margin_right)
        .attr("y1", this.y(parseInt(this.config.target)))
        .attr("y2", this.y(parseInt(this.config.target)))
        .attr("fill", "none");

      //add target to legend
      this.svg.append("line")
        .attr("class", "targetline")
        .attr("x1", this.config.width - this.config.margin_right + 10)
        .attr("x2", this.config.width - this.config.margin_right + 30)
        .attr("y1", this.config.margin_top)
        .attr("y2", this.config.margin_top)
        .attr("fill", "none");

      //add target text to legend
      this.svg.append('text')
        .attr("class", "linelegend")
        .attr('x', this.config.width - this.config.margin_right + 35)
        .attr('y', this.config.margin_top + 5)
        .text("Target");
    }
    //});    

    //Add legend for each group by value
    this.config.categories.forEach((d: any, i: number) => {

      //draw box with color code
      this.svg.append('rect')
        .attr('x', this.config.width - this.config.margin_right + 10)
        .attr('y', this.config.margin_top + 20 * (i + 1) - 8)
        .attr('width', 20)
        .attr('height', 15)
        .attr('stroke-width', 2)
        .attr('fill', this.config.colormap[i])

      //draw text of group by value (e.g. Service)
      this.svg.append('text')
        .attr("class", "linelegend")
        .attr('x', this.config.width - this.config.margin_right + 35)
        .attr('y', this.config.margin_top + 20 * (i + 1) + 5)
        //.attr('fill', d)
        .text(d)

    });
  }
}
