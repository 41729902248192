import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { httpInterceptorProviders } from './http-interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxCaptureModule } from 'ngx-capture';
import { AppInitService } from './app-init.service';
import { OrsnapshotComponent } from './orsnapshot/orsnapshot.component';
import { D3chartsModule } from './d3charts/d3charts.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CaseMixComponent } from './case-mix/case-mix.component';
import { FiltersComponent } from './filters/filters.component';
import { TimestatComponent } from './timestat/timestat.component';
import { CaseLogComponent } from './case-log/case-log.component';
import { RuvComponent } from './ruv/ruv.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DistributionRankComponent } from './distribution-rank/distribution-rank.component';
import { PatientOutcomeComponent } from './patient-outcome/patient-outcome.component';
import { MetricDashboardComponent } from './metric-dashboard/metric-dashboard.component';
import { ProvidercompComponent } from './providercomp/providercomp.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AuthGuardService as AuthGuard } from '../app/auth-guard.service';
import { IQuestComponent } from './i-quest/i-quest.component';
import { PeriopQualityComponent } from './periop-quality/periop-quality.component';
import { PeriopOperationalComponent } from './periop-operational/periop-operational.component';
import { PeriopOutcomeComponent } from './periop-outcome/periop-outcome.component';
import { PicklistOptimizationComponent } from './picklist-optimization/picklist-optimization.component';
import { DemandForecastsComponent } from './demand-forecasts/demand-forecasts.component';
import { WorkflowComponent } from './workflow/workflow.component';
import { TabComponent } from './tab/tab.component';
import { TabsComponent } from './tab/tabs.component';
import { BlockutilizationComponent } from './blockutilization/blockutilization.component';
import { BlockTableComponent } from './block-table/block-table.component';
import { FinancialPortfolioComponent } from './financial-portfolio/financial-portfolio.component';

import { BmOverviewComponent } from './bm-overview/bm-overview.component';
import { BmActionsComponent } from './bm-actions/bm-actions.component';
import { BmCalendarViewComponent } from './bm-calendar-view/bm-calendar-view.component';
import { BmClaimReleaseComponent } from './bm-claim-release/bm-claim-release.component';
import { DefaultComponent } from './default/default.component';
import { HeaderComponent } from './header/header.component';
import { PicklistStandardizationComponent } from './picklist-standardization/picklist-standardization.component';
import { UsageCostComponent } from './usage-cost/usage-cost.component';
import { ScheduleSupplyComponent } from './schedule-supply/schedule-supply.component';
import { HomeComponent } from './home/home.component';
import { GlucoseManagementComponent } from './glucose-management/glucose-management.component';
import { TwoRoomSurgeonsComponent } from './two-room-surgeons/two-room-surgeons.component';
import { BlockScheduleComponent } from './block-schedule/block-schedule.component';
import { BlockRequestComponent } from './block-request/block-request.component';
import { ProviderReportComponent } from './provider-report/provider-report.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';
import { DynamicRecomendationsComponent } from './dynamic-recomendations/dynamic-recomendations.component';
import { FailedCasesComponent } from './failed-cases/failed-cases.component';
import { DatePipe } from '@angular/common';
import { SafePipe } from './safe-pipe.pipe';
import { DownloadDialogComponent } from './download-dialog/download-dialog.component';
import { CaseVolumeForecastComponent } from './case-volume-forecast/case-volume-forecast.component';
import { MetricComparisonComponent } from './metric-comparison/metric-comparison.component';
import { PeriopExecutiveComponent } from './periop-executive/periop-executive.component';
import { OrderByPipe } from './order-by-pipe.pipe';
import { FilterPipe } from './filter-pipe.pipe';
import { FacilitySummaryComponent } from './facility-summary/facility-summary.component';
import { ProductivityReportsModule } from './productivity-reports/productivity-reports.module';
import { D3NewModule } from './productivity-reports/d3-new/d3-new.module';
import { PeriopOutcomeMetricsModule } from './periop-outcome-metrics/periop-outcome-metrics.module';
import { PerivisionComponent } from './perivision/perivision.component';

@NgModule({
  declarations: [
    AppComponent,
    OrsnapshotComponent,
    CaseMixComponent,
    FiltersComponent,
    TimestatComponent,
    CaseLogComponent,
    RuvComponent,
    PaginationComponent,
    DistributionRankComponent,
    PatientOutcomeComponent,
    MetricDashboardComponent,
    ProvidercompComponent,
    UnauthorizedComponent,
    DefaultComponent,
    IQuestComponent,
    PeriopExecutiveComponent,
    PeriopQualityComponent,
    PeriopOperationalComponent,
    PeriopOutcomeComponent,
    PicklistOptimizationComponent,
    WorkflowComponent,
    DemandForecastsComponent,
    TabComponent,
    TabsComponent,
    BlockutilizationComponent,
    BlockTableComponent,
    PicklistStandardizationComponent,
    UsageCostComponent,
    ScheduleSupplyComponent,
    BmOverviewComponent,
    BmActionsComponent,
    BmCalendarViewComponent,
    BmClaimReleaseComponent,
    HomeComponent,
    HeaderComponent,
    FinancialPortfolioComponent,
    GlucoseManagementComponent,
    TwoRoomSurgeonsComponent,
    BlockScheduleComponent,
    BlockRequestComponent,
    ProviderReportComponent,
    RecommendationsComponent,
    DynamicRecomendationsComponent,
    FailedCasesComponent,
    SafePipe,
    DownloadDialogComponent,
    MetricComparisonComponent,
    CaseVolumeForecastComponent,
    OrderByPipe,
    FilterPipe,
    FacilitySummaryComponent,
    PerivisionComponent
  ],
  imports: [
    //PeriopOutcomeMetricsModule,
    //ProductivityReportsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    NgSelectModule,
    FormsModule,
    ToastrModule.forRoot(),
    NgxCaptureModule,
    BsDatepickerModule.forRoot(),
    D3chartsModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    PeriopOutcomeMetricsModule
  ],
  providers: [ToastrService, httpInterceptorProviders, AuthGuard, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
