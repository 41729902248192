<header class="navbar  navbar-light sticky-top d-print-none">
  <div class="container-xl">
    <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
      <a href=".">
        <img src="../assets/logo.PNG" width="110" height="32" alt="Tabler" class="navbar-brand-image">
      </a>
    </h1>
    <div class="navbar-nav flex-row order-md-last">
      <div class="nav-item d-none d-md-flex me-3">

      </div>

      <div class="nav-item dropdown">
        <div class="nav-link d-flex lh-1 text-reset p-0">
          <span class="avatar avatar-sm" style="background-image: url(./static/avatars/000m.jpg)"></span>
          <div class="d-none d-xl-block ps-2">
            <div>{{user}}</div>
            <div class="mt-1 small ">
              <a (click)="logout()" style="cursor:pointer !important">
                Logout
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</header>
