import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Filters } from '../Filters';
import { CommonService } from '../common.service';
import { Page } from '../Page';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-provider-report',
  templateUrl: './provider-report.component.html',
  styleUrls: ['./provider-report.component.css']
})
export class ProviderReportComponent implements OnInit {

  caselogs: any;
  ORBISClosedCaseFields: any;
  ORBISClosedCase: any;
  metricfields: any;
  metrics: any;
  filters: Filters;
  totalrecords: number = 0;
  page: Page;
  sortObjects: any = {};
  sortColumn: string = 'staffName';
  sortOrder: string = 'ASC';

  constructor(private commonService: CommonService, private toastr: ToastrService) {
    this.page = new Page();
    this.page.currentPage = 1;
    this.page.recordsPerPage = 100;
  }

  ngOnInit(): void {
    this.commonService.GetAllMetrics().subscribe(d => {
      this.metrics = d;

    })
  }
  PageEvent(e: Page) {
    this.page = e;
    this.LoadData(false);
  }

  DownloadCallBack(password: string) {
    var providers = this.filters.isProviderSelectAll ? "All" : this.filters.multiprovider.join(";");
    this.commonService.DownloadProviderReportInit(this.filters.facility, this.filters.staffRole, this.filters.year, this.filters.month, this.filters.surgeService, this.page.currentPage, this.page.recordsPerPage, this.sortColumn, this.sortOrder, providers).subscribe(p => {
      this.commonService.DownloadProviderReport(p, password);
    });
  }

  SendEmailCallBack(password: string) {
    var providers = this.filters.isProviderSelectAll ? "All" : this.filters.multiprovider.join(";");
    this.commonService.DownloadProviderReportInit(this.filters.facility, this.filters.staffRole, this.filters.year, this.filters.month, this.filters.surgeService, this.page.currentPage, this.page.recordsPerPage, this.sortColumn, this.sortOrder, providers).subscribe(p => {
      this.commonService.EmailProviderReport(p, password)
        .subscribe(res => {
          if (res == "NO") {
            this.toastr.warning("Email is not configured");
          }
          else {
            this.toastr.success(`Email sent to ${res}. It can take up to 5 minutes for email delivery to complete.`);
          }
        });
    });
  }


  LoadData(t: boolean = true) {
    this.caselogs = undefined;
    this.ORBISClosedCaseFields = undefined;
    this.metricfields = undefined;
    this.totalrecords = 0;
    this.filters = this.setYearQuarter(this.filters);
    var providers = this.filters.isProviderSelectAll ? "All" : this.filters.multiprovider.join(";");
    this.commonService.GetProviderComparsion(this.filters.facility, this.filters.staffRole, this.filters.year, this.filters.month, this.filters.surgeService, this.page.currentPage, this.page.recordsPerPage, this.sortColumn, this.sortOrder, providers).subscribe(d => {

      var d1 = JSON.parse(d);

      this.caselogs = d1;
      this.ORBISClosedCaseFields = this.metrics;
      if (this.caselogs != undefined && this.caselogs != null && this.caselogs.length > 0) {
        this.metricfields = (Object.keys(this.caselogs[0])).filter(d => d != 'TotalRecords');

      }
      if (this.caselogs != undefined && this.caselogs != null && this.caselogs.length > 0)
        this.totalrecords = d1[0] ? d1[0].TotalRecords : 0;

    })
  }
  OnFilter(e: Filters) {
    this.filters = e;
    this.page.currentPage = 1;
    this.LoadData();
  }
  getORBISClosedCaseFields(fieldname: string) {
    return this.ORBISClosedCaseFields.filter(c => c.Metric == fieldname)[0]?.MetricName;
  }
  getORBISClosedCaseFieldValue(fieldname: string) {
    return this.ORBISClosedCase[0][fieldname];
  }
  Sort(f) {
    this.sortColumn = f;
    this.sortOrder = this.sortObjects[f] == "ASC" ? "DESC" : "ASC";

    this.sortObjects[f] = this.sortOrder;
    Object.keys(this.sortObjects).forEach(k => {
      if (k != f) {
        this.sortObjects[k] = undefined;
      }
    });
    this.page.currentPage = 1;
    this.LoadData();
  }

  setYearQuarter(filters: Filters) {
    if (filters.dateRange == "year") {
      filters.month = "0";
    }
    else if (filters.dateRange == "quarter") {
      if (filters.quarter == "q1")
        filters.month = "13";
      if (filters.quarter == "q2")
        filters.month = "14";
      if (filters.quarter == "q3")
        filters.month = "15";
      if (filters.quarter == "q4")
        filters.month = "16";
    }
    return filters;
  }

}
