<div>
  <div class="row row-cards">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <ul class="pagination ">
            <li class="page-item">


              <div class="page-link" href="#">
                <a [class.disabled]="currentPage==1" [class.enabled]="currentPage!=1" (click)="first()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                       stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="11 7 6 12 11 17" [ngStyle]="{'opacity': currentPage!=1?1:0.3}" style="stroke-width: 2px; fill: none;" />
                    <polyline points="17 7 12 12 17 17" [ngStyle]="{'opacity': currentPage!=1?1:0.3}" style="stroke-width: 2px; fill: none;" />
                  </svg>
                </a>
                <a [class.disabled]="currentPage==1" [class.enabled]="currentPage!=1" (click)="prev()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                       stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="15 6 9 12 15 18" [ngStyle]="{'opacity': currentPage!=1?1:0.3}" style="stroke-width: 2px; fill: none;" />
                  </svg>
                </a>
              </div>




            </li>
            <li class="page-item"><a class="page-link">{{currentPage}}</a></li>
            <li class="page-item"><a class="page-link" href="#">of</a></li>
            <li class="page-item"><a class="page-link">{{ChageNumberOfPages()}}</a> </li>
            <li>
              <select [(ngModel)]="recordsPerPage" (change)="RecordCountChange()">
                <option [ngValue]="2">2 </option>
                <option [ngValue]="10">10 </option>
                <option [ngValue]="20">20 </option>
                <option [ngValue]="50"> 50</option>
                <option [ngValue]="100">100 </option>
                <option [ngValue]="250">250 </option>
                <option [ngValue]="500">500 </option>
              </select>
            </li>
            <li class="page-item">


              <div class="page-link" href="#">
                <a [class.disabled]="currentPage==numberOfPages" [class.enabled]="currentPage!=numberOfPages"
                   (click)="next()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                       stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="9 6 15 12 9 18" [ngStyle]="{'opacity': currentPage!=numberOfPages?1:0.3}" style="stroke-width: 2px; fill: none;" />
                  </svg>
                </a>
                <a [class.disabled]="currentPage==numberOfPages" [class.enabled]="currentPage!=numberOfPages"
                   (click)="last()">

                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                       stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="7 7 12 12 7 17" [ngStyle]="{'opacity': currentPage!=numberOfPages?1:0.3}" style="stroke-width: 2px; fill: none;" />
                    <polyline points="13 7 18 12 13 17" [ngStyle]="{'opacity': currentPage!=numberOfPages?1:0.3}" style="stroke-width: 2px; fill: none;" />
                  </svg>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
