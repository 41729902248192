import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SimpleBar  } from '../SimpleBar';

@Component({
  selector: 'app-simple-bar',
  templateUrl: './simple-bar.component.html',
  styleUrls: ['./simple-bar.component.css']
})
export class SimpleBarComponent implements OnInit {

  @Input() container: string = ''
  @Input() barData: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<SimpleBar> = new EventEmitter();

  simpleBar!: SimpleBar;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.simpleBar = new SimpleBar(this.container, this.barData, this.configuration);
    this.simpleBar.Render();
    this.myOutput.emit(this.simpleBar);
  }

}
