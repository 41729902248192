import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class FinancialsGraph {
  private config: any = {
    height: 350,
    margin_left: 80,
    margin_right: 60,
    margin_top: 35,
    margin_bottom: 120,
    sectionwidth: 150
  };

  private metrics: any = undefined;
  private width: any = undefined;
  private mintotal: any = undefined;
  private maxtotal: any = undefined;
  private maxcases: any = undefined;
  private x0: any = undefined;
  private x1: any = undefined;
  private xc: any = undefined;
  private yf: any = undefined;
  private yc: any = undefined;
  private svg: any = undefined;
  private line: any = undefined;

  private container: string;
  private financialsdata: any;  
  private configuration: any;

  constructor(container: string, financialsdata: any, configuration: any) {
    this.container = container;
    this.financialsdata = financialsdata;    
    this.configuration = configuration;
    this.configure(this.configuration);
  }

  private configure(configuration) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    //Labels and bar colors for each of the financial metrics to display
    this.metrics = {
      TotalNetRevenue: { label: "Total Net Revenue", barcolor: "#9b3228" },
      TotalContributionMargin: { label: "Total Contribution Margin", barcolor: "#708748" },
      TotalOperatingMargin: { label: "Total Operating Margin", barcolor: "#514263" }
    };

    //alert(d3.keys(metrics));

    //calculate width of the graphic based on the number of quarters are available in the dataset
    this.width = this.config.sectionwidth * Object.keys(this.financialsdata).length + this.config.margin_left + this.config.margin_right;

    //sort the data in order of quarter (Q1, Q2, Q3, Q4)
    this.financialsdata.sort( (x, y) => {
      return d3.ascending(x.FYQuarter, y.FYQuarter);
    })

    //determine the mix and max dollar amount to use for the y-axis scale for financial metrics
    this.mintotal = d3.min(this.financialsdata.map( (d) => { return d.TotalOperatingMargin; }));
    this.maxtotal = d3.max(this.financialsdata.map( (d) => { return d.TotalNetRevenue; }));//{ return Math.max([d.TotalNetRevenue, d.TotalContributionMargin, d.TotalOperatingMargin]); }));

    //add 10% to min and max for buffer
    this.mintotal = this.mintotal + this.mintotal * 0.1;
    this.maxtotal = this.maxtotal + this.maxtotal * 0.1;

    //determine the max case count for a quarter in the dataset for the y-axis scale for case count
    this.maxcases = d3.max(this.financialsdata.map((d) => { return d.CaseCount; }))

    //map financial data for each quarter to location on the x-axis
    this.x0 = d3.scaleBand()
      .rangeRound([this.config.margin_left, this.width - this.config.margin_right])
      .domain(this.financialsdata.map((d) => { return d.FYQuarter; }))
      .paddingOuter(0.2)
      .paddingInner(0.2);

    //map financial metrics to location within the quarter's area on the x-axis
    this.x1 = d3.scaleBand()
      .rangeRound([0, this.x0.bandwidth()])
      .domain(Object.keys(this.metrics));
    //.padding(0.05);

    //scale financial totals to y-axis
    this.yf = d3.scaleLinear()
      .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
      .domain([Math.min(0, this.mintotal), this.maxtotal]);

    //map case count data for each quarter to location on the x-axis
    this.xc = d3.scaleBand()
      .rangeRound([this.config.margin_left, this.width - this.config.margin_right])
      .domain(this.financialsdata.map( (d) => { return d.FYQuarter; }));

    //scale case counts to y-axis
    this.yc = d3.scaleLinear()
      .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
      .domain([0, this.maxcases])
      .nice();

    //create line function to map case counts to graph location
    this.line = d3.line()
      .x( (d : any) => { return this.x0(d.FYQuarter) + this.x0.bandwidth() / 2; })
      .y( (d : any) => { return this.yc(d.CaseCount); });

  }
  Render() {
    this.draw();
  }
  private draw() {

    //create svg to to display bars
    this.svg = d3.select(this.container)
      .append('svg:svg')
      .attr('width', this.width)
      .attr('height', this.config.height);

    //append the x-axis
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x0)
        .tickSize(0)
      )
      .selectAll("text")
      //.attr("transform", "rotate(40)")
      .style("text-anchor", "start");

    //append the y-axis for Financials data
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(" + this.config.margin_left + ",0)")
      .call(d3.axisLeft(this.yf)
        .tickFormat( (d : any) => { return "$" + d3.format(",")(d); })
        .tickSize((this.config.margin_left + this.config.margin_right) - this.width)
      );

    //append the y-axis for case count data
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(" + (this.width - this.config.margin_right) + ",0)")
      .call(d3.axisRight(this.yc));

    //draw label for case count axis
    this.svg.append("text")
      .attr("x", 0)
      .attr("y", 0)
      .attr("transform", "translate(" + (this.width - 5) + "," + (this.config.height - this.config.margin_bottom) / 2 + ")rotate(-90)")
      .attr("class", "graphtitle")
      .text("Cases");

    //draw bars for TotalNetRevenue
    this.svg.append("g")
      .selectAll("rect")
      .data(this.financialsdata)
      .enter().append("rect")
      .attr("fill", (d) => { return this.metrics.TotalNetRevenue.barcolor; })
      .attr("x", (d) => { return this.x0(d.FYQuarter) + this.x1("TotalNetRevenue"); })
      .attr("y", (d) => { return this.yf(Math.max(0, d.TotalNetRevenue)); })
      .attr("width", (d) => { return this.x1.bandwidth(); })
      .attr("height", (d) => { return Math.abs(this.yf(0) - this.yf(d.TotalNetRevenue)); });

    //draw bars for TotalContributionMargin
    this.svg.append("g")
      .selectAll("rect")
      .data(this.financialsdata)
      .enter().append("rect")
      .attr("fill", (d) => { return this.metrics.TotalContributionMargin.barcolor; })
      .attr("x", (d) => { return this.x0(d.FYQuarter) + this.x1("TotalContributionMargin"); })
      .attr("y", (d) => { return this.yf(Math.max(0, d.TotalContributionMargin)); })
      .attr("width", (d) => { return this.x1.bandwidth(); })
      .attr("height", (d) => { return Math.abs(this.yf(0) - this.yf(d.TotalContributionMargin)); });

    //draw bars for TotalOperatingMargin
    this.svg.append("g")
      .selectAll("rect")
      .data(this.financialsdata)
      .enter().append("rect")
      .attr("fill", (d) => { return this.metrics.TotalOperatingMargin.barcolor; })
      .attr("x", (d) => { return this.x0(d.FYQuarter) + this.x1("TotalOperatingMargin"); })
      .attr("y", (d) => { return this.yf(Math.max(0, d.TotalOperatingMargin)); })
      .attr("width", (d) => { return this.x1.bandwidth(); })
      .attr("height", (d) => { return Math.abs(this.yf(0) - this.yf(d.TotalOperatingMargin)); });

    //draw lines for case counts
    this.svg.append("path")
      .datum(this.financialsdata)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("stroke-width", 2.5)
      .attr("d", this.line);

    //draw circles for case count points
    this.svg.selectAll(".dot")
      .data(this.financialsdata)
      .enter().append("circle") // Uses the enter().append() method
      .attr("class", "dot") // Assign a class for styling
      .attr("cx", (d) => { return this.x0(d.FYQuarter) + this.x0.bandwidth() / 2; })
      .attr("cy", (d) => { return this.yc(d.CaseCount); })
      .attr("r", 4);

    //draw legend
    //line for case counts
    this.svg.append("line")
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("stroke-width", 2.5)
      .attr("x1", 10)
      .attr("x2", 30)
      .attr("y1", this.config.height - this.config.margin_bottom + 45)
      .attr("y2", this.config.height - this.config.margin_bottom + 45);

    this.svg.append("circle")
      .attr("fill", "none")
      .attr("class", "dot")
      .attr("cx", 20)
      .attr("cy", this.config.height - this.config.margin_bottom + 45)
      .attr("r", 4);

    this.svg.append("text")
      .attr("x", 40)
      .attr("y", this.config.height - this.config.margin_bottom + 50)
      .attr("class", "linelegend")
      .text("Total Cases");

    //boxes for financial totals
    this.svg.append("g")
      .selectAll("rect")
      .data(values(this.metrics))
      .enter().append("rect")
      .attr("fill", (d) => { return d.barcolor; })
      .attr("x", (d) => { return 10; })
      .attr("y", (d, i) => { return this.config.height - this.config.margin_bottom + 40 + 20 * (i + 1); })
      .attr("width", 20)
      .attr("height", 10);

    this.svg.append("g")
      .selectAll("text")
      .data(values(this.metrics))
      .enter().append("text")
      .attr("x", (d) => { return 40; })
      .attr("y", (d, i) => { return this.config.height - this.config.margin_bottom + 50 + 20 * (i + 1); })
      .attr("class", "linelegend")
      .text((d) => { return d.label; });

  }
  public update(updateFinancialsData: any, newConfiguration: any) {

    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }

    this.financialsdata = updateFinancialsData;
    d3.select("" + this.container).select("svg").remove();
    this.draw();
  }
}
