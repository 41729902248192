import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LineGraph } from '../LineGraph';

@Component({
  selector: 'app-linegraph',
  templateUrl: './linegraph.component.html',
  styleUrls: ['./linegraph.component.css']
})
export class LinegraphComponent {
  @Input() container: string = ''
  @Input() lineData: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<LineGraph> = new EventEmitter();

  linegraph!: LineGraph;

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.linegraph = new LineGraph(this.container, this.lineData, this.configuration);
    this.linegraph.Render();
    this.myOutput.emit(this.linegraph);
  }
}
