import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';

@Component({
  selector: 'app-two-room-surgeons',
  templateUrl: './two-room-surgeons.component.html',
  styleUrls: ['./two-room-surgeons.component.css']
})
export class TwoRoomSurgeonsComponent implements OnInit {
  filters: Filters;
  TwoRoomSurgeons: any;

  columns: Array<any> = [
    { "name": "Surgeon", "fil": "StaffName" },
    { "name": "Days 2 Rooms", "fil": "Days2Rooms" },
    { "name": "OR Dormancy", "fil": "ORDormancy" }    
  ];

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }

  OnFilter(e: Filters) {

    this.filters = e;
    this.LoadData();
  }

  LoadData() {
    this.TwoRoomSurgeons = undefined;

    this.commonService.GetTwoRoomSurgeons(this.filters.fromDate, this.filters.toDate).subscribe(d => {
      this.TwoRoomSurgeons = d["TwoRoomSurgeons"];
    })
  }
}
