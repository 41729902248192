import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { ColumnModel, Page, TableModel } from '../models/table';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.css']
})
export class CommonTableComponent implements OnInit, OnChanges {
  @Input() config: TableModel | undefined;
  @Output() pageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() emailEvent: EventEmitter<any> = new EventEmitter<any>();

  immediateClicked: string;
  headers: any;
  objectKeys = Object.keys;
  sortObject: any = {};
  dataFiltered: any;
  isFilterAvailable: boolean = false;
  filterChange: any = new Subject<string>();
  filterText: any = {};
  filterCondition: any = {};
  filterNumber: any = {};

  filterDateFrom: any = {};
  filterDateTo: any = {};
  switch: boolean = true;

  page: Page;
  isChanged: boolean = false;
  groupBy: any = function (xs: any, key: string) {
    return xs.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  ngOnChanges() {
    this.dataFiltered = undefined;
    setTimeout(() => {
      this.dataFiltered = this.config.table_data;
    });
  }
  constructor() {


  }
  PageEvent(e: Page) {
    this.page = e;
    var obj = {};
    obj['page'] = e;
    obj['switch'] = this.switch;
    this.pageEvent.emit(obj);
    this.isChanged = false;
  }

  ngOnInit(): void {
    this.sortObject[this.config.default_sort_column] = this.config.default_sort_order;
    this.page = new Page();
    this.page.currentPage = 1;
    this.page.recordsPerPage = 100;

    this.filterChange.pipe(
      debounceTime(this.config.is_pagination ? 0 : 700),
      distinctUntilChanged())
      .subscribe((value: any) => {
        this.isChanged = true;
        this.FilterData();
      });

    this.headers = this.groupBy(this.config?.columns, 'group');
    this.dataFiltered = this.config?.table_data;
    if (this.config) {
      let filters = this.config?.columns?.filter(f => f.filter_type != "none");
      this.isFilterAvailable = filters.length > 0;
      if (this.isFilterAvailable) {
        this.config?.columns.filter(f => f.filter_type == "string").forEach(f => {
          if (!f.filter_texts || (f.filter_texts && f.filter_texts.length == 0)) {
            f.filter_texts = this.config?.table_data.map(d => {
              return d[f.data_key];
            }).filter((item, i, ar) => ar.indexOf(item) === i)
              .sort((a, b) => {
                if (a < b)
                  return -1;
                if (a > b)
                  return 1;
                return 0;
              });
            f.filter_texts?.unshift("All");
          }
        });
      }
    }
  }
  Apply() {
    this.isChanged = false;
    let filters = this.GetFilters();
    let obj = {};
    obj['filters'] = filters;
    obj['switch'] = this.switch;
    if (this.filterEvent) {
      this.filterEvent.emit(obj);
    }
  }

  DownloadCallBack(password: string) {
    this.isChanged = false;
    let filters = this.GetFilters();
    let obj = {};
    obj['filters'] = filters;
    obj['switch'] = this.switch;
    if (this.downloadEvent) {
      this.downloadEvent.emit(obj);
    }
  }

  SendEmailCallBack(password: string) {
    this.isChanged = false;
    let filters = this.GetFilters();
    let obj = {};
    obj['filters'] = filters;
    obj['switch'] = this.switch;
    if (this.emailEvent) {
      this.emailEvent.emit(obj);
    }
  }

  FilterData() {
    if (!this.config.is_pagination) {
      this.dataFiltered = undefined;
      let condition: Array<boolean> = [];

      this.dataFiltered = this.config?.table_data.filter(data => {
        condition = [];
        let filteredColumns = this.config?.columns.filter(f => f.filter_type != "none");
        if (filteredColumns?.length == 0) {
          return true;
        }
        else if (filteredColumns) {
          for (var i = 0; i < filteredColumns.length; i++) {
            let filterColumn = filteredColumns[i];
            if (filterColumn) {
              if (filterColumn.filter_type == "freetext") {
                let filterText = this.filterText[filterColumn.data_key];
                let dt = data[filterColumn.data_key];
                if (filterText && dt) {
                  //let dt = data[filterColumn.data_key]?.toString().toLowerCase().replaceAll(/\s/g, '');
                  condition[i] = dt?.toString().toLowerCase().replaceAll(/\s/g, '').includes(filterText?.toString()?.toLowerCase()?.replaceAll(/\s/g, ''));
                }
                else {
                  condition[i] = true;
                }

              }
              else if (filterColumn.filter_type == "date") {
                let filterDateFrom = this.filterDateFrom[filterColumn.data_key];
                let filterDateTo = this.filterDateTo[filterColumn.data_key];
                if (filterDateFrom && filterDateTo) {
                  let dt = data[filterColumn.data_key];
                  condition[i] = dt.getTime() >= filterDateFrom.getTime() && dt.getTime() <= filterDateTo.getTime();
                }
                else {
                  condition[i] = true;
                }
              }
              else if (filterColumn.filter_type == "string") {
                let filterText = this.filterText[filterColumn.data_key];
                if (filterText && filterText?.toString().toLowerCase().replaceAll(/\s/g, '') != "all") {
                  let dt = data[filterColumn.data_key]?.toString().toLowerCase().replaceAll(/\s/g, '');
                  condition[i] = dt == filterText?.toString()?.toLowerCase()?.replaceAll(/\s/g, '');
                }
                else {
                  condition[i] = true
                }
              }
              else {

                let dynamiccondition = this.filterCondition[filterColumn.data_key];
                dynamiccondition = (!dynamiccondition ? '=' : dynamiccondition);
                if (!this.filterNumber[filterColumn.data_key] || (this.filterNumber[filterColumn.data_key] && this.filterNumber[filterColumn.data_key].toString().toLowerCase().split(' ').join('') == "")) {
                  condition[i] = true;
                }
                else if (dynamiccondition == '=') {
                  condition[i] = data[filterColumn.data_key] == this.filterNumber[filterColumn.data_key];
                } else if (dynamiccondition == '>') {
                  condition[i] = data[filterColumn.data_key] > this.filterNumber[filterColumn.data_key];
                }
                else {
                  condition[i] = data[filterColumn.data_key] < this.filterNumber[filterColumn.data_key];
                }


              }
            }
          }
        }
        else {
          return true;
        }
        return condition.filter(f => !f).length == 0;


      });
    }
    //this.failedcasesFiltered = failedcasesFiltered.map((d, i) => {
    //  d.isActive = i == 0;
    //  return d;
    //})
    //this.failedcasesFiltered = this.failedcases.filter(p => {
    //  return (p.Room.toLowerCase().split(' ').join('').includes(this.room.toLowerCase().split(' ').join('')) || this.room.split(' ').join('') == "" || this.room == 'All')
    //    && (this.datepipe.transform(p.CaseDate, 'shortDate').split(' ').join('').includes(this.caseDate.toLowerCase().split(' ').join(''))
    //      || this.caseDate.split(' ').join('') == "" || this.caseDate == 'All')
    //    && (p.ProcedureDescription.toLowerCase().split(' ').join('').includes(this.procedureDesc.toLowerCase().split(' ').join('')) || this.procedureDesc.split(' ').join('') == "")
    //});
  }



  Sort(c: any) {
    if (c && c.length == 1 && c[0].is_soratable) {
      let col = c[0] as ColumnModel;

      //if (sessionStorage.getItem("tableOrder")) {
      //  this.sortObject = JSON.parse(sessionStorage.getItem("tableOrder"));
      //}
      //console.log(this.immediateClicked)
      //console.log(col.data_key)
      //console.log(this.sortObject)

      let k = Object.keys(this.sortObject);
      for (var i = 0; i < k.length; i++) {
        if (this.immediateClicked != col.data_key) {
          this.sortObject[k[i]] = undefined;
        }
      }
      this.immediateClicked = col.data_key;
      /********************* To find previous is asc or desc *******************************/
      if (this.sortObject[col.data_key]) {
        this.sortObject[col.data_key] = this.sortObject[col.data_key] == 'asc' ? 'desc' : 'asc';
      }
      else {
        this.sortObject[col.data_key] = 'asc';
      }
      // sessionStorage.setItem("tableOrder", JSON.stringify(this.sortObject));

      if (!this.config.is_pagination) {
        this.dataFiltered = this.CommonSort(this.dataFiltered, col, this.sortObject[col.data_key]);
      }
      else {
        if (this.sortEvent) {
          this.sortEvent.emit({
            "key": col.data_key, "order": this.sortObject[col.data_key],
            "switch": this.switch
          });
        }
      }
    }
  }



  /********************* Common Sortine *****************************/
  CommonSort(data: any, col: ColumnModel, sortorder: string) {
    ///********************* To find column is string or number *******************************/
    //let is_numeric = (str:any) => {
    //  return /^\d+$/.test(str);
    //}
    //let isNum = data.map((d: any) => d[col.data_key]).filter((f:any) => !is_numeric(f));

    /********************* To sort Date column *******************************/
    if (col.column_type == 'date') {
      if (sortorder == 'asc') {
        data = data.sort(function (obj1: any, obj2: any) {
          return new Date(obj1[col.data_key]).getTime() - new Date(obj2[col.data_key]).getTime();
        });
      }
      else {
        data = data.sort(function (obj1: any, obj2: any) {
          return new Date(obj2[col.data_key]).getTime() - new Date(obj1[col.data_key]).getTime();
        });
      }
    }
    /********************* To sort number column *******************************/
    else if (col.column_type == 'number') {
      if (sortorder == 'asc') {
        data = data.sort(function (obj1: any, obj2: any) {
          return obj1[col.data_key] - obj2[col.data_key];
        });
      }
      else {
        data = data.sort(function (obj1: any, obj2: any) {
          return obj2[col.data_key] - obj1[col.data_key];
        });
      }
    }
    /********************* To sort string column *******************************/
    else {
      if (sortorder == 'asc') {
        data = data.sort(function (obj1: any, obj2: any) {
          return ('' + obj1[col.data_key]).localeCompare(obj2[col.data_key]);
        });
      }
      else {
        data = data.sort(function (obj1: any, obj2: any) {
          return ('' + obj2[col.data_key]).localeCompare(obj1[col.data_key]);
        });
      }
    }
    return data;
  }





  GetFilters(): Array<any> {
    let filters = [];
    let k1 = Object.keys(this.filterText);
    let k2 = Object.keys(this.filterNumber);
    let k3 = Object.keys(this.filterDateFrom);
    for (var i = 0; i < k3.length; i++) {
      if (this.filterDateFrom[k3[i]] && this.filterDateTo[k3[i]]) {
        let fromDate = new Date(this.filterDateFrom[k3[i]]);
        let from = fromDate.getFullYear().toString() + "-" + (fromDate.getMonth() + 1).toString() + "-" + fromDate.getDate().toString();

        let toDate = new Date(this.filterDateTo[k3[i]]);
        let to = toDate.getFullYear().toString() + "-" + (toDate.getMonth() + 1).toString() + "-" + toDate.getDate().toString();

        let obj = {};
        obj['val'] = "'" + from + "' AND '" + to + "'";
        obj['col'] = k3[i];
        obj["type"] = this.config?.columns.filter(f => f.data_key == k3[i])[0].filter_type;
        obj['condition'] = "";
        filters.push(obj);
      }
    }
    for (var i = 0; i < k1.length; i++) {
      let obj = {};
      obj['val'] = this.filterText[k1[i]];
      obj['col'] = k1[i];
      obj["type"] = this.config?.columns.filter(f => f.data_key == k1[i])[0].filter_type;
      obj['condition'] = "";
      filters.push(obj);
    }
    for (var i = 0; i < k2.length; i++) {
      let obj = {};
      obj['val'] = this.filterNumber[k2[i]];
      obj['col'] = k2[i];
      obj["type"] = this.config?.columns.filter(f => f.data_key == k2[i])[0].filter_type;
      obj['condition'] = this.filterCondition[k2[i]] ?? "All";
      filters.push(obj);
    }
    return filters;
  }
}
