import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';

@Component({
  selector: 'app-facility-summary',
  templateUrl: './facility-summary.component.html',
  styleUrls: ['./facility-summary.component.css']
})
export class FacilitySummaryComponent implements OnInit {
  metrics: any;
  details: any;
  filters: Filters;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.LoadData();
  }

  OnFilter(e: Filters) {
    this.filters = e;
    this.LoadData();
  }

  LoadData() {
    this.metrics = undefined;
    this.details = undefined;

    this.commonService.GetFacilitySummary(this.filters.facility).subscribe(d => {
      this.metrics = d["FacilitySummaryMetrics"];
      this.details = d["FacilitySummaryDetails"];
    })
  }

  GetSummaryDetails(Metric: string) {

    if (this.details != undefined) {
      var x = this.details.filter(d => d.Metric == Metric);
      if (x != undefined && x.length > 0) {
        var y;
        y = x.sort((x, y) => x.DetailIndex < y.DetailIndex ? -1 : 1);
        return y;
      }
    }
    return [];
  }
}
