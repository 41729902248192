import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewFilters } from './NewFilters';
import { ProductivityService } from '../productivity.service';
import { concatMap } from 'rxjs/operators';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-new-filters',
  templateUrl: './new-filters.component.html',
  styleUrls: ['./new-filters.component.css']
})
export class NewFiltersComponent implements OnInit {
  @Output() onfilter: EventEmitter<NewFilters> = new EventEmitter();
  @Input({ required: true }) reportHeader: string = ''
  filteroptions: any = {};
  isLoader: boolean = false;
  isChanged: boolean = false;
  selectedDateRange: string = "custom";
  currentYear: number;
  selectedYear: string;
  selectedMonth: string;
  selectedQuarter: string;
  fromDate: any;
  toDate: any;
  months: Array<any>;
  quarters: Array<any>;
  dayCategories: Array<any>;
  sites: Array<string>;
  roles: Array<string>;
  selectedStaffRole: string;
  selectedHoursType: string='All';
  selectedSite: string;
  selectedDayCategory: any;
  providerMultiSettings: IDropdownSettings = {};
  providers: Array<any>;
  selectedMultiProvider: Array<any> = [];
  isSelectAll: boolean = true;
  constructor(private prodService: ProductivityService) {
    this.providerMultiSettings = {
      singleSelection: false,
      idField: 'ProviderId',
      textField: 'ProviderName',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };

  }
  ngOnInit(): void {
    this.currentYear = (new Date()).getFullYear();
    /* Load Months & Quarters */
    
    if (sessionStorage.getItem("newfilters")) {
      this.LoadFiltersFromSession();
    }
    else {
      this.SetDefaultFilters();
    }
    
    this.ChangeDateRange();
    this.GetMonths();
    this.GetQuarters();
    /* Above  variable is for control if the page is not having date filfilters we should put false based on page*/
    this.filteroptions.daterange = true;

    this.GetFiltersFromDB();
  }
  GetFiltersFromDB() {
    /*For on progress bar in filter portion*/
    this.isLoader = true;

    this.prodService.GetFilters().pipe(
      concatMap(f => {
        this.roles = f['Role'];
        this.sites = f['Site'];
        this.dayCategories = f['Day'];


        /*Set Default selections*/
        this.selectedStaffRole = this.roles && this.roles.length > 0 ? this.roles[0] : '';
        this.selectedSite = this.sites && this.sites.length > 0 ? this.sites[0] : '';
        this.selectedDayCategory = this.dayCategories && this.dayCategories.length > 0 ? this.sites[0] : undefined;

        let obj = this.FormatFiltersForEmit();
        return this.prodService.GetProviderFilters(obj.fromDate, obj.toDate, obj.role, obj.site, obj.daycategory, obj.hours_type)
      })).subscribe(
        p => {
          this.isSelectAll = true;
          this.providers = p['Providers'];
          this.selectedMultiProvider = this.providers;
          /*For off progress bar in filter portion*/
          this.isLoader = false;

          this.OnFilter();
        }
      );


    //this.prodService.GetFilters().subscribe(f => {
    //  this.roles = f['Role'];
    //  this.sites = f['Site'];
    //  this.dayCategories = f['Day'];

    //  /*Set Default selections*/
    //  this.selectedStaffRole = this.roles && this.roles.length > 0 ? this.roles[0] : '';
    //  this.selectedSite = this.sites && this.sites.length > 0 ? this.sites[0] : '';
    //  this.selectedDayCategory = this.dayCategories && this.dayCategories.length > 0 ? this.sites[0] : undefined;
    //  /*For off progress bar in filter portion*/
    //  this.isLoader = false;
    //})
  }
  GetProviders() {
    this.isLoader = true;
    let obj = this.FormatFiltersForEmit();
    this.prodService.GetProviderFilters(obj.fromDate, obj.toDate, obj.role, obj.site, obj.daycategory, obj.hours_type)
      .subscribe(
        p => {
          this.providers = p['Providers'];
          this.selectedMultiProvider = this.providers;
          /*For off progress bar in filter portion*/
          this.isLoader = false;
        });
  }
  SetDefaultFilters() {


    /************** Set last Month as default date *********/
    var lastMonth = new Date();
    lastMonth.setDate(1);
    lastMonth.setHours(-1);

    var firstDate = new Date();
    firstDate.setDate(1);
    firstDate.setHours(-1)
    firstDate.setDate(1);

    this.fromDate = firstDate;
    this.toDate = lastMonth;

    /************** Set default year and quarter *********/
    let mn = (new Date()).getMonth();

    if (mn != 0) {
      this.selectedYear = this.currentYear.toString();
      this.selectedMonth = ((new Date()).getMonth()).toString();
    }
    else {
      this.selectedYear = (this.currentYear - 1).toString();
      this.selectedMonth = "12";
    }
    this.selectedQuarter = "q" + (Math.ceil(((new Date()).getMonth() + 1) / 3)).toString();
   // this.ChangeDateRange();
  }


  /*For Date Range selection chage*/
  ChangeDateRange() {
    //setTimeout(() => {
    if (this.selectedDateRange == "custom") {
      this.filteroptions.month = false;
      this.filteroptions.year = false;
      this.filteroptions.customdaterange = true;
      this.filteroptions.quarter = false;
    }
    else if (this.selectedDateRange == "month") {
      this.filteroptions.month = true;
      this.filteroptions.year = true;
      this.filteroptions.customdaterange = false;
      this.filteroptions.quarter = false;
    }
    else if (this.selectedDateRange == "quarter") {
      this.filteroptions.month = false;
      this.filteroptions.year = true;
      this.filteroptions.customdaterange = false;
      this.filteroptions.quarter = true;
    }
    else {
      this.filteroptions.month = false;
      this.filteroptions.year = true;
      this.filteroptions.customdaterange = false;
      this.filteroptions.quarter = false;
    }
    //});
  }

  LoadFiltersFromSession() {
   // setTimeout(() => {
      var obj = JSON.parse(sessionStorage.getItem("newfilters")) as NewFilters;

      this.selectedQuarter = obj.quarter;
      if (obj.fromDate) {
        let from = obj.fromDate.split("-")
        this.fromDate = [from[1], from[2], from[0]].join('/');
        this.fromDate = new Date(this.fromDate);
      }
      if (obj.toDate) {
        let to = obj.toDate.split("-");
        this.toDate = [to[1], to[2], to[0]].join('/');
        this.toDate = new Date(this.toDate);
      }
      this.selectedYear = obj.year;
      this.selectedMonth = obj.month;
      this.selectedDateRange = obj.dateRange;

    this.selectedStaffRole = obj.role;
    this.selectedHoursType = obj.hours_type;
      this.selectedSite = obj.site;
      this.selectedDayCategory = obj.daycategory;

      this.isSelectAll = obj.isSelectAll == undefined ? true : obj.isSelectAll;
      this.selectedMultiProvider = obj.multiproviderObject;

      //this.ChangeDateRange();

   // });


  }

  FormatFiltersForEmit(): NewFilters {
    let obj = new NewFilters();

    //obj.multiprovider = this.selectedMultiProvider.map(p => { return p.UserLogin });

    /*preparing selected filters emit to the page*/
    this.SetFromToDate(obj);
    obj.year = this.selectedYear;
    obj.month = this.selectedMonth;
    obj.dateRange = this.selectedDateRange
    obj.quarter = this.selectedQuarter;

    obj.role = this.selectedStaffRole;
    obj.site = this.selectedSite
    obj.daycategory = this.selectedDayCategory;
    obj.hours_type = this.selectedHoursType;
    return obj;
  }
  /*Apply Button Click*/
  OnFilter(isEmit: boolean = true) {
    this.isChanged = false;
    let obj = this.FormatFiltersForEmit();

    obj.multiprovider = this.selectedMultiProvider.map(p => { return p.ProviderId });
    obj.multiproviderObject = this.selectedMultiProvider;
    obj.isProviderSelectAll = (this.selectedMultiProvider.length == this.providers.length ? true : false);

    sessionStorage.setItem("newfilters", JSON.stringify(obj));
    if (isEmit) {
      this.onfilter.emit(obj);
    }   
  }


  SetFromToDate(obj) {
    if (this.filteroptions.daterange == true) {
      if (this.selectedDateRange == "custom") {
        obj.fromDate = this.FormatDate(this.fromDate);
        obj.toDate = this.FormatDate(this.toDate);
      }
      else if (this.selectedDateRange == "month") {
        var lastDate = new Date(parseInt(this.selectedYear), parseInt(this.selectedMonth), 0);
        obj.fromDate = this.selectedYear + "-" + this.selectedMonth + "-01";
        obj.toDate = this.selectedYear + "-" + this.selectedMonth + "-" + (lastDate.getDate());
      }
      else if (this.selectedDateRange == "quarter") {
        if (this.selectedQuarter == "q1") {
          obj.fromDate = this.selectedYear + "-01" + "-01";
          obj.toDate = this.selectedYear + "-03" + "-31";
        }
        else if (this.selectedQuarter == "q2") {
          obj.fromDate = this.selectedYear + "-04" + "-01";
          obj.toDate = this.selectedYear + "-06" + "-30";
        }
        else if (this.selectedQuarter == "q3") {
          obj.fromDate = this.selectedYear + "-07" + "-01";
          obj.toDate = this.selectedYear + "-09" + "-30";
        }
        else {
          obj.fromDate = this.selectedYear + "-10" + "-01";
          obj.toDate = this.selectedYear + "-12" + "-31";
        }
      }
      else {
        obj.fromDate = this.selectedYear + "-01" + "-01";
        obj.toDate = this.selectedYear + "-12" + "-31";
      }
    }
    else {
      obj.fromDate = this.FormatDate(this.fromDate);
      obj.toDate = this.FormatDate(this.toDate);
    }
  }

  private FormatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  GenerateMonth(m) {
    this.months = [];
    var monthsNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    for (var i = 1; i <= m; i++) {
      this.months.push({ "value": i.toString(), "name": monthsNames[i - 1] });
    }
  }
  GenerateQuartes(m) {
    this.quarters = [];
    for (var i = 1; i <= Math.ceil(m / 3); i++) {
      this.quarters.push({ "value": "q" + i.toString(), "name": "Q" + i.toString() });
    }
  }
  GetMonths() {
    //this.selectedMonth = "1";
    if ((new Date()).getFullYear().toString() == this.selectedYear) {
      this.GenerateMonth(((new Date()).getMonth() + 1));
    }
    else {
      this.GenerateMonth(12);
    }
  }
  GetQuarters() {
    //this.selectedQuarter = "q1";
    if ((new Date()).getFullYear().toString() == this.selectedYear) {
      this.GenerateQuartes(((new Date()).getMonth() + 1));
    }
    else {
      this.GenerateQuartes(12);
    }
  }

  FiltersChanged() {
    this.isChanged = true;
  }
  /*For multiprovider All selection*/
  SelectAll() {
    if (this.isSelectAll) {
      this.providerMultiSettings.limitSelection = 100000;
      this.selectedMultiProvider = this.providers;

    }
    else {
      this.selectedMultiProvider = [];
      this.providerMultiSettings.limitSelection = 25;
    }
  }
  /*Date Change event*/
  DateChanged(arg) {
    this.GetProviders();
    this.FiltersChanged();
  }
}
