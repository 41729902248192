import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
//import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class ProductivityService {

  constructor(private httpClient: HttpClient) {
  }

  /********************* Filters API *****************************/
  GetFilters() {
    return this.httpClient.get<any>(environment.apiURL + "/productivitydashboard/getFilters");
  }
GetProviderFilters(startDate: string, endDate: string, role: string, site: string, dayCategory: string,hoursType:string) {
  return this.httpClient.get<any>(environment.apiURL + "/productivitydashboard/getProviderFilters/" + startDate + "/" + endDate + "/" + role + "/" + site + "/" + dayCategory + "/" + hoursType);
  }
  /*GetProductivityReports(startDate: string, endDate: string, role: string,
    site: string, dayCategory: string, providername: string) {
    alert(providername);
    return this.httpClient.get<any>(environment.apiURL + "/productivitydashboard/get/"
      + startDate + "/" + endDate + "/" + role + "/" + site + "/" + dayCategory + "/"
      + providername);
      
  }
  */
  GetProductivityReports(startDate: string, endDate: string, role: string,
    site: string, dayCategory: string, providername: string, hoursType: string) {
    var obj = {}
    obj['stDate'] = startDate;
    obj['endDate'] = endDate;
    obj['role'] = role;
    obj['site'] = site;
    obj['dayCategory'] = dayCategory;
    obj['providername'] = providername;
    obj['hoursType'] = hoursType;
    return this.httpClient.post<any>(environment.apiURL + "/productivitydashboard/get/", obj);
  }

  /********************* Unutilized Dashboards API *****************************/

  GetUnutilizedReports(startDate: string, endDate: string, role: string,
    site: string, dayCategory: string, providername: string, hoursType: string) {
    var obj = {}
    obj['stDate'] = startDate;
    obj['endDate'] = endDate;
    obj['role'] = role;
    obj['site'] = site;
    obj['dayCategory'] = dayCategory;
    obj['providername'] = providername;
    obj['hoursType'] = hoursType;
    return this.httpClient.post<any>(environment.apiURL + "/unutilizeddashboard/get/", obj);
  }
}
