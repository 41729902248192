import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-perivision',
  templateUrl: './perivision.component.html',
  styleUrls: ['./perivision.component.css']
})
export class PerivisionComponent implements OnInit {
  PerivisionSchedule: any;
  PerivisionGap: any;
  PerivisionCounts: any;
  PerivisionLateCases: any;
  PerivisionOccupancy: any;
  PerivisionLine: any;
  PerivisionPACU: any;
  filters: Filters;
  currentTab: TabComponent;
  reportview: string = "ORTiming";
  overtimeview: Array<string> = [];

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }

  OnFilter(e: Filters) {
    this.filters = e;

    this.LoadData();
  }

  OnSelectTab(t: TabComponent) {
    this.currentTab = t;
    this.reportview = t.id;

    this.LoadData();
  }
  LoadData() {

    this.PerivisionSchedule = undefined;
    this.PerivisionGap = undefined;
    this.PerivisionCounts = undefined;
    this.PerivisionLateCases = undefined;
    this.PerivisionOccupancy = undefined;
    this.PerivisionLine = undefined;
    this.PerivisionPACU = undefined;

    this.commonService.GetPerivision(this.filters.facility, this.filters.location, this.filters.caseDate).subscribe(d => {
      this.PerivisionSchedule = d["PerivisionSchedule"];
      this.PerivisionGap = d["PerivisionGap"];
      this.PerivisionCounts = d["PerivisionCounts"];
      this.PerivisionLateCases = d["PerivisionLateCases"];
      this.PerivisionOccupancy = d["PerivisionOccupancy"];
      this.PerivisionLine = d["PerivisionLine"];
      this.PerivisionPACU = d["PerivisionPACU"];
    })
  }

  GetConfig() {
    var linecolors = Object.assign({}, ...this.PerivisionLine.map((x) => ({ [x.Metric]: x.LineColor })));
    var linestyle = Object.assign({}, ...this.PerivisionLine.map((x) => ({ [x.Metric]: "0,0" })));
    var linelabels = Object.assign({}, ...this.PerivisionLine.map((x) => ({ [x.Metric]: x.LineLabel })));

    var configLineGraph = {
      axisclass: "perivisionaxis",
      key: "TOD",
      linecolors: linecolors,
      linestyle: linestyle,
      metriclabels: linelabels
    };

    return configLineGraph;
  }

  GetOvertimeCases(facility: string, location: string, hour: number) {
    if (this.overtimeview.indexOf(facility + '_' + location + '_' + hour.toString()) < 0) {
      return [];
    }

    var dateThreshold = new Date((this.filters.caseDate + "T00:00:00").replace(/-/g, '\/').replace(/T.+/, ''));
    dateThreshold.setHours(hour);

    //console.log(dateThreshold.toLocaleString());

    if (this.PerivisionSchedule != undefined) {
      var x = this.PerivisionSchedule.filter((c: any) => c.Facility.toLowerCase() == facility.toLowerCase() && c.Location.toLowerCase() == location.toLowerCase() && new Date(c.Predicted_Wheels_Out).getTime() >= dateThreshold.getTime());
      if (x != undefined && x.length > 0) {
        return x;
      }
    }
    return [];
  }

  ToggleOvertimeView(component: string) {
    var i = this.overtimeview.indexOf(component);

    if (i >= 0) {
      this.overtimeview.splice(i, 1);
    }
    else {
      this.overtimeview.push(component);
    }
  }
}

