import { PieData, PieModel } from "../models/piemodel";
import * as d3 from 'd3';

export class PieChart {
  _config: PieModel
  pieclick: any;
  private isPieSelected: boolean = false;
  deselectpie: any;

  private selectedBar: any;
  private _svg: any;
  constructor(config: PieModel) {
    this._config = config;
  }
  Render() {
    d3.select(this._config.container).selectAll("*").remove();

    const radius = Math.min(this._config.width, this._config.height) / 2 - 2;// - 15;

    let _width = this._config.width;// (radius + (this._config.margin_left + this._config.margin_right) );
    let _height = (this._config.height);
    //let _width = (this._config.width + (this._config.margin_left + this._config.margin_right));
    //let _height = (this._config.height + (this._config.margin_top + this._config.margin_bottom));

    let svg = d3.select(this._config.container)
      .append("svg")
      .on("click", () => {

        if (!this.isPieSelected) {
          this._svg.selectAll(".arcs").select("path").style("opacity", 1);
          this.selectedBar = undefined;
          if (this.deselectpie) {
            this.deselectpie();
          }
        }
        this.isPieSelected = false;
      })
      .attr("width", _width)
      .attr("height", _height);
    this._svg = svg;

    const g = svg.append("g")
      .attr("transform", "translate(" + ((this._config.width / 2)) + "," + ((this._config.height / 2)) + ")");

    //let keys = this._config.chart_data.map(k => k.key);
    //const color = this._config.color_scale ?? d3.scaleOrdinal(['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de'])
    //d3.scaleOrdinal(this._config.color_scale?.length > 0 ? this._config.color_scale : ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de'])
    //.domain(keys);

    const pie = d3.pie<PieData>().value((d) => d.value);

    /* ------- TOOLTIP -------*/
    const _tooltip = d3.select(this._config.container)
      .append("div")
      .attr("class", "d3-tooltip-new")
      .style("display", "none")
      .style("opacity", 0)
      .style("z-index", "10000")
      .style("position", "absolute")
    // .style("text-align", "center")
    //// .style("font-weight", "bold")
    // .style("padding", "10px")
    // .style("background", "#fff")
    // .style("color", "#cacaca")
    // .style("border-radius", "2px")
    // .style("border", "solid #ccc 1px");

    /* ------- ARC TO PIE CHART -------*/
    const path = d3.arc<d3.PieArcDatum<PieData>>().outerRadius(radius - 10).innerRadius(this._config.chart_type == 'pie' ? 0 : radius / 2);
    const pathHover = d3.arc<d3.PieArcDatum<PieData>>().outerRadius(radius - 7).innerRadius(this._config.chart_type == 'pie' ? 0 : radius / 2);

    /* ------- ARC TO PIE LINES & TEXTS -------*/
    const textArc = d3.arc<d3.PieArcDatum<PieData>>().innerRadius(radius * 0.9).outerRadius(radius * 0.9);
    const textArc1 = d3.arc<d3.PieArcDatum<PieData>>().outerRadius(radius * 0.8).innerRadius(radius * 0.7);


    /* ------- DRAW PIE -------*/
    const pieArc = g.selectAll(".arcs")
      .data(pie(this._config.chart_data))
      .enter().append("g")
      .attr("class", "arcs")
      .append("path")
      .style("stroke", "#fff")
      .style("stroke-width", 1)
      .attr("id", (d1: any) => {
        return this._config.container.replace("#", "") + d1.data.key.split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
      })
      .attr("d", path)
      .attr("fill", function (d) { return d.data.color; });

    // if (this._config.tooltip_keys && this._config.tooltip_keys.length > 0) {
    pieArc.on("mouseover", function () {
      let currentPath = d3.select<SVGElement, d3.PieArcDatum<PieData>>(this)
      currentPath
        .transition()
        .duration(500)
        .ease(d3.easeBounce)
        .attr('d', pathHover);
      currentPath.style("filter", "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))");
      _tooltip.style("display", "block").style("opacity", 1);
    })
      .attr("class", "mousehover-paths")
      .attr("id", (d: any) => {
        return this._config.container.replace("#", "") + (d.data.key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "");
      })
      .on("click", (d: any, val: any) => {
        if (this.pieclick) {
          if (this.selectedBar == val.data.key) {
            this._svg.selectAll(".arcs").select("path").style("opacity", 1);
            this.selectedBar = undefined;
            if (this.deselectpie) {
              this.deselectpie();
            }
            this.isPieSelected = false;

          } else {
            this.selectedBar = val.data.key;
            this.isPieSelected = true;

            let obj = {}
            obj["data"] = val.data;
            obj["id"] = this._config.container.replace("#", "") + "@@" + (val.data.key).split(' ').join('').replace("<", "lt").replace(">", "gt").replace(/[^a-zA-Z0-9 ]/g, "");


            this.ClickDisable(val.data);
            this.pieclick(obj);
          }

        }
      })
      .on("mousemove", (d: any, val: any) => {

        let w = this._config.width - this._config.margin_left - this._config.margin_right;
        _tooltip.style("top", (d.layerY + 30) + 'px').style("left", (w < d.layerX + 250 ? d.layerX - 100 : d.layerX) + 'px')
          .style("border", () => { return "solid 1px " + val.data.color; })
          .style("display", "block").style("opacity", 1).style("height", "auto")
          .html(() => {

            let html = "";
            /*For group Bar charts*/
            if (val.data) {
              // html += `<div style='text-align:center;font-weight:bold;margin-bottom:5px;'>${val.data.key}</div>`;

              let data = val.data;
              html += "<div class='body'>" + this.SingleBar(data, data.key);
              //if (this._config.tooltip_keys && this._config.tooltip_keys.length > 0 && data.additional_data) {
              //  html += "<div style='border-bottom:1px dotted gray;margin-bottom:7px;'></div>"
              //}

            }
            else {
              /*For single Bar charts*/
              let data = val.data;
              html += "<div class='body'>" + this.SingleBar(data, data.key);
            }
            return html;

            //if (this._config.tooltip_keys && this._config.tooltip_keys.length > 0) {
            //  let html = "";
            //  for (var i = 0; i < this._config.tooltip_keys.length; i++) {
            //    let t = this._config.tooltip_keys[i];
            //    if (t.display_text) { html += `<span class='text'>${val.data.additional_data[t.display_text]}</span>`; }
            //    if (t.display_key) { html += `<span class='value'>${val.data.additional_data[t.display_key]}</span>`; }
            //    html += "</br>";
            //  }
            //  return html;
            //}
            //else {
            //  return `<span class='text'>${val.data.key}</span>&nbsp;<span class='value'>${val.data.value}</span>`;
            //}
          });
      })
      .on("mouseout", function () {
        let currentPath = d3.select<SVGElement, d3.PieArcDatum<PieData>>(this)
        currentPath
          .transition()
          .duration(500)
          .ease(d3.easeBounce)
          .attr('d', path);
        currentPath.style("filter", null);
        _tooltip.style("display", "none").style("opacity", 0);
      });
    // }


    if (this._config.display_keys && this._config.display_keys.length > 0 && this._config.is_display_text) {
      /* ------- SLICE TO TEXT POLYLINES -------*/

      let polyline = g.selectAll("polyline")
        .data(pie(this._config.chart_data), (d: any) => { return d.data.key; });

      polyline.enter()
        .append("polyline")
        // .style("stroke", (d: any) => { return color(d.data.key); })
        .attr("points", (d: any) => {
          var interpolate = d3.interpolate(d, d);
          var d21 = interpolate(1);
          var pos = textArc.centroid(d21);
          pos[0] = radius * 0.95 * ((d21.startAngle + (d21.endAngle - d21.startAngle) / 2) < Math.PI ? 1 : -1);
          return [textArc1.centroid(d21), textArc.centroid(d21), pos].toString();
        });



      /* ------- DRAW PIE TEXTS -------*/

      let text = g.selectAll("text")
        .data(pie(this._config.chart_data), (d: any) => { return d.data.key; });

      text.enter()
        .append("text")
        .attr("class", "pie-label")
        .style("font-size", "15px")
        .attr("dy", ".35em")
        .text((d: any) => {
          if (this._config.display_keys.length > 0) {
            let html = "";
            for (var i = 0; i < this._config.display_keys.length; i++) {
              let t = this._config.display_keys[i];
              if (t.display_text) {
                html += d.data.additional_data[t.display_text];
              }
              if (t.display_key) {
                html += d.data.additional_data[t.display_key]
              }
            }
            return html;
          }
          else {
            return d.data.key;
          }
          // d.data.key;
        })
        .attr("transform", function (d) {
          var interpolate = d3.interpolate(d, d);
          var d21 = interpolate(1);
          var pos = textArc.centroid(d21);
          pos[0] = radius * ((d21.startAngle + (d21.endAngle - d21.startAngle) / 2) < Math.PI ? 1 : -1);
          return "translate(" + pos + ")";
        })
        .style("text-anchor", function (d) {
          var interpolate = d3.interpolate(d, d);
          var d21 = interpolate(1);
          return (d21.startAngle + (d21.endAngle - d21.startAngle) / 2) < Math.PI ? "start" : "end";
        });
    }
  }
  /* Tooltip single bar */
  private SingleBar(data: PieData, key: string) {
    let html = "";
    //for (var i = 0; i < d1.data.length; i++) {
    //let data = d1.data[i];
    if (data && data.value) {
      html += `<div style="display:flex;text-align:center;">
                            <div style="background-color:${data.color};" class="marker"></div>
                            <div class="marker-text">${key}:</div>
                            <div class="marker-value">${data.value}%</div>
                         </div>`;

      if (this._config.tooltip_keys && this._config.tooltip_keys.length > 0 && data.additional_data) {
        for (var j = 0; j < this._config.tooltip_keys.length; j++) {
          let g = this._config.tooltip_keys[j];
          html += `<div class="additional-text" style="padding:5px;">${g.display_text} : ${data.additional_data[g.display_key]}</div>`
        }
      }

      // }
    }
    return html;
  }

  ClickDisable(head: PieData) {
    let h = this._config.container.replace("#", "") + head.key.split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
    this._svg.selectAll(".arcs").each((child: any) => {
      let h1 = this._config.container.replace("#", "") + child.data.key.split(' ').join('').replace(/[^a-zA-Z0-9 ]/g, "");
      if (h == h1) {
        d3.select("#" + h1)
          .style("opacity", 1);
      }
      else {
        d3.select("#" + h1)
          .style("opacity", 0.2);
      }
    })
  }
}
