<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'timestat'"></app-filters>
</div>

<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Time Stats for&nbsp;
          <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
          {{filters?.facility=="All"?'All Facilities':''}}&nbsp;
          <span *ngIf="filters!=undefined && filters?.surgeService!='All'">{{filters?.surgeService}}</span>&nbsp; {{filters?.surgeService=="All"?'All Services':''}}&nbsp;{{totalCases}} &nbsp;Total Cases
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-body">

  <div class="container-xl">
    <div class="row row-deck row-cards">
      <ng-container *ngIf="occupancy!=undefined && occupancy.length>0">
        <div class="col-xxl-4 col-xl-4 col-md-4" *ngFor="let oc of occupancy">
          <div class="card">
            <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric"
                 (click)="GetMetricDescription(oc.Metric,oc.MetricName)">
              <h4 class="card-title" style="cursor:pointer;">{{oc.MetricName}}</h4>
            </div>
            <div class="card-body p-10 click-body" data-bs-toggle="modal" style="text-align: center !important; width: 60%; margin: 0 auto;">
              <div>
                <div class="row" *ngIf="oc.IsInsights && oc.IsInsights=='Y'">
                  <div class="col" style="text-align:right;">
                    <img src="./assets/insights.png" style="width: 30px; height: 30px; cursor: pointer;" (click)="GetInsights(oc.Metric,oc.MetricName)" data-bs-toggle="modal" data-bs-target="#modal-recomendations" />
                  </div>
                </div>
                <div style="border: solid 1px #206bc4;border-radius:7px;">
                  <div style="background-color: #206bc4; height: 30px; color: #fff; text-align: center; padding-top:7px; font-size: 15px; ">
                    Average Rooms/day
                  </div>
                  <div style="height: 50px; font-size: 40px; padding-top: 17px; color: #206bc4;"> {{oc.RoomCount}}</div>
                </div>
                <!--<app-occupancy [container]="'#occupancy' + oc.Metric" [occupied]="oc.RoomCount" [allocated]="oc.AllocatedCount" [configuration]="occupanceMax">
        <div id="{{'occupancy'+oc.Metric}}" style="text-align: center !important;"></div>
      </app-occupancy>-->
              </div>
            </div>
            <div class="occupaid">
              Cumulative overtime cases
            </div>
            <div class="card-footer click-footer" style="text-align: center!important;height:120px;overflow-y:auto;margin:0 auto;font-size:1.25rem">
              <table style="padding: 0px !important; text-align: center;">
                <tr *ngFor="let oc of GetOccupancyTable(oc.Metric)">
                  <td style="padding:2px !important">
                    {{oc.SurgService}} &nbsp;&nbsp;
                  </td>
                  <td style="padding:2px !important">
                    <b>{{oc.SurgServiceCount}}</b>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-md-12" *ngIf="filters?.location.toLowerCase()!='nora'">
        <div class="card">
          <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric"
               (click)="GetMetricDescription('RoomUseByHour','Room Use By Hour')">
            <h4 class="card-title" style="cursor:pointer;">Room Use By Hour</h4>
          </div>
          <div class="card-body p-0 click-body" data-bs-toggle="modal" *ngIf="barData!=undefined">
            <app-barline [container]="'#barline'" [barData]="barData" [screen]="'timestat'">
              <div id="barline" style="width: 97%;"></div>
            </app-barline>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric"
               (click)="GetMetricDescription('PhaseTimes','Case Phase Durations')">
            <h4 class="card-title" style="cursor:pointer;">Case Phase Durations</h4>

          </div>
          <div class="card-body p-0 click-body" data-bs-toggle="modal" *ngIf="timeLineData!=undefined">
            <app-timeline [container]="'#timeline'" [timeLineData]="timeLineData">
              <div id="timeline" style="width: 97%;margin-top: 50px;"></div>
            </app-timeline>
           
            <div *ngIf="timeTableData!=undefined" class="phasetimetable" style="padding-top:50px;">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th *ngFor="let t of timeTableData;let i = index" [ngStyle]="bgColors[i]">{{t.MetricName}}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let m of aggregate">
                    <td style="background-color: #bbbec1;">{{m}}</td>
                    <td *ngFor="let t of timeTableData;let i = index" [ngStyle]="bgColors[i]">{{t[m]}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

  <div class="modal modal-blur fade show" id="modal-metric" tabindex="-1" style="display: none;" aria-modal="true"
       role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header metrictitle">
          <h5 class="modal-title">Definition of {{metricName}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div [innerHtml]="metric"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-blur fade show blur" id="modal-recomendations" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="min-width: 1000px;min-height:50px;overflow-y:auto">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title" *ngIf="filters">
          Insights for: <span style="color:blue!important">{{metricName}}</span> Period: <span style="color:blue!important">{{filters.fromDate}}</span> to <span style="color:blue!important">{{filters.toDate}}</span> Facility: <span style="color:blue!important">{{filters.facility}}</span> Location: <span style="color:blue!important">{{filters.location}}</span> Service: <span style="color:blue!important">{{filters.surgeService}}</span>
        </h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <div class="card" style="text-align: center;">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div *ngIf="insightsData" style="height: 500px; overflow-y: auto;">
              <app-dynamic-recomendations [recomendations]="insightsData" [recomendationString]="undefined" [recomendationFilter]="insightsFilter"></app-dynamic-recomendations>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
