import * as d3 from 'd3';


export class Gauge {

  private container: string | undefined;
  private gaugevalue: number;
  private configuration: any;

  private range: any | undefined;
  private r: any | undefined;
  private pointerHeadLength: any | undefined;
  private value: any | 0;

  private svg: any | undefined;
  private arc: any | undefined;
  private scale: any | undefined;
  private ticks: any | undefined;
  private tickData: any | undefined;
  private pointer: any | undefined;
  private numberText: any | undefined;
  

  //private donut: any | undefined;

  private config: any = {
    size: 200,
    margin: 15,
    clipWidth: 200,
    clipHeight: 170,
    ringInset: 20,
    ringWidth: 20,

    pointerWidth: 10,
    pointerTailLength: 5,
    pointerHeadLengthPercent: 0.9,

    minValue: 0,
    maxValue: 100,

    minAngle: -90,
    maxAngle: 90,

    transitionMs: 750,

    majorTicks: 10,
    labelFormat: d3.format('<,d'),
    labelInset: 10,

    arcColorFn: d3.interpolateHsl(d3.rgb('#cc1c16'), d3.rgb('#62ce48')),
    targetValue: -1,
    targetText: ''
  };
  constructor(container: string, gaugevalue: number, configuration: any) {
    this.container = container;
    this.gaugevalue = gaugevalue;
    this.configuration = configuration;
    if (configuration.Metric != undefined && configuration.Metric!='' && configuration.Metric.toLowerCase() == 'dormancy_twoplussurgeons') {
      configuration.arcColorFn = d3.interpolateHsl(d3.rgb('#62ce48'), d3.rgb('#cc1c16'));
    }
    else {
      configuration.arcColorFn = d3.interpolateHsl(d3.rgb('#cc1c16'), d3.rgb('#62ce48'));
    }
    this.configure(configuration);
  }
  Render() {
    if (this.gaugevalue == -1) {
      d3.select("" + this.container).html("<img src='/secure/Content/Images/info.png'>");
      return;
    }
    else if (this.gaugevalue > 100) {
      this.gaugevalue = 100;
    }
    this.draw();
  }
  private draw() {

    if (this.gaugevalue < this.config.minValue || this.gaugevalue > this.config.maxValue) {
      this.gaugevalue = this.config.minValue;
    }

    this.svg = d3.select("" + this.container)
      .append("div")
      .classed("orbisgaugecontainer", true)
      .append('svg:svg')
      .attr('class', 'gauge')
      .attr('viewBox', "0 0 " + this.config.clipWidth + " " + this.config.clipHeight)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    var centerTx = this.centerTranslation();

    var arcs = this.svg.append('g')
      .attr('class', 'arc')
      .attr('transform', centerTx);

    arcs.selectAll('path')
      .data(this.tickData)
      .enter().append('path')
      .attr('fill', (d: any, i: any) => {
        return this.config.arcColorFn(d * i);
      })
      .attr('d', this.arc);

    var lg = this.svg.append('g')
      .attr('class', 'label')
      .attr('transform', centerTx);
    lg.selectAll('text')
      .data(this.ticks)
      .enter().append('text')
      .attr('transform', (d: any) => {
        var ratio = this.scale(d);
        var newAngle = this.config.minAngle + (ratio * this.range);
        return 'rotate(' + newAngle + ') translate(0,' + (this.config.labelInset - this.r) + ')';
      })
      .text(this.config.labelFormat);

    var lineData = [[this.config.pointerWidth / 2, 0],
    [0, -this.pointerHeadLength],
    [-(this.config.pointerWidth / 2), 0],
    [0, this.config.pointerTailLength],
    [this.config.pointerWidth / 2, 0]];
    var pointerLine = d3.line().curve(d3.curveMonotoneX);
    var pg = this.svg.append('g').data([lineData])
      .attr('class', 'pointer')
      .attr('transform', centerTx);

    this.pointer = pg.append('path')
      .attr('d', pointerLine)
      .attr('transform', 'rotate(' + this.config.minAngle + ')');

    //display value as a percentage rounded to nearest integer below the gauge
    var formatPercent = d3.format(',d');
    this.numberText = this.svg.append('text')
      .attr('x', this.r)
      .attr('y', this.config.clipHeight - 25)
      .attr('class', 'gaugevalue')
      .text(formatPercent(this.gaugevalue) + '%');

    //display line indicating target if specified
    if (this.config.targetValue >= 0) {
      var arrowData = [[0, 0],
      [0, 40]];

      var arrow = d3.line();
      var ratio = this.scale(this.config.targetValue);
      var newAngle = this.config.minAngle + (ratio * this.range);

      var ar = this.svg.append('g').data([arrowData])
        .attr('class', 'arrow')
        .attr('transform', centerTx);

      ar.append('path')
        .attr('d', arrow)
        .attr('transform', 'rotate(' + newAngle + ') translate(0,' + (this.config.labelInset - this.r) + ')'); //'rotate(45)');
    }

    //display target value text if specified
    if (this.config.targetText != '') {
      this.svg.append('text')
        .attr('x', this.r)
        .attr('y', this.config.clipHeight - 10)
        .attr('class', 'gaugetarget')
        .text(this.config.targetText);
    }

    this.update(this.gaugevalue === undefined ? 0 : this.gaugevalue, {});
  }
  private deg2rad(deg: number) {
    return deg * Math.PI / 180;
  }

  private newAngle(d: any) {
    var ratio = this.scale(d);
    var newAngle = this.config.minAngle + (ratio * this.range);
    return newAngle;
  }

  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      
      this.config[prop] = configuration[prop];
    }

    this.range = this.config.maxAngle - this.config.minAngle;
    this.r = this.config.size / 2; // - config.margin * 2;
    this.pointerHeadLength = Math.round(this.r * this.config.pointerHeadLengthPercent);

    // a linear scale that maps domain values to a percent from 0..1
    this.scale = d3.scaleLinear()
      .range([0, 1])
      .domain([this.config.minValue, this.config.maxValue]);

    this.ticks = this.scale.ticks(this.config.majorTicks);
    this.tickData = d3.range(this.config.majorTicks).map(() => { return 1 / this.config.majorTicks; });

    this.arc = d3.arc()
      .innerRadius(this.r - this.config.ringWidth - this.config.ringInset)
      .outerRadius(this.r - this.config.ringInset)
      .startAngle((d: any, i) => {
        var ratio = d * i;
        return this.deg2rad(this.config.minAngle + (ratio * this.range));
      })
      .endAngle((d: any, i) => {
        var ratio = d * (i + 1);
        return this.deg2rad(this.config.minAngle + (ratio * this.range));
      });
  }
  private centerTranslation() {
    return 'translate(' + this.r + ',' + this.r + ')';
  }

  private isRendered() {
    return (this.svg !== undefined);
  }
  public update(updategaugevalue: any, newConfiguration: any) {
    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }
    this.gaugevalue = updategaugevalue;
    var ratio = this.scale(this.gaugevalue);
    var newAngle = this.config.minAngle + (ratio * this.range);
    this.pointer.transition()
      .duration(this.config.transitionMs)
      .attr('transform', 'rotate(' + newAngle + ')');

    var formatPercent = d3.format(',d');
    this.numberText.transition()
      .duration(this.config.transitionMs)
      .text(formatPercent(this.gaugevalue) + '%');

  }
}
