<div #screen>
  <div class="sticky-top">
    <app-filters (onfilter)="OnFilter($event)" [reportName]="'blockutil'"></app-filters>
  </div>
  <div class="container-xl container-sm container-md">
    <!-- Page title -->
    <div class="page-header d-print-none">
      <div class="row g-2 align-items-center">
        <div class="col-8">
          <!-- Page pre-title -->
          <div class="page-pretitle">

          </div>
          <h2 class="page-title">
            <!--*ngIf="filters?.facility!='All'"-->
            Block Utilization for
            &nbsp;
            <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
            {{filters?.facility=="All"?'All Facilities':''}} &nbsp;
            <span *ngIf="filters!=undefined && filters.surgeService!='All'">
              {{filters?.surgeService}}
            </span>&nbsp; {{filters?.surgeService=="All"?'All Services':''}}
            &nbsp;{{totalCases}}  &nbsp;Total Cases&nbsp;&nbsp;


          </h2>

          <!--<h2 class="page-title">Select a Facility to display Block Utilization</h2>--> <!--*ngIf="filters?.facility=='All'"-->
        </div>
        <div class="col-4" style="text-align:right">
          <!--<a title="Recomendations" data-bs-toggle="modal" data-bs-target="#modal-all-recomendations" (click)="GetAllRecomendations()" style="cursor:pointer;color:darkblue;">
           Recomendations
          </a>-->

          <!--*ngIf="filters?.facility!='All'"-->
          <a title="Download PDF Reports" data-bs-toggle="modal" data-bs-target="#modal-pdf-reports" style="cursor:pointer;color:darkblue;">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-text" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
              <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
              <path d="M9 9l1 0"></path>
              <path d="M9 13l6 0"></path>
              <path d="M9 17l6 0"></path>
            </svg>
            PDF Reports
          </a>
          <a title="Download Image" (click)="downloadImage()" style="cursor:pointer;"
             aria-label="Download Image">
            <!-- Download SVG icon from http://tabler-icons.io/i/file-download -->
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="12" y1="11" x2="12" y2="17" /><polyline points="9 14 12 17 15 14" /></svg> Image
          </a>
        </div>
        <!-- Page title actions -->
        <!--<div class="col-12 col-md-auto ms-auto d-print-none">
          <div class="btn-list">
          </div>
        </div>-->
      </div>
    </div>
  </div>

  <div class="page-body">
    <!--*ngIf="filters?.facility!='All'"-->

    <div class="container-xl container-sm container-md">
      <p-tabs (onSelect)="OnSelectTab($event)" *ngIf="dataByService!=undefined||dataByDay!=undefined">
        <p-tab [tabTitle]="'By Service'" [active]="filters.surgeService=='All'" [id]="'Service'" *ngIf="filters.surgeService=='All'">
          <div class="row">
            <div class="col-xxl-1 col-xl-1 col-md-0 col-sm-0"></div>
            <div class="col-xxl-10 col-xl-10 col-md-12 col-sm-12">
              <div class="card">
                <div class="card-header metrictitle">
                  <h3 class="card-title">By Service</h3>

                </div>
                <div class="card-body p-0 click-body" *ngIf="dataByService!=undefined" style="text-align: center">
                  <app-simple-bar [container]="'#serviceBar'" [barData]="dataByService" [configuration]="configServiceBar">
                    <div id="serviceBar"></div>
                  </app-simple-bar>
                </div>
              </div>
            </div>
            <div class="col-xxl-1 col-xl-1 col-md-0 col-sm-0"></div>
          </div>
          <div class="row">
            <div class="col-xxl-12 col-xl-12 col-md-12 col-sm-12">
              <div class="card">
                <div *ngIf="tableDataByService!=undefined && tableDataByService!=null && tableDataByService.length>0">
                  <app-block-table [data]="tableDataByService"></app-block-table>
                </div>

              </div>
            </div>
          </div>
        </p-tab>
        <p-tab [tabTitle]="'By Day of the Week'" [active]="filters.surgeService!='All'" [id]="'Day'">
          <div class="row">
            <div class="col-xxl-2 col-xl-2 col-md-1 col-sm-0"></div>
            <div class="col-xxl-8 col-xl-8 col-md-10 col-sm-12">
              <div class="card">
                <div class="card-header metrictitle">
                  <h3 class="card-title">By Day</h3>
                </div>
                <div class="card-body p-0 click-body" *ngIf="dataByDay!=undefined" style="text-align: center;">
                  <app-simple-bar [container]="'#dayBar'" [barData]="dataByDay" [configuration]="configDayBar">
                    <div id="dayBar"></div>
                  </app-simple-bar>
                </div>
              </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-md-1 col-sm-0"></div>
          </div>
          <div class="row">
            <div class="col-xxl-12 col-xl-12 col-md-12 col-sm-12">
              <div class="card">
                <div *ngIf="tableDataByDay!=undefined && tableDataByDay!=null && tableDataByDay.length>0">
                  <app-block-table [data]="tableDataByDay"></app-block-table>
                </div>
              </div>
            </div>
          </div>
        </p-tab>












        <p-tab [tabTitle]="'Recommendations'"    [id]="'Recomendations'">
          <div class="row">
           
            <div class="col-12">
              <div class="card">
                
                <div class="card-body p-0 click-body"  style="text-align: center;">
                  <table class="casemixgraphic fixTableHead " *ngIf="allRecomendations!=undefined" style="width:100%">

                    <thead>
                      <tr>

                        <th>
                          Facility
                        </th>
                        <th>
                          Location
                        </th>
                        <th>
                          SurgService
                        </th>
                        <!--<th>
                          Recommendation Type
                        </th>-->
                        <th>
                          Recommendations
                        </th>

                      </tr>
                    </thead>

                    <caption *ngIf="allRecomendations!=undefined && allRecomendations.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
                    <tbody>
                      <tr class="recomendation" *ngFor="let v of allRecomendations" (click)="GetRecomentationPopup(v.RecommendationID,v.Recommendation)" data-bs-toggle="modal" data-bs-target="#modal-recomendations">
                        <td>{{v.Facility}}</td>
                        <td>{{v.Location}}</td>
                        <td>{{v.SurgService}}</td>
                        <!--<td>{{v.Recommendation_Type}}</td>-->
                        <td>{{v.Recommendation}}</td>

                      </tr>
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
            <div class="col-xxl-2 col-xl-2 col-md-1 col-sm-0"></div>
          </div>
           
        </p-tab>

      </p-tabs>
    </div>
  </div>

</div>






<div class="modal modal-blur fade show" id="modal-pdf-reports" tabindex="-1" style="display: none;width:100%"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="width:100%;overflow-y:auto;max-width:1000px !important">
    <div class="modal-content" style="width: 100%">
      <div class="row">
        <div class="modal-header metrictitle">

          <div class="col-6" style="text-align:left;">  <h5 class="modal-title">PDF Reports</h5></div>

          <div class="col-6">
            <select [(ngModel)]="dateType" (change)="GetReport()">
              <option value="quarterly">Quarterly</option>
              <option value="monthly">Monthly</option>
            </select>&nbsp;&nbsp;&nbsp;&nbsp;
            <select [(ngModel)]="reportType" (change)="GetReport()">
              <option value="current">Current</option>
              <option value="archive">Archive</option>
            </select>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>


      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <div class="card" style="text-align: center;">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div tabindex="-1" style="margin:10px">
              <!--*ngIf="filters?.facility=='All'"-->

              <div class="row">
                <div class="col-12" *ngIf="blockreportMonths.length==0" style="text-align:center">
                  No Reports available
                </div>
                <div class="col-6" *ngFor="let m of blockreportMonths">
                  <div class="modal-content">
                    <div class="modal-header metrictitle">
                      <h5 class="modal-title">{{m}}</h5>
                    </div>
                    <div class="modal-body p-0" style="width:100%;text-align:center;">
                      <div class="card" style="text-align: center;">
                        <div class="card-body-scrollable card-body-scrollable-shadow">
                          <div>
                            <table class="casemixgraphic">
                              <thead>
                                <tr>
                                  <th>Facility</th>
                                  <th>Location</th>
                                  <th style="width:50px">Link</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let d of blockreport[m]">

                                  <td>{{d.Facility}}</td>
                                  <td>{{d.Location}}</td>
                                  <td><img src="./assets/view.png" style="width:20px;height:20px;cursor:pointer;" (click)="OpenPDF(d.Link)" /></td>
                                </tr>


                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal modal-blur fade show blur" id="modal-all-recomendations" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="min-width: 1000px;min-height:50px;overflow-y:auto">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Recommendations</h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <div class="card" style="text-align: center;">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div *ngIf="allRecomendations">

              <div *ngIf="allRecomendations" class="page-body">
                <div class="container-xl">

                  <div class="card" style="max-height: calc(100vh - 260px);">
                    <div class="card-body-scrollable card-body-scrollable-shadow">

                      <table class="casemixgraphic fixTableHead " *ngIf="allRecomendations!=undefined">

                        <thead>
                          <tr>

                            <th>
                              Facility
                            </th>
                            <th>
                              Location
                            </th>
                            <th>
                              SurgService
                            </th>
                            <th>
                              Recommendation Type
                            </th>
                            <th>
                              Recommendation
                            </th>

                          </tr>
                        </thead>

                        <caption *ngIf="allRecomendations!=undefined && allRecomendations.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
                        <tbody>
                          <tr class="recomendation" *ngFor="let v of allRecomendations" (click)="GetRecomentationPopup(v.RecommendationID,v.Recommendation)" data-bs-toggle="modal" data-bs-target="#modal-recomendations">
                            <td>{{v.Facility}}</td>
                            <td>{{v.Location}}</td>
                            <td>{{v.SurgService}}</td>
                            <td>{{v.Recommendation_Type}}</td>
                            <td>{{v.Recommendation}}</td>

                          </tr>
                        </tbody>

                      </table>

                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                    </div>
                  </div>

                </div>
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal modal-blur fade show blur" id="modal-recomendations" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="min-width: 1000px;min-height:50px;overflow-y:auto">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Recommendations</h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <div class="card" style="text-align: center;">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div *ngIf="recomendations">
              <app-dynamic-recomendations [recomendations]="recomendations" [recomendationString]="recomendationString"></app-dynamic-recomendations>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
