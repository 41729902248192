import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';
import { TabComponent } from '../tab/tab.component';
import { NgxCaptureService } from 'ngx-capture';
import { tap } from 'rxjs/operators';
import * as d3 from 'd3';

@Component({
  selector: 'app-blockutilization',
  templateUrl: './blockutilization.component.html',
  styleUrls: ['./blockutilization.component.css']
})
export class BlockutilizationComponent implements OnInit {

  recomendations: any;
  allRecomendations: Array<any>;
  recomendationString: string;

  filters: Filters;
  totalCases: number;
  currentTab: TabComponent;
  //blockreportFull: any;
  blockreport: any;
  blockreportMonths: Array<string> = [];
  reportType: string = "current";
  dateType: string = "quarterly";
  configServiceBar: any = { height: 250, width: 700, margin_top: 30, margin_right: 90, margin_left: 40, yaxis_ticks: 5, margin_bottom: 60, categories: ["In Block"], ylabel: "Utilization", max: 110 };
  configDayBar: any = { height: 250, width: 700, margin_top: 30, margin_left: 40, margin_right: 90, yaxis_ticks: 5, margin_bottom: 45, categories: ["In Block"], ylabel: "Utilization", max: 110 }
  dataByService: Array<any>;
  dataByDay: Array<any>;
  @ViewChild('screen', { static: true }) screen: any;
  captureService: any;
  img: any;
  tableDataByService: Array<any>;
  tableDataByDay: Array<any>;

  constructor(private commonService: CommonService, captureService: NgxCaptureService) {
    this.currentTab = new TabComponent();
    this.currentTab.active = true;
    this.currentTab.id = "Service";
    this.currentTab.title = "By Service";
    this.captureService = captureService;
  }
  downloadBase64File = function (contentType, base64Data, fileName) {
    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  downloadImage() {

    d3.select("#serviceBar").select("svg").attr("width", (this.configServiceBar.width)).attr("height", (this.configServiceBar.height));
    d3.select("#dayBar").select("svg").attr("width", (this.configServiceBar.width)).attr("height", (this.configServiceBar.height));

    this.captureService.getImage(this.screen.nativeElement, true)
      .pipe(
        tap(img => {
          this.img = img;
          this.downloadBase64File('image/png', img, 'Blockutilization.png');
          d3.select("#serviceBar").select("svg").attr("width", null).attr("height", null);
          d3.select("#dayBar").select("svg").attr("width", null).attr("height", null);
        })

      ).subscribe();

  }
  ngOnInit(): void {
    this.GetReport();
  }
  GetReport() {
    this.blockreport = undefined;
    this.blockreportMonths = [];
    this.commonService.GetReport(this.reportType, this.dateType)
      .subscribe((d: Array<any>) => {
        this.blockreport = d
        this.blockreportMonths = Object.keys(this.blockreport);
      });
  }
  //ChangeReportType() {
  //  this.blockreport = undefined;
  //  this.blockreportMonths = [];
  //  this.blockreport = this.blockreportFull.filter(f => f.ReportType == this.reportType)
  //  this.blockreportMonths = Object.keys(this.blockreport);
  //}
  OnFilter(e: Filters) {
    this.filters = e;
    if (this.filters.facility == "All") {
      return;
    }

    this.dataByService = undefined;
    this.dataByDay = undefined;
    this.tableDataByService = undefined;
    this.tableDataByDay = undefined;
    this.totalCases = undefined;
    if (this.filters.surgeService != "All") {
      this.currentTab.id = "Day";
    }
    else {

      this.currentTab.id = "Service";
    }
    this.LoadData();
    this.GetAllRecomendations();
  }
  OnSelectTab(t: TabComponent) {
    this.currentTab = t;
    if ((t.id == "Service" && t.title != "By Day of the Week") && (this.dataByService == undefined || this.dataByService == null)) {
      this.currentTab.id = "Service";
      this.LoadData();
    }
    if ((t.id == "Day" || t.title == "By Day of the Week") && (this.dataByDay == undefined || this.dataByDay == null)) {
      this.currentTab.id = "Day";
      this.LoadData();
    }
  }

  GetRecomentationPopup(recomendationId: number, r: string) {
    this.recomendationString = r;
    this.recomendations = undefined;
    this.commonService.GetBlockRecomendations(recomendationId).subscribe(d => {
      this.recomendations = d;
    });
  }
  GetAllRecomendations() {
    this.allRecomendations = undefined;
    this.commonService.GetAllBlockRecommendations(this.filters.facility, this.filters.location,
      this.filters.surgeService, this.filters.fromDate, this.filters.toDate).subscribe(d => {
        this.allRecomendations = d['Recomendations'];
      });
  }



  LoadData() {


    this.commonService.GetBlockData(this.filters.facility, this.filters.location,
      this.filters.surgeService, this.filters.fromDate, this.filters.toDate, this.currentTab.id)
      .subscribe(d => {

        if (this.currentTab.id == "Service") {
          this.dataByService = this.GenerateData(d["BlockData"]);
          this.tableDataByService = d["BlockData"];
          this.totalCases = d["TotalCases"];
        }
        else {
          this.dataByDay = this.GenerateData(d["BlockData"]);
          this.totalCases = d["TotalCases"];
          this.tableDataByDay = d["BlockData"];
        }
      });
  }
  private GenerateData(data: Array<any>) {
    let finalData = [];

    data.forEach(d => {
      if (parseInt(d["utilization"]) > 0) {
        let obj = {};
        obj["Category"] = d["GroupBy"];
        obj["Value"] = parseInt(d["utilization"]);
        obj["Label1"] = parseInt(d["utilization"]).toString() + '%';
        finalData.push(obj);
      }
    });
    return finalData;
  }
  OpenPDF(link) {
    window.open(link);
  }

  IsEnableRecomendation() {
    let user = sessionStorage.getItem("userlogin").toLowerCase();
    return user == 'nishray' || user == 'dkota';
  }
}
