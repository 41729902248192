import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-recomendations',
  templateUrl: './dynamic-recomendations.component.html',
  styleUrls: ['./dynamic-recomendations.component.css']
})
export class DynamicRecomendationsComponent implements OnInit {

  @Input()
  recomendations: any;
  @Input()
  recomendationString: string;
  //@Input()
  //recomendationFilter: any;

  recomendationFilterData: Array<any> = [];
  headerTableKeys: Array<string> = [];
  sortObject: any = {};
  recomendationsDataFiltered: any;

  filterChange: any = new Subject<any>();
  filterText: any = {};


  constructor(private commonService: CommonService) {

  }
  KeyUp(table: string, num: string = undefined) {
    this.recomendationsDataFiltered[table] = undefined;
    this.filterChange.next("");
    let condition = [];
    let keys = Object.keys(this.filterText).filter(k => !k?.toLowerCase().endsWith("_condition"));
    let fil = this.recomendations[table + '_F']
    this.recomendationsDataFiltered[table] = this.recomendations[table].filter(p => {

      for (var i = 0; i < keys.length; i++) {

        if (this.filterText[keys[i]] && (fil[keys[i]] == 'select')) {
          //condition[i] = (p[keys[i]].toString().toLowerCase().split(' ').join('').includes(this.filterText[keys[i]].toLowerCase().split(' ').join(''))
          condition[i] = (p[keys[i]].toString().toLowerCase().split(' ').join('') == this.filterText[keys[i]].toLowerCase().split(' ').join('')
            || this.filterText[keys[i]].toLowerCase().split(' ').join('') == ""
            || this.filterText[keys[i]].toLowerCase().split(' ').join('') == 'all')
        }
        else if (this.filterText[keys[i]] && (fil[keys[i]] == 'freetext')) {
          condition[i] = (p[keys[i]].toString().toLowerCase().split(' ').join('').includes(this.filterText[keys[i]].toLowerCase().split(' ').join(''))
            || this.filterText[keys[i]].toLowerCase().split(' ').join('') == ""
            || this.filterText[keys[i]].toLowerCase().split(' ').join('') == 'all')
        }
        else if (fil[keys[i]] == 'number') {
          let dynamiccondition = this.filterText[keys[i] + '_Condition'];
          dynamiccondition = (!dynamiccondition ? '=' : dynamiccondition);
          if (!this.filterText[keys[i]] || (this.filterText[keys[i]] && this.filterText[keys[i]].toString().toLowerCase().split(' ').join('') == "")) {
            condition[i] = true;
          }
          else if (dynamiccondition == '=') {
            condition[i] = p[keys[i]] == this.filterText[keys[i]];
          } else if (dynamiccondition == '>') {
            condition[i] = p[keys[i]] > this.filterText[keys[i]];
          }
          else {
            condition[i] = p[keys[i]] < this.filterText[keys[i]];
          }
        }
        else {
          condition[i] = true;
        }
      }
      return condition.filter(f => !f).length == 0;
    });
  }
  ngOnInit(): void {

    this.headerTableKeys = Object.keys(this.recomendations).filter(k => !k?.toLowerCase().endsWith("_c") && !k?.toLowerCase().endsWith("_f"));
    this.GenerateFilterData();
    this.recomendationsDataFiltered = JSON.parse(JSON.stringify(this.recomendations));
  }
  GenerateFilterData() {

    let onlyUnique = (value, index, array) => {
      return array.indexOf(value) === index;
    }

    //// usage example:
    //var a = ['a', 1, 'a', 2, '1'];
    //var unique = a.filter(onlyUnique);

    //console.log(unique); // ['a', 1, 2, '1']


    for (var i = 0; i < this.headerTableKeys.length; i++) {
      let f = this.recomendations[this.headerTableKeys[i] + "_F"];
      let c = this.recomendations[this.headerTableKeys[i] + "_C"];
      if (f) {
        for (var j = 0; j < c.length; j++) {
          if (f[c[j]] == 'select') {
            let d = this.recomendations[this.headerTableKeys[i]].map(k => k[c[j]]);
            //d.unshift("All");
            this.recomendations[this.headerTableKeys[i] + "_F"][c[j] + "_D"] = d.filter(onlyUnique)
              .sort(function (a, b) {
                if (a == b) return 0;
                if (a == 'All' || a == 'Over All') return -1;
                if (b == 'All' || b == 'Over All') return 1;

                if (a < b)
                  return -1;
                if (a > b)
                  return 1;
                return 0;
              });
            this.recomendations[this.headerTableKeys[i] + "_F"][c[j] + "_D"].unshift("All");
             /* .sort((a, b) => (a < b || a.toLowerCase().split(' ').join('') =='all' ? -1 : 1));*/
          }
        }
      }
    }
  }
  Sort(table: string, col: string) {
    /********************* To find previous is asc or desc *******************************/
    if (this.sortObject[table]) {
      if (this.sortObject[table][col]) {
        this.sortObject[table][col] = this.sortObject[table][col] == 'asc' ? 'desc' : 'asc';
      }
      else {
        this.sortObject[table][col] = 'asc';
      }
    }
    else {
      this.sortObject[table] = {};
      this.sortObject[table][col] = 'asc';
    }
    this.recomendationsDataFiltered[table] = this.commonService.Sort(this.recomendationsDataFiltered[table], col, this.sortObject[table][col]);
  }
}
