import { CommonChartModel, DisplayModel } from "./commonmodel";

export class PieModel {
  width: number = 0;
  height: number = 0;
  margin_left: number = 0;
  margin_right: number = 0;
  margin_top: number = 0;
  margin_bottom: number = 0;
  //color_scale: any | undefined = undefined;
  container: string = '';
  chart_type: 'pie' | 'donut' | undefined = 'pie';
  display_keys: DisplayModel[] = [];
  tooltip_keys: DisplayModel[] = [];
  chart_data: PieData[] = [];

  is_legend: boolean = false;
  is_display_text: boolean = false;
}


export class PieData {
  key: string = '';
  value: number = 0;
  color: string = ""
  additional_data: any = {};
}
