<div class="row">
  <div class="col">
    <div class="card" style="height: 30vh !important">

      <div class="card-body-scrollable card-body-scrollable-shadow">

        <table class="casemixgraphic fixTableHead-failed">
          <thead>
            <tr>
              <th style="min-width:80px !important;" (click)="Sort('CaseDate')">
                Case Date&nbsp;
                <img src="../assets/sort.png" alt="Orbis" style="width:15px;height:15px;cursor:pointer;">
              </th>
              <th style="min-width:80px !important;" (click)="Sort('Room')">
                Room&nbsp;
                <img src="../assets/sort.png" alt="Orbis" style="width:15px;height:15px;cursor:pointer;">
              </th>
              <th style="max-width:100px!important;" (click)="Sort('ProcedureDescription')">
                Procedure Description&nbsp;
                <img src="../assets/sort.png" alt="Orbis" style="width:15px;height:15px;cursor:pointer;">
              </th>
              <ng-container *ngFor="let k of adhocKeys">
                <th [style.min-width]="widths[k]" (click)="Sort(k+'_sort')">
                  {{k}}&nbsp;
                  <img src="../assets/sort.png" alt="Orbis" style="width:15px;height:15px;cursor:pointer;">
                </th>
              </ng-container>
            </tr>
          </thead>
          <caption *ngIf="failedcasesFiltered!=undefined && failedcasesFiltered.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
          <tr>
            <td>
              <select class="form-select" [(ngModel)]="caseDate" (ngModelChange)="this.filterChange.next($event)">
                <option *ngFor='let c of caseDateCollection' [value]="c">{{c}}</option>
              </select>

            </td>
            <td>
              <select class="form-select" [(ngModel)]="room" (ngModelChange)="this.filterChange.next($event)">
                <option *ngFor='let r of roomCollection' [value]="r">{{r}}</option>
              </select>


            </td>
            <td><input type="text" [(ngModel)]="procedureDesc" (ngModelChange)="this.filterChange.next($event)" style="width:100%" /></td>

            <td *ngFor="let k of adhocKeys">
              <ng-container *ngIf="adFilter && adFilter[k]=='select'">
                <select class="form-select" (change)="KeyUp()" [(ngModel)]="filterText[k]">
                  <option *ngFor="let r of adFilter[k+'_F']" [value]="r">{{r}}</option>
                </select>
              </ng-container>
              <ng-container *ngIf="(adFilter && adFilter[k]=='freetext') || (adFilter && !adFilter[k])">

                <input type="text" style="width:100%" [(ngModel)]="filterText[k]" [placeholder]="'Search '+k+'..'" (keyup)="KeyUp()" />
              </ng-container>

              <ng-container *ngIf="adFilter && adFilter[k]=='number'">
                <div class="row">
                  <div class="col">
                    <select class="form-select" [(ngModel)]="filterText[k+'_Condition']" style="width:100%" (change)="KeyUp()">
                      <option [value]="'='" selected>Equal to</option>
                      <option [value]="'>'">Greater than</option>
                      <option [value]="'<'">Less than</option>
                    </select>
                  </div>
                  <div class="col"> <input type="number" style="width:100%;height:29px;" [(ngModel)]="filterText[k]" [placeholder]="'Search '+k+'..'" (keyup)="KeyUp()" /></div>

                </div>


              </ng-container>

            </td>

            <!--<td>{{adhocKeys | json}}</td>
            <td><input type="text" [(ngModel)]="procedureDesc" (ngModelChange)="this.filterChange.next($event)" style="width:100%" /></td>
            <td><input type="text" [(ngModel)]="procedureDesc" (ngModelChange)="this.filterChange.next($event)" style="width:100%" /></td>-->
          </tr>
          <tbody *ngFor="let c of failedcasesFiltered">
            <ng-container *ngIf="adhocKeys.length>0">
              <ng-container *ngIf="c.Adhoc">
                <tr (click)="GetcaseDetail(c.ProcID)" class="rowhighlight"
                    [ngStyle]="c.isActive?{'background-color':'#dddcdc','font-weight':'bold'} : {'background-color': 'white','font-weight':'normal'}"
                    *ngFor="let a of c.Adhoc;let i=index">
                  <td [attr.rowspan]="c.Adhoc.length" *ngIf="i==0">{{c.CaseDate | date:'shortDate' }}</td>
                  <td [attr.rowspan]="c.Adhoc.length" *ngIf="i==0">{{c.Room}}</td>
                  <td title="{{c.ProcedureDescription}}" [attr.rowspan]="c.Adhoc.length" *ngIf="i==0">{{c.ProcedureDescription.substring(0,50)}}</td>
                  <td *ngFor="let k of adhocKeys">{{a[k]}}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="!c.Adhoc">
                <tr (click)="GetcaseDetail(c.ProcID)"
                    [ngStyle]="c.isActive?{'background-color':'#dddcdc','font-weight':'bold'} : {'background-color': 'white','font-weight':'normal'}"
                    class="rowhighlight">
                  <td>{{c.CaseDate | date:'shortDate' }}</td>
                  <td>{{c.Room}}</td>
                  <td title="{{c.ProcedureDescription}}">{{c.ProcedureDescription.substring(0,50)}}</td>
                  <td *ngFor="let k of adhocKeys"></td>
                </tr>
              </ng-container>

            </ng-container>

            <tr (click)="GetcaseDetail(c.ProcID)" *ngIf="adhocKeys.length==0"
                [ngStyle]="c.isActive?{'background-color':'#dddcdc','font-weight':'bold'} : {'background-color': 'white','font-weight':'normal'}"
                class="rowhighlight">
              <td>{{c.CaseDate | date:'shortDate' }}</td>
              <td>{{c.Room}}</td>
              <td title="{{c.ProcedureDescription}}">{{c.ProcedureDescription.substring(0,50)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row">

  <div class="col">
    <div class="card">
      <div class="card-body card-body-scrollable card-body-scrollable-shadow" style="padding:0px;overflow-x:hidden;">
        <h2 style="background-color:#ccc">&nbsp;Procedure Details</h2>
        <div class="row">
          <div class="col">
            <table class="table card-table table-vcenter" *ngIf="ORBISClosedCase!=undefined">

              <caption *ngIf="ORBISClosedCase!=undefined && ORBISClosedCase.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
              <tbody *ngFor="let c of metricfieldsLeft">
                <tr *ngIf="c.Field=='CaseDate'">
                  <td class="faildetailfield">{{getORBISClosedCaseFields(c.Field) }}</td>
                  <td>{{getORBISClosedCaseFieldValue(c.Field)| date:'shortDate' }}</td>

                </tr>
                <tr *ngIf="c.Field!='CaseDate' && timeComponents.indexOf(c.Field)>-1">

                  <td class="faildetailfield">{{getORBISClosedCaseFields(c.Field) }}</td>

                  <td>{{getORBISClosedCaseFieldValue(c.Field)  | date:'HH:mm' }}</td>
                </tr>

                <tr *ngIf="c.Field!='CaseDate' &&  timeComponents.indexOf(c.Field)==-1">

                  <td class="faildetailfield">{{getORBISClosedCaseFields(c.Field) }}</td>

                  <td>{{getORBISClosedCaseFieldValue(c.Field) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col">
            <table class="table card-table table-vcenter" *ngIf="ORBISClosedCase!=undefined">

              <caption *ngIf="ORBISClosedCase!=undefined && ORBISClosedCase.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
              <tbody *ngFor="let c of metricfieldsRight">
                <tr *ngIf="c.Field=='CaseDate'">
                  <td class="faildetailfield">{{getORBISClosedCaseFields(c.Field) }}</td>
                  <td>{{getORBISClosedCaseFieldValue(c.Field)| date:'shortDate' }}</td>

                </tr>
                <tr *ngIf="c.Field!='CaseDate' && timeComponents.indexOf(c.Field)>-1">

                  <td class="faildetailfield">{{getORBISClosedCaseFields(c.Field) }}</td>

                  <td>{{getORBISClosedCaseFieldValue(c.Field)  | date:'HH:mm' }}</td>
                </tr>

                <tr *ngIf="c.Field!='CaseDate' &&  timeComponents.indexOf(c.Field)==-1">

                  <td class="faildetailfield">{{getORBISClosedCaseFields(c.Field) }}</td>

                  <td>{{getORBISClosedCaseFieldValue(c.Field) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="row">

  <div class="col">
    <h2 style="background-color:#ccc">&nbsp;Failure Details</h2>
    <div>{{ORBISClosedCaseMetric[0]?.FailReason}}</div>
    <table class="table card-table table-vcenter" *ngIf="getMaxCountRowsFailcase()>0">
      <caption *ngIf="ORBISFailDetails!=undefined && ORBISFailDetails.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
      <tbody>
        <tr>
          <th *ngFor="let c of ORBISFailDetails;let i = index">{{getMaxCountcolumnvalues(i+1)}}</th>
        </tr>
        <tr>
          <td *ngFor="let c of ORBISFailDetails;let j = index">c.ColumnValue</td>
        </tr>
      </tbody>
    </table>
    <br />
    <div style="font-weight:600;" *ngIf="ORBISClosedCaseMetric[0]?.Cost!=null && ORBISClosedCaseMetric[0]?.Cost!=undefined">Hospital Cost Implications&nbsp;:&nbsp;<span style="color: #cc1c16">$ {{ORBISClosedCaseMetric[0]?.Cost  | number}}</span></div>
  </div>
</div>
