import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters'

@Component({
  selector: 'app-timestat',
  templateUrl: './timestat.component.html',
  styleUrls: ['./timestat.component.css']
})
export class TimestatComponent implements OnInit {

  filters: Filters;
  insightsData: any;
  barData: any;
  timeLineData: any;
  timeTableData: any;
  metricName: any;
  metric: any;
  aggregate: Array<string> = ["mean", "median"];
  bgColors: Array<any> = [
    { 'background': 'repeating-linear-gradient(150deg, #fff, #fff 6px, #d0d6e0 2px, #d0d6e0 8px)' },
    { 'background-color': '#afc3d8' },
    { 'background-color': '#d6d19e' },
    { 'background-color': '#aca3b7' },
    { 'background-color': '#8bba91' },
    { 'background-color': '#8bba91' },
  ];
  totalCases: number;
  occupancy: Array<any>;
  occupancyTable: Array<any>;
  occupanceMax: any;

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
  }

  OnFilter(e: Filters) {
    this.filters = e;

    this.barData = undefined;
    this.timeLineData = undefined;
    this.occupancy = undefined;
    this.occupancyTable = undefined;
    this.totalCases = 0;
    this.commonService.GetTimeStat(e.facility, e.location, e.surgeService, e.fromDate, e.toDate).subscribe(d => {
      this.barData = d["useByHour"];
      this.timeTableData = d["timeLine"];
      this.FormatTimeLine(d["timeLine"]);
      this.totalCases = d["totalCases"];
      this.occupancy = d["occupancy"];
      let occ = this.commonService.GetMax(this.occupancy, 'RoomCount')?.RoomCount;
      let allo = this.commonService.GetMax(this.occupancy, 'AllocatedCount')?.AllocatedCount;
      this.occupanceMax = { max: (occ && allo ? !occ && allo ? allo : occ && !allo ? occ : occ > allo ? occ : allo : 15) + 1 };
      this.occupancyTable = d["occupancyTable"];
      console.log(this.barData)
    })
  }
  GetMetricDescription(metric: string, metricName: string) {
    this.metricName = metricName;
    this.metric = undefined;
    this.commonService.GetMetricDescription(metric).subscribe(d => {
      this.metric = d;
    })
  }
  GetInsights(metric: string, metricName: string) {
    this.metricName = metricName;
    this.insightsData = undefined;

    this.commonService.GetORSnapshotInsights(this.filters.facility, this.filters.location, (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")), this.filters.surgeService, this.filters.fromDate, this.filters.toDate, metric).subscribe(d => {
      this.insightsData = d;

    });

  }
  GetOccupancyTable(m) {
    return this.occupancyTable.filter(f => f.Metric == m);
  }
  private FormatTimeLine(timeLine: any) {
    var arr = [];
    ["mean", "median"].forEach(a => {
      var obj = {};
      obj['aggregate'] = a;
      timeLine.forEach(el => {
        obj[el.metric] = el[a];
      });
      arr.push(obj);
    });
    this.timeLineData = arr;
  }
}
