import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PeriopOutcomeFilter } from './periop-filters/PeriopOutcomeFilter';
import { Filters } from '../Filters';

@Injectable({
  providedIn: 'root'
})
export class OutcomeService {

  constructor(private httpClient: HttpClient) {

  }

  /********************* Filters API *****************************/
  GetAnesthesiologist(obj: Filters) {
    return this.httpClient.post<Array<string>>(environment.apiURL + `/outcomefilter/anesthesiologist`, obj);
  }
  GetCRNAResident(obj: Filters) {
    return this.httpClient.post<Array<string>>(environment.apiURL + `/outcomefilter/crnares`, obj);
  }


  /********************* PONV *****************************/
  GetDashboard(key: string, currentPage: string, recordsPerPage: string, sortColumn: string, sortOrder: string, filterObj: Filters) {
    return this.httpClient.post<any>(environment.apiURL + "/periops/outcome/" + key + "/" + currentPage + "/" + recordsPerPage + "/" + sortColumn + "/" + sortOrder, filterObj);
    // return this.httpClient.get<Array<string>>(environment.apiURL + "/periops/outcome/ponv_occurrence");
  }
  GetTable(key: string, currentPage: string, recordsPerPage: string, sortColumn: string, sortOrder: string, filterObj: Filters) {
    return this.httpClient.post<any>(environment.apiURL + "/periops/outcome/" + key + "_table/" + currentPage + "/" + recordsPerPage + "/" + sortColumn + "/" + sortOrder, filterObj);
    // return this.httpClient.get<Array<string>>(environment.apiURL + "/periops/outcome/ponv_occurrence");
  }

  DownloadData(key: string, currentPage: string, recordsPerPage: string, sortColumn: string, sortOrder: string, filterObj: Filters, type: string) {
    return this.httpClient.post<any>(environment.apiURL + "/periops/outcome/download/" + key + "_table/" + type + "/" + currentPage + "/" + recordsPerPage + "/" + sortColumn + "/" + sortOrder, filterObj);
    // return this.httpClient.get<Array<string>>(environment.apiURL + "/periops/outcome/ponv_occurrence");
  }
  ///********************* Hypotension *****************************/
  //GetHypoTension(key: string, filterObj: PeriopOutcomeFilter) {
  //  return this.httpClient.post<Array<string>>(environment.apiURL + "/periops/outcome/hypotension/" + key, {});
  //}

  ///********************* Lung Protective *****************************/
  //GetLungProtective(key: string, filterObj: PeriopOutcomeFilter) {
  //  return this.httpClient.post<Array<string>>(environment.apiURL + "/periops/outcome/lungprotective/" + key, {});
  //}

  ///********************* Pain Score *****************************/
  //GetPainScore(key: string, filterObj: PeriopOutcomeFilter) {
  //  return this.httpClient.post<Array<string>>(environment.apiURL + "/periops/outcome/painscore/" + key, {});
  //}
  ///********************* Common Method - Get Data *****************************/
  //GetData(key: string, filterObj: PeriopOutcomeFilter) {
  //  return this.httpClient.post<Array<string>>(environment.apiURL + "/periops/outcome/normothermia/" + key, {});
  //  //return this.httpClient.post<Array<string>>(environment.apiURL + "/periops/outcome/" + key, {});
  //}
}
