import * as d3 from 'd3';


export class PercentageBar {
  private config = {
    width: 180,
    height: 120,
    margin_left: 25,
    margin_right: 5,
    margin_top: 15,
    margin_bottom: 40
  };

  private max: any = undefined;
  private svg: any = undefined;

  private x: any = undefined;
  private y: any = undefined;

  private container: string | undefined;
  private percentbardata: any;
  private configuration: any;

  barcolors: Array<any> = ["#bbc3ce", "#eaedef", "#8a95a3"];
  constructor(container: string, percentbardata: any, configuration: any) {
    this.container = container;
    this.percentbardata = percentbardata;
    this.configuration = configuration;
    // this.donut = d3.pie();
    this.configure(configuration);
  }

  private configure(configuration) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    this.max = d3.max(this.percentbardata.map((d: any) => { return d.CasePercent; }));

    //map metric to location on the x-axis
    this.x = d3.scaleBand()
      .rangeRound([this.config.margin_left, this.config.width - (this.config.margin_left + this.config.margin_right)])
      .domain(this.percentbardata.map(function (d) { return d.Metric; }));

    //scale percentage to y-axis
    this.y = d3.scaleLinear()
      .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
      .domain([0, this.max])
  }
  private draw() {

    //create svg to to display percent bar
    this.svg = d3.select(this.container)
      .append("div")
      .classed("percentbarcontainer", true)
      .append('svg:svg')
      .attr('viewBox', "0 0 " + this.config.width + " " + this.config.height)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    //append the x-axis
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x)
        .tickSize(0)
      )
      .selectAll("text")
      .attr("transform", "rotate(40)")
      .style("text-anchor", "start");

    //draw the rects
    this.svg.append("g")
      .selectAll("rect")
      .data(this.percentbardata)
      .enter().append("rect")
      .attr("x", (d: any) => { return this.x(d.Metric); })
      .attr("y", this.config.height - this.config.margin_bottom)
      .attr("width", (d: any) => { return this.x.bandwidth(); })
      .attr("height", 0)
      .attr("fill", (d: any, i: number) => { return this.barcolors[i]; })
      .transition()
      .duration(1500)
      .attr("y", (d: any) => { return this.y(d.CasePercent); })
      .attr("height", (d: any) => { return this.config.height - this.config.margin_bottom - this.y(d.CasePercent); });

    //add the labels with percentages
    this.svg.append("g")
      .selectAll("text")
      .data(this.percentbardata)
      .enter().append("text")
      .attr("class", "phasetimetext")
      .attr("x", (d: any) => { return this.x(d.Metric) + this.x.bandwidth() / 2; })
      .attr("y", (d: any) => { return this.y(d.CasePercent) - 5; })
      .style("text-anchor", "middle")
      .text((d: any) => { return d.CasePercent + "%"; })
  }
  Render() {
    this.draw();
  }
  public update(updatepercentbardata: any, newConfiguration: any) {

    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }

    this.percentbardata = updatepercentbardata;
    d3.select("" + this.container).select("svg").remove();
    this.draw();
  }
}
