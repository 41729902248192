import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Gauge } from '../d3charts/Gauge';
import { Filters } from '../Filters'
import { Page } from '../Page';

@Component({
  selector: 'app-metric-dashboard',
  templateUrl: './metric-dashboard.component.html',
  styleUrls: ['./metric-dashboard.component.css']
})
export class MetricDashboardComponent implements OnInit {


  filters: Filters;
  caseVolume: Array<any>;
  failedCasesPercentage: Array<any>;
  cmm: Array<any>;
  metric: string
  metricName: string;
  metricCode: string
  value: number = 50;
  value1: number = 50;
  gauge!: Gauge;
  caseVolumeTotal: number;
  metrics: any;
  barData: any;
  margin: any = { height: 140, margin_top: 20, margin_left: 40, margin_bottom: 30 };
  fullbarData: any;
  failedcases: any;
  metricfields: any;
  insightsHeader: string;
  ORBISClosedCaseFields: any;
  ORBISClosedCase: any;
  ORBISFailDetails: any;
  ORBISClosedCaseMetric: any;
  Allcases: any;
  totalCount: number;

  turnOver: Array<any>;
  turnoverThreshold: number;
  totalrecords: number = 0;
  page: Page;
  dormancytwoPlusSurgeonsDetails: Array<any>;
  configuration: any =
    {
      metric: 'NumCases',
      datefield: 'CaseDate',
      datefilter: 'Week',
      xlabel: 'Week',
      field: 'Count',
      group_by: 'Id',
      group_by_label: 'GroupBy',
      categories: undefined,
      selectedcategory: "All",
      ylabel: '% Cases'
    };

  insightsData: any;
  adhocKeys: Array<string>;
  timestatbarData: any;
  timeLineData: any;
  timeTableData: any;
  aggregate: Array<string> = ["mean", "median"];
  bgColors: Array<any> = [
    { 'background': 'repeating-linear-gradient(150deg, #fff, #fff 6px, #d0d6e0 2px, #d0d6e0 8px)' },
    { 'background-color': '#afc3d8' },
    //{ 'background-color': '#afc3d8' },
    { 'background-color': '#d6d19e' },
    { 'background-color': '#aca3b7' },
    { 'background-color': '#8bba91' },
  ];


  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.page = new Page();
    this.page.currentPage = 1;
    this.page.recordsPerPage = 100;
  }

  OnFilter(e: Filters) {
    this.filters = e;
    this.caseVolume = undefined;
    this.failedCasesPercentage = undefined;
    this.barData = undefined;
    this.cmm = undefined;
    this.caseVolumeTotal = undefined;
    this.metrics = undefined;
    this.totalCount = 0;
    if (e.surgeService != 'All') {
      this.GetTurnoverThresholdSingle();
    }

    this.commonService.GetMetricDashboardData(e.facility, e.location, e.staffRole, e.provider, e.surgeService, e.fromDate, e.toDate).subscribe(d => {
      this.metrics = d["ORBISMetric"];
      this.failedCasesPercentage = d["FailedCasesPercentage"];
      this.barData = d["ScheduleVsActual"];
      this.cmm = d["CMM"];
      this.totalCount = d["totalCases"];

    });
    this.timestatbarData = undefined;
    this.timeLineData = undefined;
    this.commonService.GetMetricTimeStat(e.facility, e.location, e.staffRole, e.provider, e.surgeService, e.fromDate, e.toDate).subscribe(d => {
      this.timestatbarData = d["useByHour"];
      this.timeTableData = d["timeLine"];
      this.FormatTimeLine(d["timeLine"]);
    })
  }
  private FormatTimeLine(timeLine: any) {
    var arr = [];
    ["mean", "median"].forEach(a => {
      var obj = {};
      obj['aggregate'] = a;
      timeLine.forEach(el => {
        obj[el.metric] = el[a];
      });
      arr.push(obj);
    });
    this.timeLineData = arr;
  }
  GetGauge(g: Gauge) {
    this.gauge = g;
  }
  GetCMM(metricName: string) {

    var v = this.cmm.filter(c => c.Metric.toLowerCase() == metricName.toLowerCase());
    if (v.length > 0) {
      return v;
    }
    else {
      return null;
    }
  }
  GetfailedCasesPercentage(metric: string) {
    var v = this.failedCasesPercentage.filter(c => c.Metric.toLowerCase() == metric.toLowerCase());
    if (v.length > 0) {
      return v;
    }
    else {
      return null;
    }
  }

  GetIsInsights(metric: string) {
    let m = this.metrics.filter(t => t.Metric == metric);
    if (m.length > 0 && m[0].IsInsights)
      return m[0].IsInsights;
    else
      return "N";
  }
  GetMetricBar(metric: string, metricName: string) {
    this.metricCode = metric;
    this.metricName = metricName;
    this.fullbarData = undefined;
    this.configuration.categories = undefined;

    this.commonService.GetMetricDashboardBar(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, metric).subscribe(d => {
      if (metric.toLowerCase() == 'dormancy_twoplussurgeons') {
        this.configuration.ylabel = '% Non Utilization';
      }
      else {
        this.configuration.ylabel = '% Cases';
      }
      this.fullbarData = d["CaseVolumeBar"];
      this.configuration.selectedcategory = this.fullbarData.length > 0 ? this.fullbarData[0]['GroupBy'] : "";
      var catagories = [];
      for (var i = 0; i < this.fullbarData.length; i++) {
        var fil = catagories.filter(f => f.GroupBy == this.fullbarData[i].GroupBy);
        if (fil.length == 0) {
          catagories.push({ "Id": this.fullbarData[i].Id, "GroupBy": this.fullbarData[i].GroupBy })
        }
      }
      this.configuration.datefilter = this.fullbarData[0]?.DateInterval;
      this.configuration.xlabel = this.fullbarData[0]?.DateInterval;

      this.configuration.categories = catagories;
    });
  }
  GetMetricDescription(metric: string, metricName: string) {
    this.metricCode = metric;
    this.metricName = metricName;
    this.metric = undefined;
    this.commonService.GetMetricDescription(metric).subscribe(d => {
      this.metric = d;
    })
  }

  GetFailedCase(metric: string, metricName: string) {
    this.metricCode = metric;
    this.metricName = metricName;
    this.failedcases = undefined;
    this.metricfields = undefined;
    //this.metricfieldsLeft = [];
    //this.metricfieldsRight = [];
    this.commonService.GetMetricFailedCases(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, metric).subscribe(d => {
      this.failedcases = d["casedetails"].map(d => {
        if (d.Adhoc) {
          d.Adhoc = JSON.parse(d.Adhoc);
        }
        return d;
      });
      this.metric = d["orbismetric"][0]?.MetricDescription;
      this.metricfields = d["orbismetricfield"];
      this.ORBISClosedCaseFields = d["orbisClosedcaseFielddetails"];
      this.ORBISClosedCase = d["closedcases"];
      this.ORBISFailDetails = d["OrbisFaildetails"];
      this.ORBISClosedCaseMetric = d["closedcasemetricdetails"];




    })
  }

  //getORBISClosedCaseFields(field: string) {
  //  return this.ORBISClosedCaseFields.filter(c => c.Field == field)[0]?.FieldName;
  //}
  //getORBISClosedCaseFieldValue(fieldname: string) {
  //  return this.ORBISClosedCase.length > 0 ? this.ORBISClosedCase[0][fieldname] : "";
  //}
  //getMaxCountRowsFailcase() {
  //  return Math.max(...this.ORBISFailDetails.map(o => o.RowIndex), 0);
  //}
  getMaxCountColumnsFailcase() {//
    return Math.max(...this.ORBISFailDetails.map(o => o.ColumnIndex), 0);
  }

  //getMaxCountcolumnvalues(column) {
  //  var x = this.ORBISFailDetails.filter(f => f.ColumnIndex == column);
  //  return Math.max(...x.map(c => c.ColumnName), 0)
  //}
  getMaxrowcolumnvalues(column) {//
    var x = this.ORBISFailDetails.filter(f => f.ColumnIndex == column);
    return Math.max(...x.map(c => c.ColumnName), 0)
  }
  //GetcaseDetail(ProcID: string) {

  //  this.commonService.GetcaseDetail(this.metricCode, ProcID).subscribe(d => {
  //    this.ORBISClosedCase = d["closedcases"];
  //    this.ORBISFailDetails = d["OrbisFaildetails"];
  //    this.ORBISClosedCaseMetric = d["closedcasemetricdetails"];
  //  })
  //}
  GetTurnoverThresholds() {//
    this.turnOver = undefined;
    this.commonService.GetTurnoverThresholds(this.filters.facility, this.filters.surgeService, this.filters.location).subscribe(d => {
      this.turnOver = d["TurnoverThresholds"];
    })
  }
  GetTurnoverThresholdSingle() {
    this.turnoverThreshold = undefined;
    this.commonService.GetTurnoverThresholds(this.filters.facility, this.filters.surgeService, this.filters.location).subscribe(d => {
      this.turnoverThreshold = d["TurnoverThresholds"][0]?.TurnoverThreshold;
    })
  }
  GetDormancy_twoPlusSurgeons() {
    this.totalrecords = 0;
    this.dormancytwoPlusSurgeonsDetails = undefined;
    this.commonService.GetDormancy_twoPlusSurgeons(this.filters.facility, this.filters.staffRole, this.filters.provider, this.filters.location, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, this.page.currentPage, this.page.recordsPerPage).subscribe(d => {
      this.dormancytwoPlusSurgeonsDetails = d["dormancytwoPlusSurgeons"];
      this.totalrecords = this.dormancytwoPlusSurgeonsDetails.length > 0 ? this.dormancytwoPlusSurgeonsDetails[0]["Total"] : 0;
    });
  }

  PageEventCaseCancel(e: Page) {
    this.page = e;
    this.GetDormancy_twoPlusSurgeons();
  }

  GetInsights(metric: string, metricName: string,insightsHeader:string) {
    this.metricName = metricName;
    this.insightsData = undefined;
    this.insightsHeader = insightsHeader;
    this.commonService.GetInights(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, metric).subscribe(d => {
      this.insightsData = d;

    });
    //this.commonService.GetRecomendations(recomendationId).subscribe(d => {
    //  this.recomendations = d;

    //});
  }
}
