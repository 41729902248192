import { Component, OnInit } from '@angular/core';
import { Filters } from '../Filters';
import { CommonService } from '../common.service';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.css']
})
export class RecommendationsComponent implements OnInit {

  allRecomendations: Array<any>;
  allRecomendationsFiltered: Array<any>;
  filters: Filters;
  StartPeriod: any;
  EndPeriod: any;
  recomendations: any;
  recomendationString: string;
  currentRecomId: number;
  Service = null;
  ProviderName = null;

  isStudiesPosted: boolean = false;
  study: string;
  dashboardURL: string;

  searchRecommendation: string;
  searchProvider: string;
  searchObservation: string;
  filterChange: any = new Subject<string>();

  constructor(private commonService: CommonService) {
    this.filterChange.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.allRecomendationsFiltered = undefined;
        this.allRecomendationsFiltered = this.allRecomendations.filter(p => {
          return (p.Recommendation.toLowerCase().split(' ').join('').includes(this.searchRecommendation.toLowerCase().split(' ').join(''))
            || this.searchRecommendation.split(' ').join('') == "")
            //|| (p.UX_String.toLowerCase().split(' ').join('').includes(this.searchObservation.toLowerCase().split(' ').join(''))

            //|| this.searchObservation.split(' ').join('') == "")
            //|| (p.StaffName.toLowerCase().split(' ').join('').includes(this.searchProvider.toLowerCase().split(' ').join(''))
            //  || this.searchProvider.split(' ').join('') == "")
            ;
        });
      })
  }

  ngOnInit(): void {
  }

  GetRecomentationPopup(recomendationId: number, r: string, sd: any, ed: any, service: any, providername: any) {
    this.recomendationString = r;
    this.recomendations = undefined;
    this.currentRecomId = recomendationId;
    this.StartPeriod = null;
    this.EndPeriod = null;
    this.Service = null;
    this.ProviderName = null;
    this.commonService.GetRecomendations(recomendationId).subscribe(d => {
      this.recomendations = d;
      this.StartPeriod = sd;
      this.EndPeriod = ed;
      this.Service = service;
      this.ProviderName = providername;
    });
  }
  OnFilter(e: Filters) {
    this.filters = e;
    this.commonService.GetAllRecommendations(this.filters.facility, this.filters.fromDate, this.filters.toDate, this.filters.metric, this.filters.multiprovider.toString()).subscribe(d => {
      this.allRecomendations = d['Recomendations'];
      this.allRecomendationsFiltered = JSON.parse(JSON.stringify(d['Recomendations']));
    });
  }

  GetHeaders(d: any) {
    return Object.keys(d);
  }
  SaveStudies() {
    this.dashboardURL = undefined;
    this.commonService.PostStudies(this.currentRecomId).subscribe(s => {
      this.study = s;
      this.dashboardURL = environment.IquestDashBoardURL + s.split(' ').join('%20');
      this.isStudiesPosted = true;
    });
  }
}
