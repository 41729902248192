<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'block-schedule'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Block Operating Schedule
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">

  <div class="container-xl container-sm container-md">
    <p-tabs (onSelect)="OnSelectTab($event)" *ngIf="blocktimes!=undefined">

      <p-tab *ngFor="let d of daysofweek" [tabTitle]="d" [active]="currentdayofweek==d" [id]="d">
        <div class="row">
          
          <div>

            <table *ngIf="blocktimes!=undefined && blocktimes.length > 0">
              <tr>
                <th class="blockscheduleroom">Time</th>
                <th *ngFor="let r of blockschedulerooms" class="blockscheduleroom">
                  {{r}}
                </th>
              </tr>
              <tr *ngFor="let t of blocktimes">

                <th class="blockscheduletime">
                  <div style="position: relative; top: -15px">{{t.substring(0,5)}}</div>
                </th>
                <td *ngFor="let r of blockschedulerooms" class="blockscheduleservice" height="100">
                  <table class="blockschedulealt">
                    <tr *ngFor="let b of GetBlockForTimeRoom(t, r)">
                      <td [style.backgroundColor]="b.BackgroundColor" [style.color]="b.TextColor">
                        <span *ngIf="b.DisplayOrder == 1">{{b.ShortName}}</span>
                        <span *ngIf="b.DisplayOrder > 1">&nbsp;</span>
                      </td>
                    </tr>
                    <tr *ngIf="GetBlockForTimeRoom(t, r).length == 0">
                      <td style="background-color: black">
                      </td>
                    </tr>
                  </table>
                  <div class="blockscheduletooltip" *ngIf="GetBlockForTimeRoom(t, r).length > 0">
                    <table>
                      <tr><th>Week</th><th>Block</th></tr>
                      <tr *ngFor="let b of GetBlockForTimeRoom(t, r)"><td>{{b.BlockWeek}}</td><td>{{b.BlockName}}</td></tr>
                    </table>
                  </div>
                </td>
              </tr>
            </table>          
          </div>
        </div>
      </p-tab>
    </p-tabs>
  </div>
</div>

