<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'two-room-surgeons'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Two-Room Surgeon OR Dormancy
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-body">
  <div class="card-body-scrollable card-body-scrollable-shadow tworoom">
    <table *ngIf="TwoRoomSurgeons!=undefined">

      <thead>
        <tr>
          <ng-container *ngFor="let c of columns;">
            <th>
              {{c.name}}
            </th>
          </ng-container>
        </tr>
      </thead>
      <caption *ngIf="TwoRoomSurgeons!=undefined && TwoRoomSurgeons.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
      <tbody>

        <tr *ngFor="let c of TwoRoomSurgeons">          
          <td>{{c.StaffName}}</td>
          <td>{{c.Days2Rooms}}</td>
          <td>{{c.DormancyPercent}}%</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
