import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Page } from '../models/table';

@Component({
  selector: 'app-pagging',
  templateUrl: './pagging.component.html',
  styleUrls: ['./pagging.component.css']
})
export class PaggingComponent {

  @Input("TotalRecords")
  count: number;
  @Input("RecordsPerPage")
  recordsPerPage: number = 10

  @Input("CurrentPage")
  currentPage: number = 1;
  @Output() pageEvent: EventEmitter<Page> = new EventEmitter<Page>();
  numberOfPages: number;
  constructor() {

  }
  RecordCountChange() {
    this.currentPage = 1;
    this.Emit();
  }
  Emit() {
    var page = new Page();
    page.currentPage = this.currentPage;
    page.recordsPerPage = this.recordsPerPage;
    this.pageEvent.emit(page);
  }
  next() {
    if (this.currentPage < this.numberOfPages) {
      this.currentPage++;
      this.Emit();
    }
  }
  prev() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.Emit();
    }
  }
  last() {
    if (this.currentPage != this.numberOfPages) {
      this.currentPage = this.numberOfPages;
      this.Emit();
    }
  }
  first() {
    if (this.currentPage != 1) {
      this.currentPage = 1;
      this.Emit();
    }

  }

  ngOnInit(): void {
    this.ChageNumberOfPages();
  }
  ChageNumberOfPages() {
    var n = this.count / this.recordsPerPage;
    this.numberOfPages = parseInt(n.toString()) < n ? parseInt(n.toString()) + 1 : parseInt(n.toString());
    return this.numberOfPages;
  }
}
