import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeLine } from '../TimeLine';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {

  @Input() container: string = ''
  @Input() timeLineData: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<TimeLine> = new EventEmitter();

  timeLine!: TimeLine;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.timeLine = new TimeLine(this.container, this.timeLineData, this.configuration);
    this.timeLine.Render();
    this.myOutput.emit(this.timeLine);
  }

}
