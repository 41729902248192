import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Mean } from '../Mean';

@Component({
  selector: 'app-mean',
  templateUrl: './mean.component.html',
  styleUrls: ['./mean.component.css']
})
export class MeanComponent implements OnInit {

  @Input() container: string = ''
  @Input() meanData: any;
  @Input() configuration: any;
  @Output() myOutput: EventEmitter<Mean> = new EventEmitter();

  mean!: Mean;


  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.mean = new Mean(this.container, this.meanData, this.configuration);
    this.mean.Render();
    this.myOutput.emit(this.mean);
  }

}
