import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters'
import { Page } from '../Page';

@Component({
  selector: 'app-patient-outcome',
  templateUrl: './patient-outcome.component.html',
  styleUrls: ['./patient-outcome.component.css']
})
export class PatientOutcomeComponent implements OnInit {
  filters: Filters;
  reportmetrics: any;
  resultset; any;
  Patientoutcomemetric: any;
  Patientoutcomemetricpie: any;
  Patientoutcomeboxplot: any;
  metric: any;
  metricName: any;
  chartmetricType: any;
  chartmetric: any;
  selectedmetric: any;
  configuration: any = { unitslabel: 'mean score' };
  metricfields: any;
  reportmetricfields: any;
  ORBISClosedCaseFields: any;
  Allcases: any;
  AllclosedcaseMetrics: any;
  AllclosedcaseTextMetrics: any;
  margin: any = { height: 140, margin_top: 20, margin_left: 40, margin_bottom: 30 };
  selectedValue: string = 'All';
  selectedOrderBy: any;
  selectedColumn: any;
  totalrecords: number = 0;
  totalCount: number;
  page: Page;
  metricScreen: string;
  sortObjects: any = {};
  sortColumn: string = 'CaseDate';
  sortOrder: string = 'ASC';

  failedDetails: Array<any> = [];
  cols: Array<string>;
  rows: Array<string>;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.page = new Page();
    this.page.currentPage = 1;
    this.page.recordsPerPage = 100;

  }
  PageEvent(e: Page) {
    this.page = e;
    this.LoadData(this.chartmetric);

  }
  GetcaseDetail(procId) {
    this.failedDetails = [];
    var distinct = function (items, key) {
      var result = [];
      for (var i = 0; i < items.length; i++) {
        var val = items[i][key];
        if (result.indexOf(val) < 0) {
          result.push(val);
        }
      }
      return result;
    }
    this.commonService.GetPatientOutcomeFailed(this.chartmetric, procId)
      .subscribe(d => {
        this.failedDetails = d;
        this.rows = distinct(this.failedDetails, 'RowIndex');
        this.cols = distinct(this.failedDetails, 'ColumnIndex');
      });
  }
  GetColumnName(c) {
    return this.failedDetails.filter(ci => ci.ColumnIndex == c)[0].ColumnName;
  }
  GetRows(r) {
    return this.failedDetails.filter(ri => ri.RowIndex == r);
  }
  getORBISClosedCaseFields(fieldname: string) {
    return this.ORBISClosedCaseFields.filter(c => c.Field == fieldname)[0].FieldName;
  }
  getORBISClosedCaseFieldValue(ProcId: string, FieldTable: string, fieldname: string) {
    var source = undefined;
    if (FieldTable == 'ORBISClosedCaseMetrics')
      source = this.AllclosedcaseMetrics;
    if (FieldTable == 'ORBISClosedCaseTextMetrics')
      source = this.AllclosedcaseTextMetrics;

    if (source != undefined && source.length > 0) {
      var res = source.filter(c => c.ProcID == ProcId)
      return res != undefined && res.length > 0 ? res[0][fieldname] : null;
    }
    else
      return null;
  }

  onchartClick(metricname: string, metric: string, charttype: string) {

    this.chartmetric = undefined;
    this.chartmetricType = undefined;

    this.commonService.GetMetricDescription(metric).subscribe(d => {
      this.chartmetric = metric;
      this.chartmetricType = charttype;
    });
  }

  GetMetricDescription(metric: string,metricName:string) {
    this.metricName = metricName;
    this.metric = undefined;
    this.commonService.GetMetricDescription(metric).subscribe(d => {
      this.metric = d;
    })
  }

  filtermetric(resultset: any, Outcome: any) {

    if (resultset != undefined)
      return resultset.filter(c => c.Outcome.toLowerCase() == Outcome.toLowerCase());
    else
      return [];
  }
  filterpiemetric(resultset: any, Outcome: any) {
    var x = this.Patientoutcomemetric.filter(c => c.Outcome.toLowerCase() == Outcome.toLowerCase() && c.MetricType == 'Text');
    if (resultset != undefined && x != undefined && x.length > 0) {
      var z = x[0].Metric;
      var y = resultset.filter(c => c.Outcome.toLowerCase() == x[0].Metric.toLowerCase());
      return y;
    }
    else
      return [];
  }
  OpenOutCome(metricName: string, outComeLinks: string) {
    this.metricScreen = undefined;
    setTimeout(() => {
      this.metricScreen = outComeLinks;
      this.metricName = metricName;
    },10);   
  }
  OnFilter(e: Filters) {
    this.filters = e;
    this.resultset = undefined;
    this.reportmetrics = undefined;
    this.totalCount = 0;
    this.commonService.GetPatientOutcome(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.surgeService, this.filters.provider, this.filters.fromDate, this.filters.toDate).subscribe(d => {

      this.reportmetrics = d["Orbisreportmetric"];
      this.reportmetricfields = d["Outcometablefields"];
      this.Patientoutcomemetric = d["Patientoutcomemetric"];
      this.Patientoutcomemetricpie = d["Patientoutcomepie"];
      this.Patientoutcomeboxplot = d["Patientoutcomeboxplot"];
      this.totalCount = d["totalCases"];

    })
  }
  Change(metric: string) {
    this.page.currentPage = 1;
    this.LoadData(metric);
  }
  LoadData(metric: string) {


    this.commonService.GetPatientOutcomeAllcases(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.surgeService, this.filters.provider, this.filters.fromDate, this.filters.toDate, this.chartmetric, this.page.currentPage, this.page.recordsPerPage, this.sortColumn, this.sortOrder, this.selectedValue).subscribe(d => {
      this.metricfields = this.reportmetricfields.filter(c => c.Metric.toLowerCase() == metric.toLowerCase());

      this.Allcases = JSON.parse(d);
      this.totalrecords = this.Allcases[0]['TotalRecords'];
    });
  }
  GetAllCases(metric) {
    this.Allcases = undefined;
    this.chartmetric = metric;
    this.selectedValue = 'All'
    this.LoadData(metric);
  }

  Sort(f, m) {
    var field = this.reportmetricfields.filter(c => c.Field == f)[0].Field;
    this.sortColumn = field;
    this.sortOrder = this.sortObjects[f] == "ASC" ? "DESC" : "ASC";

    this.sortObjects[f] = this.sortOrder;
    Object.keys(this.sortObjects).forEach(k => {
      if (k != f) {
        this.sortObjects[k] = undefined;
      }
    });

    this.metricfields.filter(c => c.Field == f)[0].sortOrder = this.sortOrder;
    this.metricfields = this.metricfields;

    this.LoadData(m);
  }
}
