import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PieComponent } from './pie/pie.component';
import { VBarStackedComponent } from './v-bar-stacked/v-bar-stacked.component';
import { HBarStackedComponent } from './h-bar-stacked/h-bar-stacked.component';
import { LineComponent } from './line/line.component';
import { VBarComponent } from './v-bar/v-bar.component';
import { HBarComponent } from './h-bar/h-bar.component';
import { CommonTableComponent } from './common-table/common-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderByPipe } from './order-by.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaggingComponent } from './pagging/pagging.component';
import { DownloadDialogComponent } from './download-dialog/download-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgxCaptureModule } from 'ngx-capture';
import { httpInterceptorProviders } from '../http-interceptors';



@NgModule({
  declarations: [
    PieComponent,
    VBarStackedComponent,
    HBarStackedComponent,
    LineComponent,
    VBarComponent,
    HBarComponent,
    CommonTableComponent,
    OrderByPipe,
    PaggingComponent,
    DownloadDialogComponent
  ],
  exports: [
    PieComponent,
    VBarStackedComponent,
    HBarStackedComponent,
    LineComponent,
    VBarComponent,
    HBarComponent,
    CommonTableComponent,
    DownloadDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    NgSelectModule,
    FormsModule,
    ToastrModule.forRoot(),
    NgxCaptureModule,
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule
  ],
  providers: [ToastrService, httpInterceptorProviders,  DatePipe],
})
export class D3componentsModule { }
