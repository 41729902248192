import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaugeComponent } from './gauge/gauge.component';
import { PieComponent } from './pie/pie.component';
import { PercentagebarComponent } from './percentagebar/percentagebar.component';
import { BarComponent } from './bar/bar.component';
import { BarlineComponent } from './barline/barline.component';
import { TimelineComponent } from './timeline/timeline.component';
import { LineComponent } from './line/line.component';
import { MeanComponent } from './mean/mean.component';
import { OccupancyComponent } from './occupancy/occupancy.component';
import { WorkflowmapComponent } from './workflowmap/workflowmap.component';
import { SimpleBarComponent } from './simple-bar/simple-bar.component';
import { BarCompareComponent } from './bar-compare/bar-compare.component';
import { FinancialsgraphComponent } from './financialsgraph/financialsgraph.component';
import { LinegraphComponent } from './linegraph/linegraph.component';
import { PerivisionScheduleComponent } from './perivision-schedule/perivision-schedule.component';


@NgModule({
    declarations: [GaugeComponent, PieComponent, PercentagebarComponent, BarComponent, BarlineComponent, TimelineComponent, LineComponent, MeanComponent, OccupancyComponent, SimpleBarComponent, BarCompareComponent, WorkflowmapComponent, FinancialsgraphComponent, LinegraphComponent, PerivisionScheduleComponent],
  imports: [
    CommonModule
  ],
  exports: [
    GaugeComponent,
    PieComponent,
    PercentagebarComponent,
    BarComponent,
    BarlineComponent,
    TimelineComponent,
    LineComponent,
    MeanComponent,
    WorkflowmapComponent,
    OccupancyComponent,
    SimpleBarComponent,
    BarCompareComponent,
    FinancialsgraphComponent,
    LinegraphComponent,
    PerivisionScheduleComponent
  ]
})
export class D3chartsModule { }
