<div *ngIf="recomendationsDataFiltered" class="page-body">
  <div class="row">
    <div class="col" style="padding:15px;text-align:left;" *ngIf="recomendationString">
      {{recomendationString}}
    </div>
  </div>

  <div *ngFor="let h of headerTableKeys">
    <div style="text-align:right;padding-right:20px;font-weight:bold" *ngIf="!recomendationString">
      <span *ngIf="recomendations[h].length!=recomendationsDataFiltered[h].length">
        <span style="color: #2b4163">Filtered: {{recomendationsDataFiltered[h].length}}</span>  &nbsp;out of&nbsp; <span style="color: #2b4163">
          {{recomendations[h].length}}&nbsp;records ({{(recomendationsDataFiltered[h].length/recomendations[h].length*100).toFixed(0)}}%)
        </span>
      </span>
      <span *ngIf="recomendations[h].length==recomendationsDataFiltered[h].length">
        Total Records: {{recomendations[h].length}}
      </span>
    </div>
    <table class="casemixgraphic fixTableHead" style="text-align:center;">
      <thead>
        <tr>
          <th *ngFor="let nk of recomendationsDataFiltered[h+'_C']" (click)="Sort(h,nk)">
            {{nk.split('_').join(' ')}}&nbsp;
            <img src="../assets/sort.png" alt="Orbis" style="width:15px;height:15px;cursor:pointer;">
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td *ngFor="let nk of recomendations[h+'_C']">
            <ng-container *ngIf="recomendations[h+'_F'] && recomendations[h+'_F'][nk]=='select'">
              <select class="form-select" [(ngModel)]="filterText[nk]" (change)="KeyUp(h)" [placeholder]="'Search '+nk+'..'">
                <option *ngFor="let r of recomendations[h+'_F'][nk+'_D']" [value]="r">{{r}}</option>
              </select>
            </ng-container>
            <ng-container *ngIf="(recomendations[h+'_F'] && recomendations[h+'_F'][nk]=='freetext')">
              <!--!recomendations[h+'_F'] ||-->
              <input type="text" style="width:100%" [(ngModel)]="filterText[nk]" [placeholder]="'Search '+nk+'..'" (keyup)="KeyUp(h)" />
            </ng-container>

            <ng-container *ngIf="(recomendations[h+'_F'] && recomendations[h+'_F'][nk]=='number')">
              <div class="row">
                <div class="col">
                  <select class="form-select" [(ngModel)]="filterText[nk+'_Condition']" style="width:100%" (change)="KeyUp(h)">
                    <option [value]="'='" selected>Equal to</option>
                    <option [value]="'>'">Greater than</option>
                    <option [value]="'<'">Less than</option>
                  </select>
                </div>
                <div class="col"> <input type="number" style="width:100%;height:29px;" [(ngModel)]="filterText[nk]" [placeholder]="'Search '+nk+'..'" (keyup)="KeyUp(h,'num')" /></div>

              </div>


            </ng-container>

            <!--(keyup)="KeyDown(h)" (ngModelChange)="this.filterChange.next($event)"-->
          </td>
        </tr>
        <tr *ngFor="let v of recomendationsDataFiltered[h]">
          <td *ngFor="let nk of recomendationsDataFiltered[h+'_C']">
            <div>{{v[nk]}}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
  </div>
</div>
