import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bm-calendar-view',
  templateUrl: './bm-calendar-view.component.html',
  styleUrls: ['./bm-calendar-view.component.css']
})
export class BmCalendarViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
