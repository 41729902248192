<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'distrank'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Distribution & Rank for
          &nbsp;
          <span *ngIf="filters!=undefined && filters?.provider=='All'">
            <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
            {{filters?.facility=="All"?'All Facilities':''}}&nbsp;
            <span *ngIf="filters!=undefined && filters?.surgeService!='All'">{{filters?.surgeService}}</span>&nbsp; {{filters?.surgeService=="All"?'All Services':''}}&nbsp;
            {{filters?.staffRole+'s'}} &nbsp;
          </span>
          <span *ngIf="filters!=undefined && filters?.provider!='All'">
            {{filters?.provider!="All"?filters?.providerName:''}} &nbsp;
          </span>{{totalCount}} Total Cases
          <!--&nbsp;<span *ngIf="filters!=undefined"> {{filters?.provider=="All" && filters?.surgeService=="All"?' All '+filters?.staffRole:''}} &nbsp; {{filters?.provider=="All" && filters?.surgeService!="All" ?filters?.surgeService:''}}&nbsp; {{filters?.provider!="All"?filters?.providerName:''}} &nbsp; {{totalCount}}</span>&nbsp; Total Cases-->
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-body">

  <div class="container-xl" *ngIf="metrics!=undefined">
    <div class="row row-deck row-cards">
      <ng-container *ngFor='let in of counter(rowsCount) ;let i = index'>
        <div class="col-xxl-3 col-xl-3 col-md-6 col-sm-12" *ngFor='let in of counter(3) ;let ii = index'>


          <div class="card" *ngIf="metrics[i+ii+(i*2)]!=undefined">
            <div class="card-header metrictitle click-header metrictitle metrictitleuw"
                 data-bs-toggle="modal" data-bs-target="#modal-metric" (click)="GetDefinition(metrics[i+ii+(i*2)].MetricName)">
              <h4 class="card-title" style="cursor:pointer;"> {{metrics[i+ii+(i*2)].MetricName}} </h4>
            </div>
            <div *ngIf="GetShow(metrics[i+ii+(i*2)].Metric)"
                 class="card-body p-10 click-body" style="text-align: center!important;">
              <div *ngIf="this.filters.provider!='All'">

                <div style="text-align:right;">
                  <img src="./assets/chart.png" style="width:20px;height:20px;cursor:pointer;" (click)="OpenMultiChart(metrics[i+ii+(i*2)])"
                       data-bs-toggle="modal" data-bs-target="#modal-multi-chart" />
                </div>
                <div data-bs-toggle="modal" data-bs-target="#modal-bar" (click)="GetBar(metrics[i+ii+(i*2)].Metric)">
                  <app-line [container]="'#line' + i+''+ii" [lineData]="distrankData[metrics[i+ii+(i*2)].Metric]"
                            [configuration]="distrankData[metrics[i+ii+(i*2)].Metric+'_Config']"
                            [scaleValue]="distrankData[metrics[i+ii+(i*2)].Metric+'_Scale']">
                    <div id="{{'line'+i+''+ii}}">
                    </div>
                  </app-line>
                </div>
              </div>
              <div *ngIf="this.filters.provider=='All'">
                <div style="text-align:right;">
                  <img src="./assets/chart.png" style="width:20px;height:20px;cursor:pointer;" data-bs-toggle="modal" data-bs-target="#modal-multi-chart"
                       (click)="OpenMultiChart(metrics[i+ii+(i*2)])" />
                </div>

                <app-line [container]="'#line' + i+''+ii" [lineData]="distrankData[metrics[i+ii+(i*2)].Metric]"
                          [configuration]="distrankData[metrics[i+ii+(i*2)].Metric+'_Config']"
                          [scaleValue]="distrankData[metrics[i+ii+(i*2)].Metric+'_Scale']">
                  <div id="{{'line'+i+''+ii}}">
                  </div>
                </app-line>
              </div>
            </div>
            <div *ngIf="GetShow(metrics[i+ii+(i*2)].Metric)==false" class="card-body p-10 click-body" style="width: 300px;">
              No Data Found
            </div>
            <div *ngIf="GetShow(metrics[i+ii+(i*2)].Metric)" class="card-footer click-footer" style="text-align: center!important;">
              <div>
                <div class="ranktext">{{GetRange(metrics[i+ii+(i*2)].Metric)}}</div>
              </div>
              <div class="rankpercenttext">{{GetPercentage(metrics[i+ii+(i*2)].Metric)}}</div>
              <div class="avg">
                {{GetGroupAverage(metrics[i+ii+(i*2)].Metric)}}
              </div>
            </div>
          </div>



        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal modal-blur fade show" id="modal-metric" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Definition of {{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div [innerHtml]="metricDescription"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-blur fade show" id="modal-bar" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document" style="width: 60%;max-width:none!important;height:80vh">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">{{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="fullbarData!=undefined">

        <app-bar [container]="'#bar11'" [barData]="fullbarData" [configuration]="configuration">
          <div id="bar11" style="width: 100%; margin:0 auto"></div>
        </app-bar>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-blur fade show" id="modal-multi-chart" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document" style="width: 70%;max-width:none!important;height:80vh">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">{{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="currentData!=undefined">

        <p-tabs>
          <p-tab [tabTitle]="'Table'">
            <table class="casemixgraphic fixTableHead">
              <thead>
                <tr>
                  <th>Pass (%)</th>
                  <th>Number of Providers</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let m of currentData.data">
                  <td>{{m.newpasspercent}}</td>
                  <td>{{m.NumCases}}</td>
                </tr>
              </tbody>
            </table>
          </p-tab>

          <p-tab [tabTitle]="'Profile Chart'">
            <div style="width:600px;margin:0 auto !important">
              <app-line [container]="'#line-popup'" [lineData]="currentData.data"
                        [configuration]="currentData.config"
                        [scaleValue]="currentData.scale">
                <div id="line-popup" style="height:330px;">
                </div>
              </app-line>
              <div>

                <div class="card-footer click-footer" style="text-align: center!important;">
                  <div>
                    <div class="ranktext">{{GetRange(currentData.config.metric)}}</div>
                  </div>
                  <div class="rankpercenttext">{{GetPercentage(currentData.config.metric)}}</div>
                  <div class="avg">
                    {{GetGroupAverage(currentData.config.metric)}}
                  </div>
                </div>


              </div>
            </div>
          </p-tab>
          <p-tab [tabTitle]="'Bar Chart'">  
              <app-barline [container]="'#barline'" [barData]="currentData.data" [configuration]="configBar">
                <div id="barline" style="width: 97%;"></div>
              </app-barline>
             
          </p-tab>
        </p-tabs>

      </div>
    </div>
  </div>
</div>
