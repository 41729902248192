<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'providerreport'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Provider Report for&nbsp;<span *ngIf="filters?.isSelectAll">All</span>&nbsp;<span *ngIf="filters!=undefined"> {{filters?.staffRole}}&nbsp; {{filters?.staffRole=="All"?'Services':''}}&nbsp; {{totalrecords}}</span>&nbsp; Provider count

          &nbsp;
          &nbsp;
          &nbsp;
         <app-download-dialog (download)="DownloadCallBack($event)" (sendemail)="SendEmailCallBack($event)" [reporttype]="'ProviderReport'"></app-download-dialog>
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-body">

  <div class="container-xl">

    <div *ngIf="caselogs!=undefined && caselogs.length == 0" style="text-align:center;font-weight:600"> No Records to Display</div>
    <div class="card" style="max-height: calc(100vh - 265px);" *ngIf="caselogs!=undefined && metricfields!=undefined">
      <div class="card-body-scrollable card-body-scrollable-shadow">
        <table class="casemixgraphic fixTableHead" *ngIf="caselogs!=undefined">

          <thead>
            <tr>
              <ng-container *ngFor="let d of metricfields;">
                <th *ngIf="d!='staffName'" (click)="Sort(d)" style="cursor:pointer; min-width:90px !important">
                  {{getORBISClosedCaseFields(d)}}
                  <span *ngIf="sortObjects[d]!=undefined && sortObjects[d]=='ASC'">
                    <img src="../../assets/sortasc.png" />
                  </span>
                  <span *ngIf="sortObjects[d]!=undefined && sortObjects[d]=='DESC'">
                    <img src="../../assets/sortdesc.png" />
                  </span>
                </th>
                <th *ngIf="d=='staffName'" (click)="Sort(d)" style="cursor:pointer; min-width:200px !important">
                  Provider Name
                  <span *ngIf="sortObjects[d]!=undefined && sortObjects[d]=='ASC'">
                    <img src="../../assets/sortasc.png" />
                  </span>
                  <span *ngIf="sortObjects[d]!=undefined && sortObjects[d]=='DESC'">
                    <img src="../../assets/sortdesc.png" />
                  </span>
                </th>
              </ng-container>

            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let c of caselogs">
              <ng-container *ngFor="let d of metricfields">

                <td *ngIf="d=='staffName'">
                  {{c[d] }}
                </td>

                <td *ngIf="d!='staffName'">
                  {{c[d]}}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div class="col-12">
      <app-pagination [RecordsPerPage]="100" [TotalRecords]="totalrecords" [CurrentPage]="page.currentPage" (pageEvent)="PageEvent($event)">
      </app-pagination>
    </div>
  </div>
</div>

