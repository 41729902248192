<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'outcome'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Patient Outcome for  &nbsp;
          <span *ngIf="filters!=undefined && filters?.provider=='All'">
            <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
            {{filters?.facility=="All"?'All Facilities':''}}&nbsp;
            <span *ngIf="filters!=undefined && filters?.surgeService!='All'">{{filters?.surgeService}}</span>&nbsp; {{filters?.surgeService=="All"?'All Services':''}}&nbsp;
            {{filters?.staffRole+'s'}} &nbsp;
          </span>
          <span *ngIf="filters!=undefined && filters?.provider!='All'">
            {{filters?.provider!="All"?filters?.providerName:''}} &nbsp;
          </span>{{totalCount}} Total Cases

        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-body">

  <div class="container-xl">
    <div class="row row-deck row-cards" *ngIf="Patientoutcomemetric!=undefined && Patientoutcomeboxplot!=undefined">
      <div class="col-xxl-3 col-xl-3 col-md-4 col-sm-6 " *ngFor="let f of reportmetrics; index as i;">
        <div class="card">
          <div class="card-header metrictitle">
            <h3 class="card-title" style="cursor:pointer;">
              <span data-bs-toggle="modal" data-bs-target="#modal-metric-desc" (click)="GetMetricDescription(f.Metric,f.MetricName)">{{f.MetricName}}</span> 

              <!--&nbsp; <img src="./assets/maximize.png" style="width:14px;height:14px;cursor:pointer;" data-bs-toggle="modal" *ngIf="f.OutComeLinks" data-bs-target="#modal-outcome" (click)="OpenOutCome(f.MetricName,f.OutComeLinks)"/>-->

            </h3>
            <!--<div data-bs-toggle="modal" *ngIf="f.OutComeLinks" data-bs-target="#modal-outcome" (click)="OpenOutCome(f.MetricName,f.OutComeLinks)">Test</div>-->

          </div>
          <div class="card-body click-body" style="text-align:center;padding:10px"
               *ngIf="Patientoutcomemetric!=undefined" data-bs-toggle="modal"  data-bs-target="#modal-outcome" (click)="OpenOutCome(f.MetricName,f.OutComeLinks)">
               <!--(click)="GetAllCases(f.Metric)"
               data-bs-toggle="modal" data-bs-target="#modal-metric-allcases"-->
               
            <ng-container *ngFor="let g of filtermetric(Patientoutcomemetric,f.Metric); index as j;">
              <div *ngIf="g.MetricType=='FailTotal' || g.MetricType=='FailCohort'" class="orbisoutcome">
                <div class="orbiscount">
                  {{g.MetricLabel}}
                </div>
                <div class="orbisproportion">{{g.Casepercentage}}%</div>
                <div class="orbiscount">({{g.FailedCases}} / {{g.TotalCases}})</div>

              </div>
              <div *ngIf="g.MetricType=='Text'">
                <app-pie [container]="'#piechart' + i" [pieData]="filtermetric(Patientoutcomemetricpie,g.Metric)" [margin]="margin">
                  <div id="{{'piechart'+i}}"></div>
                </app-pie>
              </div>
            </ng-container>
            <ng-container *ngIf="filtermetric(Patientoutcomeboxplot,f.Metric)?.length>0">
              <div>
                <app-mean [container]="'#meanchart' + i" [meanData]="filtermetric(Patientoutcomeboxplot,f.Metric)" [configuration]="configuration">
                  <div id="{{'meanchart'+i}}"></div>
                </app-mean>

              </div>

            </ng-container>
          </div>

        </div>
      </div>



    </div>


  </div>
</div>
<div class="modal modal-blur fade" id="modal-metric-desc" tabindex="-1"  aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header  metrictitle">
        <h5 class="modal-title">Definition of {{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div [innerHtml]="metric"></div>
      </div>
    </div>
  </div>
</div>
<div class="modal modal-blur fade" id="modal-metric-allcases" tabindex="-1" 
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title"> Case Details  </h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;" *ngIf="Allcases!=undefined">

        <div class="row" style="padding:10px 0px">
          <div class="col-md-12">
            <div class="card" style="height: 80vh !important">
              <span *ngIf="Allcases.length==100">only 100 cases shown {{chartmetric}}</span>
              <div class="card-body-scrollable card-body-scrollable-shadow">
                <div class="row">
                  <div class="col-md-6 col-xl-12">
                    <div class="mb-3" *ngIf="chartmetric!=undefined && Allcases!=undefined ">
                      <div class="form-label" style="max-width:150px !important" *ngIf="filterpiemetric(Patientoutcomemetricpie,chartmetric).length>0">

                        {{ filterpiemetric(Patientoutcomemetricpie,chartmetric)[0]?.Outcome}}
                        <select class="form-select" [(ngModel)]="selectedValue" (change)="Change(chartmetric)">
                          <option value="All">All</option>
                          <option *ngFor='let f of  filterpiemetric(Patientoutcomemetricpie,chartmetric)' [value]="f.PieValue">{{f.PieValue}}</option>

                        </select>
                      </div>

                    </div>
                  </div>

                  <table class="casemixgraphic" *ngIf="Allcases!=undefined">
                    <tr>
                      <th *ngFor="let c of metricfields" (click)="Sort(c.Field,chartmetric)" style="cursor:pointer;">
                        {{c.FieldName}}

                        <span *ngIf="sortObjects[c.Field]!=undefined && sortObjects[c.Field]=='ASC'">
                          <img src="../../assets/sortasc.png" />
                        </span>
                        <span *ngIf="sortObjects[c.Field]!=undefined && sortObjects[c.Field]=='DESC'">
                          <img src="../../assets/sortdesc.png" />
                        </span>
                      </th>
                    </tr>

                    <caption *ngIf="Allcases!=undefined && Allcases.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
                    <tbody>

                      <tr *ngFor="let c of Allcases" (click)="GetcaseDetail(c.ProcID)" class="rowhighlight">
                        <ng-container *ngFor="let d of metricfields">
                          <ng-container>
                            <td *ngIf="d.Field!='CaseDate' && d.Field!='InRoom' && d.Field!='LeaveOR'">
                              {{c[d.Field] }}
                            </td>
                            <td *ngIf="d.Field=='CaseDate' ">{{c[d.Field] | date:'MM/dd/yyyy'  }}</td>
                            <td *ngIf="d.Field=='InRoom' || d.Field=='LeaveOR'" style="min-width:150px">{{c[d.Field] | date:'MM/dd/yyyy h:mm a'  }}</td>
                          </ng-container>
                        </ng-container>

                      </tr>
                    </tbody>
                  </table>
                  <div class="row" *ngIf="Allcases!=undefined && Allcases.length > 0">
                    <div class="col-12">
                      <app-pagination [RecordsPerPage]="100" [TotalRecords]="totalrecords" [CurrentPage]="page.currentPage" (pageEvent)="PageEvent($event)">

                      </app-pagination>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-2" *ngIf="false">
            <div class="card" style="height: 85vh !important">
              <span *ngIf="failedDetails==undefined || failedDetails.length==0 " style="padding:5px">No additional outcome details</span>

              <div *ngIf="failedDetails!=undefined && failedDetails.length>0">
                <table class="casemixgraphic">


                  <tr>
                    <th *ngFor="let c of cols">
                      {{GetColumnName(c)}}
                    </th>
                  </tr>
                  <tbody>



                    <tr *ngFor="let r of rows" class="rowhighlight">
                      <td *ngFor="let v of GetRows(r)">
                        {{v.ColumnValue}}
                      </td>
                    </tr>

                  </tbody>

                </table>
              </div>

            </div>

          </div>


        </div>
      </div>
    </div>

  </div>
</div>





























<div class="modal modal-blur fade" id="modal-outcome" tabindex="-1"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title"> {{metricName}} </h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="metricScreen=undefined"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;" *ngIf="filters && metricScreen">
        <app-common-page [page]="metricScreen" [filter]="filters" [isFilter]="false"></app-common-page>
      </div>
    </div>

  </div>
</div>
