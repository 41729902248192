import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Gauge } from '../Gauge'

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.css']
})
export class GaugeComponent implements OnInit {


  @Input() container: string = ''
  @Input() targetValue: number = 0;
  @Input() metric: string = '';
  @Input() targetText: string = ''
  @Input() tickValue: number = 0;
  @Output() eventOut: EventEmitter<Gauge> = new EventEmitter();

  gauge!: Gauge;

  constructor() {
  }
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
   
    this.gauge = new Gauge(this.container, this.tickValue, { targetValue: this.targetValue, targetText: this.targetText,Metric:this.metric });
    this.gauge.Render();
    this.eventOut.emit(this.gauge);
  }

}
