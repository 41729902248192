<div>
  <table class="casemixgraphic fixTableHead" *ngIf="data!=undefined">
    <thead>
      <tr style="border-bottom:1px solid #fff;text-align:center;">
        <th colspan="5">
          Block
        </th>
        <th colspan="4">
          Cases
        </th>
      </tr>
      <tr>
        <th class="rowHead"></th>
        <th class="rowHead">Total Block</th>
        <th class="rowHead">Release</th>
        <th class="rowHead">Net Block</th>
        <th class="rowHead">Block Not Used</th>

        <th class="rowHead">Total Min Used</th>
        <th class="rowHead">Blk Min Used</th>
        <th class="rowHead">Non Blk Min Used</th>
        <th class="rowHead">Block Utilization</th>
      </tr>
    </thead>
    <caption *ngIf="data!=undefined && data.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
    <tbody>

      <tr *ngFor="let d of data; let i = index" style="text-align:center;">
        <td [ngStyle]="GetBackGroundColor(d.GroupBy,i)" style="font-weight:bold;">{{d.GroupBy}}</td>
        <td [ngStyle]="GetBackGroundColor(d.GroupBy,i)">{{d.TotalBlocks | number}}</td>
        <td [ngStyle]="GetBackGroundColor(d.GroupBy,i)">{{d.ReleaseTime | number}}</td>
        <td [ngStyle]="GetBackGroundColor(d.GroupBy,i,true)" style="font-weight:bold;">{{d.NetBlock  | number}}</td>
        <td [ngStyle]="GetBackGroundColor(d.GroupBy,i)">{{d.BlockNotUsed | number}}</td>
        <td [ngStyle]="GetBackGroundColor(d.GroupBy,i)">{{d.TotalMinUsed | number}}</td>
        <td [ngStyle]="GetBackGroundColor(d.GroupBy,i,true)" style="font-weight:bold;">{{d.BlkMinUsed | number}}</td>
        <td [ngStyle]="GetBackGroundColor(d.GroupBy,i)">{{d.NonBlkMinUsed | number}}</td>
        <td style="font-weight: bold; background-color: #c2cfd6; ">{{d.utilization}}%</td>
      </tr>
    </tbody>
  </table>
</div>
<div style="margin-top:30px;">
  <table class="casemixgraphic fixTableHead" *ngIf="data!=undefined">
    <tbody>
      <tr><td class="rowHead" style="width:170px;">Total Block</td><td>Minutes (Start to End) allocated to services in block schedule</td></tr>
      <tr><td class="rowHead" style="width:170px;">Release</td><td>If release date 6 weeks or more prior, then release full block minutes. If release date 2-6 weeks prior, then release half block minutes. If release date under 2 weeks prior, then release no minutes.</td></tr>
      <tr><td class="rowHead" style="width:170px;">Net Block</td><td>Total Block - Release</td></tr>
      <tr><td class="rowHead" style="width:170px;">Block Not Used</td><td>Net Block - Blk Min Used</td></tr>
      <tr><td class="rowHead" style="width:170px;">Total Min Used</td><td>Total minutes in the operating room, including setup and cleanup, that the service used</td></tr>
      <tr><td class="rowHead" style="width:170px;">Blk Min Used</td><td>Minutes in the operating room, including setup and cleanup, that fall within the block start and stop time allocated to service</td></tr>
      <tr><td class="rowHead" style="width:170px;">Non Blk Min Used</td><td>Total Min Used - Blk Min Used</td></tr>
      <tr><td class="rowHead" style="width:170px;">Block Utilization</td><td style="background-color: #c2cfd6;">Blk Min Used / Net Block</td></tr>
    </tbody>
  </table>
</div>
