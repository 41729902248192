export class NewFilters {
  public fromDate: string;
  public toDate: string;
  public month: string;
  public year: string;
  public quarter: string;
  public dateRange: string;

  public role: string;
  public site: string;
  public daycategory: any;
  public multiprovider: Array<string>;

  public isProviderSelectAll: boolean;
  public isSelectAll: boolean;
  public multiproviderObject: Array<any>;

  public hours_type: string;
}
