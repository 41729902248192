export class TableModel {
  columns: Array<ColumnModel> = [];
  table_data: Array<any> = [];
  is_downloadable: boolean = false;
  is_pagination: boolean = false;
  total_records: number = 0;
  is_switch: boolean = false;
  width: string | undefined = undefined;
  default_sort_column: string | undefined = undefined;
  default_sort_order: string | undefined = undefined;
}
export class ColumnModel {
  constructor(data: Partial<ColumnModel>) {
    Object.assign(this, data);
  }
  is_soratable: boolean = false;
  header: string = "";
  data_key: string = "";
  width: string | undefined = undefined;
  column_type: 'date' | 'string' | 'number' = "string";
  filter_type: 'date' | 'string' | 'number' | 'freetext' | 'none' = 'none';
  date_format: string = "MM/dd/yyyy";
  number_format: string = "";
  group: string | undefined = undefined;
  filter_texts: Array<string> | undefined = [];
  max_width: string | undefined = undefined;
  min_width: string | undefined = undefined;
  width_header_split: string | undefined = undefined;
  width_data_split: string | undefined = undefined;
  order: number;
  //text_align: string = "center";
  //row_span: number | undefined = undefined;
  //col_span: number | undefined = undefined;

}

export class Page {
  recordsPerPage: number = 50;
  currentPage: number = 1;
}



