import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { environment } from '../environments/environment';
import { CommonService } from './common.service';
import IdleTimer from "./d3charts/IdleTimer";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Orbis';
  selectedcategory: any = {};
  header: Array<string>;
  child: Array<any>;
  timer: any;
  appHeader: string;
  constructor(private route: Router, private commonService: CommonService) {


    //forkJoin([
    //  this.commonService.GetRole(),
    //  this.commonService.GetUserMenu(),
    //  this.commonService.GetUser(),
    //  this.commonService.GetLastupdatedDate()
    //])
    //  .subscribe(([r, m, u, v]) => {

    //    this.header = m["header"];
    //    this.child = m["child"];

    //    let p = JSON.stringify(m["child"].map(a => { return "/" + a.AngularRoute }));
    //    sessionStorage.setItem("usermenu", p);
    //    sessionStorage.setItem("userrole", r);
    //    sessionStorage.setItem("userlogin", u);
    //    sessionStorage.setItem("lastUpdateddate", v["LastupdatedDate"]);

    //    if (m["child"].length > 0) {
    //      route.navigate(['/' + m["child"][0].AngularRoute]);
    //    }
    //    else {
    //      route.navigate(['']);
    //    }
    //  })

    //setTimeout(() => {
    //  if (sessionStorage.getItem("menuheader")) {
    //    this.header = JSON.parse(sessionStorage.getItem("menuheader"));
    //    this.child = JSON.parse(sessionStorage.getItem("menuchild"));

    //  }
    //  else {
    //    
    //    window.location.href = environment.loginURL;
    //  }
    //}, 5000);



  }
  async GetUserDetails() {
    setTimeout(async () => {
      var role = await this.commonService.GetRole();
      var menu = await this.commonService.GetUserMenu();
      var user = await this.commonService.GetUser();
      var ld = await this.commonService.GetLastupdatedDate(environment.AppId)
      var lastUpdatedDate = ld.LastupdatedDate;
      this.appHeader = ld.ApplicationName;
      this.header = menu["header"];
      this.child = menu["child"];

      //let p = JSON.stringify(menu["child"].map(a => { return "/" + a.AngularRoute }));
      //sessionStorage.setItem("usermenu", p);
      sessionStorage.setItem("usermenu", JSON.stringify(menu));
      sessionStorage.setItem("userrole", role);
      sessionStorage.setItem("userlogin", user);
      sessionStorage.setItem("lastUpdateddate", lastUpdatedDate);
      sessionStorage.setItem("appHeader", this.appHeader);

      if (this.child.length > 0) {
        let c = this.child.filter(m => m.IsDefaultRoute);
        if (c.length > 0)
          this.route.navigate([c[0].AngularRoute]);
        else
          this.route.navigate(['/' + this.child[0].AngularRoute]);
      }
      else {
        this.route.navigate(['']);
      }
    }, 1000);
  }
  GetChildMenu(h: string) {
    return this.child.filter(c => c.Header == h);
  }
  showorhidemenu(menu) {
    this.selectedcategory[menu] = this.selectedcategory[menu] ? false : true;
  }

  ngOnInit() {
    if (sessionStorage.getItem("usermenu") && sessionStorage.getItem("userrole") && sessionStorage.getItem("userlogin") && sessionStorage.getItem("lastUpdateddate") && sessionStorage.getItem("appHeader")) {
      let menu = JSON.parse(sessionStorage.getItem("usermenu"));
      this.header = menu["header"];
      this.child = menu["child"];
      this.appHeader = sessionStorage.getItem("appHeader")
    } else {
      this.GetUserDetails();
    }

    if (environment.IsProd) {
      this.timer = new IdleTimer({
        timeout: 3600000, //expired after 30 mins
        onTimeout: () => {
          this.title = "Timeout";
          //TODO
          sessionStorage.clear();

          window.location.href = environment.loginURL;
        }
      });
    }
  }
  ngOnDestroy() {
    this.timer.clear();
  }
}
