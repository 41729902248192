import { Component, OnInit } from '@angular/core';
import { Filters } from '../Filters';
import { CommonService } from '../common.service';
import { filter } from 'd3-array';

@Component({
  selector: 'app-distribution-rank',
  templateUrl: './distribution-rank.component.html',
  styleUrls: ['./distribution-rank.component.css']
})
export class DistributionRankComponent implements OnInit {
  filters: Filters;
  metricDescription: string
  metricName: string;
  fullbarData: any;

  // totalCases: number;
  metrics: Array<any>;
  distrankData: Array<any>;
  distrankProviderData: Array<any>;
  distrankBarData: Array<any>;
  // distrankData1: Array<any>;
  rowsCount: number = 0;
  totalCount: number;
  currentData: any;

  configuration: any =
    {
      yrange: 'percentage',
      metric: 'OnTimeAnes',
      datefield: 'CaseMonth',
      field: 'RankPercent',
      datefilter: "Month",
      group_by: 'StaffID',
      group_by_label: 'StaffName',
      categories: [{ "StaffID": "5225000", "StaffName": "ANWAR, ANJUM" }],
      selectedcategory: 'SelectAll',
      ylabel: '% Rank'
    };
  configBar: any = {
    xaxis: "newpasspercent",
    yaxis: "NumCases",
    ytext: "Numberber of Providers",
    xtext: "Pass (%)",
    legendtext: "Number of Providers"
  }


  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {

  }

  LoadData() {
    this.metrics = undefined;
    this.distrankData = undefined;
    this.distrankProviderData = undefined;
    this.rowsCount = 0;
    this.totalCount = 0;
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    if (this.filters.provider.toLowerCase() == "all") {
      this.filters = this.setYearQuarter(this.filters);
      this.commonService.GetDistRank(this.filters.staffRole, this.filters.facility, this.filters.surgeService, this.filters.year, this.filters.month).subscribe(d => {
        this.metrics = d["MetricData"];
        this.totalCount = d["totalCases"];
        let distrankData = groupBy(d["DistAndRank"], 'Metric');
        this.rowsCount = Math.ceil((this.metrics.length / 3));

        let keys = Object.keys(distrankData);
        for (var i = 0; i < keys.length; i++) {
          let d1 = distrankData[keys[i]];

          d1 = d1.map((r, i) => {
            if (i == 0) {
              //r.newpasspercent = "0-" + r.PassPercent.toString();
              r.newpasspercent =r.PassPercent.toString();
            } else {
              r.newpasspercent = (d1[i - 1].PassPercent).toString() + "-" + r.PassPercent.toString();
            }
            return r;
          });
          let d = d1[0];

          distrankData[keys[i] + "_Config"] = this.GetConfig(d);
          distrankData[keys[i] + "_Scale"] = this.GetScale(keys[i], d);
        }
        this.distrankData = distrankData;
      });
    }
    else {
      this.filters = this.setYearQuarter(this.filters);
      this.commonService.GetDistRankProvider(this.filters.staffRole, this.filters.facility, this.filters.surgeService, this.filters.provider, this.filters.year, this.filters.month).subscribe(d => {

        this.metrics = d["MetricData"];
        this.totalCount = d["totalCases"];
        let distrankData = groupBy(d["DistAndRank"], 'Metric');
        this.distrankProviderData = groupBy(d["DistAndRankProvider"], 'Metric');
        this.rowsCount = Math.ceil((this.metrics.length / 3));

        let keys = Object.keys(distrankData);
        for (var i = 0; i < keys.length; i++) {
          let d1 = distrankData[keys[i]];

          d1 = d1.map((r, i) => {
            if (i == 0) {
              //r.newpasspercent = "0-" + r.PassPercent.toString();
              r.newpasspercent = r.PassPercent.toString();
            } else {
              r.newpasspercent = (d1[i - 1].PassPercent).toString() + "-" + r.PassPercent.toString();
            }
            return r;
          });
          let d = d1[0];

          distrankData[keys[i] + "_Config"] = this.GetConfig(d);
          distrankData[keys[i] + "_Scale"] = this.GetScale(keys[i], d);
        }
        this.distrankData = distrankData;
      });
    }

  }
  OpenMultiChart(m: any) {
    this.currentData = undefined;
    this.metricName = m.MetricName;
    setTimeout(() => {
      this.currentData = { data: this.distrankData[m.Metric], scale: this.distrankData[m.Metric + "_Scale"], config: this.distrankData[m.Metric + "_Config"] };
    }, 100);
    //console.log(this.currentData)
  }
  GetShow(m) {
    if (this.filters.provider.toLowerCase() == "all") {
      if (this.distrankData[m] != undefined && this.distrankData[m].length > 0)
        return true;
      else {
        return false;
      }
    }
    else {
      if (this.distrankProviderData[m] != undefined && this.distrankProviderData[m].length > 0 && this.distrankData[m] != undefined && this.distrankData[m].length > 0)
        return true;
      else {
        return false;
      }

    }

  }
  GetConfig(c: any) {
    //console.log(m + "---->" + mn)
    //if (this.distrankData[m] == undefined)
    //  console.log(m + "---->" + mn)
    // var c = this.distrankData[m][0];
    //var minrange = Math.floor(c.MetricMin / 10) * 10;
    var minrange = c.MetricMin;
    var maxrange = Math.floor(c.MetricMax / 10) * 10;
    return { yrange: 'percentage', metric: c.Metric, minrange: minrange, maxrange: maxrange };

  }
  GetScale(m, distData) {
    if (this.filters.provider.toLowerCase() == "all") {
      return distData?.MetricAvg;
    }
    else {
      var distData = this.distrankProviderData[m];

      if (distData != undefined && distData.length > 0)
        if (distData[0].RankValue > 0)
          return distData[0]?.PassPercent;
        else
          return distData[0]?.MetricAvg;
      else {
        return "No Data"
      }

    }
  }
  GetRange(m) {
    if (this.filters.provider.toLowerCase() == "all") {
      var c = this.distrankData[m];
      return "Range = " + c[0].MetricMin + "% to " + c[c.length - 1].MetricMax + "%";
    }
    else {
      var c = this.distrankProviderData[m];
      if (c != undefined && c.length > 0) {
        if (c[0].RankValue > 0 && c[0].RankDenom > 0)
          return "Rank = " + c[0].RankValue + "  /  " + c[0].RankDenom + "peers";
        else
          return "Five or more cases required for ranking";
      }
      else
        return "";

    }

  }
  GetPercentage(m) {
    if (this.filters.provider.toLowerCase() != "all") {
      var c = this.distrankProviderData[m];
      if (c != undefined && c.length > 0) {
        if (c[0].RankPercent > 0)
          return c[0].RankPercent + " Percentile";
        else
          return "";

      }
      else
        return "";
    }
  }
  GetGroupAverage(m) {
    if (this.filters.provider.toLowerCase() == "all") {
      var c = this.distrankData[m][0];
      return "Group Average = " + c.MetricAvg + "% ";
    }
    else {
      var c = this.distrankProviderData[m];
      if (c != undefined && c.length > 0) {

        return "Group Average = " + c[0].MetricAvg + "%";

      }
      else
        return "";
    }

  }
  GetDefinition(m) {

    var c = this.metrics.filter(x => x.MetricName == m)[0];

    this.metricName = c.MetricName;
    this.metricDescription = c.MetricDescription;
  }
  GetBar(m) {
    var c = this.metrics.filter(x => x.Metric == m)[0];

    this.metricName = c != undefined ? c.MetricName : m;
    this.fullbarData = undefined;
    this.configuration.categories = undefined;
    this.filters = this.setYearQuarter(this.filters);
    this.commonService.GetDistRankBar(this.filters.staffRole, this.filters.facility, this.filters.surgeService, this.filters.provider, this.filters.year, this.filters.month, m, this.filters.dateRange).subscribe(d => {
      this.fullbarData = d['DistAndRankBar'];
      var obj = {};
      obj['StaffID'] = this.fullbarData[0].StaffID;
      obj['StaffName'] = this.filters?.providerName;
      this.configuration.categories = [obj];
      this.configuration.datefilter = this.filters.dateRange;
    });
  }
  setYearQuarter(filters: Filters) {
    if (filters.dateRange == "year") {
      filters.month = "0";
    }
    else if (filters.dateRange == "quarter") {
      if (filters.quarter == "q1")
        filters.month = "13";
      if (filters.quarter == "q2")
        filters.month = "14";
      if (filters.quarter == "q3")
        filters.month = "15";
      if (filters.quarter == "q4")
        filters.month = "16";
    }
    return filters;
  }
  OnFilter(e: Filters) {
    this.filters = e;
    this.LoadData();
  }
  counter(i: number) {
    return new Array(i);
  }
}
