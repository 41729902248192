<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'recomendations'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Recommendations
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="allRecomendationsFiltered" class="page-body">
  <div class="container-xl">

    <div class="card" style="max-height: calc(100vh - 260px);">
      <div class="card-body-scrollable card-body-scrollable-shadow">

        <table class="casemixgraphic fixTableHead " *ngIf="allRecomendationsFiltered!=undefined">

          <thead>
            <tr>

              <!--<th>
    Facility
  </th>
  <th>
    Metric
  </th>-->
              <!--<th>
    Feature Set
  </th>
  <th>
    Feature Value
  </th>-->
              <th>
                Provider Name
              </th>
              <th>
                Observation
              </th>
              <th>
                Magnitude
              </th>

              <th>
                Recommendation
              </th>
            </tr>
          </thead>

          <caption *ngIf="allRecomendationsFiltered!=undefined && allRecomendationsFiltered.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
          <tbody>
            <tr>
              <td>
               
              </td>
              <td>
                
              </td>
              <td></td>
              <td><input type="text" style="width:100%" [(ngModel)]="searchRecommendation" (ngModelChange)="this.filterChange.next($event)" [placeholder]="'Search Recommendation..'" /></td>
            </tr>
            <tr class="recomendation" *ngFor="let v of allRecomendationsFiltered" (click)="GetRecomentationPopup(v.RecommendationID,v.Recommendation,v.StartPeriod,v.EndPeriod,v.ServiceLine,v.StaffName)" data-bs-toggle="modal" data-bs-target="#modal-recomendations">
              <td>{{v.StaffName}}</td>
              <td>{{v.UX_String}}</td>
              <td>{{v.Magnitude}}</td>
              <td>{{v.Recommendation}}</td>
            </tr>
          </tbody>

        </table>

      </div>
    </div>

    <div class="row">
      <div class="col-12">
      </div>
    </div>

  </div>
</div>


<div class="modal modal-blur fade show blur" id="modal-recomendations" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="min-width: 1000px;min-height:50px;overflow-y:auto">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">
          Recommendations &nbsp;
          {{StartPeriod| date:'MM/dd/yyyy' }} to {{EndPeriod| date:'MM/dd/yyyy' }}, {{Service}},{{ProviderName}}
        </h5>
        <button data-bs-target="#modal-recomendations-loading" data-bs-toggle="modal" type="button" class="btn btn-primary" (click)="SaveStudies()">
          Create iQuest Study
        </button>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <div class="card" style="text-align: center;">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div *ngIf="recomendations">
              <app-dynamic-recomendations [recomendations]="recomendations" [recomendationString]="recomendationString"></app-dynamic-recomendations>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal modal-blur fade show blur" id="modal-recomendations-loading" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document">
    <div class="modal-content" style="width: 450px!important;min-height:250px!important;border:1px solid #ccc">
      <div class="modal-header metrictitle">

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;padding:15px">
        <div class="card" style="text-align: center;margin-top:25px;">
          <div *ngIf="!isStudiesPosted" style="text-align:center;">
            <img src="../../assets/loader.gif" /><br /><br />
            <b>iQuest study creation in progress..</b>
          </div>
          <div *ngIf="isStudiesPosted">
            <!--<h2>{{study}}</h2><br /><br />-->
            Study ready! Click link to view
            <a class="btn btn-primary" style="width:200px!important;" data-bs-target="#modal-dashboard" data-bs-toggle="modal">
              Go to Dashboard
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>



<!--<div class="modal modal-blur fade show blur" id="modal-dashboard" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document">
    <div class="modal-content" style="width: 600px!important;min-height:250px!important;border:1px solid #ccc">
      <div class="modal-header metrictitle">

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;padding:15px">
        <div class="card" style="text-align: center;margin-top:25px;">
          <iframe class="O_iframe" [src]="dashboardURL | safe" frameborder="0" allowfullscreen="true" style="width:1500px; height: 99.5vh;"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>-->


<div class="modal modal-blur fade show blur" id="modal-dashboard" tabindex="-1" style="display: none; "
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered tmodal-dialog" role="document" style="min-width:100%!important;">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <!--<h5 class="modal-title">Cases Failing {{metricName}}</h5>-->
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="dashboardURL!=undefined" style="height: 90vh; overflow-y: auto; padding-top: 2px !important">
        <iframe class="O_iframe" [src]="dashboardURL | safe" frameborder="0" allowfullscreen="true" style="width:100%; height: 99.5vh;"></iframe>

      </div>

    </div>
  </div>
</div>
