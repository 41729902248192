import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';

@Component({
  selector: 'app-glucose-management',
  templateUrl: './glucose-management.component.html',
  styleUrls: ['./glucose-management.component.css']
})
export class GlucoseManagementComponent implements OnInit {
  metrics: any;
  glucosecohorts: any;
  glucosemetricvalues: any;
  glucosecases: any;
  selectedcase: any;
  glucosecasedetails: any;
  glucosecasemetric: any;
  metricfields: any;
  metricoptions: any;
  casefields: any;  
  filters: Filters;
  metric: string;  
  metricName: string;
  metricDesc: string;
  metricValue: string;
  gliflozinfilter: number;
  diabetesfilter: number;
  afterhoursfilter: number;

  timeComponents: Array<string> = ['SchedSurgDtTm', 'InRoom', 'ProcStart', 'ProcEnd', 'LeaveOR', 'AnesStart', 'AnesEnd'];
  colormap: any =
    {
    'Completed': '#64824c',    
    'Partially Completed': '#b76735',   
    'Insulin Started': '#46546d',   
    'Holding': '#2c385e',
    'OR': '#61677a'
  };

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }
  LoadData() {
    this.metrics = undefined;
    this.glucosecohorts = undefined;
    this.glucosemetricvalues = undefined;

    this.gliflozinfilter = 0;
    this.diabetesfilter = 0;
    this.afterhoursfilter = 0;

    var gliflozincheckbox = <HTMLInputElement>document.getElementById("gliflozinfilter");
    if (gliflozincheckbox.checked) {
      this.gliflozinfilter = this.gliflozinfilter + 1;
    }

    var nogliflozincheckbox = <HTMLInputElement>document.getElementById("nogliflozinfilter");
    if (nogliflozincheckbox.checked) {
      this.gliflozinfilter = this.gliflozinfilter + 2;
    }

    var diabetescheckbox = <HTMLInputElement>document.getElementById("diabetesfilter");
    if (diabetescheckbox.checked) {
      this.diabetesfilter = this.diabetesfilter + 1;
    }

    var nodiabetescheckbox = <HTMLInputElement>document.getElementById("nodiabetesfilter");
    if (nodiabetescheckbox.checked) {
      this.diabetesfilter = this.diabetesfilter + 2;
    }

    var normalhourscheckbox = <HTMLInputElement>document.getElementById("normalhoursfilter");
    if (normalhourscheckbox.checked) {
      this.afterhoursfilter = this.afterhoursfilter + 1;
    }

    var afterhourscheckbox = <HTMLInputElement>document.getElementById("afterhoursfilter");
    if (afterhourscheckbox.checked) {
      this.afterhoursfilter = this.afterhoursfilter + 2;
    }

    this.commonService.GetGlucoseManagementData(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, this.gliflozinfilter, this.diabetesfilter, this.afterhoursfilter).subscribe(d => {
      this.metrics = d["ORBISMetric"];
      this.glucosecohorts = d["GlucoseManagementCohort"];
      this.glucosemetricvalues = d["GlucoseManagementMetric"];
    })
  }
  OnFilter(e: Filters) {

    this.filters = e;
    this.LoadData();
  }

  GetMetricDescription(glucosemetric: string, metricName: string) {
    this.metricName = metricName;
    this.metricDesc = undefined;
    this.commonService.GetMetricDescription(glucosemetric).subscribe(d => {
      this.metricDesc = d;
    })
  }

  GetGlucoseCohortCount(cohort: string) {
    var x = this.glucosecohorts.filter(c => c.Cohort.toLowerCase() == cohort.toLowerCase());
    if (x != undefined && x.length > 0) {
      return x[0].CaseCount;
    }
    else
      return 0;
  }

  GetGlucoseMetric(glucosemetric: string) {
    var x = this.glucosemetricvalues.filter(c => c.Metric.toLowerCase() == glucosemetric.toLowerCase() && !c.Value.includes('Not'));
    if (x != undefined && x.length > 0) {
      return x.sort((x, y) => x.OrderValue > y.OrderValue ? 1 : -1);
    }
    else
      return [];
  }

  GetGlucoseCaseCount(glucosemetric: string) {
    var x = this.glucosemetricvalues.filter(c => c.Metric.toLowerCase() == glucosemetric.toLowerCase());
    if (x != undefined && x.length > 0) {
      return x.reduce((sum, current) => sum + current.ValueCount, 0);
    }
    else
      return 0;
  }
  GetGlucoseCases(glucosemetric: string, glucosemetricname: string, glucosemetricvalue: string) {
    this.GetMetricDescription(glucosemetric, glucosemetricname);
    this.metric = glucosemetric;
    this.metricName = glucosemetricname;
    this.metricValue = glucosemetricvalue;
    this.glucosecases = undefined;
    this.selectedcase = undefined;

    this.commonService.GetGlucoseCases(this.filters.facility, this.filters.location, this.filters.staffRole, this.filters.provider, this.filters.surgeService, this.filters.fromDate, this.filters.toDate, this.gliflozinfilter, this.diabetesfilter, this.afterhoursfilter, glucosemetric, glucosemetricvalue).subscribe(d => {
      this.metricfields = d["ORBISMetricField"];
      this.casefields = d["ORBISClosedCaseField"];
      this.glucosecases = d["ORBISClosedCaseAll"];
      this.selectedcase = d["ORBISClosedCaseFirst"];      
      this.glucosecasedetails = d["GlucoseCaseDetail"];
      this.glucosecasemetric = d["GlucoseCaseMetric"];
      this.metricoptions = d["GlucoseMetricOption"];
    })
  }

  GetGlucoseCaseDetails(procid: string) {
    this.selectedcase = undefined;
    this.glucosecasedetails = undefined;
    this.glucosecasemetric = undefined;

    this.commonService.GetGlucoseCaseDetails(procid).subscribe(d => {
      this.selectedcase = d["ORBISClosedCase"];
      this.glucosecasedetails = d["GlucoseCaseDetail"];
      this.glucosecasemetric = d["GlucoseCaseMetric"];
    })
  }

  getORBISClosedCaseFields(field: string) {
    return this.casefields.filter(c => c.Field == field)[0]?.FieldName;
  }
  getORBISClosedCaseFieldValue(field: string) {
    return this.selectedcase.length > 0 ? this.selectedcase[0][field] : "";
  }
  getORBISGlucoseValue(field: string) {
    return this.glucosecasemetric.filter(c => c.Metric == field)[0]?.Value;
  }
}
