import { Component, ViewChild } from '@angular/core';
import { NewFilters } from '../new-filters/NewFilters';
import { ProductivityService } from '../productivity.service';
import { HBarConfig, HData, HbarData } from '../d3-new/models/hbarmodel';
import { LineConfig, LineModel, LineStyle, LineValues, MarkerStyle } from '../d3-new/models/linemodel';
import { ChartService } from '../d3-new/chart.service';
import { DisplayModel } from '../d3-new/models/commonmodel';
import { NewFiltersComponent } from '../new-filters/new-filters.component';

@Component({
  selector: 'app-productivity-report',
  templateUrl: './productivity-report.component.html',
  styleUrls: ['./productivity-report.component.css']
})
export class ProductivityReportComponent {

  @ViewChild(NewFiltersComponent)
  childComponentRef: NewFiltersComponent;

  _barConfig: HBarConfig = undefined;
  _lineConfig: LineConfig = undefined;
  filters: NewFilters;
  widgets: any;
  barData: any;
  lineData: any;
  //lineDataMaster: any;
  tabularDataMaster: any;
  tabularData: any;

  groupBy: any = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  constructor(private prodService: ProductivityService, private chartService: ChartService) {
    //this.FormData();
  }



  /*On Bar click Event*/
  OnBarClick(e: NewFilters) {

    this.widgets = undefined;

    this.lineData = undefined;
    this.tabularData = undefined;

    this._lineConfig = undefined;
    let providername = e.isProviderSelectAll === true ? 'All' : e.multiprovider.join(";");
    this.prodService.GetProductivityReports(e.fromDate, e.toDate, e.role, e.site, e.daycategory, providername, e.hours_type).subscribe(d => {

      this.widgets = d["Widget"];

      /* this.barData = d["BarData"];*/
      this.FeedLineData(d["LineData"]);
      this.lineData = d["LineData"];
      //this.lineDataMaster = this.lineData;
      this.tabularData = d["TableData"];
      this.tabularDataMaster = this.tabularData;

    });

  }

  /*On Apply click Event*/
  OnFilter(e: NewFilters) {
    this.filters = e;
    this.widgets = undefined;
    this.barData = undefined;
    this.lineData = undefined;
    this.tabularData = undefined;
    this._barConfig = undefined;
    this._lineConfig = undefined;
    let providername = e.isProviderSelectAll === true ? 'All' : e.multiprovider.join(";");
    this.prodService.GetProductivityReports(e.fromDate, e.toDate, e.role, e.site, e.daycategory, providername, e.hours_type).subscribe(d => {

      this.widgets = d["Widget"];

      /* this.barData = d["BarData"];*/
      this.FeedBarData(d["BarData"]);
      this.FeedLineData(d["LineData"]);
      this.lineData = d["LineData"];
      //this.lineDataMaster = this.lineData;
      this.tabularData = d["TableData"];
      this.tabularDataMaster = this.tabularData;

    });

  }
  FeedWidgets(data: Array<any>) {

  }
  FeedLineData(data: Array<any>) {

    data = data.map(d => {
      let dd = d['FirstDayOfWeek'].split('-');
      d.FirstDayOfWeek = new Date(parseInt(dd[2]), parseInt(dd[1]) - 1, parseInt(dd[0]));
      return d;
    });
    let l = new LineStyle();
    l.color = "#269ffb";
    l.width = 5;

    let m = new MarkerStyle();
    m.shape = "diamond";
    m.stroke = "#269ffb";
    m.fill = "#269ffb";
    m.size = 10;

    let l1 = new LineStyle();
    l1.color = "#26e7a5";
    l1.width = 5;

    let m1 = new MarkerStyle();
    m1.shape = "plus";
    m1.stroke = "#26e7a5";
    m1.fill = "#26e7a5";
    m1.size = 10;

    let lineObj = this.chartService.GenerateLineData(data, 'FirstDayOfWeek', 'AvgHours', 'HoursType',
      ['Prime', 'NonPrime'], [l, l1], [m, m1]);

    lineObj.margin_top = 20;
    lineObj.margin_bottom = 40;
    lineObj.margin_right = 10;
    lineObj.margin_left = 30;
    lineObj.xaxistype = "datetime";
    lineObj.zoomtype = "zoom";
    lineObj.container = "#linecontainer";
    lineObj.height = 200;
    lineObj.xlabel = "";
    lineObj.ylabel = "";

    setTimeout(() => {
      this._lineConfig = lineObj;
    });

  }
  FeedBarData(data: Array<any>) {
    let hBarObj = this.chartService.GeneratePlainBarData(data, 'Providername',
      ['PrimeAvgHoursPercentage', 'NonPrimeAvgHoursPercentage'], ['#269ffb', '#26e7a5'],//feb019
      ['Prime', 'Non Prime'], ['PrimeAvgHours', 'NonPrimeAvgHours']);


    //let hBarObj = new HBarConfig();
    hBarObj.margin_top = 20;
    hBarObj.margin_bottom = 20;
    hBarObj.margin_right = 10;
    hBarObj.margin_left = 140;
    hBarObj.height = 200;
    hBarObj.bar_height = 40;
    hBarObj.container = "#barcontainer";
    hBarObj.axis_position = "top";

    /* Tool tip purpose */
    let dd = new DisplayModel();
    dd.display_text = "Prime Avg Hours";
    dd.display_key = "PrimeAvgHours";
    hBarObj.tooltip_keys.push(dd);
    dd = new DisplayModel();
    dd.display_text = "Non Prime Avg Hours";
    dd.display_key = "NonPrimeAvgHours";
    hBarObj.tooltip_keys.push(dd);

    setTimeout(() => {
      this._barConfig = hBarObj;
    });
  }

  BarBulkSelected(d: HbarData) {
    /* Change Filter component */
    this.childComponentRef.isSelectAll = false;
    this.childComponentRef.selectedHoursType = "All";
    this.childComponentRef.selectedMultiProvider = [{ ProviderId: d.key, ProviderName: d.key }];
    /* Set Session */
    this.childComponentRef.OnFilter(false);

    /* Changing local filter object */
    this.filters.isProviderSelectAll = false;
    this.filters.isSelectAll = false;
    this.filters.multiprovider = [d.key];
    this.filters.hours_type = "All";

    /* Render Charts */
    this.OnBarClick(this.filters);

  }
  DeselectBar() {
    /* Change Filter component */
    this.childComponentRef.isSelectAll = true;
    this.childComponentRef.selectedHoursType = 'All';
    this.childComponentRef.selectedMultiProvider = this.childComponentRef.providers;
    /* Set Session */
    this.childComponentRef.OnFilter(false);

    /* Changing local filter object */
    this.filters.isProviderSelectAll = true;
    this.filters.isSelectAll = true;
    this.filters.hours_type = 'All';
    this.filters.multiprovider = this.childComponentRef.providers.map(f => f.ProviderId);


    /* Render Charts */
    this.OnBarClick(this.filters);

  }
}
