import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-block-request',
  templateUrl: './block-request.component.html',
  styleUrls: ['./block-request.component.css']
})
export class BlockRequestComponent implements OnInit {
  user: string;
  lastUpdateddate: string;
  blockrequest: any;

  columns: Array<any> = [
    { "name": "Request ID" },
    { "name": "Request Date" },
    { "name": "Requester Name" },
    { "name": "Requester Email" },
    { "name": "Service" },
    { "name": "Day of Week" },
    { "name": "Frequency" },
    { "name": "Justification" },
    { "name": "Status" },
    { "name": "Status Comment" }    
  ];

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.user = sessionStorage.getItem("userlogin");
    this.lastUpdateddate = sessionStorage.getItem("lastUpdateddate");
    this.LoadData();
  }
  LoadData() {
    this.commonService.GetBlockRequest().subscribe(d => {
      this.blockrequest = d["blockrequest"];    
    })
  }
  logout() {
    if (environment.IsProd) {
      var obj = {};
      obj['ApplicationId'] = environment.AppId;
      obj['UserLogin'] = sessionStorage.getItem("userlogin");
      obj['ActionType'] = "LOGOUT";
      obj['URI'] = 'ORBIS LOGOUT';
      this.commonService.PostLog(obj).subscribe(d => {
        sessionStorage.clear();

        window.location.href = environment.loginURL;
      });
    }
    else {
      window.location.href = environment.loginURL;
    }
  }
}
