import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bm-claim-release',
  templateUrl: './bm-claim-release.component.html',
  styleUrls: ['./bm-claim-release.component.css']
})
export class BmClaimReleaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
