<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'orsnapshot'"></app-filters>
</div>

<div class="container-xl container-sm container-md blur">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Snapshot for&nbsp;
          <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
          {{filters?.facility=="All"?'All Facilities':''}}&nbsp;
          <span *ngIf="filters!=undefined && filters.surgeService!='All'">{{filters?.surgeService}}</span>&nbsp;
          {{filters?.surgeService=="All"?'All Services':''}}&nbsp; {{caseVolumeTotal}} &nbsp;Total Cases
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-body blur">

  <div class="container-xl container-sm container-md">


    <div class="row row-cards" id="main" *ngIf="this.metricOrder!=undefined">
      <!--<div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{GetOrder('numcases')}}">-->
      <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{'id_'+metricOrder['NumCases']?.MetricOrder}}">
        <div class="card">
          <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric"
               (click)="GetMetricDescription('NumCases','Case Volume')">
            <h4 class="card-title" style="cursor:pointer;">Case Volume</h4>

          </div>
          <div class="card-body p-0 click-body"
               data-bs-toggle="modal" (click)="GetMetricBar('numcases','Case Volume')" data-bs-target="#modal-metric-bar-detail" style="max-height:185px;overflow-y:auto;padding:4px !important;">
            <table class="table card-table table-vcenter casemixgraphic">

              <tbody>
                <tr *ngFor="let c of caseVolume">
                  <td style=" font-weight: bold; color: #193959; font-size: 120%; padding-left: 0px !important; padding-right: 0px !important ">
                    {{c.Location}}
                  </td>
                  <td style=" font-weight: 500; font-size: 120%; padding-left: 0px !important; padding-right: 0px !important ">{{c.LocationCount}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer click-footer" style="text-align: center!important;background-color:#eee;">
            <div class="countcontainer">

              <span class="reportcount" (click)="GetAllCases()" data-bs-toggle="modal"
                    data-bs-target="#modal-metric-allcases" style="font-size:200%;font-family:Arial;">{{caseVolumeTotal}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" *ngFor="let f of failedCasesPercentage; index as i;" id="{{'id_'+f.MetricOrder}}">
        <div class="card">
          <div class="card-header metrictitle click-header">
            <h4 class="card-title">
              <span data-bs-toggle="modal" data-bs-target="#modal-metric" (click)="GetMetricDescription(f.Metric,f.MetricName)">{{f.MetricName}}</span>
              &nbsp;
              <span *ngIf="f.Metric.toLowerCase()=='turnovertime' && filters.surgeService=='All'" data-bs-toggle="modal" data-bs-target="#modal-turnover-thresholds"
                    (click)="GetTurnoverThresholds()">(mins) &#8804; &nbsp; <img src="./assets/info.png" style="width:16px;height:16px;" /></span>

              <span style="color: #811b01;" *ngIf="f.Metric.toLowerCase()=='turnovertime' && filters.surgeService!='All'">(mins) &#8804; &nbsp; {{turnoverThreshold}}</span>
              <span class="failimg" data-bs-toggle="modal" data-bs-target="#modal-data-revision" (click)="GetDataRevision(f.Metric)">
                <img src="../../assets/failed.png" style="width:20px;height:20px;" *ngIf="metricOrder[f.Metric]?.DataRevision">
              </span>
            </h4>

          </div>
          <div class="card-body p-10 click-body" style="text-align: center!important;">
            <ng-container *ngIf="f.TotalCases>0">
              <!--<div class="row" *ngIf="f.Metric.toLowerCase() =='utilization' && f.IsInsights && f.IsInsights=='Y'">
        <div class="col" style="text-align:right;">
          <img src="./assets/insights.png" style="width:30px;height:30px;" (click)="GetUtilInights(f.MetricName)" data-bs-toggle="modal" data-bs-target="#modal-recomendations" />
        </div>
      </div>-->
              <div class="row" *ngIf="f.IsInsights && f.IsInsights=='Y'">
                <div class="col" style="text-align:right;">
                  <img src="./assets/insights.png" style="width:30px;height:30px;" (click)="GetInsights(f.Metric,f.MetricName,f.InsightsHeader)" data-bs-toggle="modal" data-bs-target="#modal-recomendations" />
                </div>
              </div>
              <app-gauge [container]="'#gauge' + i" [targetValue]="-1" [targetText]="''" [tickValue]="f.FailedPercentage"
                         (eventOut)="GetGauge($event)">
                <div id="{{'gauge'+i}}" class="orbisgauge" data-bs-toggle="modal" (click)="GetMetricBar(f.Metric,f.MetricName)"
                     data-bs-target="#modal-metric-bar-detail"></div>
              </app-gauge>
            </ng-container>
            <div *ngIf="f.TotalCases<=0" style="text-align:center;">
              No Data Available
            </div>
          </div>
          <div class="card-footer click-footer"
               style="text-align: center!important;" *ngIf="f.TotalCases>0 && (f.Metric=='Utilization' || f.Metric=='StaticUtilization')">
            <diV *ngIf="cmm!=undefined && (f.Metric=='Utilization' || f.Metric=='StaticUtilization')">
              <table class="table card-table table-vcenter">
                <tbody *ngFor="let c of GetCMM(f.Metric)">

                  <tr>
                    <td class="orbisvaluename">{{c.MetricName}}</td>
                    <td class="orbisvaluedata" style="font-size:1em;font-weight:bold">{{c.Mean | number}}</td>
                  </tr>
                </tbody>
              </table>
            </diV>
          </div>

          <div class="card-footer click-footer" data-bs-toggle="modal" data-bs-target="#modal-metric-failcasedetail"
               style="text-align: center!important;" *ngIf="f.TotalCases>0 && f.Metric!='Utilization'  && f.Metric!='StaticUtilization'" (click)="GetFailedCase(f.Metric,f.MetricName)">

            <div class="countcontainer" *ngIf="f.Metric!='Utilization'  && f.Metric!='StaticUtilization'">
              <span class="failimg"><img src="../../assets/failed.png"></span>
              <span class="reportcount">{{f.FailedCases}}</span>
              <span class="denominator"> of {{f.TotalCases}} cases</span>
            </div>
            <div style="font-weight:600;" *ngIf="f.Cost!=null && f.Cost!=undefined">Hospital Cost Implications&nbsp;:&nbsp;<span style="color: #cc1c16">$ {{f.Cost  | number}}</span></div>
            <diV *ngIf="cmm!=undefined && f.Metric!='Utilization'  && f.Metric!='StaticUtilization'">
              <table class="table card-table table-vcenter" style="border-top: 1px solid lightgray;">
                <tbody *ngFor="let c of GetCMM(f.Metric)">
                  <tr>
                    <td class="orbisvaluename">Mean</td>
                    <td class="orbisvaluedata">{{c.Mean}}</td>
                    <td class="orbisvalueunit">Minutes</td>
                  </tr>
                  <tr>
                    <td class="orbisvaluename">Median</td>
                    <td class="orbisvaluedata">{{c.Median}} </td>
                    <td class="orbisvalueunit">Minutes</td>
                  </tr>
                </tbody>
              </table>
            </diV>
          </div>
        </div>
      </div>
      <!--<div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{GetOrder('ScheduledVsActual')}}">-->
      <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{'id_'+metricOrder['ScheduledVsActual']?.MetricOrder}}">
        <div class="card">
          <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric"
               (click)="GetMetricDescription('ScheduledVsActual', 'Scheduled vs Actual')">
            <h4 class="card-title" style="cursor:pointer;">Scheduled vs Actual</h4>

          </div>
          <div class="card-body p-0 click-body" style=" text-align: center !important;">
            <div *ngIf="barData!=undefined && barData.length>0">
              <app-percentagebar [container]="'#percentageBar'" [barData]="barData" [margin]="margin">
                <div id="percentageBar"></div>
              </app-percentagebar>
            </div>
            <div *ngIf="barData==undefined || barData?.length==0" style="text-align:center;">
              No Data Available
            </div>
          </div>
          <div class="card-footer click-footer" style="text-align: center!important;">
            <diV *ngIf="cmm!=undefined">
              <table class="table card-table table-vcenter">
                <tbody *ngFor="let c of GetCMM('ScheduledVsActual')">
                  <tr>
                    <td class="orbisvaluename">{{c.MetricName}}</td>
                    <td class="orbisvaluedata"><span *ngIf="c.Mean>0">+</span>{{c.Mean}}</td>
                    <td class="orbisvalueunit">Minutes</td>
                  </tr>
                </tbody>
              </table>
            </diV>
          </div>
        </div>
      </div>

      <!--<div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{GetOrder('CaseTime')}}">-->
      <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{'id_'+metricOrder['CaseTime']?.MetricOrder}}">
        <div class="card">
          <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric"
               (click)="GetMetricDescription('CaseTime','Case Duration')">
            <h4 class="card-title" style="cursor:pointer;"> Case Duration</h4>

          </div>
          <div class="card-body p-10 click-body" style="text-align: center!important;height: 180px;" *ngIf="cmm!=undefined">
            <table class="table card-table table-vcenter">
              <tbody *ngFor="let c of GetCMM('Casetime')">
                <tr>
                  <td class="orbisvaluename">Count</td>
                  <td class="orbisvaluedata">{{c.Count}}</td>
                  <td class="orbisvalueunit">Cases</td>
                </tr>
                <tr>
                  <td class="orbisvaluename">Mean</td>
                  <td class="orbisvaluedata">{{c.Mean}}</td>
                  <td class="orbisvalueunit">Minutes</td>
                </tr>
                <tr>
                  <td class="orbisvaluename">Median</td>
                  <td class="orbisvaluedata">{{c.Median}} </td>
                  <td class="orbisvalueunit">Minutes</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--<div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{GetOrder('CaseCancellationRate')}}" *ngIf="filters?.location.toLowerCase()!='nora'">-->
      <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{'id_'+metricOrder['CaseCancellationRate']?.MetricOrder}}" *ngIf="filters?.location.toLowerCase()!='nora'">
        <div class="card">
          <div class="card-header metrictitle">
            <h4 class="card-title" style="cursor:pointer;">
              <span data-bs-toggle="modal" data-bs-target="#modal-metric"
                    (click)="GetMetricDescription('CaseCancellationRate',metricOrder['CaseCancellationRate'].MetricName)">{{metricOrder['CaseCancellationRate'].MetricName}}</span>&nbsp;
              <span class="failimg" data-bs-toggle="modal" data-bs-target="#modal-data-revision" (click)="GetDataRevision('CaseCancellationRate')"><img src="../../assets/failed.png" style="width:20px;height:20px;" *ngIf="metricOrder['CaseCancellationRate']?.DataRevision"></span>
            </h4>

          </div>
          <div class="card-body p-10 click-body" style="text-align: center!important;height: auto">
            <div *ngIf="caseCancellationPie!=undefined && caseCancellationPie.length>0">
              <!--<div class="row">
            <div class="col" style="text-align:right;">
              <img src="./assets/insights.png" style="width:30px;height:30px;" (click)="GetCaseCancelInights('Case Cancellation Rate')" data-bs-toggle="modal" data-bs-target="#modal-recomendations" />
            </div>
          </div>-->
              <app-pie [container]="'#piechartCaseCancel'" [pieData]="caseCancellationPie" [margin]="margin" [config]="config" [scale]="casecancelScale">
                <div id="piechartCaseCancel" data-bs-toggle="modal" data-bs-target="#modal-case-cancelation-zoom"></div>
              </app-pie>
            </div>
            <div *ngIf="caseCancellationPie==undefined || caseCancellationPie.length==0" style="text-align:center;">
              No Data Available
            </div>
          </div>

          <div class="card-footer click-footer" data-bs-toggle="modal" data-bs-target="#modal-case-cancelation-table" (click)="GetCaseCancellation('sameday',metricOrder['CaseCancellationRate'].MetricName)"
               style="text-align: center!important;">
            <diV *ngIf="caseCancellation!=undefined && caseCancellation.length>0">
              <div class="countcontainer">
                <span class="reportcount">Cancellations : {{caseCancellation[0].Cancellation}} %</span>
              </div>
              <div class="countcontainer">
                <span class="reportcount">{{caseCancellation[0].Failed}}</span>
                <span class="denominator"> of {{caseCancellation[0].Total}} cases</span>
              </div>
              <div class="countcontainer">
                <div style="font-weight:600;" *ngIf="caseCancellation[0]?.Cost!=null && caseCancellation[0]?.Cost!=undefined">Hospital Cost Implications&nbsp;:&nbsp;<span style="color: #cc1c16">$ {{caseCancellation[0]?.Cost  | number}}</span></div>
              </div>
            </diV>
          </div>
        </div>
      </div>

      <!--<div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{GetOrder('CaseCancellationRate5Day')}}" *ngIf="GetOrder('CaseCancellationRate5Day') && filters?.location.toLowerCase()!='nora'">-->
      <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{'id_'+metricOrder['CaseCancellationRate5Day']?.MetricOrder}}" *ngIf="metricOrder['CaseCancellationRate5Day'] && filters?.location.toLowerCase()!='nora'">
        <div class="card">
          <div class="card-header metrictitle">
            <h4 class="card-title" style="cursor:pointer;">
              <span data-bs-toggle="modal" data-bs-target="#modal-metric"
                    (click)="GetMetricDescription('CaseCancellationRate5Day',metricOrder['CaseCancellationRate5Day'].MetricName)">{{metricOrder['CaseCancellationRate5Day'].MetricName}}</span> &nbsp;
              <span class="failimg" data-bs-toggle="modal" data-bs-target="#modal-data-revision" (click)="GetDataRevision('CaseCancellationRate5Day')">
                <img src="../../assets/failed.png" style="width:20px;height:20px;" *ngIf="metricOrder['CaseCancellationRate5Day']?.DataRevision">
              </span>
            </h4>

          </div>
          <div class="card-body p-10 click-body" style="text-align: center!important;height: auto">
            <div *ngIf="caseCancellationPie5Day!=undefined && caseCancellationPie5Day.length>0">
              <!--<div class="row">
            <div class="col" style="text-align:right;">
              <img src="./assets/insights.png" style="width:30px;height:30px;" (click)="GetCaseCancelInights('Case Cancellation Rate')" data-bs-toggle="modal" data-bs-target="#modal-recomendations" />
            </div>
          </div>-->
              <app-pie [container]="'#piechartCaseCancel5Day'" [pieData]="caseCancellationPie5Day" [margin]="margin" [config]="config" [scale]="casecancelScale">
                <div id="piechartCaseCancel5Day" data-bs-toggle="modal" data-bs-target="#modal-case-cancelation5day-zoom"></div>
              </app-pie>
            </div>
            <div *ngIf="caseCancellationPie5Day==undefined || caseCancellationPie5Day.length==0" style="text-align:center;">
              No Data Available
            </div>
          </div>

          <div class="card-footer click-footer" data-bs-toggle="modal" data-bs-target="#modal-case-cancelation-table" (click)="GetCaseCancellation('5day',metricOrder['CaseCancellationRate5Day'].MetricName)"
               style="text-align: center!important;">
            <diV *ngIf="caseCancellation5Day!=undefined && caseCancellation5Day.length>0">
              <div class="countcontainer">
                <span class="reportcount">Cancellations : {{caseCancellation5Day[0].Cancellation}} %</span>
              </div>
              <div class="countcontainer">
                <span class="reportcount">{{caseCancellation5Day[0].Failed}}</span>
                <span class="denominator"> of {{caseCancellation5Day[0].Total}} cases</span>
              </div>
              <div class="countcontainer">
                <div style="font-weight:600;" *ngIf="caseCancellation5Day[0]?.Cost!=null && caseCancellation5Day[0]?.Cost!=undefined">Hospital Cost Implications&nbsp;:&nbsp;<span style="color: #cc1c16">$ {{caseCancellation5Day[0]?.Cost  | number}}</span></div>
              </div>
            </diV>
          </div>
        </div>
      </div>


      <div class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 hide" id="{{'id_'+metricOrder['CaseCancellationRate3Day']?.MetricOrder}}" *ngIf="metricOrder['CaseCancellationRate3Day'] && filters?.location.toLowerCase()!='nora'">
        <div class="card">
          <div class="card-header metrictitle">
            <h4 class="card-title" style="cursor:pointer;">
              <span data-bs-toggle="modal" data-bs-target="#modal-metric"
                    (click)="GetMetricDescription('CaseCancellationRate3Day',metricOrder['CaseCancellationRate3Day'].MetricName)">{{metricOrder['CaseCancellationRate3Day'].MetricName}}</span> &nbsp;
              <span class="failimg" data-bs-toggle="modal" data-bs-target="#modal-data-revision" (click)="GetDataRevision('CaseCancellationRate3Day')">
                <img src="../../assets/failed.png" style="width:20px;height:20px;" *ngIf="metricOrder['CaseCancellationRate3Day']?.DataRevision">
              </span>
            </h4>

          </div>
          <div class="card-body p-10 click-body" style="text-align: center!important;height: auto">
            <div *ngIf="caseCancellationPie3Day!=undefined && caseCancellationPie3Day.length>0">
              <!--<div class="row">
            <div class="col" style="text-align:right;">
              <img src="./assets/insights.png" style="width:30px;height:30px;" (click)="GetCaseCancelInights('Case Cancellation Rate')" data-bs-toggle="modal" data-bs-target="#modal-recomendations" />
            </div>
          </div>-->
              <app-pie [container]="'#piechartCaseCancel3Day'" [pieData]="caseCancellationPie3Day" [margin]="margin" [config]="config" [scale]="casecancelScale">
                <div id="piechartCaseCancel3Day" data-bs-toggle="modal" data-bs-target="#modal-case-cancelation3day-zoom"></div>
              </app-pie>
            </div>
            <div *ngIf="caseCancellationPie3Day==undefined || caseCancellationPie3Day.length==0" style="text-align:center;">
              No Data Available
            </div>
          </div>

          <div class="card-footer click-footer" data-bs-toggle="modal" data-bs-target="#modal-case-cancelation-table" (click)="GetCaseCancellation('3day',metricOrder['CaseCancellationRate3Day'].MetricName)"
               style="text-align: center!important;">
            <diV *ngIf="caseCancellation3Day!=undefined && caseCancellation3Day.length>0">
              <div class="countcontainer">
                <span class="reportcount">Cancellations : {{caseCancellation3Day[0].Cancellation}} %</span>
              </div>
              <div class="countcontainer">
                <span class="reportcount">{{caseCancellation3Day[0].Failed}}</span>
                <span class="denominator"> of {{caseCancellation3Day[0].Total}} cases</span>
              </div>
              <div class="countcontainer">
                <div style="font-weight:600;" *ngIf="caseCancellation3Day[0]?.Cost!=null && caseCancellation3Day[0]?.Cost!=undefined">Hospital Cost Implications&nbsp;:&nbsp;<span style="color: #cc1c16">$ {{caseCancellation3Day[0]?.Cost  | number}}</span></div>
              </div>
            </diV>
          </div>
        </div>
      </div>



      <!--<div class="col-xxl-4 col-xl-4 col-md-6 col-sm-8 hide" id="{{GetOrder('UtilizationByDay')}}" *ngIf="filters?.location.toLowerCase()=='nora'">
        <div class="card">
          <div class="card-header metrictitle" data-bs-toggle="modal" data-bs-target="#modal-metric"
               (click)="GetMetricDescription('UtilizationByDay','Utilization By Day')">
            <h4 class="card-title" style="cursor:pointer;">Utilization By Day</h4>

          </div>
          <div class="card-body p-10 click-body" style="height: auto" id="barDiv">
            <div *ngIf="utilDayData!=undefined && utilDayData.length>0">
              <app-simple-bar [container]="'#dayBar'" [barData]="utilDayData" [configuration]="configDayBar">
                <div id="dayBar" [ngStyle]="{'width': barWidth}"></div>
              </app-simple-bar>
            </div>
            <div *ngIf="utilDayData==undefined || utilDayData?.length==0" style="text-align:center;">
              No Data Available
            </div>
          </div>


        </div>
      </div>-->
    </div>
  </div>
</div>
<div class="modal modal-blur fade" id="modal-metric" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Definition of {{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div [innerHtml]="metric"></div>
      </div>
    </div>
  </div>
</div>
<div class="modal modal-blur fade" id="modal-metric-allcases" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title"> Case Details  </h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;" *ngIf="Allcases!=undefined">
        <span *ngIf="Allcases.length==1000">only 1000 cases shown</span>
        <div class="card" style="height: 80vh !important">

          <div class="card-body-scrollable card-body-scrollable-shadow">

            <table class="casemixgraphic fixTableHead" *ngIf="Allcases!=undefined">
              <thead>
                <tr>
                  <th *ngFor="let c of metricfields" (click)="Sort(c.Field)" style="cursor:pointer;">
                    {{c.FieldName}}
                    <span *ngIf="sortObjects[c.Field]!=undefined && sortObjects[c.Field]=='ASC'">
                      <img src="../../assets/sortasc.png" />
                    </span>
                    <span *ngIf="sortObjects[c.Field]!=undefined && sortObjects[c.Field]=='DESC'">
                      <img src="../../assets/sortdesc.png" />
                    </span>
                  </th>
                </tr>
              </thead>
              <caption *ngIf="Allcases!=undefined && Allcases.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
              <tbody>

                <tr *ngFor="let c of Allcases">
                  <ng-container *ngFor="let d of metricfields">

                    <td *ngIf="d.Field!='CaseDate' && d.Field!='InRoom' && d.Field!='LeaveOR'">
                      {{c[d.Field] }}
                    </td>
                    <td *ngIf="d.Field=='CaseDate' ">{{c[d.Field] | date:'MM/dd/yyyy'  }}</td>
                    <td *ngIf="d.Field=='InRoom' || d.Field=='LeaveOR'" style="min-width:150px">{{c[d.Field] | date:'MM/dd/yyyy h:mm a'  }}</td>
                  </ng-container>
                </tr>
              </tbody>

            </table>
            <div class="row" *ngIf="Allcases!=undefined && Allcases.length > 0">
              <div class="col-12">
                <app-pagination [RecordsPerPage]="100" [TotalRecords]="totalrecords" (pageEvent)="PageEvent($event)" [CurrentPage]="page.currentPage">
                </app-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal modal-blur fade" id="modal-metric-bar-detail" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered tmodal-dialog" role="document" style="width:65%!important">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">{{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="height: 80vh !important; text-align: center !important" *ngIf="fullbarData!=undefined">
        <app-bar [container]="'#bar11'" [barData]="fullbarData" [configuration]="configuration">
          <div id="bar11" style="width: 80%; height: 300px; margin:0 auto"></div>
        </app-bar>
      </div>
    </div>
  </div>
</div>
<div class="modal modal-blur fade" id="modal-metric-failcasedetail" tabindex="-1" style="display: none; "
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered tmodal-dialog" role="document" style="min-width:100%!important;">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Cases Failing {{metricName}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="failedcases!=undefined" style="height: 82vh; overflow-y: auto; padding-top: 2px !important">
        <app-failed-cases [metric]="metric"
                          [metricfields]="metricfields"
                          [ORBISClosedCaseFields]="ORBISClosedCaseFields"
                          [ORBISClosedCase]="ORBISClosedCase"
                          [ORBISFailDetails]="ORBISFailDetails"
                          [ORBISClosedCaseMetric]="ORBISClosedCaseMetric"
                          [metricCode]="metricCode"
                          [failedcases]="failedcases"></app-failed-cases>

      </div>

    </div>
  </div>
</div>

<div class="modal modal-blur fade" id="modal-case-cancelation5day-zoom" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="width: 800px; height: 450px">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title" *ngIf="metricOrder && metricOrder['CaseCancellationRate5Day']">{{metricOrder['CaseCancellationRate5Day'].MetricName}}</h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">

        <div class="card" style="height: 80vh !important; text-align: center;">

          <div class="card-body-scrollable card-body-scrollable-shadow">

            <div *ngIf="caseCancellationPie5Day!=undefined && caseCancellationPie5Day.length>0">
              <app-pie [container]="'#piechartCaseCancel5Dayzoom'" [pieData]="caseCancellationPie5Day" [margin]="margin" [config]="config" [scale]="casecancelScale">
                <div id="piechartCaseCancel5Dayzoom" style="width:700px;height:450px"></div>
              </app-pie>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal modal-blur fade" id="modal-case-cancelation3day-zoom" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="width: 800px; height: 450px">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title" *ngIf="metricOrder && metricOrder['CaseCancellationRate3Day']">{{metricOrder['CaseCancellationRate3Day'].MetricName}}</h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">

        <div class="card" style="height: 80vh !important; text-align: center;">

          <div class="card-body-scrollable card-body-scrollable-shadow">

            <div *ngIf="caseCancellationPie3Day!=undefined && caseCancellationPie3Day.length>0">
              <app-pie [container]="'#piechartCaseCancel3Dayzoom'" [pieData]="caseCancellationPie3Day" [margin]="margin" [config]="config" [scale]="casecancelScale">
                <div id="piechartCaseCancel3Dayzoom" style="width:700px;height:450px"></div>
              </app-pie>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal modal-blur fade" id="modal-case-cancelation-zoom" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="width: 800px; height: 450px">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title" *ngIf="metricOrder && metricOrder['CaseCancellationRate']">{{metricOrder['CaseCancellationRate'].MetricName}} </h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">

        <div class="card" style="height: 80vh !important; text-align: center;">

          <div class="card-body-scrollable card-body-scrollable-shadow">

            <div *ngIf="caseCancellationPie!=undefined && caseCancellationPie.length>0">
              <app-pie [container]="'#piechartCaseCancelzoom'" [pieData]="caseCancellationPie" [margin]="margin" [config]="config" [scale]="casecancelScale">
                <div id="piechartCaseCancelzoom" style="width:700px;height:450px"></div>
              </app-pie>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal modal-blur fade" id="modal-case-cancelation-table" tabindex="-1" style="display: none; "
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="min-width:95vw !important">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">
          <!--<span *ngIf="is5Day">5 Days Cancellations</span><span *ngIf="!is5Day">Case Cancellation Rate Details</span>-->
          {{metricName}}
        </h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="CloseCaseCancel()"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <!--<div style="padding:10px; text-align:right;font-weight:bold;">
          <span style=" background-color: #9ef79e; border: lightgray solid 1px; ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;>72 Hours&nbsp;
          <span style="background-color: #fbfb84; border: lightgray solid 1px; ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;24-72 Hours&nbsp;
          <span style="background-color: #fba7a7; border: lightgray solid 1px; ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;< 24 Hours&nbsp;
        </div>-->
        <div class="card" style="height: 80vh !important; text-align: center;">

          <div class="card-body-scrollable card-body-scrollable-shadow">


            <table class="casemixgraphic fixTableHead">

              <thead>
                <tr>
                  <th>Case Number</th>
                  <th>Cancel Date</th>
                  <th>Date of Service</th>
                  <th>Cancellation category</th>
                  <th>Cancellation phase</th>
                  <th style="min-width:180px !important">Cancellation Reason</th>
                  <th>Surgical Service</th>
                  <th>Cost</th>
                  <th style="min-width:220px !important">Cancel Comment</th>
                </tr>
                <tr>
                  <td>
                    <input type="text" class="form-control" [(ngModel)]="caseNumber">
                  </td>
                  <td>
                    <input type="text" class="form-control" bsDatepicker [(ngModel)]="cancelDate">
                  </td>
                  <td>
                    <input type="text" class="form-control" bsDatepicker [(ngModel)]="dos">
                  </td>
                  <td>
                    <select class="form-select" [(ngModel)]="selectedBucket">
                      <option *ngFor='let b of bucket' [value]="b">{{b}}</option>
                    </select>
                  </td>

                  <td>
                    <select class="form-select" [(ngModel)]="selectedCategory">
                      <option *ngFor='let b of category' [value]="b">{{b}}</option>
                    </select>
                  </td>
                  <!--<td>
                    <input type="text" class="form-control" [(ngModel)]="cancelReason">
                  </td>
                  <td>
                    <input type="text" class="form-control" [(ngModel)]="cancelComment">
                  </td>-->
                  <td>
                    <input type="text" class="form-control" [(ngModel)]="failedDetails">
                  </td>
                  <td>
                    <select class="form-select" [(ngModel)]="selectedService">
                      <option *ngFor='let b of service' [value]="b">{{b}}</option>
                    </select>
                  </td>

                  <td>
                    <div class="row">
                      <div class="col">
                        <select class="form-select" [(ngModel)]="selectedCondition" style="width:50px">
                          <option [value]="'='">=</option>
                          <option [value]="'<'"><</option>
                          <option [value]="'>'">></option>
                        </select>
                      </div>
                      <div class="col"><input type="number" class="form-control" [(ngModel)]="cost" style="width:100px"></div>
                    </div>
                  </td>

                </tr>
                <tr>
                  <td colspan="8" style="text-align:left;"><button (click)="SearchCaseCancel()">Search</button></td>
                </tr>
              </thead>

              <caption *ngIf="caseCancellationDetails!=undefined && caseCancellationDetails.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
              <tbody *ngIf="caseCancellationDetails!=undefined && caseCancellationDetails.length>0">

                <tr *ngFor="let c of caseCancellationDetails">
                  <!--[ngClass]="c.Color"-->
                  <td>{{c.CaseNumber}}</td>
                  <td>{{c.CancelDate  | date:'MM/dd/yyyy'}}</td>
                  <td>{{c.DateOfService  | date:'MM/dd/yyyy'}}</td>
                  <td>{{c.Fail_Details_Bucket}}</td>
                  <td>{{c.Fail_Category}}</td>
                  <td>{{c.Fail_Details}}</td>
                  <!--<td>{{c.CancelReason}}</td>
                  <td>{{c.CancelComment}}</td>-->
                  <td>{{c.Service}}</td>
                  <td>${{c.Cost}}</td>
                  <td>{{c.CancelComment}}</td>
                </tr>
              </tbody>
            </table>


          </div>
          <div class="card-footer">
            <div class="row" *ngIf="caseCancellationDetails!=undefined && caseCancellationDetails.length > 0">
              <div class="col-12">
                <app-pagination [RecordsPerPage]="100" [TotalRecords]="totalrecords" (pageEvent)="PageEventCaseCancel($event)" [CurrentPage]="page.currentPage">
                </app-pagination>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>


<div class="modal modal-blur fade" id="modal-turnover-thresholds" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="width: 500px;min-height:50px;overflow-y:auto">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title">Turnover Thresholds</h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <div class="card" style="text-align: center;">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div>
              <table class="casemixgraphic fixTableHead" *ngIf="turnOver!=undefined">
                <thead>
                  <tr>
                    <th>Procedure Type</th>
                    <th>Turnover Threshold (mins)</th>
                    <th>Facility</th>
                  </tr>
                </thead>
                <caption *ngIf="turnOver!=undefined && turnOver.length == 0" style="text-align:center;font-weight:600">No Records to Display</caption>
                <tbody>
                  <tr *ngFor="let c of turnOver">
                    <td>{{c.SurgeService}}</td>
                    <td>{{c.TurnoverThreshold  | number}}</td>
                    <td>{{c.Facility}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal modal-blur fade" id="modal-recomendations" tabindex="-1" style="display: none;"
     aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  tmodal-dialog" role="document" style="min-width: 1000px;min-height:50px;overflow-y:auto">
    <div class="modal-content">
      <div class="modal-header metrictitle">
        <h5 class="modal-title" *ngIf="filters" style="font-size: 15px;">
          Insights for: <span style="color:blue!important">{{metricName}}</span> Period: <span style="color:blue!important">{{filters.fromDate}}</span> to <span style="color:blue!important">{{filters.toDate}}</span> Facility: <span style="color:blue!important">{{filters.facility}}</span> Location: <span style="color:blue!important">{{filters.location}}</span> Service: <span style="color:blue!important">{{filters.surgeService}}</span>
        </h5>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <!--<div style="text-align:center;font-size:18px;padding-top:7px;">
        <div [innerHtml]="insightsHeader"></div>
      </div>-->
      <div class="modal-body p-0" style="width:100%;text-align:center;">
        <div class="card" style="text-align: center;">
          <div class="card-body-scrollable card-body-scrollable-shadow">
            <div *ngIf="insightsData" style="height: 500px; overflow-y: auto;">
              <app-dynamic-recomendations [recomendations]="insightsData" [recomendationString]="undefined" [recomendationFilter]="insightsFilter"></app-dynamic-recomendations>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal modal-blur fade" id="modal-data-revision" tabindex="-1" style="display: none;" aria-modal="true"
     role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header metrictitle">

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div [innerHtml]="metricDataRevision"></div>
      </div>
    </div>
  </div>
</div>


