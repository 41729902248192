
<div class="sticky-top">

</div>


<app-new-filters [reportHeader]="'Unutilized Report'" (onfilter)="OnFilter($event)">

</app-new-filters>
<div class="page-body blur">

  <div class="container-xl container-sm container-md">
    <!--Widget first row-->
    <div class="row">
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Shift days</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.ShiftDays | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">%Unutilized</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.UnutilizedPercent | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Prime %Unutilized</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.PrimeUnutilizedPercent | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">NonPrime %Unutilized</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.NonPrimeUnutilizedPercent | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">AvgPrime Unutilized Mins</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.AvgPrimeUnutilizedMins | number}}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">AvgNonPrime Unutilized Mins</h4>
          </div>
          <div class="text row1-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.AvgNonPrimeUnutilizedMins | number}}</div>
        </div>
      </div>
    </div>


    <!--Widget second row-->
    <div class="row">
      <div class="col-4">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">ShiftDaysUnuntilized>60Mins</h4>
          </div>
          <div class="text row2-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.ShiftDaysUnuntilizedGTSixtyMins | number}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">ShiftDaysUnuntilized>90Mins</h4>
          </div>
          <div class="text row2-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.ShiftDaysUnuntilizedGTNintytyMins | number}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="widget">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">ShiftDaysUnuntilized>120Mins</h4>
          </div>
          <div class="text row2-text" *ngIf="widgets && widgets.length > 0">{{widgets[0]?.ShiftDaysUnuntilizedGTOneTwentyMins | number}}</div>
        </div>
      </div>
    </div>


    <div class="row">
      <!--Line chart-->
      <div class="col-6">
        <div class="chart-container"  style="height:300px;">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;"> Avg unutilized mins Per Providers And Day by Week</h4>
          </div>


          <div style="height: 265px;">

            <div style="padding-left:10px;padding-top:10px">
              <img src="./assets/quantityp.svg" height="10" width="10"> Prime <img src="./assets/forecastp.svg" height="10" width="10"> Non Prime
            </div>
            <div *ngIf="_lineConfig">
              <d3-line [config]="_lineConfig">
                <div id="linecontainer" style="width:100%;height:100%"></div>
              </d3-line>
            </div>
          </div>


        </div>
      </div>

      <!--Bar chart -->
      <div class="col-6">
        <div class="chart-container" style="height:300px;">
          <div class="header card-header">
            <h4 class="card-title" style="cursor:pointer;">Avg Unutilized Mins Per Day by Provider</h4>
          </div>
          <div style="padding-left:10px;padding-top:10px">
            <span style="height: 70px; width: 70px; background-color: #269ffb; border-radius: 10px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> &nbsp;Prime&nbsp;
            <span style="height:70px;width:70px;background-color:#26e7a5;border-radius:10px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> &nbsp;Non Prime
          </div>
          <div style="height: 235px; overflow-y: auto ">
            <div *ngIf="_barConfig">
              <d3-h-bar-stacked [config]="_barConfig" (barclick)="BarBulkSelected($event)" (deselectbar)="DeselectBar()">
                <div id="barcontainer" style="width:95%;"></div>
              </d3-h-bar-stacked>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="card" style="max-height: calc(100vh - 260px);">
        <div class="card-body-scrollable card-body-scrollable-shadow">


          <table class="casemixgraphic fixTableHead ">


            <thead>
              <tr>
                <th>Provider Name</th>
                <th>No of cases</th>
                <th>No of days worked</th>
                <th>Avg Case count per day</th>
                <th>Total Shift Mins</th>
                <th>Total Unutilized Mins</th>
                <th>% of shift unutilized</th>
                <th>Total Prime Shift Mins</th>
                <th>Total Prime Unutilized Mins</th>
                <th>Total NonPrime Shift Mins</th>
                <th>Total NonPrime Unutilized Mins</th>
              </tr>
            </thead>
            <caption *ngIf="tabularData!=undefined && tabularData.length == 0" style="text-align:center;font-weight:600"> No Records to Display</caption>
            <tbody>
              <tr *ngFor="let c of tabularData">
                <td>{{c.Providername}}</td>
                <td>{{c.NoofCases}}</td>
                <td>{{c.NoofDaysWorked}}</td>
                <td>{{c.AvgCaseCountPerDay}}</td>
                <td>{{c.TotalShiftMins}}</td>
                <td>{{c.TotalUnutilizedMins}}</td>
                <td>{{c.UnutilizedPercent  | number : '1.2-2'}}</td>
                <td>{{c.TotalPrimeShiftMins}}</td>
                <td>{{c.TotalPrimeUnutilizedMins}}</td>
                <td>{{c.TotalNonPrimeShiftMins}}</td>
                <td>{{c.TotalNonPrimeUnutilizedMins}}</td>
              </tr>
            </tbody>

          </table>


        </div>
      </div>
    </div>
  </div>
</div>
