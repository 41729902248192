import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeriopFiltersComponent } from './periop-filters/periop-filters.component';
  
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { D3componentsModule } from '../d3components/d3components.module';
import { CommonPageComponent } from './common-page/common-page.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgxCaptureModule } from 'ngx-capture';
import { SharedModule } from '../shared-module/shared-module.module';



@NgModule({
  declarations: [
    PeriopFiltersComponent,
    CommonPageComponent
  ],
  exports: [
    CommonPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    NgSelectModule,
    FormsModule,
    ToastrModule.forRoot(),
    NgxCaptureModule,
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    D3componentsModule,
    SharedModule
  ]
})
export class PeriopOutcomeMetricsModule { }
