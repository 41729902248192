import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { PieData, PieModel } from '../models/piemodel';
import { PieChart } from './Pie';

@Component({
  selector: 'd3-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent {

  @Input() config: PieModel | undefined;
  @Output() pieclick: EventEmitter<PieData> = new EventEmitter();
  @Output() deselectpie: EventEmitter<any> = new EventEmitter();

  private _pie: PieChart | undefined;

  ngAfterViewInit(): void {
    if (this.config) {
      this._pie = new PieChart(this.config);
      if (this.pieclick.observers.length > 0) {
        this._pie.pieclick = (d: PieData) => {
          
          this.pieclick.emit(d);
        }
      }

      if (this.deselectpie.observers.length > 0) {
        this._pie.deselectpie = () => {
          this.deselectpie.emit();
        }
      }

      let d = document.getElementById(this.config.container.replace("#", ""));
      if (d && this._pie) {
       // alert(d.parentNode.parentElement.clientHeight)
        this.config.width = d.offsetWidth;//  - this.config.margin_left - this.config.margin_right;
        this.config.height = d.offsetHeight;// - this.config.margin_top - this.config.margin_bottom;
        this._pie.Render();
      }
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this.config) {

      let d = document.getElementById(this.config.container.replace("#", ""));
      if (d && this._pie) {
        this.config.width = d.offsetWidth;// - this.config.margin_left - this.config.margin_right;
        this.config.height = d.offsetHeight;// - this.config.margin_top - this.config.margin_bottom;
        this._pie.Render();
      }
    }
  }

}

