<div class="sticky-top">
  <app-filters (onfilter)="OnFilter($event)" [reportName]="'perivision'"></app-filters>
</div>
<div class="container-xl">
  <!-- Page title -->
  <div class="page-header d-print-none">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle">

        </div>
        <h2 class="page-title">
          Perivision Schedule for&nbsp;
          <span *ngIf="filters!=undefined && filters.facility!='All'">{{filters?.facility}}</span>&nbsp;
          {{filters?.facility=="All"?'All Facilities':''}}&nbsp;
          <span *ngIf="filters!=undefined && filters.location!='All'">{{filters?.location}}</span>&nbsp;
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-12 col-md-auto ms-auto d-print-none">
        <div class="btn-list">

        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">
  <div class="container-xl container-sm container-md">
    <p-tabs (onSelect)="OnSelectTab($event)" *ngIf="PerivisionSchedule!=undefined && PerivisionSchedule.length > 0">
      <p-tab [tabTitle]="'OR Timing'" [active]="reportview=='ORTiming'" [id]="'ORTiming'" *ngIf="PerivisionSchedule!=undefined && PerivisionSchedule.length > 0">

        <div class="row">
          <div class="col-xl-4 col-md-4 col-sm-12" *ngIf="PerivisionCounts!=undefined && PerivisionCounts.length > 0">
            <!--<h2>Predicted Schedule Summary</h2>-->
            <table class="casemixgraphic">
              <tr>
                <td>
                  <div class="perivisioncount">{{PerivisionCounts[0].CasesScheduled}}</div>
                  <div>Cases Scheduled</div>
                </td>
                <td>
                  <div class="perivisioncount">{{PerivisionCounts[0].ORsUsed}}</div>
                  <div>ORs Used</div>
                </td>
                <td>
                  <div class="perivisioncount">{{PerivisionCounts[0].LateRooms}}</div>
                  <div>Late Rooms</div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="perivisionlegend col-xl-8 col-md-12 col-sm-12">
            <table class="perivisionlegendtext">
              <tr>
                <td style="width: 150px; padding: 4px 10px; background-color:#aad490">&lt;20 min delay</td>
                <td style="width: 150px; padding: 4px 10px; background-color: #eded8c ">&lt;20-40 min delay</td>
                <td style="width: 150px; padding: 4px 10px; background-color: #db9651 ">&lt;41-60 min delay</td>
                <td style="width: 150px; padding: 4px 10px; background-color: #d67263 ">&gt;60 min delay</td>
                <td style="width: 150px; padding: 4px 10px; background: repeating-linear-gradient(-45deg, #b6b7b8, #b6b7b8 2px, white 2px, white 10px); ">&gt;2 hr gap</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-12" style="overflow-x: scroll; height: 700px; overflow-y: scroll;">
            <app-perivision-schedule [container]="'#perivision'" [scheduledata]="PerivisionSchedule" [gapdata]="PerivisionGap">
              <div id="perivision" style="margin-top: 20px;"></div>
            </app-perivision-schedule>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-8 col-md-8 col-sm-12" *ngIf="PerivisionLateCases!=undefined && PerivisionLateCases.length > 0">
            <h2 class="perivisiontitle">Estimated Overtime Case Counts</h2>
            <table class="perivision">
              <tr>
                <th>Facility</th>
                <th>Location</th>
                <th>Past 5pm</th>
                <th>Past 7pm</th>
                <th>Past 10pm</th>
              </tr>
              <tr *ngFor="let c of PerivisionLateCases">
                <td>{{c.Facility}}</td>
                <td>{{c.Location}}</td>
                <td class="overtimecell" (click)="ToggleOvertimeView(c.Facility + '_' + c.Location + '_17')">
                  {{c.Past5pm}}
                  <div id="{{c.Facility}}_{{c.Location}}_17" *ngIf="GetOvertimeCases(c.Facility, c.Location, 17)?.length>0">
                    <table class="overtime">
                      <tr>
                        <th>Room</th>
                        <th>Surgeon</th>
                        <th>Procedures</th>
                        <th>Service</th>
                        <th>Time In</th>
                        <th>Time Out</th>
                        <th>Delay Minutes</th>
                      </tr>
                      <tr *ngFor="let t of GetOvertimeCases(c.Facility, c.Location, 17)">
                        <td>{{t.Room}}</td>
                        <td>{{t.Surgeon}}</td>
                        <td>{{t.Procedures}}</td>
                        <td>{{t.Service}}</td>
                        <td>{{t.Predicted_Wheels_In | date:'HH:mm'}}</td>
                        <td>{{t.Predicted_Wheels_Out | date:'HH:mm'}}</td>
                        <td>{{t.DelayMinutes}}</td>
                      </tr>
                    </table>
                  </div>
                </td>
                <td class="overtimecell" (click)="ToggleOvertimeView(c.Facility + '_' + c.Location + '_19')">
                  {{c.Past7pm}}
                  <div id="{{c.Facility}}_{{c.Location}}_19" *ngIf="GetOvertimeCases(c.Facility, c.Location, 19)?.length>0">
                    <table class="overtime">
                      <tr>
                        <th>Room</th>
                        <th>Surgeon</th>
                        <th>Procedures</th>
                        <th>Service</th>
                        <th>Time In</th>
                        <th>Time Out</th>
                        <th>Delay Minutes</th>
                      </tr>
                      <tr *ngFor="let t of GetOvertimeCases(c.Facility, c.Location, 19)">
                        <td>{{t.Room}}</td>
                        <td>{{t.Surgeon}}</td>
                        <td>{{t.Procedures}}</td>
                        <td>{{t.Service}}</td>
                        <td>{{t.Predicted_Wheels_In | date:'HH:mm'}}</td>
                        <td>{{t.Predicted_Wheels_Out | date:'HH:mm'}}</td>
                        <td>{{t.DelayMinutes}}</td>
                      </tr>
                    </table>
                  </div>
                </td>
                <td class="overtimecell" (click)="ToggleOvertimeView(c.Facility + '_' + c.Location + '_22')">
                  {{c.Past10pm}}
                  <div id="{{c.Facility}}_{{c.Location}}_22" *ngIf="GetOvertimeCases(c.Facility, c.Location, 22)?.length>0">
                    <table class="overtime">
                      <tr>
                        <th>Room</th>
                        <th>Surgeon</th>
                        <th>Procedures</th>
                        <th>Service</th>
                        <th>Time In</th>
                        <th>Time Out</th>
                        <th>Delay Minutes</th>
                      </tr>
                      <tr *ngFor="let t of GetOvertimeCases(c.Facility, c.Location, 22)">
                        <td>{{t.Room}}</td>
                        <td>{{t.Surgeon}}</td>
                        <td>{{t.Procedures}}</td>
                        <td>{{t.Service}}</td>
                        <td>{{t.Predicted_Wheels_In | date:'HH:mm'}}</td>
                        <td>{{t.Predicted_Wheels_Out | date:'HH:mm'}}</td>
                        <td>{{t.DelayMinutes}}</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-8 col-md-8 col-sm-12" *ngIf="PerivisionGap!=undefined && PerivisionGap.length > 0">
            <h2 class="perivisiontitle">Over 2 hour gaps</h2>
            <table class="perivision">
              <tr>
                <th>Facility</th>
                <th>Location</th>
                <th>Room</th>
                <th>Start</th>
                <th>End</th>
                <th>Gap Length</th>
              </tr>
              <tr *ngFor="let g of PerivisionGap">
                <td>{{g.Facility}}</td>
                <td>{{g.Location}}</td>
                <td>{{g.Room}}</td>
                <td>{{g.GapStart | date:'HH:mm'}}</td>
                <td>{{g.GapEnd | date:'HH:mm'}}</td>
                <td>{{g.GapLength}}</td>
              </tr>
            </table>
          </div>
        </div>
      </p-tab>

      <p-tab [tabTitle]="'Occupancy'" [active]="reportview=='Occupancy'" [id]="'Occupancy'" *ngIf="PerivisionOccupancy!=undefined">
        <div class="row">

          <div class="col-xl-4 col-md-4 col-sm-12" *ngIf="PerivisionPACU!=undefined && PerivisionPACU.length > 0">
            <h2>PACU Occupancy</h2>
            <table class="casemixgraphic">
              <tr>
                <td>
                  <div class="perivisioncount">{{PerivisionPACU[0].PACUMaxOccupancy}}</div>
                  <div>Max Occupancy</div>
                </td>
                <td>
                  <div class="perivisioncount">{{PerivisionPACU[0].TOD}}</div>
                  <div>Busiest Time</div>
                </td>
              </tr>
            </table>
          </div>

          <div class="col-12" style="overflow-x: scroll">
            <app-linegraph [container]="'#linegraph'" [lineData]="PerivisionOccupancy" [configuration]="GetConfig()">
              <div id="linegraph" style="width: 97%;margin-top: 50px;"></div>
            </app-linegraph>
          </div>
        </div>
      </p-tab>

    </p-tabs>
  </div>
</div>
