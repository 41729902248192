import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class PerivisionSchedule {

  private config: any = {
    margin_top: 15,
    margin_right: 15,
    margin_bottom: 25,
    margin_left: 80,

    barHeight: 20,
    barPadding: 8,
    showLabels: true
  };

  private numRooms: any = undefined;
  private rooms: any = undefined;
  private room_y: any = undefined;
  private room_height: any = undefined;
  private width: any = undefined;
  private height: any = undefined;
  private mindate: any = undefined;
  private maxdate: any = undefined;
  private svg: any = undefined;
  private sortGroup: any = undefined;
  private sortGap: any = undefined;
  private x: any = undefined;
  private y0: any = undefined;
  private rect_height: any = undefined;

  private roomcolors: Array<string> = ["#f4f5f7", "#e8e7e3"];
  private delaycolors: Array<string> = ["#aad490", "#eded8c", "#db9651", "#d67263"];

  private container: string;
  private scheduledata: any;
  private gapdata: any;
  private configuration: any;

  constructor(container: string, scheduledata: any, gapdata: any, configuration: any) {
    this.container = container;
    this.scheduledata = scheduledata;
    this.gapdata = gapdata;
    this.configuration = configuration;
    this.configure(this.configuration);

  }

  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    //alert(JSON.stringify(this.scheduledata));

    //Set min time value to 7am and max time value to 10pm
    //var casedate = this.scheduledata[0].CaseDate.split("-");
    //var year = parseInt(casedate[0]);
    //var month = parseInt(casedate[1]) - 1;
    //var day = parseInt(casedate[2]);

    //this.mindate = new Date(year, month, day, 7, 0);
    //this.maxdate = new Date(year, month, day, 22, 0);

    //code for using first and last predicted times as min and max times instead
    this.mindate = d3.min(this.scheduledata, (d: any) => {
      var mindate = new Date(d.Predicted_Wheels_In);
      mindate.setHours(mindate.getHours());
      mindate.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

      return mindate;
    });

    this.maxdate = d3.max(this.scheduledata, (d: any) => {      
      var maxdate = new Date(d.Predicted_Wheels_Out);
      maxdate.setHours(maxdate.getHours() + 1);
      maxdate.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

      return maxdate;
    });  

    //alert("mindate= " + this.mindate + " maxdate= "  + this.maxdate);   

    //Group data by room and then sort each room's data by time of predicted wheels in
    this.sortGroup = nest()
      .key(function (d: any) {
        return d.Room;
      }).sortKeys(d3.ascending)
      .sortValues(function (a, b) { return (a.Predicted_Wheels_In < b.Predicted_Wheels_In ? -1 : 1); })
      .entries(this.scheduledata);   

    //Group gap data by room
    this.sortGap = nest()
      .key(function (d: any) {
        return d.Room;
      }).sortKeys(d3.ascending)
      .sortValues(function (a, b) { return (a.GapStart < b.GapEnd ? -1 : 1); })
      .entries(this.gapdata);

    //get number of rooms with a case during the day
    this.numRooms = Object.keys(this.sortGroup).length;

    //calculate image width based on the number hours with an active case during the day
    this.width = Math.ceil(Math.abs(this.maxdate - this.mindate) / (1000 * 40)) + this.config.margin_left + this.config.margin_right;
    //this.width = 700;

    //calculate image height based on the number of rooms and the number of cases in each room
    this.height = this.config.margin_top + this.config.margin_bottom + this.numRooms * (this.config.barHeight + this.config.barPadding * 2);

    //get array of all room names
    this.rooms = values(this.sortGroup).map(function (d) { return d.key; });

    //room y coordinate in the graphic is determined based on the number of rooms that come before
    this.room_y = values(this.sortGroup).map((d: any) => {
      var numPriorRooms = Object.keys(this.sortGroup.filter(function (j) { return j.key < d.key; })).length;
      return this.config.margin_top + numPriorRooms * (this.config.barHeight + this.config.barPadding * 2);
    });

    //room height
    this.room_height = values(this.sortGroup).map((d: any) => {
      return this.config.barHeight + this.config.barPadding * 2;
    });

    //x-axis scales hour of the day
    this.x = d3.scaleTime()
      .range([this.config.margin_left, this.width - this.config.margin_right])
      .domain([this.mindate, this.maxdate]);

    //y-axis includes each room with at least one case in the day
    this.y0 = d3.scaleOrdinal()
      .range(this.room_y)
      .domain(this.rooms);

    //allocate enough space for each room on the y-axis to draw all cases in the room for the day
    this.rect_height = d3.scaleOrdinal()
      .range(this.room_height)
      .domain(this.rooms);

  }
  Render() {
    this.draw();
  }
  private draw() {
    this.svg = d3.select(this.container)
      .append('svg:svg')
      .attr('width', this.width)
      .attr('height', this.height + 30);

    //d3.select(this.container).select("div").remove();
    var _tooltip = d3.select(this.container)
      .append("div")
      .attr("class", "d3-div")
      .style("display", "none")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("font-weight", "bold")
      .style("padding", "10px")
      .style("width", "300px")
      .style("background", "rgba(0, 0, 0, 0.8)")
      .style("color", "#fff")
      .style("border-radius", "2px");

    //loop through each operating room to draw sections behind axes and phase times
    this.sortGroup.forEach((d, i) => {

      var roomsSection = this.svg.append("g")
        .attr("transform", "translate(0," + this.y0(d.key) + ")");

      //alternate background color of operating rooms to facilitate distinguishing between rooms
      roomsSection.append("rect")
        .attr("x", 5)
        .attr("width", this.width - 5)
        .attr("height", this.rect_height(d.key))
        .attr("fill", this.roomcolors[i % 2]);
    });

    //append an x-axis at the top - draw grid lines through graphic
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(0," + (this.config.margin_top) + ")")
      .call(d3.axisTop(this.x)
        .ticks(d3.timeHour.every(1))
        .tickSize(0)
        .tickFormat(d3.timeFormat("%H:%M"))
    );

    //append an x-axis at the bottom - draw grid lines through graphic
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(0," + (this.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x)
        .ticks(d3.timeHour.every(1))
        .tickSize(-this.height+this.config.margin_bottom+this.config.margin_top)
        .tickFormat(d3.timeFormat("%H:%M"))
      );

    //loop through each operating room to draw predicted scheduled times

    this.sortGroup.forEach((d, i) => {      

      var roomsSection = this.svg.append("g")
        .attr("transform", "translate(0," + this.y0(d.key) + ")");

      //label the room in the left axis area
      roomsSection.append("text")
        .attr("class", "workflowroomlabel")
        .attr("x", 10)
        .attr("y", this.rect_height(d.key) / 2 + 5)
        .text(d.key)
        ;     
     
      //rect for predicted case time
      roomsSection.append("g")
        .selectAll("g")
        .data(d.values)
        .enter().append("rect")
        .attr("x", (d) => { return this.x(new Date(d.Predicted_Wheels_In)); })
        .attr("y", this.config.barPadding)
        .attr("width", (d) => { if (d.Predicted_Wheels_In !== null && d.Predicted_Wheels_Out !== null) { return this.x(new Date(d.Predicted_Wheels_Out)) - this.x(new Date(d.Predicted_Wheels_In)); } else { return 0; } })
        .attr("height", this.config.barHeight)
        .attr("fill", (d) => { if (d.DelayMinutes > 60) return this.delaycolors[3]; else if (d.DelayMinutes > 40) return this.delaycolors[2]; else if (d.DelayMinutes >= 20) return this.delaycolors[1]; else return this.delaycolors[0]; })
        .on("mouseover", function (v) {

          d3.select(this)
            .style("cursor", "pointer")
          _tooltip.style("display", "block").style("opacity", 1);

        })
        .on("mouseout", function () {
          _tooltip.style("display", "none");
        })
        .on("mousemove", (d: any, val: any) => {
          let w = this.config.width - this.config.margin_left - this.config.margin_right;
          _tooltip.style("top", (d.layerY + 15) + 'px').style("left", (w < d.layerX + 250 ? d.layerX - 100 : d.layerX) + 'px')
            .style("display", "block").style("opacity", 1).style("height", "auto")
            .html(() => {
              return val.Comment;
            });
        });

      if (this.config.showLabels) {
        //text for Surgeon
        roomsSection.append("g")
          .selectAll("g")
          .data(d.values)
          .enter().append("text")
          .attr("x", (d) => { return this.x(new Date(d.Predicted_Wheels_In)) + 5; })
          .attr("y", this.config.barHeight)
          .attr("class", "phasetimetext")
          .style("font-size", 11)
          .style("font-weight", "bold")
          .text((d) => {
            if (d.Surgeon !== null) {
              var rectwidth = this.x(new Date(d.Predicted_Wheels_Out)) - this.x(new Date(d.Predicted_Wheels_In));
              return d.Surgeon.substring(0, rectwidth/6);
            }
            else {
              return "";
            }
          })
          .on("mouseover", function (v) {

            d3.select(this)
              .style("cursor", "pointer")
            _tooltip.style("display", "block").style("opacity", 1);

          })
          .on("mouseout", function () {
            _tooltip.style("display", "none");
          })
          .on("mousemove", (d: any, val: any) => {
            let w = this.config.width - this.config.margin_left - this.config.margin_right;
            _tooltip.style("top", (d.layerY + 15) + 'px').style("left", (w < d.layerX + 250 ? d.layerX - 100 : d.layerX) + 'px')
              .style("display", "block").style("opacity", 1).style("height", "auto")
              .html(() => {
                return val.Comment;
              });
          });
      }

    });

    //loop through each operating room to draw predicted gaps

    var pattern = this.svg.append("pattern")
      .attr('id', 'hash4_4')
      .attr('width', 8)
      .attr('height', 8)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('patternTransform', 'rotate(50)');

    pattern.append("rect")
      .attr('width', 2)
      .attr('height', 8)
      .attr('transform', 'translate(0,0)')
      .attr('fill', '#b6b7b8');

    this.sortGap.forEach((d, i) => {

      var roomsSection = this.svg.append("g")
        .attr("transform", "translate(0," + this.y0(d.key) + ")");      

      //rect for predicted gap
      roomsSection.append("g")
        .selectAll("g")
        .data(d.values)
        .enter().append("rect")
        .attr("x", (d) => { return this.x(new Date(d.GapStart)); })
        .attr("y", this.config.barPadding) 
        .attr("width", (d) => { if (d.GapStart !== null && d.GapEnd !== null) { return this.x(new Date(d.GapEnd)) - this.x(new Date(d.GapStart)); } else { return 0; } })
        .attr("height", this.config.barHeight)
        .attr("fill", "url(#hash4_4)")
        .attr("stroke", "#c3c5c7")
        .attr("stroke-width", 1)
        .on("mouseover", function (v) {

          d3.select(this)
            .style("cursor", "pointer")
          _tooltip.style("display", "block").style("opacity", 1);

        })
        .on("mouseout", function () {
          _tooltip.style("display", "none");
        })
        .on("mousemove", (d: any, val: any) => {
          let w = this.config.width - this.config.margin_left - this.config.margin_right;
          _tooltip.style("top", (d.layerY + 15) + 'px').style("left", (w < d.layerX + 250 ? d.layerX - 100 : d.layerX) + 'px')
            .style("display", "block").style("opacity", 1).style("height", "auto")
            .html(() => {
              return val.Comment;
            });
        });      
    });
  }
  public update(updatePerivisionData: any, newConfiguration: any) {

    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }

    this.scheduledata = updatePerivisionData;
    d3.select("" + this.container).select("svg").remove();
    this.draw();
  }
}
