import * as d3 from 'd3';
import { nest, values } from 'd3-collection';

export class BarLine {
  private config: any = {
    height: 250,
    width: 900,
    margin_left: 50,
    margin_right: 10,
    margin_top: 20,
    margin_bottom: 80,
    xaxis: "DailyTime",
    yaxis: "OccupiedRoomCount",
    ytext: "Mean # of rooms",
    legendtext: "Occupied"
  };

  private maxrooms: any = undefined;
  private starttime: any = undefined;
  private endtime: any = undefined;
  private x: any = undefined;
  private y: any = undefined;
  private svg: any = undefined;
  private line: any = undefined;

  private barcolors: Array<string> = ["#bbc3ce", "#8a95a3"];

  private container: string;
  private roomdata: any;
  private configuration: any;

  constructor(container: string, roomdata: any, configuration: any) {
    this.container = container;
    this.roomdata = roomdata;
    this.configuration = configuration;
    this.configure(this.configuration);
  }

  private configure(configuration: any) {
    var prop = undefined;
    for (prop in configuration) {
      this.config[prop] = configuration[prop];
    }

    //sort data by hour of the day
    this.roomdata.sort((x, y) => {
      return d3.ascending(x.HourIndex, y.HourIndex);
    })

    if (this.config.screen && this.config.screen == 'timestat') {

      //identify max number of rooms, allocated or occupied to use as the upper range of the y-axis
      this.maxrooms = d3.max(this.roomdata.map((d) => { return Math.max(d[this.config.yaxis], d.AllocatedRoomCount); }));

      //identify the first and last hours of the day with either allocated or occupied room count > 0
      this.starttime = d3.min(this.roomdata.map((d) => { if (d[this.config.yaxis] > 0 || d.AllocatedRoomCount > 0) return d.HourIndex; else return 23; }));
      this.endtime = d3.max(this.roomdata.map((d) => { if (d[this.config.yaxis] > 0 || d.AllocatedRoomCount > 0) return d.HourIndex; else return 0; }));

      //only draw bars for hours of the day with either allocated or occupied room count > 0
      this.roomdata = this.roomdata.filter((d) => { return d.HourIndex >= this.starttime && d.HourIndex <= this.endtime; });
    }
    else {
      //identify max number of rooms, allocated or occupied to use as the upper range of the y-axis
      this.maxrooms = d3.max(this.roomdata, (d: any) => { return d[this.config.yaxis]; });
    }
    //scale indicating time of day
    this.x = d3.scaleBand()
      .rangeRound([this.config.margin_left, this.config.width - (this.config.margin_left + this.config.margin_right)])
      .domain(this.roomdata.map((d) => { return d[this.config.xaxis]; }))
      .paddingOuter(0.2)
      .paddingInner(0.2);

    //scale for bars indicating count of rooms
    this.y = d3.scaleLinear()
      .range([this.config.height - this.config.margin_bottom, this.config.margin_top])
      .domain([0, this.maxrooms])

    //line function for line graph indicating allocated rooms
    this.line = d3.line()
      .x((d) => { return this.x(d['DailyTime']) + this.x.bandwidth() / 2; })
      .y((d) => { return this.y(d['AllocatedRoomCount']); });
  }

  Render() {
    this.draw();
  }
  private draw() {

    //create svg to to display bars
    this.svg = d3.select(this.container)
      .append("div")
      .classed("roomusebyhourcontainer", true)
      .append('svg:svg')
      .attr('viewBox', "0 0 " + this.config.width + " " + this.config.height)
      .attr('preserveAspectRatio', "xMinYMin meet")
      .classed("svg-content-responsive", true);

    //append the x-axis
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(0," + (this.config.height - this.config.margin_bottom) + ")")
      .call(d3.axisBottom(this.x)
        .tickSize(0)
      )
      .selectAll("text")
      .attr("transform", "rotate(40)")
      .style("text-anchor", "start");

    //append the y-axis
    this.svg.append("g")
      .attr("class", "workflowaxis")
      .attr("transform", "translate(" + this.config.margin_left + ",0)")
      .call(d3.axisLeft(this.y)
        .ticks(5)
        .tickSize(this.config.margin_left - this.config.width));

    //draw the title for y-axis
    this.svg.append("text")
      .attr("class", "roomusebyhourtext")
      .attr("text-anchor", "middle")
      .attr("transform", "translate(20," + ((this.config.height - this.config.margin_bottom) / 2) + ")rotate(-90)")
      .text(this.config.ytext);





    //draw bars indicating count of occupied rooms
    this.svg.append("g")
      .selectAll("rect")
      .data(this.roomdata)
      .enter().append("rect")
      .attr("fill", (d) => { return this.barcolors[0]; })
      .attr("x", (d) => { return this.x(d[this.config.xaxis]); })
      .attr("y", this.config.height - this.config.margin_bottom)
      .attr("width", (d) => { return this.x.bandwidth(); })
      .attr("height", 0)
      .transition()
      .duration(1500)
      .attr("y", (d) => {
        return this.y(d[this.config.yaxis]);
      })
      .attr("height", (d) => { return this.config.height - this.config.margin_bottom - this.y(d[this.config.yaxis]); });

      if (this.config.screen && this.config.screen == 'timestat' && this.roomdata.filter(r => r.AllocatedRoomCount > 0).length>0) {
      //draw lines indicating count of allocated rooms
      this.svg.append("path")
        .datum(this.roomdata)
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-width", 2.5)
        .attr("d", this.line);
    }

    //draw text with label
    this.svg.append("g")
      .selectAll("text")
      .data(this.roomdata)
      .enter().append("text")
      .attr("class", "phasetimetext")
      .attr("x", (d) => { return this.x(d[this.config.xaxis]) + this.x.bandwidth() / 2; })
      .attr("y", (d) => { return this.y(d[this.config.yaxis]) - 5; })
      .style("text-anchor", "middle")
      .text((d) => { return d[this.config.yaxis]; })

    //box for occupied rooms
    this.svg.append("rect")
      .attr("fill", this.barcolors[0])
      .attr("x", this.config.margin_left + 100)
      .attr("y", this.config.height - this.config.margin_bottom + 30)
      .attr("height", 15)
      .attr("width", 20);

    this.svg.append("text")
      .attr("class", "roomusebyhourtext")
      .attr("x", this.config.margin_left + 130)
      .attr("y", this.config.height - this.config.margin_bottom + 45)
      .text(this.config.legendtext);

    if (this.config.screen && this.config.screen == "timestat") {
      //add legend
      //line for allocated rooms
      this.svg.append("line")
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-width", 2.5)
        .attr("x1", this.config.margin_left)
        .attr("x2", this.config.margin_left + 20)
        .attr("y1", this.config.height - this.config.margin_bottom + 40)
        .attr("y2", this.config.height - this.config.margin_bottom + 40);

      this.svg.append("text")
        .attr("class", "roomusebyhourtext")
        .attr("x", this.config.margin_left + 30)
        .attr("y", this.config.height - this.config.margin_bottom + 45)
        .text("Allocated");

    }
    else {
      this.svg.append("text")
        .attr("class", "roomusebyhourtext")
        .attr("text-anchor", "middle")
        .attr("transform", "translate(" + (this.config.width / 2) + ", " + ((this.config.height - 15)) + ")")
        .text(this.config.xtext);
    }
  }

  public update(updateBarChartData: any, newConfiguration: any) {

    if (newConfiguration !== undefined) {
      this.configure(newConfiguration);
    }

    this.roomdata = updateBarChartData;
    d3.select("" + this.container).select("svg").remove();
    this.draw();
  }
}
