import { Component } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';

@Component({
  selector: 'app-metric-comparison',
  templateUrl: './metric-comparison.component.html',
  styleUrls: ['./metric-comparison.component.css']
})
export class MetricComparisonComponent {
  availableMetrics: Array<string> = [];
  metricsMultiSettings: IDropdownSettings = {};
  metrics: any;
  selectedMetrics: any;
  selectedMetricCategory: string;
  metricComparisonData: any;
  filters: Filters;
  metricComparisonGroupedData: any;
  headers: Array<any> = [];
  rows: Array<string> = [];
  metricName: string = "";
  metric: string = "";
  insightsData: any;
  fullbarData: any;
  title: string;
  configuration: any =
    {
      metric: 'NumCases',
      datefield: 'CaseDate',
      datefilter: 'Week',
      xlabel: 'Week',
      field: 'Count',
      group_by: 'Id',
      group_by_label: 'GroupBy',
      categories: undefined,
      selectedcategory: "All",
      ylabel: '% Cases'
    };
  header: string = "";


  constructor(private commonService: CommonService, private toastr: ToastrService) {
    this.metricsMultiSettings = {
      singleSelection: false,
      idField: 'Metric',
      textField: 'MetricName',
      itemsShowLimit: 5,
      enableCheckAll: false,
      allowSearchFilter: false
    };
  }

  OnSelect(eve: any) {
  }
  OnDeSelect(eve: any) {
  }
  ngOnInit(): void {
    this.metrics = undefined;
    this.selectedMetrics = undefined;

    this.selectedMetricCategory = "Operational";
    this.GetMetrics();
  }

  GetMetrics() {
    this.metrics = undefined;
    this.selectedMetrics = undefined;
    this.commonService.GetProviderComparisonMetrics(this.selectedMetricCategory).subscribe(d => {

      this.metrics = d["ProviderComparisonMetrics"]
        .filter(m => m.Metric.toLowerCase() != 'rvu' && m.Metric.toLowerCase() != 'inductiontime')
        .sort((x, y) => x.MetricName > y.MetricName ? 1 : -1);
      this.selectedMetrics = this.metrics.map(m => {
        m.selected = true;
        return m;
      });
    });
  }


  GetMetricComparisonData() {
    this.metricComparisonData = undefined;
    this.commonService.GetMetricComparisonData(this.filters.comptype, this.filters.facility, this.filters.surgeService, this.filters.fromDate, this.filters.toDate,this.filters.location).subscribe(d => {
      this.metricComparisonData = d["MetricComparisonData"];
      this.GetGroupedData();
    });
  }
  GetGroupedData() {
    let h = this.metricComparisonData.map(f => f.Header);

    this.headers = h.filter(function (v, i, self) {
      return i == self.indexOf(v);
    }).map(h => {
      let obj: any = {};
      obj.Header = h;
      obj.selected = true;
      return obj;
    })

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    let d = groupBy(this.metricComparisonData, 'Metric');
    this.rows = Object.keys(d);
    this.metricComparisonGroupedData = d;
    this.availableMetrics = Object.keys(this.metricComparisonGroupedData);

    for (var i = 0; i < this.availableMetrics.length; i++) {
      this.metricComparisonGroupedData[this.availableMetrics[i]] = this.metricComparisonGroupedData[this.availableMetrics[i]].map(m => { m.selected = true; return m; });
    }
  }
  onFilterHeader() {
    let selectedHeader = this.headers.filter(h => h.selected).map(h => h.Header);
    for (var i = 0; i < this.availableMetrics.length; i++) {
      this.metricComparisonGroupedData[this.availableMetrics[i]]
        = this.metricComparisonGroupedData[this.availableMetrics[i]].map(m => {
          m.selected = selectedHeader.indexOf(m.Header) > -1;
          return m;
        });
    }
  }

  OnFilter(e: Filters) {
    this.filters = e;
    this.GetMetricComparisonData();
  }

  GetMetricDescription(metric: string, metricName: string) {
    this.metricName = metricName;
    this.metric = undefined;
    this.commonService.GetMetricDescription(metric).subscribe(d => {
      this.metric = d;
    })
  }

  GetInsights(metric: string, metricName: string, header: string, title: string) {
    this.title = title;
    this.metricName = metricName;
    this.insightsData = undefined;
    this.header = header;
    let insight$;
    if (metric.toLowerCase() == 'utilization') {
      if (this.filters.comptype == "service") {
        insight$ = this.commonService.GetUtilInights(this.filters.facility, "All", "All", header, this.filters.fromDate, this.filters.toDate, metric)
      }
      else {
        insight$ = this.commonService.GetUtilInights(header, "All", "All", this.filters.surgeService, this.filters.fromDate, this.filters.toDate, metric)
      }
    }
    else {
      if (this.filters.comptype == "service") {
        insight$ = this.commonService.GetORSnapshotInsights(this.filters.facility, "All", "All", header, this.filters.fromDate, this.filters.toDate, metric)
      }
      else {
        insight$ = this.commonService.GetORSnapshotInsights(header, "All", "All", this.filters.surgeService, this.filters.fromDate, this.filters.toDate, metric)
      }
    }
    insight$.subscribe(d => {
      this.insightsData = d;

    });
    this.GetMetricBar(metric, metricName, header);
  }

  GetMetricBar(metric: string, metricName: string, header: string) {
    this.fullbarData = undefined;
    this.configuration.categories = undefined;

    if (metric.toLowerCase() == "utilization" || metric.toLowerCase() == "staticutilization") {
      this.commonService.GetUtilizationMetricBar('All', this.filters.fromDate, this.filters.toDate, metric).subscribe(d => {
        this.fullbarData = d["CaseVolumeBar"];
        this.configuration.ylabel = d["MetricComparisonType"];
        this.configuration.selectedcategory = this.fullbarData.length > 0 ? this.fullbarData[0]['GroupBy'] : "";
        var catagories = [];

        for (var i = 0; i < this.fullbarData.length; i++) {
          var fil = catagories.filter(f => f.GroupBy == this.fullbarData[i].GroupBy);
          if (fil.length == 0) {
            catagories.push({ "Id": this.fullbarData[i].Id, "GroupBy": this.fullbarData[i].GroupBy })
          }
        }
        this.configuration.datefield = 'DOS';
        this.configuration.datefilter = this.fullbarData[0].DateInterval;
        this.configuration.xlabel = this.fullbarData[0].DateInterval;
        //this.configuration.ylabel = "% Utilization";
        this.configuration.categories = catagories;

      });
    }
    else {
      let bar$
      if (this.filters.comptype == "service") {
        bar$ = this.commonService.GetMetricComparisonBar(this.filters.facility, this.filters.location, (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")), this.filters.fromDate, this.filters.toDate, metric, header, "Anesthesia Attending");
      }
      else {
        bar$ = this.commonService.GetMetricComparisonBar(header, this.filters.location, (this.filters.isRoomSelectAll ? "All" : this.filters.rooms.join("@")), this.filters.fromDate, this.filters.toDate, metric, this.filters.surgeService, "Anesthesia Attending");
      }
      bar$.subscribe(d => {
        this.fullbarData = d["CaseVolumeBar"];
        this.configuration.ylabel = d["MetricComparisonType"];
        this.configuration.selectedcategory = this.fullbarData.length > 0 ? this.fullbarData[0]['GroupBy'] : "";
        var catagories = [];
        for (var i = 0; i < this.fullbarData.length; i++) {
          var fil = catagories.filter(f => f.GroupBy == this.fullbarData[i].GroupBy);
          if (fil.length == 0) {
            catagories.push({ "Id": this.fullbarData[i].Id, "GroupBy": this.fullbarData[i].GroupBy })
          }
        }

        this.configuration.datefilter = this.fullbarData[0].DateInterval;
        this.configuration.xlabel = this.fullbarData[0].DateInterval;
        this.configuration.datefield = 'CaseDate';
        //if (metric.toLowerCase() == "numcases") {
        //  this.configuration.ylabel = "Count";
        //}
        //else {
        //  this.configuration.ylabel = "% Cases";
        //}
        this.configuration.categories = catagories;
      });
    }
  }
}
