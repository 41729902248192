import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Line } from '../Line';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.css']
})
export class LineComponent implements OnInit {

  @Input() container: string = ''
  @Input() lineData: any;
  @Input() configuration: any;
  @Input() scaleValue: any;
  @Output() myOutput: EventEmitter<Line> = new EventEmitter();

  line!: Line;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.line = new Line(this.container, this.lineData, this.configuration, this.scaleValue);
    this.line.Render();
    this.myOutput.emit(this.line);
  }

}
